import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Spinner,
  Button,
  Heading,
  Text,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { verifyEmailWithToken } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Header from "../landing/layout/Header";
import RegisterPromo from "./partials/RegisterPromo";
import {
  purple,
  white,
  textPrimary,
  textSecondary,
  textWhite,
  primary,
} from "../../utils/colors";
import { shade } from "polished";

const VerifyRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  // Extract token from URL query
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  // REDUX
  const dispatch = useDispatch();

  const userVerifyEmailToken = useSelector(
    (state) => state.userVerifyEmailToken
  );
  const { loading, error, success, userInfo } = userVerifyEmailToken;

  // HANDLERS
  const handleVerification = () => {
    dispatch(verifyEmailWithToken(token));
  };

  // USE EFFECT

  useEffect(() => {
    if (token) {
      dispatch(verifyEmailWithToken(token));
    } else {
      navigate("/register");
      toast({
        title: "Token error",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [token]);

  useEffect(() => {
    if (success) {
      toast({
        title: t("toasts.successRegisterVerification"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else if (error) {
      toast({
        title: t("toasts.errorRegisterVerification"),
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [success, error]);

  useEffect(() => {
    if (userInfo) {
      navigate("/account");
    }
  }, [userInfo]);

  // LOGS
  console.log("token", token);

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        minH="100vh"
        align="stretch"
        justify="flex-start"
        bg={white}
        p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
      >
        <Header
          bg="transparent"
          isRegister
          showPromo={true}
          showForm={true}
          isVerify
        />

        <RegisterPromo setShowPromo={true} setShowForm={true} />

        <Flex
          w={["100%", "100%", "70%"]}
          h={["100%", "100%", "100%"]}
          align="flex-start"
          justify="center"
          ml={["0%", "0%", "30%"]}
          direction="column"
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          p={["80px 0 0", "80px 0 0", "100px 0 0 100px"]}
          // display={["none", "none", "flex"]}
          // overflow={["auto", "auto", "initial"]}
          bg={[purple, purple, "initial"]}
          position={["fixed", "fixed", "relative"]}
          top={["0", "0", "initial"]}
          minH={["100vh", "100vh", "80vh"]}
        >
          {!loading ? (
            <>
              <Stack gap="0.5rem" p={["1.5rem 1.5rem", "0rem", "0rem"]}>
                <Heading
                  fontSize="1.875rem"
                  fontWeight="700"
                  color={[white, white, textPrimary]}
                >
                  {error
                    ? t("landingMegagen.verifyTokenErrorTitle")
                    : t("landingMegagen.verifyTokenTitle")}
                </Heading>

                <Text
                  fontSize="1rem"
                  color={[textWhite, textWhite, textSecondary]}
                  fontWeight="500"
                >
                  {error
                    ? t("landingMegagen.verifyTokenErrorText")
                    : t("landingMegagen.verifyTokenText")}
                </Text>
                {!error && (
                  <Flex
                    w="100%"
                    m="2rem auto"
                    align="center"
                    justify="flex-start"
                  >
                    <Button
                      variant="outline"
                      h="3.5rem"
                      borderRadius="100rem"
                      bg={primary}
                      color={white}
                      fontSize="md"
                      fontWeight="500"
                      align="center"
                      justifyContent="center"
                      minW="160px"
                      whiteSpace="nowrap"
                      isLoading={loading}
                      onClick={handleVerification}
                      isDisabled={!token}
                      _hover={{
                        bg: shade(0.3, primary),
                      }}
                    >
                      {t("landingMegagen.verifyButton")}
                    </Button>
                  </Flex>
                )}
              </Stack>
            </>
          ) : (
            <Flex
              w="100%"
              maxW="600px"
              h="100%"
              align="center"
              justify="center"
            >
              <Spinner size="xl" color={primary} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default VerifyRegistration;
