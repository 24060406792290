import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { white, newBorder, textPrimary } from "../../utils/colors";
import {
  CountrySelector,
  PhoneInput,
  CountrySelectorDropdown,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/style.css";

const FormControlPhone = ({
  label,
  value,
  onChange,
  isRequired,

  isError,
  errorMessage,
  w,
  defaultCountry,
}) => {
  const [isTouched, setIsTouched] = useState(false); // Local state to track interaction

  const initialValue = value || ""; // The initial value of the phone input

  const handlePhoneChange = ({ phone, inputValue, country }) => {
    if (!isTouched) return; // Ignore changes unless the input is touched
    if (inputValue.replace(/\D/g, "").length > country.dialCode.length) {
      onChange(phone, inputValue, country); // Update with the full phone number
    } else {
      onChange("", "", country); // Reset the phone input if only the country code is present
    }
  };

  const { inputValue, country, setCountry, handlePhoneValueChange, inputRef } =
    usePhoneInput({
      defaultCountry: defaultCountry || "lt",
      value: value || "",
      onChange: handlePhoneChange,
    });

  const handleInputChange = (e) => {
    setIsTouched(true); // Mark as touched on user interaction
    handlePhoneValueChange(e);
  };

  // Function to set caret at the end of the input value
  const setCaretAtEnd = (e) => {
    const inputLength = e.target.value.length;
    e.target.setSelectionRange(inputLength, inputLength);
  };

  const formControlRef = useRef();

  useEffect(() => {
    const countrySelectButton = formControlRef.current?.querySelector(
      ".react-international-phone-country-selector-button"
    );
    if (countrySelectButton) {
      countrySelectButton.tabIndex = -1;
    }
  }, []);

  // console.log("isError", isError);
  // console.log("isTouched", isTouched);
  // console.log("value", value);

  return (
    <>
      <Box w={w} ref={formControlRef}>
        <FormControl
          mb={4}
          isRequired={isRequired}
          isInvalid={isTouched && isError}
          w="100%"
          className={
            isError && isTouched
              ? "customPhone customPhone-error"
              : "customPhone"
          }
        >
          <FormLabel fontSize="sm" position="relative" color={textPrimary}>
            {label}
            {isError && isTouched && (
              <Box position="absolute" top={-1} right={0} zIndex={2}>
                <FormErrorMessage fontSize="xs">
                  {errorMessage}
                </FormErrorMessage>
              </Box>
            )}
          </FormLabel>

          <Flex tabIndex={-1} w="100%">
            <InputGroup
              size="lg"
              w="100%"
              bg={white}
              borderRadius="0.75rem"
              border={`1px solid ${newBorder}`}
              overflow="hidden"
              position="initial"
            >
              <InputLeftElement
                className="customPhone"
                w="56px"
                top="initial"
                left="initial"

                // position="relative"
              >
                <CountrySelector
                  selectedCountry={country.iso2}
                  onSelect={({ iso2 }) => setCountry(iso2)}
                  style={{
                    border: "none",
                    width: "100%",
                  }}
                />
              </InputLeftElement>

              <Input
                w="100%"
                bg="white"
                type="tel"
                color={textPrimary}
                value={inputValue}
                onChange={handleInputChange}
                defaultcountry={defaultCountry}
                ref={inputRef}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`0px solid ${newBorder}`}
                borderRadius="0rem"
                onFocus={setCaretAtEnd}
                p="0 0 0 66px"
                // _focusVisible={{
                //   zIndex: "1",
                //   borderColor: "primary",
                //   boxShadow: `0 0 0 1px primary`,
                // }}
              />
            </InputGroup>
          </Flex>
        </FormControl>
      </Box>
    </>
  );
};

export default FormControlPhone;
