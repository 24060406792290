import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Heading,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import {
  primary,
  newBorder,
  textPrimary,
  textSecondary,
  white,
  buttonRedText,
  buttonRed,
  greenVivid,
  buttonYellowText,
} from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import { useTranslation } from "react-i18next";
import MoreButton from "../../../../components/buttons/MoreButton";
// import ToastComponent from "../../../../components/partials/ToastComponent";
import ReportInfo from "./partials/ReportInfo";
import {
  getImplantFailureReportById,
  updateImplantFailureReportStatus,
  deleteImplantFailureReport,
} from "../../../../actions/reportActions";
import { shade } from "polished";
import dayjs from "dayjs";
import ConfirmRejectModal from "../../../../components/modals/ConfirmRejectModal";
import ConfirmApproveModal from "../../../../components/modals/ConfirmApproveModal";
import ConfirmClosedModal from "../../../../components/modals/ConfirmClosedModal";
import {
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET,
  REPORT_IMPLANT_FAILURE_DELETE_RESET,
} from "../../../../constants/reportConstants";

const SingleImplantFailureReport = () => {
  // GENERAL
  const id = useParams().id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureDetailsReducer = useSelector(
    (state) => state.reportImplantFailureDetails
  );

  const {
    error: errorImplantFailureReportDetails,
    loading: loadingImplantFailureReportDetails,
    report,
  } = reportImplantFailureDetailsReducer;

  const reportImplantFailureUpdateStatus = useSelector(
    (state) => state.reportImplantFailureUpdateStatus
  );

  const {
    error: errorImplantFailureUpdateStatus,
    loading: loadingImplantFailureUpdateStatus,
    success: successImplantFailureUpdateStatus,
  } = reportImplantFailureUpdateStatus;

  const reportImplantFailureDelete = useSelector(
    (state) => state.reportImplantFailureDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = reportImplantFailureDelete;

  // HANDLERS

  const rejectReportHandler = () => {
    const status = "rejected";
    console.log("id", id);
    console.log("userInfo._id", userInfo._id);
    console.log("cancelReason", cancelReason);
    console.log("status", status);

    if (cancelReason === "other") {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, otherReason, status)
      );
    } else {
      dispatch(
        updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
      );
    }
  };

  const approveReportHandler = () => {
    const status = "approved";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, cancelReason, status)
    );
  };

  const closeReportHandler = () => {
    const status = "closed";
    dispatch(
      updateImplantFailureReportStatus(id, userInfo._id, documentNumber, status)
    );
  };

  const deleteCourseHandler = () => {
    dispatch(deleteImplantFailureReport(id));
  };

  // USE EFFECT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!report || report._id !== id) {
      dispatch(getImplantFailureReportById(id));
    }
  }, [report, successImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.reportUpdated"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      dispatch({ type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET });
      dispatch(getImplantFailureReportById(id));
    } else if (errorImplantFailureUpdateStatus) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successImplantFailureUpdateStatus, errorImplantFailureUpdateStatus]);

  useEffect(() => {
    if (successDelete) {
      toast({
        title: t("implantFailureReports.reportDeleted"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
      navigate("/admin/reports/implant-failure-reports");
    } else if (errorDelete) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successDelete, errorDelete]);

  // LOGS
  // console.log("report", report);
  console.log("cancelReason", cancelReason);
  console.log("otherReason", otherReason);
  // console.log("id", id);

  // console.log("showCancelModal", showCancelModal);
  // console.log("showApproveModal", showApproveModal);
  // console.log("showCloseModal", showCloseModal);

  // console.log("report", report);
  // console.log("author", report.author);

  // RENDER
  return (
    <>
      {showCancelModal && (
        <ConfirmRejectModal
          report={report}
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          confirmAction={() => {
            rejectReportHandler();
            setShowCancelModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
          otherReason={otherReason}
          setOtherReason={setOtherReason}
        />
      )}

      {showApproveModal && (
        <ConfirmApproveModal
          report={report}
          isOpen={showApproveModal}
          onClose={() => setShowApproveModal(false)}
          confirmAction={() => {
            approveReportHandler();
            setShowApproveModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          isTextArea
        />
      )}

      {showCloseModal && (
        <ConfirmClosedModal
          report={report}
          isOpen={showCloseModal}
          onClose={() => setShowCloseModal(false)}
          confirmAction={() => {
            closeReportHandler();
            setShowCloseModal(false);
          }}
          documentNumber={documentNumber}
          setDocumentNumber={setDocumentNumber}
        />
      )}

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={t("implantFailureReports.singleTitle")}
              type={report.trackingNumber}
              //   loading={loading}
            >
              <StatusButton
                isUnclickable
                currentStatus={report.status}
                //  onUpdateStatus={handleUpdateStatus}
                options="report"
              />
              <MoreButton
                id={id}
                isBig
                deleteHandler={deleteCourseHandler}
                deleteLabel={t("implantFailureReports.deleteReport")}
                // editHandler={(e) => {
                //   e.stopPropagation();
                //   openEditSidePanel(courseId);
                // }}
              />
            </SinglePageTopBar>
            {!loadingImplantFailureReportDetails ? (
              <>
                <Flex direction="column" w="100%">
                  <Flex
                    gap={0}
                    p="2.5rem 2rem 1.5rem 2rem"
                    alignItems="flex-start"
                    justifyContent="center"
                    w="100%"
                  >
                    <Flex direction="column" w="100%">
                      <ReportInfo data={report} />
                    </Flex>
                    {/* <SinglePageImageWrapper
                    id={id}
                    borderRadius="2rem"
                    // image={course.image}
                    options="course"
                  /> */}
                  </Flex>

                  <Flex w="100%" p="0rem 2.5rem 4rem" direction="column">
                    <Flex justify="space-between" w="100%">
                      <Flex alignItems="center" m="0rem 0 1.5rem">
                        <Heading
                          fontSize="md"
                          fontWeight="600"
                          color={textPrimary}
                          mr="1rem"
                        >
                          {t("implantFailureReports.confirmReportTitle")}
                        </Heading>
                        <Flex p="0.35rem 0" alignItems="center">
                          {report.authorType === "employee" && (
                            <Flex direction="column">
                              <Flex align="center">
                                <Text
                                  fontSize="sm"
                                  color={textSecondary}
                                  fontWeight="500"
                                >
                                  {t("implantForm.author")}:
                                </Text>
                                <Text
                                  fontSize="sm"
                                  ml={1}
                                  color={buttonYellowText}
                                  fontWeight="500"
                                >
                                  {report.author.firstName}{" "}
                                  {report.author.lastName}
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      border={`1px solid ${newBorder}`}
                      borderRadius="1rem"
                      w="100%"
                      p="2rem 2rem"
                    >
                      <Flex
                        align="center"
                        justify="space-between"
                        w="100%"
                        gap="2rem"
                      >
                        {report.status === "pending" && (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color={textSecondary}
                            maxW="50%"
                          >
                            {t("implantFailureReports.reportStatusTextAdmin")}
                          </Text>
                        )}

                        {report.status === "closed" && (
                          <Stack
                            gap="0.25rem"
                            maxW="100%"
                            justify="center"
                            align="center"
                            textAlign="center"
                            w="100%"
                          >
                            <StatusButton
                              isUnclickable
                              currentStatus={report.status}
                              //  onUpdateStatus={handleUpdateStatus}
                              options="report"
                              isBig
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                              maxW="100%"
                              m="1rem 0 0"
                            >
                              {t("implantFailureReports.closedReportTextAdmin")}{" "}
                              -
                              <Text
                                as="span"
                                color={textPrimary}
                                fontWeight="500"
                                ml={1}
                                textTransform="capitalize"
                              >
                                {dayjs(report.statusDate).format(
                                  "D MMMM YYYY, HH:mm"
                                )}
                              </Text>
                            </Text>
                          </Stack>
                        )}

                        {report.status === "approved" && (
                          <Flex
                            gap="0.25rem"
                            maxW="100%"
                            justify="space-between"
                            align="center"
                            textAlign="center"
                            w="100%"
                            isBig
                          >
                            <Flex align="center" gap="0rem">
                              <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                                isBig
                              />
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                                m="0rem 0 0 1rem"
                              >
                                {t(
                                  "implantFailureReports.approvedReportTextAdmin"
                                )}{" "}
                                -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                            </Flex>
                            <Flex align="center" gap="1rem">
                              <Button
                                size="lg"
                                borderRadius="10rem"
                                bg={greenVivid}
                                color={textPrimary}
                                fontSize="sm"
                                fontWeight="500"
                                border={`1px solid ${newBorder}`}
                                // fontWeight="500"
                                _hover={{
                                  bg: shade(0.1, greenVivid),
                                }}
                                onClick={() => setShowCloseModal(true)}
                              >
                                {t("implantFailureReports.closeReport")}
                              </Button>
                            </Flex>
                          </Flex>
                        )}

                        {report.status === "rejected" && (
                          <Stack
                            gap="0.25rem"
                            maxW="100%"
                            justify="center"
                            align="center"
                            textAlign="center"
                            w="100%"
                          >
                            <StatusButton
                              isUnclickable
                              currentStatus={report.status}
                              //  onUpdateStatus={handleUpdateStatus}
                              options="report"
                              isBig
                            />

                            <Flex align="center" m="1rem 0 0">
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                              >
                                {report &&
                                report.cancelAuthor &&
                                report.cancelAuthor ? (
                                  <>
                                    <Text
                                      as="span"
                                      fontWeight="600"
                                      color={primary}
                                    >
                                      {report.cancelAuthor.firstName}{" "}
                                      {report.cancelAuthor.lastName}{" "}
                                    </Text>
                                    {t(
                                      "implantFailureReports.rejectedReportTextAdminAlt"
                                    )}
                                  </>
                                ) : (
                                  t(
                                    "implantFailureReports.rejectedReportTextAdmin"
                                  )
                                )}
                                -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                            </Flex>

                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                              maxW="100%"
                            >
                              {t("implantFailureReports.cancelReason")} -
                              <Text
                                as="span"
                                color={textPrimary}
                                fontWeight="500"
                                ml={1}
                                textTransform="capitalize"
                              >
                                {report.cancelReason}
                              </Text>
                            </Text>
                          </Stack>
                        )}

                        {report.status === "cancelled" && (
                          <Stack
                            gap="0.25rem"
                            maxW="100%"
                            justify="center"
                            align="center"
                            textAlign="center"
                            w="100%"
                          >
                            <StatusButton
                              isUnclickable
                              currentStatus={report.status}
                              //  onUpdateStatus={handleUpdateStatus}
                              options="report"
                              isBig
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                              maxW="100%"
                              m="1rem 0 0"
                            >
                              {t(
                                "implantFailureReports.cancelledReportTextAdmin"
                              )}{" "}
                              -
                              <Text
                                as="span"
                                color={textPrimary}
                                fontWeight="500"
                                ml={1}
                                textTransform="capitalize"
                              >
                                {dayjs(report.statusDate).format(
                                  "D MMMM YYYY, HH:mm"
                                )}
                              </Text>
                            </Text>
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                              maxW="100%"
                            >
                              {t("implantFailureReports.cancelReason")} -
                              <Text
                                as="span"
                                color={textPrimary}
                                fontWeight="500"
                                ml={1}
                                textTransform="capitalize"
                              >
                                {report.cancelReason}
                              </Text>
                            </Text>
                          </Stack>
                        )}
                        {report.status === "pending" && (
                          <Flex
                            align="center"
                            justify="flex-end"
                            gap="1.5rem"
                            w="50%"
                          >
                            <Button
                              size="lg"
                              borderRadius="10rem"
                              bg={primary}
                              color={white}
                              fontSize="sm"
                              // fontWeight="500"
                              border={`1px solid ${primary}`}
                              onClick={() => setShowApproveModal(true)}
                              _hover={{
                                bg: shade(0.3, primary),
                              }}
                            >
                              {t("implantFailureReports.approveExchange")}
                            </Button>

                            <Button
                              size="lg"
                              borderRadius="10rem"
                              bg={white}
                              color={buttonRedText}
                              fontSize="sm"
                              fontWeight="500"
                              border={`1px solid ${newBorder}`}
                              // fontWeight="500"
                              onClick={() => setShowCancelModal(true)}
                              _hover={{
                                bg: buttonRed,
                              }}
                            >
                              {t("implantFailureReports.rejectExchange")}
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p="8rem 2rem">
                <Spinner size="xl" color={primary} />
              </Flex>
            )}

            <Box p="0 2.5rem">
              {/* <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                isProfessionValid={true}
                disabledTabs={[4]}
                isSinglePage
              >
                <TabPanels p="0 0 2rem">
                  <TabPanel pb={4} px={0}>
                    1
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    2
                  </TabPanel>
                </TabPanels>
              </TabsComponent> */}
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default SingleImplantFailureReport;
