import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  listCourses,
  deleteCourse,
  updateCourseStatus,
} from "../../../../actions/courseActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, Text, Button } from "@chakra-ui/react";
import {
  COURSE_STATUS_UPDATE_RESET,
  COURSE_UPDATE_RESET,
  COURSE_CREATE_RESET,
  COURSE_DELETE_RESET,
} from "../../../../constants/courseConstants";
import {
  textSecondary,
  white,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/CoursesColumns"; // Adjust the path according to your file structure
import CreateCourse from "../sidepanels/CreateCourse";
import EditCourse from "../sidepanels/EditCourse";
import { useTranslation } from "react-i18next";
import CoursesCards from "../card/CoursesCards";
import ToastComponent from "../../../../components/partials/ToastComponent";

import CourseFilters from "./filters/CourseFilters";

const Courses = () => {
  const navigate = useNavigate();
  const toastComponent = ToastComponent();

  const { t } = useTranslation();

  const translate = (key) => t(key);

  const [courseId, setCourseId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchCity, setSearchCity] = useState({
    value: "",
    label: "All cities",
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const pageSizes = [4, 10, 25, 100];
  const [isCardView] = useState(false);
  const [setIsConfirmModalOpen] = React.useState(false);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [searchStatus, setSearchStatus] = useState(""); // Add this state for status filter
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const courseList = useSelector((state) => state.courseList);
  const { loading, error, courses, pages } = courseList;

  const courseDelete = useSelector((state) => state.courseDelete);
  const { success: successDelete } = courseDelete;

  const courseCreate = useSelector((state) => state.courseCreate);
  const { error: errorCreate, success: successCreate } = courseCreate;

  const courseUpdate = useSelector((state) => state.courseUpdate);
  const { success: successUpdate } = courseUpdate;

  const courseStatusUpdate = useSelector((state) => state.courseStatusUpdate);

  const { success: successStatusUpdate } = courseStatusUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // SORTING
  const [sortField, setSortField] = useState("startDate");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listCourses(searchTitle, 1, pageSize, sortField, sortOrder));
  };

  const handleApplyDateRange = () => {
    dispatch(listCourses(searchTitle, 1, pageSize, sortField, sortOrder));

    setIsFilterActive(true); // Update filter state
  };

  const handleStatusSelectChange = (selectedOption) => {
    setSearchStatus(selectedOption.value);
    dispatch(listCourses(searchTitle, 1, pageSize, sortField, sortOrder));
    setIsFilterActive(true); // Update filter state
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteCourseHandler = (courseId) => {
    dispatch(deleteCourse(courseId));

    if (courses.length === 1) {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    console.log("New Page Size:", newSize);
    setPageSize(newSize);
    setPage(1); // Reset the page to 1
  };

  const handleCitySelectChange = (selectedOption) => {
    setSearchCity(selectedOption);
    const selectedCityValue = selectedOption.value; // Get the selected city value
    dispatch(listCourses(searchTitle, 1, pageSize, selectedCityValue)); // Set page to 1
    setIsFilterActive(true); // Update filter state
  };

  const handleClearFilters = () => {
    setSearchCity({ value: "", label: "All cities" });
    setSearchStatus("");
    setFilterStartDate(null);
    setFilterEndDate(null);
    setIsFilterActive(false);

    dispatch(listCourses(searchTitle, 1, pageSize, sortField, sortOrder));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (successCreate) {
      toastComponent.showToast(t("toasts.courseCreate"), "success", 3000);
      dispatch({ type: COURSE_CREATE_RESET });
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch(listCourses(searchTitle, page, pageSize, sortField, sortOrder));
    } else if (successUpdate) {
      toastComponent.showToast(t("toasts.courseEdit"), "success", 3000);
      dispatch({ type: COURSE_UPDATE_RESET });
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch(listCourses(searchTitle, page, pageSize, sortField, sortOrder));
    } else if (successStatusUpdate) {
      toastComponent.showToast(t("toasts.courseStatus"), "success", 3000);
      dispatch({ type: COURSE_STATUS_UPDATE_RESET });
      document.body.classList.remove("no-scroll");
    } else if (successDelete) {
      toastComponent.showToast(t("toasts.courseDelete"), "success", 3000);
      dispatch({ type: COURSE_DELETE_RESET });
    } else {
      dispatch(listCourses(searchTitle, page, pageSize, sortField, sortOrder));
    }
  }, [
    dispatch,
    toast,
    userInfo,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    errorCreate,
    successDelete,
    successStatusUpdate,
    sortField,
    sortOrder,
  ]);

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setCourseId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setCourseId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setCourseId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    navigate(`/admin/academy/courses/${value}`);
  };

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.adminCourses")}</title>
        <meta name="description" content={t("pageDescription.adminCourses")} />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("course.buttonLabel")}
        noBorder={true}
      >
        <CreateCourse />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("course.buttonEditLabel")}
        noBorder={true}
      >
        <EditCourse courseId={courseId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("course.courses")}
                onChange={onChangeSearchTitle}
                placeholder={t("course.search")}
                searchName={searchTitle}
                buttonLabel={t("course.buttonLabel")}
                buttonHandler={openSidePanelModal}
                isFixedTitle
              >
                {/* <Box mr={6}>
                  <Switch
                    id="card-view-switch"
                    colorScheme="teal"
                    isChecked={isCardView}
                    onChange={toggleCardView}
                  />
                </Box> */}
                {/* <Button
                  leftIcon={<FilterIcon />}
                  bg={white}
                  border={`1px solid ${isFilterActive ? primary : newBorder}`} // Apply active class if isFilterActive is true
                  variant="solid"
                  size="lg"
                  borderRadius="10rem"
                  mr={6}
                  onClick={() => setIsFilterVisible(!isFilterVisible)}
                >
                  <Text fontSize="16px" color={textSecondary} lineHeight="1">
                    {t("common.filter")}
                  </Text>
                </Button> */}
              </Table.Top>
            </Box>
            <FilterContainer isOpen={isFilterVisible}>
              <CourseFilters
                searchCity={searchCity}
                handleCitySelectChange={handleCitySelectChange}
                searchStatus={searchStatus}
                handleStatusSelectChange={handleStatusSelectChange}
                filterStartDate={filterStartDate}
                setFilterStartDate={setFilterStartDate}
                filterEndDate={filterEndDate}
                setFilterEndDate={setFilterEndDate}
                handleApplyDateRange={handleApplyDateRange}
                handleClearFilters={handleClearFilters}
                isOpen={isFilterVisible}
                translate={translate}
              />
            </FilterContainer>

            {isCardView ? (
              <CoursesCards courses={courses} translate={translate} />
            ) : (
              <>
                <Table.Container
                  columns={columns(
                    dispatch,
                    openEditSidePanelModal,
                    translate,
                    updateCourseStatus,
                    deleteCourseHandler,
                    page,
                    pageSize,
                    handleSortChange
                  )}
                  data={courses}
                  loading={loading}
                  error={error}
                  navigate={navigate}
                  navigateTo="/admin/academy/courses"
                  noItemsButtonHandler={openSidePanelModal}
                  noItemsTitle={t("noItems.noCourses")}
                  noItemsDescription={t("noItems.noCoursesText")}
                  noItemsButtonLabel={t("noItems.noCoursesButton")}
                  onRowClick={handleRowClick}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />

                <Table.Pagination
                  page={page}
                  pages={pages}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  gotoPage={gotoPage}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                  pageSizes={pageSizes}
                  navigate={navigate}
                />
              </>
            )}
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${white};
  border: 0px solid ${newBorder};
  border-radius: 10px;
  margin-bottom: 0rem;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  max-height: ${(props) => (props.isOpen ? "500px" : "0")};
  transition: opacity 0.3s ease, all 0.3s ease;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  padding: ${(props) => (props.isOpen ? "0rem 2rem 1.5rem" : "0 2rem")};
`;

export default Courses;
