import axios from "axios";
import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
} from "../constants/clientConstants";
import { logout } from "./userActions";

export const listClients =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = ""
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLIENT_LIST_REQUEST });

      // Constructing the query string
      let query = `/api/clients?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      const { data } = await axios.get(query);

      dispatch({
        type: CLIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listClientDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/clients/${id}`);

    dispatch({
      type: CLIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateClient = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/clients/${client._id}`,
      client,
      config
    );

    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: CLIENT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }

    dispatch({
      type: CLIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createClient =
  (
    image,
    clientName,
    companyName,
    email,
    phone,
    phoneAlt,
    companyCode,
    vatCode,
    employee,
    website,
    street,
    city,
    zip,
    country,
    state,
    repFirstName,
    repLastName,
    repPhone,
    repEmail
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/clients`,
        {
          image,
          companyName,
          clientName,
          companyCode,
          vatCode,
          street,
          zip,
          city,
          country,
          state,
          phone,
          phoneAlt,
          email,
          website,
          repFirstName,
          repLastName,
          repPhone,
          repEmail,
          employee,
        },
        config
      );

      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: CLIENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteClient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/clients/${id}`, config);

    dispatch({
      type: CLIENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
};
