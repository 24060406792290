import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbUserBolt,
  TbDeviceMobile,
  TbMail,
  TbMapPin,
  TbBuilding,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { shade, tint } from "polished";
import { Swiper, SwiperSlide } from "swiper/react";
import TooltipText from "../../../../../components/partials/TooltipText";
import { TbWallet } from "react-icons/tb";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const AttendeeInfo = ({ attendee }) => {
  const { t } = useTranslation();

  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const [clientPlace, setClientPlace] = useState("");
  const [clientCity, setClientCity] = useState("");

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  // Define the titles for different slides
  const slideTitles = [
    t("attendee.attendeeDetails"),
    t("attendee.clientDetails"),
  ];

  useEffect(() => {
    // Check if attendee has an address and if the address array is not empty
    if (attendee?.address && attendee.address.length > 0) {
      const address = attendee.address[0]; // Assuming you want to display the first address
      const streetZip =
        address.street && address.zip
          ? `${address.street}, ${address.zip}`
          : "-";
      const cityValue = address.city || "-";

      setPlace(streetZip);
      setCity(cityValue);
    } else {
      // Set default values if no address is available
      setPlace("-");
      setCity("-");
    }
  }, [attendee]);

  useEffect(() => {
    // Check if attendee has client and if so get client address
    if (attendee?.client?.address && attendee.client.address.length > 0) {
      const address = attendee.client.address[0]; // Assuming you want to display the first address
      const streetZip =
        address.street && address.zip
          ? `${address.street}, ${address.zip}`
          : "-";
      const cityValue = address.city || "-";
      setClientPlace(streetZip);
      setClientCity(cityValue);
    } else {
      // Set default values if no address is available
      setClientPlace("-");
      setClientCity("-");
    }
  }, [attendee]);

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // console.log("attendee in attendee info", attendee);

  const infoArray = [
    {
      label: "Birthdate",
      value: (
        <>
          <TooltipText
            icon="TbCalendarEvent"
            label={t("form.birthDate")}
            value={
              attendee.birthDate
                ? `${dayjs(attendee.birthDate).format(
                    "D MMMM YYYY"
                  )} (${dayjs().diff(attendee.birthDate, "years")}m.)`
                : "-"
            }
          />
        </>
      ),
    },
    {
      label: "Role",
      value: (
        <TooltipText
          icon="TbUserBolt"
          label={t("form.profession")}
          value={attendee.profession?.name || "-"}
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.phone")}
          value={attendee.phone || "-"}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <TooltipText
          icon="TbMail"
          label={t("form.email")}
          value={attendee.email || "-"}
        />
      ),
    },
    {
      label: "Address",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={place || "-"}
        />
      ),
    },
    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={city || "-"}
        />
      ),
    },
  ];

  const clientArray = [
    {
      label: "CompanyName",
      value: (
        <>
          <TooltipText
            icon="TbBriefcase"
            label={t("form.clientCompanyName")}
            value={attendee.client?.companyName || "-"}
          />
        </>
      ),
    },
    {
      label: "Name",
      value: (
        <TooltipText
          icon="TbBuildingHospital"
          label={t("form.clientName")}
          value={attendee.client?.clientName || "-"}
        />
      ),
    },
    {
      label: "CompanyCode",
      value: (
        <TooltipText
          icon="TbBarcode"
          label={t("form.clientCompanyCode")}
          value={attendee.client?.companyCode || "-"}
        />
      ),
    },
    {
      label: "VatNumber",
      value: (
        <TooltipText
          icon="TbClipboardList"
          label={t("form.clientVatNumber")}
          value={attendee.client?.vatCode || "-"}
        />
      ),
    },
    {
      label: "Address",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.clientAddress")}
          value={clientPlace || "-"}
        />
      ),
    },
    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.clientCity")}
          value={clientCity || "-"}
        />
      ),
    },
  ];

  console.log("attendee in attendee info", attendee);

  return (
    <>
      <Box w="45%" pr={8}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading fontSize="md" fontWeight="600" color={textPrimary}>
            {slideTitles[currentSlide]}
          </Heading>
          <Box>
            <IconButton
              className="custom-swiper-button custom-swiper-button-prev"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowBackIcon />}
              borderRadius="10.5rem"
              opacity="0.8"
              _hover={{
                background: backgroundLight,
              }}
            />
            <IconButton
              className="custom-swiper-button custom-swiper-button-next"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowForwardIcon />}
              borderRadius="10.5rem"
              ml="0.5rem"
              opacity="0.5"
              _hover={{
                background: backgroundLight,
              }}
            />
          </Box>
        </Flex>
        <Box>
          <Swiper
            navigation={{
              prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
              nextEl: ".custom-swiper-button-next", // Selector for your custom next button
            }}
            slidesPerView={1}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Navigation]}
            onSlideChange={handleSlideChange}
            className="courseInfoSlider"
          >
            <SwiperSlide>
              <Box>
                {infoArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box>
                {clientArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>

      {/* <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {t("attendee.attendeeDetails")}
        </Heading>
        <Box>
       
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
          />
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box>
        {infoArray.map((item, index) => (
          <Flex key={index} alignItems="center">
            {item.value && (
              <Flex p="0.35rem 0" alignItems="center">
                {item.value}
              </Flex>
            )}
          </Flex>
        ))}
      </Box>
    </Box> */}
    </>
  );
};

export default AttendeeInfo;
