import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import BarChart from "../../../../components/stats/BarChart";
import {
  buttonGreen,
  primary,
  textPrimary,
  textSecondary,
  greenVivid,
  buttonRedNew,
  buttonRed,
  buttonYellow,
} from "../../../../utils/colors";
import { getReportStatusCounts } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";

const ReportsStatusCount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading, data, error } = useSelector(
    (state) => state.dashboardReportStatusCounts
  );

  const { total, countsByStatus } = data;

  useEffect(() => {
    dispatch(getReportStatusCounts());
  }, [dispatch]);

  console.log("total", total);
  console.log("countsByStatus", countsByStatus);

  // CUSTOM
  const statusOrder = [
    "pending",
    "approved",
    "rejected",
    "cancelled",
    "closed",
  ];
  const statusColors = {
    Pending: buttonYellow,
    Approved: buttonGreen,
    Rejected: buttonRedNew,
    Cancelled: buttonRed,
    Closed: greenVivid,
  };

  if (loading) {
    return (
      <Flex>
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Link to="/admin/reports/implant-failure-reports">
          <Text
            color={textPrimary}
            fontSize="sm"
            fontWeight="500"
            _hover={{
              color: primary,
            }}
          >
            {t("dashboard.reportsStatusCountTitle")}
            <Text
              as="span"
              fontWeight="500"
              color={textSecondary}
              ml={1}
              fontSize="sm"
            >
              {total}
            </Text>
          </Text>
        </Link>

        {/* <Button
          variant="link"
          color={textSecondary}
          size="xs"
          as={Link}
          to="/admin/reports"
        >
          {t("dashboard.viewAll")}
        </Button> */}
      </Flex>
      <BarChart
        data={countsByStatus}
        statusOrder={statusOrder}
        statusColors={statusColors}
        backgroundLight="gray.200"
        textSecondary="gray.500"
        white="white"
      />
    </Flex>
  );
};

export default ReportsStatusCount;
