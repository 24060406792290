import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";
import successAnimation from "../../../../../assets/lottie/success.json";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Text,
  Heading,
  Button,
  Tooltip,
  Image,
  Icon,
  Stack,
  background,
} from "@chakra-ui/react";
import { FaCcStripe } from "react-icons/fa";
import dayjs from "dayjs";
import {
  textPrimary,
  textSecondary,
  primary,
  white,
  newBorder,
  secondary,
  buttonRed,
  buttonRedText,
  backgroundLight,
} from "../../../../../utils/colors";
import { shade } from "polished";

const StepSuccessAdmin = ({
  setActiveStep,
  userTrackingNumber,
  newFormHandler,
  clientEmail,
  close,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showAnimation, setShowAnimation] = useState(true);
  const [counter, setCounter] = useState(5); // Initial counter state set to 5 seconds

  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  // const isSameDay = dayjs(course.startDate).isSame(
  //   dayjs(course.endDate),
  //   "day"
  // );

  useEffect(() => {
    // If counter is greater than 0, decrement it every second
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      close(); // Call the close function once the counter reaches zero
    }
  }, [counter, close]);

  return (
    <>
      <Flex w="100%" maxW="600px" direction="column">
        <Flex w="100%" direction="column">
          <Flex
            w="100%"
            align="center"
            justify="center"
            gap="1rem"
            textAlign="center"
          >
            <Stack gap="0rem" m="6rem auto 2rem" maxW="100%" textAlign="center">
              {showAnimation && (
                <Flex m="0 auto" maxW="80px">
                  {View}
                </Flex>
              )}
              <Heading
                fontSize="1.75rem"
                fontWeight="600"
                color={textPrimary}
                m="1rem 0 0.25rem"
              >
                {t("implantForm.stepSuccessTitle")}
              </Heading>

              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {t("implantForm.stepSuccessTextAdmin")} -{" "}
                <Text as="span" color={primary}>
                  {clientEmail}
                </Text>
              </Text>
            </Stack>
          </Flex>

          <Flex
            direction="column"
            minW="300px"
            maxW="80%"
            align="center"
            justify="center"
            w="100%"
            m="0rem auto 0"
          >
            <Flex
              w="100%"
              p="1rem 1.5rem"
              border={`1px solid ${newBorder}`}
              borderRadius="1rem"
            >
              <Flex
                w="100%"
                align="center"
                justify="center"
                direction="column"
                textAlign="center"
              >
                <Text
                  color={textSecondary}
                  fontWeight="500"
                  fontSize="sm"
                  lineHeight="1.2"
                  m="0 0 0.75rem"
                >
                  {t("implantForm.formRefCode")}
                </Text>
                <Text
                  color={textPrimary}
                  fontWeight="600"
                  fontSize="lg"
                  lineHeight="1.2"
                >
                  {userTrackingNumber}
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify="center"
              align="center"
              m="3rem auto 3rem"
              w="100%"
              gap="1rem"
            >
              {/* <Button
                bg={backgroundLight}
                color={textSecondary}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                px="3rem"
                _hover={{ bg: shade(0.1, backgroundLight) }}
                onClick={newFormHandler}
              >
                {t("implantForm.startNewForm")}
              </Button> */}

              <Button
                bg={backgroundLight}
                color={textPrimary}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                px="3rem"
                _hover={{ bg: shade(0.1, backgroundLight) }}
                onClick={close}
              >
                OK{" "}
                <Text as="span" fontWeight="500" color={textSecondary} ml={1}>
                  ({counter})
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default StepSuccessAdmin;
