import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Image,
  Spinner,
  Stack,
  useToast,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { icons } from "../../../../../utils/icons";
import styled from "@emotion/styled";
import {
  backgroundLight,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";

const ImageUpload = ({ id, currentImage, image, setImage, label }) => {
  const [uploading, setUploading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);

      // Display success toast
      toast({
        title: t("imageUpload.successTitle"),
        // description: t("imageUpload.successDescription"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setUploading(false);

      // Display error toast
      toast({
        title: t("imageUpload.errorTitle"),
        // description: t("imageUpload.errorDescription"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImage("");
    toast({
      title: t("toasts.imageRemoveSuccess"),
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={`1px solid ${newBorder}`}
      borderRadius="0.75rem"
      p={8}
      direction="column"
    >
      <Stack spacing={0} w="100%">
        <Flex
          w="60%"
          h="140px"
          borderRadius="0.75rem"
          overflow="hidden"
          bg={backgroundLight}
          display="flex"
          align="center"
          justify="center"
          m="0 auto"
          onClick={() => {
            document.getElementById(id).click();
          }}
          _hover={{
            cursor: "pointer",
          }}
          role="group"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {image ? (
            <Image
              src={currentImage || image || icons.noImage}
              objectFit="cover"
              h="100%"
              w="100%"
            />
          ) : (
            <Image
              h="2rem"
              w="2rem"
              src={icons.noImage}
              transition="transform 0.2s ease-in-out"
              transform={isHovering ? "scale(1.2)" : "scale(1)"}
            />
          )}

          {uploading && <Spinner />}
        </Flex>

        <Flex
          align="center"
          justify="center"
          direction="column"
          gap={0}
          w="100%"
          my={3}
          textAlign="center"
        >
          <Text color={textPrimary} fontSize="sm" fontWeight="500">
            {image
              ? t("imageUpload.changeImage")
              : label
              ? label
              : t("imageUpload.title")}
          </Text>
          <Text color={textSecondary} fontSize="xs" fontWeight="400">
            {image ? image : t("imageUpload.description")}
          </Text>
        </Flex>
      </Stack>

      <HStack>
        <UploadButton htmlFor={id} className="custom-file-upload">
          <Icon2 src={icons.upload} />
          {image ? t("imageUpload.change") : t("imageUpload.browse")}
        </UploadButton>
        {image && (
          <Button
            fontSize="13px"
            p="10px 1.5rem"
            onClick={handleRemoveImage}
            borderRadius="12px"
            bg={backgroundLight}
            color={textSecondary}
            _hover={{ bg: "#ddd", color: "#000" }}
          >
            {t("imageUpload.remove")}
          </Button>
        )}
        <input
          id={id}
          type="file"
          onChange={uploadFileHandler}
          style={{ display: "none" }}
        />
      </HStack>
    </Flex>
  );
};
const Icon2 = styled.img`
  height: 12px;
  margin-right: 8px;
`;

const UploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px 1.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  background: ${backgroundLight};
  color: ${textSecondary};
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #ddd;
    color: #000;
  }
`;

export default ImageUpload;
