import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { listSpeakers } from "../../actions/speakerActions";
import OptionsOutsideSelect from "./OptionsOutsideSelect";

const MultiSpeakerSelectNoInactiveNoCurrent = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  placeholderText,
  title,
}) => {
  const dispatch = useDispatch();

  const speakerList = useSelector((state) => state.speakerList);
  const { speakers } = speakerList;

  const [speakerOptions, setSpeakerOptions] = useState([]);

  console.log("currentOptions", currentOptions);

  const transformedCurrentOptions = currentOptions.map((option) => ({
    value: option._id,
    label: option.name,
    image: option.image,
  }));

  console.log("transformedCurrentOptions", transformedCurrentOptions);

  useEffect(() => {
    if (speakers && speakers.length > 0) {
      const activeSpeakers = speakers.filter(
        (speaker) => speaker.status === "active"
      );

      console.log("activeSpeakers", activeSpeakers);

      // Transform currentOptions to match options structure
      const transformedCurrentOptions = currentOptions.map((option) => ({
        value: option._id,
        label: option.name,
        image: option.image,
      }));

      // Create options for active speakers, excluding transformedCurrentOptions
      const options = activeSpeakers
        .filter(
          (s) =>
            !transformedCurrentOptions.some(
              (current) => current.value === s._id
            )
        )
        .map((s) => ({
          value: s._id,
          label: s.name,
          image: s.image,
        }));

      console.log("options", options);

      setSpeakerOptions([...options]);
    }
  }, [speakers, currentOptions]);

  return (
    <div>
      <OptionsOutsideSelect
        onChange={onChange}
        isMulti
        options={speakerOptions}
        value={value}
        title={title}
        isSelectedHidden={isSelectedHidden}
        placeholderText={placeholderText}
      />
    </div>
  );
};

export default MultiSpeakerSelectNoInactiveNoCurrent;
