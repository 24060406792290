import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  IconButton,
  Select,
  HStack,
} from "@chakra-ui/react";
import {
  textPrimary,
  newBorder,
  primary,
  textSecondary,
} from "../../utils/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { t } from "i18next";
import dayjs from "dayjs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const FormControlDatePickerRangeDouble = ({
  type,
  isRequired,
  label,
  value,
  placeholder,
  onChange,

  isError,
  w,
  errorMessage,
  onOpen,
  onClose,
  isSmall,
  minDate,
  startDate,
  endDate,
  setDefaultStartTime,
  startDateTouched,
  endDateTouched,
  setStartDateTouched,
  setEndDateTouched,
}) => {
  // CONSTANTS

  // Generate a range of years from 5 years before to 5 years after the current year
  const currentYear = dayjs().year();
  const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);

  const months = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];

  // HANDLERS

  const handleStartDateChange = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0);

    // Check if endDate is not already set or is before the new startDate
    // If true, set endDate to match the startDate
    const isEndDateBeforeStartDate =
      endDate && new Date(endDate) < adjustedDate;
    if (!endDate || isEndDateBeforeStartDate) {
      onChange(adjustedDate, adjustedDate); // Update both startDate and endDate with adjustedDate
    } else {
      onChange(adjustedDate, endDate); // Only update startDate, keep existing endDate
    }
    // Call the callback function to set the startTime to 08:00
    if (setDefaultStartTime) {
      setDefaultStartTime();
    }

    setStartDateTouched(true);
  };

  const handleEndDateChange = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(23, 59, 59);

    // If startDate has not been set, set startDate to the same as endDate
    if (!startDate || new Date(startDate) > adjustedDate) {
      onChange(adjustedDate, adjustedDate); // Update both startDate and endDate
      if (setDefaultStartTime) {
        setDefaultStartTime(); // Optionally set default start time to 08:00
      }
    } else {
      onChange(startDate, adjustedDate); // Only update endDate, keep existing startDate
    }

    setEndDateTouched(true);
  };

  // // LOGS

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  // console.log("StartDate in DatePicker:", startDate);
  // console.log("EndDate in DatePicker:", endDate);

  return (
    <>
      <FormControl
        mb={4}
        isRequired={isRequired}
        isInvalid={startDateTouched && isError}
        w="100%"
        className="customDatePicker"
      >
        <FormLabel
          fontSize={isSmall ? "xs" : "sm"}
          position="relative"
          color={textPrimary}
        >
          {label}
          {isError && startDateTouched && (
            <FormErrorMessage
              fontSize="xs"
              position="absolute"
              top={-1}
              right={0}
              zIndex={2}
            >
              {errorMessage}
            </FormErrorMessage>
          )}
        </FormLabel>

        <HStack spacing={2} mb={0} w="100%">
          <Box className="popoverDatePicker singleDatepicker" mr={2} w="50%">
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              shouldCloseOnSelect={true}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={minDate ? new Date(minDate) : null}
              //   maxDate={endDate}
              dateFormat="yyyy-MM-dd" // Specify the format here
              placeholderText={t("placeholder.startDate")}
              onBlur={(e) => {
                e.preventDefault();
                console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              onClickOutside={() => setStartDateTouched(true)}
              // onCalendarClose={(e) => {
              //   e.preventDefault();
              //   console.log("Calendar closed");
              // }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
          <Box className="popoverDatePicker singleDatepicker" w="50%">
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              //   maxDate={new Date()} // or another max date
              onClickOutside={() => setStartDateTouched(true)}
              placeholderText={placeholder}
              dateFormat="yyyy-MM-dd"
              onBlur={(e) => {
                e.preventDefault();
                console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
        </HStack>
      </FormControl>
    </>
  );
};

export default FormControlDatePickerRangeDouble;
