import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { icons } from "../../../../utils/icons";
import {
  primary,
  white,
  newDarkBlue,
  backgroundLight,
  textSecondary,
  secondary,
  textPrimary,
} from "../../../utils/colors";
import LanguageButton from "../components/LanguageButton";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import logonew2 from "../../../assets/icons/logonew2.svg";
import logonew2white from "../../../assets/icons/logonew2white.svg";
import { shade } from "polished";
import { FaCaretDown } from "react-icons/fa6";
import { logout } from "../../../actions/userActions";
import { Slant as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

// Create motion components (if not already defined elsewhere)
const MotionFlex = motion(Flex);

const Header = ({ bg, isRegister, showPromo, showForm, isVerify }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const isHomeActive = location.pathname === "/";
  const isReportActive = location.pathname === "/implant-fail-form";
  const isEventActive = location.pathname === "/events";
  const isAccountActive = location.pathname.startsWith("/account");

  // STATE
  const [menuOpen, setMenuOpen] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // HANDLERS
  const openMobileMenuHandler = () => {
    setMobileMenuOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeMobileMenuHandler = () => {
    setMobileMenuOpen(false);
    document.body.style.overflow = "auto";
  };

  // Define animation variants for the mobile menu
  const mobileMenuVariants = {
    hidden: {
      y: "-100%", // Start from above the screen
      opacity: 0,
    },
    visible: {
      y: 0, // End at the top of the screen
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
    exit: {
      y: "-100%", // Exit to above the screen
      opacity: 0,
    },
  };

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  // LANGUAGE

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  // HANDLERS

  const logoutHandler = () => {
    dispatch(logout());
    console.log("Logout");
  };

  const profileHandler = () => {
    navigate("/account");
    closeMobileMenuHandler();
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  // console.log("location.pathname", location.pathname);
  // console.log("isAccountActive", isAccountActive);

  return (
    <>
      {/* MOBILE MENU */}
      <MotionFlex
        initial="hidden"
        animate={mobileMenuOpen ? "visible" : "hidden"}
        exit="exit"
        variants={mobileMenuVariants}
        bg={white}
        w="100%"
        minH="100vh"
        position={["fixed", "fixed", "fixed"]}
        top="0"
        left="0"
        zIndex="10"
        h="100%"
        maxH={["100%", "100%", "100vh"]}
        overflowY="auto"
      >
        <Flex w="100%" direction="column" p="80px 0 0">
          <Flex
            align="center"
            justify="flex-start"
            gap="1.5rem"
            direction="column"
            p="6rem 0"
            w="100%"
          >
            <Button
              h="3.5rem"
              fontSize="lg"
              color={isHomeActive ? textPrimary : textSecondary}
              fontWeight="500"
              transition="all 0.2s ease"
              _hover={{
                color: primary,
              }}
              bg="transparent"
              px="3rem"
              borderRadius="10rem"
              flexShrink="0"
              onClick={() => {
                navigate("/");
                closeMobileMenuHandler();
              }}
            >
              {t("landingMegagen.menuHome")}
            </Button>

            <Button
              h="3.5rem"
              fontSize="lg"
              color={isReportActive ? textPrimary : textSecondary}
              fontWeight="500"
              transition="all 0.2s ease"
              _hover={{
                color: primary,
              }}
              bg="transparent"
              px="3rem"
              borderRadius="10rem"
              flexShrink="0"
              onClick={() => {
                navigate("/implant-fail-form");
                document.body.style.overflow = "auto";
              }}
            >
              {t("landingMegagen.menuReports")}
            </Button>

            <Button
              h="3.5rem"
              fontSize="lg"
              color={isEventActive ? textPrimary : textSecondary}
              fontWeight="500"
              transition="all 0.2s ease"
              _hover={{
                color: primary,
              }}
              bg="transparent"
              px="3rem"
              borderRadius="10rem"
              flexShrink="0"
              onClick={() => {
                navigate("/events");
                document.body.style.overflow = "auto";
              }}
            >
              {t("landingMegagen.menuEvents")}
            </Button>

            {userInfo ? (
              <Flex direction="column">
                {userInfo && userInfo.role === "user" ? (
                  <Flex>
                    <Button
                      h="3.5rem"
                      fontSize="lg"
                      borderRadius="10rem"
                      bg={primary}
                      color={white}
                      px="3rem"
                      onClick={profileHandler}
                      flexShrink="0"
                    >
                      {t("landingMegagen.menuMyPortal")}
                    </Button>
                  </Flex>
                ) : (
                  <Flex>admin</Flex>
                )}
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="flex-end"
                gap="1.5rem"
                direction="column"
                w="100%"
                m="1rem 0 0"
              >
                {!isVerify && (
                  <>
                    <Button
                      variant="outline"
                      h="3.5rem"
                      fontSize="lg"
                      color={newDarkBlue}
                      borderRadius="10rem"
                      p="1rem 1.5rem"
                      px="3rem"
                      w="60%"
                      maxW="260px"
                      _hover={{ bg: backgroundLight }}
                      onClick={() => {
                        navigate("/login");
                        closeMobileMenuHandler();
                      }}
                    >
                      {t("implantForm.login")}
                    </Button>
                    <Button
                      h="3.5rem"
                      fontSize="lg"
                      bg={primary}
                      color={white}
                      px="3rem"
                      w="60%"
                      maxW="260px"
                      borderRadius="10rem"
                      p="1rem 1.5rem"
                      _hover={{ bg: shade(0.3, primary) }}
                      onClick={() => {
                        navigate("/register");
                        closeMobileMenuHandler();
                      }}
                    >
                      {t("implantForm.register")}
                    </Button>
                  </>
                )}
              </Flex>
            )}

            <Flex
              direction="column"
              position="relative"
              m="6rem 0 0rem"
              bottom="0rem"
              p="0 0 8rem"
            >
              {userInfo && (
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  m="0 0 1.5rem"
                >
                  <Text
                    color={textSecondary}
                    fontWeight="600"
                    fontSize="sm"
                    textAlign="center"
                  >
                    {userInfo && userInfo.clientType === "individual"
                      ? t("implantForm.loggedInIndividual")
                      : t("implantForm.loggedInCompany")}
                  </Text>

                  <Flex align="center">
                    {userInfo && userInfo.clientType === "individual" ? (
                      <Text
                        color={textSecondary}
                        fontWeight="600"
                        fontSize="sm"
                        mr={3}
                      >
                        {userInfo &&
                          userInfo.firstName &&
                          userInfo.lastName &&
                          userInfo.firstName[0] + ". " + userInfo.lastName}
                      </Text>
                    ) : (
                      <Text
                        color={textSecondary}
                        fontWeight="600"
                        fontSize="sm"
                        mr={3}
                      >
                        {userInfo && userInfo.companyName}
                      </Text>
                    )}

                    {userInfo && userInfo.isAdmin === true && (
                      <Text
                        color={textSecondary}
                        fontWeight="600"
                        fontSize="sm"
                        mr={3}
                      >
                        {userInfo &&
                          userInfo.firstName &&
                          userInfo.lastName &&
                          userInfo.firstName[0] + ". " + userInfo.lastName}
                      </Text>
                    )}

                    <Avatar
                      size="sm"
                      bg={secondary}
                      color={white}
                      name={
                        userInfo && userInfo.clientType === "individual"
                          ? `${userInfo.firstName} ${userInfo.lastName}`
                          : userInfo.companyName
                      }
                    />
                    <Icon
                      as={FaCaretDown}
                      ml={1}
                      h="0.75rem"
                      w={menuOpen ? "0.75rem" : "0rem"}
                      opacity="0.5"
                      color={textSecondary}
                      transition="all 0.2s ease"
                      transform={menuOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                      _groupHover={{
                        opacity: 1,
                        width: "0.75rem",
                        transition: "all 0.2s ease",
                      }}
                    />
                  </Flex>
                </Flex>
              )}

              {userInfo && (
                <Button
                  h="3rem"
                  fontSize="md"
                  variant="outline"
                  borderRadius="10rem"
                  bg={white}
                  color={textSecondary}
                  px="3rem"
                  onClick={logoutHandler}
                >
                  {t("common.logout")}
                </Button>
              )}

              <Flex m="1.5rem auto 0" w="100%" align="center" justify="center">
                <LanguageButton
                  currentLanguage={currentLanguage}
                  languages={["en", "lt", "ko"]}
                  onChangeLanguage={handleChangeLanguage}
                  onMenuClick={handleMenuClick}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </MotionFlex>

      <Flex
        w="100%"
        align="center"
        justify="center"
        bg={bg ? bg : white}
        h={["80px", "80px", "100px"]}
        position="fixed"
        zIndex="900"
      >
        <Flex
          maxW="100%"
          w="100%"
          align="center"
          justify={["space-between", "space-between", "space-between"]}
          h="100%"
          p={["0 1.5rem", "0 4rem", "0 4rem"]}
        >
          <Flex align="center">
            <Flex
              align="center"
              onClick={() => {
                navigate("/");
                closeMobileMenuHandler();
              }}
              _hover={{ cursor: "pointer" }}
            >
              {mobileMenuOpen ? (
                <Image
                  src={isRegister ? logonew2 : logonew2}
                  h={["1.5rem", "1.5rem", "1.75rem"]}
                />
              ) : (
                <Image
                  src={isRegister ? logonew2white : logonew2}
                  h={["1.5rem", "1.5rem", "1.75rem"]}
                />
              )}
            </Flex>

            {!isRegister && (
              <Flex
                align="center"
                justify="flex-start"
                gap="3rem"
                ml={["0rem", "0rem", "6rem"]}
                display={["none", "none", "flex"]}
              >
                <Link to="/implant-fail-form">
                  <Text
                    fontSize="15px"
                    color={
                      window.location.pathname === "/implant-fail-form"
                        ? textPrimary
                        : textSecondary
                    }
                    fontWeight="500"
                    transition="all 0.2s ease"
                    _hover={{
                      color: primary,
                    }}
                  >
                    {t("landingMegagen.menuReports")}
                  </Text>
                </Link>

                <Link to="/events">
                  <Text
                    fontSize="15px"
                    color={
                      window.location.pathname === "/events"
                        ? textPrimary
                        : textSecondary
                    }
                    fontWeight="500"
                    transition="all 0.2s ease"
                    _hover={{
                      color: primary,
                    }}
                  >
                    {t("landingMegagen.menuEvents")}
                  </Text>
                </Link>

                {userInfo && userInfo.role === "user" ? (
                  <Link to="/account">
                    <Text
                      fontSize="15px"
                      color={isAccountActive ? textPrimary : textSecondary}
                      fontWeight="500"
                      transition="all 0.2s ease"
                      _hover={{
                        color: primary,
                      }}
                    >
                      {t("landingMegagen.menuMyPortal")}
                    </Text>
                  </Link>
                ) : (
                  <Tooltip
                    label={
                      userInfo
                        ? t("landingMegagen.loggedInAsAdmin")
                        : t("landingMegagen.loginToEnter")
                    }
                    p="0.25rem 0.5rem"
                    borderRadius="1rem"
                  >
                    <Text
                      fontSize="15px"
                      color={isAccountActive ? textPrimary : textSecondary}
                      fontWeight="500"
                      transition="all 0.2s ease"
                      _hover={{
                        color: textSecondary,
                      }}
                      isDisabled
                      opacity="0.5"
                    >
                      {t("landingMegagen.menuMyPortal")}
                    </Text>
                  </Tooltip>
                )}

                {userInfo && userInfo.role === "employee" && (
                  <Link to="/admin/dashboard">
                    <Text
                      fontSize="15px"
                      color={primary}
                      fontWeight="500"
                      transition="all 0.2s ease"
                      _hover={{
                        color: primary,
                      }}
                    >
                      {t("landingMegagen.menuAdmin")}
                    </Text>
                  </Link>
                )}
              </Flex>
            )}
          </Flex>

          {/* Hamburger Menu Icon */}

          <Flex display={["flex", "flex", "none"]}>
            {mobileMenuOpen ? (
              <Hamburger
                size="18"
                toggled={mobileMenuOpen}
                toggle={closeMobileMenuHandler}
                color={isRegister ? textPrimary : textPrimary} // Adjust color as needed
              />
            ) : (
              <Hamburger
                size="18"
                toggled={mobileMenuOpen}
                toggle={openMobileMenuHandler}
                color={isRegister ? white : textPrimary} // Adjust color as needed
              />
            )}
          </Flex>
          <Flex
            align="center"
            justify="flex-end"
            gap="1rem"
            display={["none", "none", "flex"]}
          >
            <Box>
              <LanguageButton
                currentLanguage={currentLanguage}
                languages={["en", "lt", "ko"]}
                onChangeLanguage={handleChangeLanguage}
                onMenuClick={handleMenuClick}
              />
            </Box>

            {!userInfo ? (
              <Flex
                align="center"
                justify="flex-end"
                gap="1rem"
                display={["none", "none", "flex"]}
              >
                {!isVerify && (
                  <>
                    <Button
                      variant="outline"
                      size="lg"
                      fontSize="sm"
                      color={newDarkBlue}
                      borderRadius="10rem"
                      p="1rem 1.5rem"
                      _hover={{ bg: backgroundLight }}
                      onClick={() => navigate("/login")}
                    >
                      {t("implantForm.login")}
                    </Button>
                    <Button
                      size="lg"
                      fontSize="sm"
                      bg={primary}
                      color={white}
                      borderRadius="10rem"
                      p="1rem 1.5rem"
                      _hover={{ bg: shade(0.3, primary) }}
                      onClick={() => navigate("/register")}
                    >
                      {t("implantForm.register")}
                    </Button>
                  </>
                )}
              </Flex>
            ) : (
              <>
                <Flex
                  align="center"
                  justify="flex-end"
                  gap="1rem"
                  display={["none", "none", "flex"]}
                >
                  <Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)}>
                    <MenuButton
                      borderRadius="full"
                      role="group"
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      <Flex align="center" ml="30px">
                        {userInfo && userInfo.clientType === "individual" ? (
                          <Text
                            color={textSecondary}
                            fontWeight="600"
                            fontSize="sm"
                            mr={3}
                          >
                            {userInfo &&
                              userInfo.firstName &&
                              userInfo.lastName &&
                              userInfo.firstName[0] + ". " + userInfo.lastName}
                          </Text>
                        ) : (
                          <Text
                            color={textSecondary}
                            fontWeight="600"
                            fontSize="sm"
                            mr={3}
                          >
                            {userInfo && userInfo.companyName}
                          </Text>
                        )}

                        {userInfo && userInfo.isAdmin === true && (
                          <Text
                            color={textSecondary}
                            fontWeight="600"
                            fontSize="sm"
                            mr={3}
                          >
                            {userInfo &&
                              userInfo.firstName &&
                              userInfo.lastName &&
                              userInfo.firstName[0] + ". " + userInfo.lastName}
                          </Text>
                        )}

                        <Avatar
                          size="sm"
                          bg={secondary}
                          color={white}
                          name={
                            userInfo && userInfo.clientType === "individual"
                              ? `${userInfo.firstName} ${userInfo.lastName}`
                              : userInfo.companyName
                          }
                        />
                        <Icon
                          as={FaCaretDown}
                          ml={1}
                          h="0.75rem"
                          w={menuOpen ? "0.75rem" : "0rem"}
                          opacity="0.5"
                          color={textSecondary}
                          transition="all 0.2s ease"
                          transform={menuOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                          _groupHover={{
                            opacity: 1,
                            width: "0.75rem",
                            transition: "all 0.2s ease",
                          }}
                        />
                      </Flex>
                    </MenuButton>

                    <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
                      {userInfo ? (
                        <>
                          {userInfo && userInfo.role === "employee" ? (
                            <Link to="/admin/dashboard">
                              <MenuItem p="1rem 1rem" onClick={profileHandler}>
                                <Text color={textSecondary}>
                                  {t("landingMegagen.menuAdmin")}
                                </Text>
                              </MenuItem>
                            </Link>
                          ) : (
                            <>
                              <Link to="/account">
                                <MenuItem
                                  p="1rem 1rem"
                                  onClick={profileHandler}
                                >
                                  <Text color={textSecondary}>
                                    {t("landingMegagen.menuMyPortal")}
                                  </Text>
                                </MenuItem>
                              </Link>
                              <Link to="/account/settings">
                                <MenuItem
                                  p="1rem 1rem"
                                  onClick={profileHandler}
                                >
                                  <Text color={textSecondary}>
                                    {t("clientAccount.mySettings")}
                                  </Text>
                                </MenuItem>
                              </Link>
                            </>
                          )}

                          <MenuItem p="1rem 1rem" onClick={logoutHandler}>
                            <Text color={textSecondary}>
                              {t("common.logout")}
                            </Text>
                          </MenuItem>
                        </>
                      ) : (
                        <Link to="/login">
                          <MenuItem p="1rem 1rem">
                            <Text color={textSecondary}>
                              <i className="fas fa-user"></i>{" "}
                              {t("common.signin")}
                            </Text>
                          </MenuItem>
                        </Link>
                      )}
                    </MenuList>
                  </Menu>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
