import axios from "axios";
import {
  SPEAKER_LIST_REQUEST,
  SPEAKER_LIST_SUCCESS,
  SPEAKER_LIST_FAIL,
  SPEAKER_DETAILS_REQUEST,
  SPEAKER_DETAILS_SUCCESS,
  SPEAKER_DETAILS_FAIL,
  SPEAKER_UPDATE_REQUEST,
  SPEAKER_UPDATE_SUCCESS,
  SPEAKER_UPDATE_FAIL,
  SPEAKER_CREATE_REQUEST,
  SPEAKER_CREATE_SUCCESS,
  SPEAKER_CREATE_FAIL,
  SPEAKER_DELETE_REQUEST,
  SPEAKER_DELETE_SUCCESS,
  SPEAKER_DELETE_FAIL,
  SPEAKER_ADD_COURSE_REQUEST,
  SPEAKER_ADD_COURSE_SUCCESS,
  SPEAKER_ADD_COURSE_FAIL,
  SPEAKER_REMOVE_COURSE_REQUEST,
  SPEAKER_REMOVE_COURSE_SUCCESS,
  SPEAKER_REMOVE_COURSE_FAIL,
  SPEAKER_STATUS_UPDATE_REQUEST,
  SPEAKER_STATUS_UPDATE_SUCCESS,
  SPEAKER_STATUS_UPDATE_FAIL,
  SPEAKER_DESCRIPTION_UPDATE_REQUEST,
  SPEAKER_DESCRIPTION_UPDATE_SUCCESS,
  SPEAKER_DESCRIPTION_UPDATE_FAIL,
  SPEAKER_COURSE_LIST_REQUEST,
  SPEAKER_COURSE_LIST_SUCCESS,
  SPEAKER_COURSE_LIST_FAIL,
  SPEAKER_IMAGE_UPDATE_REQUEST,
  SPEAKER_IMAGE_UPDATE_SUCCESS,
  SPEAKER_IMAGE_UPDATE_FAIL,
} from "../constants/speakerConstants";
import { logout } from "./userActions";

export const listSpeakers =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    status = ""
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: SPEAKER_LIST_REQUEST });

      // Constructing the query string
      let query = `/api/speakers?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;

      const { data } = await axios.get(query);

      dispatch({
        type: SPEAKER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSpeakerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SPEAKER_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/speakers/${id}`);

    dispatch({
      type: SPEAKER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPEAKER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSpeaker = (speaker) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPEAKER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/speakers/${speaker._id}`,
      speaker,
      config
    );

    dispatch({
      type: SPEAKER_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: SPEAKER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SPEAKER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }

    dispatch({
      type: SPEAKER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createSpeaker =
  (
    firstName,
    lastName,
    image,
    phone,
    email,
    birthDate,
    street,
    zip,
    city,
    country,
    state,
    courses
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SPEAKER_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/speakers`,
        {
          firstName,
          lastName,
          phone,
          email,
          birthDate,
          image,
          street,
          zip,
          city,
          courses,
        },
        config
      );

      dispatch({
        type: SPEAKER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: SPEAKER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSpeaker = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPEAKER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/speakers/${id}`, config);

    dispatch({
      type: SPEAKER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SPEAKER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const addSpeakerCourse =
  (speakerId, courseId) => async (dispatch, getState) => {
    try {
      dispatch({ type: SPEAKER_ADD_COURSE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/speakers/${speakerId}/courses`,
        { courseId },
        config
      );

      dispatch({
        type: SPEAKER_ADD_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeSpeakerCourse =
  (speakerId, courseId) => async (dispatch, getState) => {
    try {
      dispatch({ type: SPEAKER_REMOVE_COURSE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/speakers/${speakerId}/courses/${courseId}`,
        config
      );

      dispatch({
        type: SPEAKER_REMOVE_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_REMOVE_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateSpeakerStatus =
  (speakerId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SPEAKER_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/speakers/${speakerId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: SPEAKER_STATUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateSpeakerDescription =
  (speakerId, newDescription) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SPEAKER_DESCRIPTION_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/speakers/${speakerId}/description`,
        { description: newDescription }, // Send the new status in the request body
        config
      );

      dispatch({
        type: SPEAKER_DESCRIPTION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_DESCRIPTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const listSpeakerCourses =
  (
    id,
    keyword = "",
    sortField = "", // New parameter for sort field
    sortOrder = "" // New parameter for sort order
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SPEAKER_COURSE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string with new sorting parameters
      let query = `/api/speakers/${id}/courses?keyword=${encodeURIComponent(
        keyword
      )}`;
      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: SPEAKER_COURSE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_COURSE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateSpeakerImage =
  (speakerId, image) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SPEAKER_IMAGE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/speakers/${speakerId}/image`,
        { image },
        config
      );

      dispatch({
        type: SPEAKER_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPEAKER_IMAGE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
