import {
  GET_NEW_CLIENTS_REQUEST,
  GET_NEW_CLIENTS_SUCCESS,
  GET_NEW_CLIENTS_FAIL,
  GET_NEW_CLIENTS_RESET,
  GET_NEW_CLIENTS_TOP_REQUEST,
  GET_NEW_CLIENTS_TOP_SUCCESS,
  GET_NEW_CLIENTS_TOP_FAIL,
  GET_NEW_CLIENTS_TOP_RESET,
  GET_REPORT_STATUS_COUNTS_REQUEST,
  GET_REPORT_STATUS_COUNTS_SUCCESS,
  GET_REPORT_STATUS_COUNTS_FAIL,
  GET_REPORT_STATUS_COUNTS_RESET,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_RESET,
  GET_CLIENTS_STATUS_COUNTS_REQUEST,
  GET_CLIENTS_STATUS_COUNTS_SUCCESS,
  GET_CLIENTS_STATUS_COUNTS_FAIL,
  GET_CLIENTS_STATUS_COUNTS_RESET,
  GET_TOP_REPORTS_BY_FIXTURE_REQUEST,
  GET_TOP_REPORTS_BY_FIXTURE_SUCCESS,
  GET_TOP_REPORTS_BY_FIXTURE_FAIL,
  GET_TOP_REPORTS_BY_FIXTURE_RESET,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL,
} from "../constants/dashboardConstants";

export const dashboardNewClientsReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case GET_NEW_CLIENTS_REQUEST:
      return { loading: true, clients: [] };
    case GET_NEW_CLIENTS_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_NEW_CLIENTS_FAIL:
      return { loading: false, error: action.payload };
    case GET_NEW_CLIENTS_RESET:
      return { clients: [] };
    default:
      return state;
  }
};

export const dashboardNewClientsTopReducer = (
  state = { clients: [] },
  action
) => {
  switch (action.type) {
    case GET_NEW_CLIENTS_TOP_REQUEST:
      return { loading: true, clients: [] };
    case GET_NEW_CLIENTS_TOP_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_NEW_CLIENTS_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Reports status counts reducer
export const dashboardReportStatusCountsReducer = (
  state = {
    loading: false,
    error: null,
    data: { total: 0, countsByStatus: [] },
  },
  action
) => {
  switch (action.type) {
    case GET_REPORT_STATUS_COUNTS_REQUEST:
      return { ...state, loading: true };
    case GET_REPORT_STATUS_COUNTS_SUCCESS:
      // Make sure that 'data' here matches the structure you're expecting in your components
      return { loading: false, data: action.payload, error: null };
    case GET_REPORT_STATUS_COUNTS_FAIL:
      return {
        loading: false,
        data: { total: 0, countsByStatus: [] },
        error: action.payload,
      };

    default:
      return state;
  }
};

// Reports status counts reducer
export const dashboardCoursesAttendeesStatusCountsReducer = (
  state = {
    loading: false,
    error: null,
    data: { total: 0, countsByStatus: [] },
  },
  action
) => {
  switch (action.type) {
    case GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST:
      return { ...state, loading: true };
    case GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS:
      // Make sure that 'data' here matches the structure you're expecting in your components
      return { loading: false, data: action.payload, error: null };
    case GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL:
      return {
        loading: false,
        data: { total: 0, countsByStatus: [] },
        error: action.payload,
      };
    default:
      return state;
  }
};

export const dashboardClientsStatusCountsReducer = (
  state = {
    loading: false,
    error: null,
    data: { total: 0, countsByStatus: [] },
  },
  action
) => {
  switch (action.type) {
    case GET_CLIENTS_STATUS_COUNTS_REQUEST:
      return { ...state, loading: true };
    case GET_CLIENTS_STATUS_COUNTS_SUCCESS:
      // Make sure that 'data' here matches the structure you're expecting in your components
      return { loading: false, data: action.payload, error: null };
    case GET_CLIENTS_STATUS_COUNTS_FAIL:
      return {
        loading: false,
        data: { total: 0, countsByStatus: [] },
        error: action.payload,
      };
    default:
      return state;
  }
};

export const dashboardTopReportsByFixtureReducer = (
  state = { reports: [] },
  action
) => {
  switch (action.type) {
    case GET_TOP_REPORTS_BY_FIXTURE_REQUEST:
      return { loading: true, reports: [] };
    case GET_TOP_REPORTS_BY_FIXTURE_SUCCESS:
      return { loading: false, reports: action.payload.data };
    case GET_TOP_REPORTS_BY_FIXTURE_FAIL:
      return { loading: false, error: action.payload };
    case GET_TOP_REPORTS_BY_FIXTURE_RESET:
      return { reports: [] };
    default:
      return state;
  }
};

export const dashboardEmployeeClientsLatestReportsReducer = (
  state = { employeeClientsReports: [] },
  action
) => {
  switch (action.type) {
    case GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST:
      return { loading: true, employeeClientsReports: [] };
    case GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS:
      return {
        loading: false,
        employeeClientsReports: action.payload.employeeClientsReports,
      };
    case GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
