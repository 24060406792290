import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  HStack,
  Button,
  Flex,
  Icon,
  Text,
  Divider,
  Box,
  AbsoluteCenter,
} from "@chakra-ui/react";
import {
  white,
  buttonGreen,
  buttonGreenText,
  primary,
  buttonRedText,
  textSecondary,
} from "../../../../../utils/colors";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { shade } from "polished";

const Step0Admin = ({
  setActiveStep,
  userInfo,
  clientType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  companyName,
  setCompanyName,
  companyCode,
  setCompanyCode,
  email,
  setEmail,
  isValid,
  isLoggedIn,
  client,
}) => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();

  // CUSTOM
  const iconElement = (
    <Flex
      p="0"
      mr="0.5rem"
      h="100%"
      position="absolute"
      bottom="2px"
      top="40px"
      right="0.5rem"
      opacity="0.6"
      pointerEvents="none"
    >
      <Flex
        w="1.5rem"
        h="1.5rem"
        justify="center"
        align="center"
        bg={buttonGreen}
        borderRadius="50%"
      >
        <Icon as={FaUserCircle} color={buttonGreenText} fontSize="0.875rem" />
      </Flex>
    </Flex>
  );

  const tooltipLabel = (
    <Flex align="center">
      {clientType === "individual" ? (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInIndividual")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {firstName} {lastName}
          </Text>
        </Text>
      ) : (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInCompany")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {companyName}
          </Text>
        </Text>
      )}
    </Flex>
  );

  const [representingFirstName, setRepresentingFirstName] = useState("");
  const [representingLastName, setRepresentingLastName] = useState("");
  const [representingCompany, setRepresentingCompany] = useState("myMegagen");

  useEffect(() => {
    if (userInfo) {
      setRepresentingFirstName(userInfo.firstName);
      setRepresentingLastName(userInfo.lastName);
    }
  }, [userInfo, client]);

  // LOGS
  console.log("client:", client);
  console.log("userInfo:", userInfo);
  console.log("representingFirstName:", representingFirstName);
  console.log("representingLastName:", representingLastName);
  console.log("representingCompany:", representingCompany);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex direction="column">
        <Box position="relative" padding="1.5rem 0">
          <Divider />
          <AbsoluteCenter bg="white" px="4">
            <Text color={textSecondary} fontSize="xs">
              {client &&
                client.accountType === "clinic" &&
                t("landingMegagen.registerClinicDetails")}
              {client &&
                client.accountType === "dentist" &&
                t("landingMegagen.registerDentistDetails")}
              {client &&
                client.accountType === "lab" &&
                t("landingMegagen.registerLabDetails")}
            </Text>
          </AbsoluteCenter>
        </Box>

        {client && client.clientType === "individual" ? (
          <>
            <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.firstName")}
                value={firstName}
                placeholder=""
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                w="50%"
                isDisabled
                rightElement={userInfo ? iconElement : null}
                isTooltip={userInfo}
                tooltipLabel={tooltipLabel}
                noDisabledOpacity
              />
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.lastName")}
                value={lastName}
                placeholder=""
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                w="50%"
                isDisabled
                rightElement={userInfo ? iconElement : null}
                isTooltip={userInfo}
                tooltipLabel={tooltipLabel}
                noDisabledOpacity
              />
            </Flex>

            <FormControlDefault
              type="email"
              label={t("form.email")}
              isRequired
              value={email}
              placeholder=""
              onChange={(e) => {
                setEmail(e.target.value);
                // setEmailTouched(true);
              }}
              // onBlur={() => setEmailTouched(true)}
              // isTouched={emailTouched}
              // isError={!isEmailValid}
              w="100%"
              // errorMessage={t("formError.emailInvalid")}
              isDisabled
              rightElement={userInfo ? iconElement : null}
              isTooltip={userInfo}
              tooltipLabel={tooltipLabel}
              noDisabledOpacity
            />

            {/* <FormControlDefault
              type="text"
              label={t("form.representingCompany")}
              value={representingCompany}
              placeholder=""
              // onChange={(e) => {
              //   setCompanyName(e.target.value);
              // }}
              w="100%"
              isDisabled
              noDisabledOpacity
            /> */}
          </>
        ) : (
          <>
            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.companyName")}
                value={companyName}
                placeholder=""
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                w="50%"
                isDisabled
              />

              <FormControlDefault
                type="text"
                isRequired
                label={t("form.companyCode")}
                value={companyCode}
                placeholder=""
                onChange={(e) => {
                  setCompanyCode(e.target.value);
                }}
                w="50%"
                isDisabled
              />
            </HStack>

            <FormControlDefault
              type="email"
              label={t("form.email")}
              isRequired
              value={email}
              placeholder=""
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              w="100%"
              isDisabled
            />
            {/* <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.repFirstName")}
                value={representingFirstName}
                placeholder=""
                // onChange={(e) => {
                //   setFirstName(e.target.value);
                // }}
                w="50%"
                isDisabled
              />

              <FormControlDefault
                type="text"
                label={t("form.repLastName")}
                value={representingLastName}
                placeholder=""
                // onChange={(e) => {
                //   setLastName(e.target.value);
                // }}
                w="50%"
                isDisabled
              />
            </HStack> */}
          </>
        )}
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        {isLoggedIn ? (
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={() => {
              setActiveStep(1);
            }}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!isValid}
          >
            {t("common.continue")}
          </Button>
        ) : (
          <Flex align="center" justify="flex-end">
            <Text
              fontSize="sm"
              color={buttonRedText}
              fontWeight="500"
              mr="1.5rem"
            >
              {t("implantForm.registerNotLoggedIn")}
            </Text>
            <Button
              size="lg"
              fontSize="md"
              bg={primary}
              color={white}
              fontWeight="500"
              borderRadius="10rem"
              minW="8rem"
              onClick={() => {
                navigate("/register");
              }}
              _hover={{
                bg: shade(0.3, primary),
              }}
            >
              {t("implantForm.register")}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Step0Admin;
