import React from 'react';
import { Flex, Heading, Box, Button, Text } from '@chakra-ui/react';
import { primary, textPrimary, textSecondary, white } from '../../utils/colors';
import Lottie from 'lottie-react';
import noItemsFound from '../../assets/lottie/no-items-found.json';

const MiniNoItems = ({ buttonFunction, buttonLabel, title, text }) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      margin="0 auto 4rem"
    >
      {/* <Image src={images.noData} alt="no Data" /> */}
      <Box maxWidth="60%" margin="0 auto">
        <Lottie animationData={noItemsFound} loop={true} />
      </Box>

      <Heading
        fontSize="1.125rem"
        fontWeight="500"
        color={textPrimary}
        margin="1rem 0 0rem"
      >
        {title}
      </Heading>
      <Text my={2} fontSize="1rem" color={textSecondary} fontWeight="500">
        {text}
      </Text>
      <Button
        mt={2}
        size="lg"
        bg={primary}
        color={white}
        fontSize="md"
        borderRadius="10rem"
        onClick={buttonFunction}
      >
        {buttonLabel}
      </Button>
    </Flex>
  );
};

export default MiniNoItems;
