import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listUsers } from "../../actions/userActions";

const FormControlEmployee = ({
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  onEmployeeChange,
}) => {
  // REDUX
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  // CONSTANTS
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const handleEmployeeChange = (selectedOption) => {
    onChange(selectedOption); // existing onChange call
    if (onEmployeeChange) {
      onEmployeeChange(selectedOption); // call the callback with selected option
    }
  };
  // Triggered when the dropdown is opened
  const handleMenuOpen = () => {
    // setIsTouched(true); // Set the touched state to true
  };

  // USE EFFECT LOAD SERVICE GROUPS
  useEffect(() => {
    // Dispatch the action with fetchAll set to true
    dispatch(listUsers());
  }, [dispatch]);

  // USE EFFECT TRANSFORM SERVICE GROUPS FOR REACT SELECT AND SET OPTIONS, EXCLUDE INACTIVE SERVICE GROUPS
  useEffect(() => {
    if (users && users.length > 0) {
      const activeUsers = users.filter((group) => group.status !== "inactive");

      // Transforming into format suitable for react-select
      // This should be done where you are setting the user options
      const transformedUsers = activeUsers.map((user) => ({
        value: user._id,
        label: user.firstName + " " + user.lastName,
      }));

      setEmployeeOptions(transformedUsers);
    }
  }, [users]);

  // LOG

  //   console.log("isTouched", isTouched);
  //   console.log("isError", isError);

  //   console.log("users", users);
  // console.log("employeeOptions", employeeOptions);
  // console.log("value", value);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={
          value
            ? employeeOptions.find((option) => option.value === value._id)
            : null
        }
        onChange={handleEmployeeChange}
        options={employeeOptions}
        placeholder={placeholder}
        onMenuOpen={handleMenuOpen}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor:
              isError && isTouched
                ? "red"
                : state.isFocused
                ? newBorder
                : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            // border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black", // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: primary, // Background color when hovering
              color: "white",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlEmployee;
