import React from "react";
import styled from "styled-components";
import Tabs from "../Tabs";

const StyledHeaderNavigation = styled.div`
  flex-grow: 1;
`;

const SidebarRouting = ({ menuItems }) => {
  return (
    <StyledHeaderNavigation>
      <Tabs.Container tabs={menuItems} />
    </StyledHeaderNavigation>
  );
};

export default SidebarRouting;
