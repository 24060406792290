import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  IconButton,
  Box,
} from "@chakra-ui/react";
import {
  TbEdit,
  TbTrashX,
  TbDotsVertical,
  TbCheckbox,
  TbEye,
} from "react-icons/tb";
import { textSecondary, white, newBorder, primary } from "../../utils/colors";
import ConfirmDeleteModal from "../../components/modals/ConfirmDeleteModal";
import { useTranslation } from "react-i18next";

const MoreButton = React.forwardRef(
  (
    {
      id,
      deleteHandler,
      editHandler,
      deleteLabel,
      editLabel,
      isBig,
      viewId,
      viewHandler,
      viewLabel,
      isWarning,
      warningName,
      warningDescription,
      warningDisabled,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State to control delete confirmation modal
    const { t } = useTranslation();

    const btnRef2 = useRef();

    const handleClickOutside = (event) => {
      if (btnRef2.current && !btnRef2.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleView = () => {
      viewHandler(viewId);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleDeleteConfirmation = () => {
      setShowDeleteConfirmation(true);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    return (
      <Box ref={ref} p={0} borderRadius="0.75rem" ml={2}>
        <Menu isLazy>
          <MenuButton
            as={Button}
            bg={white}
            color={textSecondary}
            border={`1px solid ${newBorder}`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            h={isBig ? "2.5rem" : "2.5rem"}
            w={isBig ? "2.5rem" : "2.5rem"}
            borderRadius="0.75rem"
            p={0}
            transition="0.2s all"
          >
            <Icon
              as={TbDotsVertical}
              h={isBig ? "1.25rem" : "1.125rem"}
              w={isBig ? "1.25rem" : "1.125rem"}
              bg="none"
              mt={1}
            />
          </MenuButton>
          <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
            {viewHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleView}
              >
                <Icon
                  as={TbEye}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {viewLabel || t("button.view")}
                </Text>
              </MenuItem>
            )}

            {editHandler && (
              <MenuItem
                py={4}
                px={4}
                borderBottom={`1px solid ${newBorder}`}
                color={textSecondary}
                transition="0.2s all"
                _hover={{ bg: primary, color: white }}
                onClick={editHandler}
              >
                <Icon as={TbEdit} mr="0.5rem" h="1rem" w="1rem" />

                <Text fontSize="0.875rem" fontWeight="500" lineHeight="1">
                  {editLabel || t("button.edit")}
                </Text>
              </MenuItem>
            )}
            {deleteHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleDeleteConfirmation}
              >
                <Icon
                  as={TbTrashX}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {deleteLabel || t("button.delete")}
                </Text>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
        {/* Render the delete confirmation modal when necessary */}
        {showDeleteConfirmation && (
          <ConfirmDeleteModal
            isOpen={showDeleteConfirmation}
            isWarning={isWarning}
            warningName={warningName}
            warningDisabled={warningDisabled}
            warningDescription={warningDescription}
            onClose={() => setShowDeleteConfirmation(false)}
            confirmAction={() => {
              deleteHandler(id); // Pass the id to the deleteHandler
              setShowDeleteConfirmation(false);
            }}
          />
        )}
      </Box>
    );
  }
);

export default MoreButton;
