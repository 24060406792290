import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Box,
  Icon,
  Avatar,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import {
  white,
  textSecondary,
  textPrimary,
  newBorder,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
} from "../../../../utils/colors";
import ConfirmDeleteModal from "../../../../components/modals/ConfirmDeleteModal";
import { FaRegCircleCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import StatusButton from "../../../../components/buttons/StatusButton"; // Update the path accordingly
import MoreButton from "../../../../components/buttons/MoreButton";

export const columns = (
  openEditSidePanelModal,
  translate,
  deleteProductHandler,
  page,
  pageSize
) => {
  return [
    {
      Header: "#",
      id: "index",
      accessor: (_row, i) => {
        // Adjust the index based on the page and pageSize
        const adjustedIndex = (page - 1) * pageSize + i + 1;
        return (
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
          >
            {adjustedIndex}
          </Text>
        );
      },
    },
    {
      Header: translate("attendee.columnAttendee"),
      disableSortBy: false,
      accessor: (row) => {
        const { name, description, image } = row;

        return (
          <HStack gap={2}>
            {image ? (
              <Box
                w="3rem"
                h="3rem"
                borderRadius="10rem"
                overflow="hidden"
                bg={backgroundLight}
              >
                <Image
                  w="3rem"
                  h="3rem"
                  src={image}
                  alt={name}
                  objectFit="cover"
                />
              </Box>
            ) : (
              <Avatar name={name} bg="#dceeff" color="#3a74a9" />
            )}
            <Stack gap={0}>
              <Text fontSize="md" color={textPrimary} fontWeight="600">
                {name}
              </Text>
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                {description || "No description"}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => {
        const { status, _id } = row;

        return (
          <HStack gap={0} justify="flex-end">
            {/* <StatusButton
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="active"
              /> */}
            <MoreButton
              id={_id}
              deleteHandler={deleteProductHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
