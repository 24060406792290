import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listProfessionGroups,
  deleteProfessionGroup,
  updateProfessionGroupStatus,
} from "../../../../actions/professionGroupActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/ProfessionGroupListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  PROFESSIONGROUP_DELETE_RESET,
  PROFESSIONGROUP_UPDATE_RESET,
  PROFESSIONGROUP_CREATE_RESET,
  PROFESSIONGROUP_UPDATE_STATUS_RESET,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET,
} from "../../../../constants/professionGroupConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateProfessionGroup from "../sidepanels/CreateProfessionGroup";
import EditProfessionGroup from "../sidepanels/EditProfessionGroup";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
// import ProfessionGroupsStats from "../partials/ProfessionGroupsStats";

const ProfessionGroupList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const professionGroupList = useSelector((state) => state.professionGroupList);
  const {
    loading,
    error,
    professionGroups,
    pages,
    countsByStatus,
    globalTotalCount,
  } = professionGroupList;

  const professionGroupCreate = useSelector(
    (state) => state.professionGroupCreate
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    professionGroup: createdProfessionGroup,
  } = professionGroupCreate;

  const professionGroupUpdate = useSelector(
    (state) => state.professionGroupUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = professionGroupUpdate;

  const professionGroupDelete = useSelector(
    (state) => state.professionGroupDelete
  );

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = professionGroupDelete;

  const professionGroupUpdateStatus = useSelector(
    (state) => state.professionGroupUpdateStatus
  );
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = professionGroupUpdateStatus;

  const professionGroupUpdateIsConsulting = useSelector(
    (state) => state.professionGroupUpdateIsConsulting
  );
  const {
    loading: loadingUpdateIsConsulting,
    error: errorUpdateIsConsulting,
    success: successUpdateIsConsulting,
  } = professionGroupUpdateIsConsulting;

  // SEARCH
  const [searchTitle, setSearchTitle] = useState("");

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const filterConfig = {
    professionGroups: true,
  };

  const [filterParams, setFilterParams] = useState({
    status: "",
  });

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setIsFilterActive(true);
    setPage(1);
    localStorage.setItem(
      "filterSettings_professionGroups",
      JSON.stringify(filters)
    ); // Example for PatientList
    dispatch(
      listProfessionGroups(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false, // Assuming `fetchAll` is false by default
        filters.status
      )
    );

    // console.log("filters", filters);
  };

  // SORTING
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listProfessionGroups(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false,
        filterParams.status
      )
    );
  };

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [professionGroupId, setProfessionGroupId] = useState("");

  // FILTER INACTIVE
  // Function to filter services based on role
  const filterProfessionGroupsByRole = (professionGroups) => {
    // Non-admins see only active services
    return professionGroups.filter(
      (professionGroup) => professionGroup.status === "active"
    );
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteProfessionGroupHandler = (professionGroupId) => {
    console.log("click");
    dispatch(deleteProfessionGroup(professionGroupId));
    toastComponent.showToast(
      t("toasts.professionGroupDelete"),
      "success",
      3000
    );
    dispatch({ type: PROFESSIONGROUP_DELETE_RESET });

    if (professionGroups.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  useEffect(() => {
    console.log("trying to access saved filters");
    const savedFilters = localStorage.getItem(
      "filterSettings_professionGroups"
    );
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setFilterParams(filters);
      setIsFilterActive(true);
    }
    console.log("savedFilters", savedFilters);
  }, []);

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(
        t("toasts.professionGroupCreate"),
        "success",
        3000
      );
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: PROFESSIONGROUP_CREATE_RESET });
    }
    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(
        t("toasts.professionGroupsUpdate"),
        "success",
        3000
      );
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: PROFESSIONGROUP_UPDATE_RESET });

      dispatch(
        listProfessionGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status
        )
      );
    }
    // USEEFFECT UPDATE STATUS
    else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.professionGroupUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: PROFESSIONGROUP_UPDATE_STATUS_RESET });

      dispatch(listProfessionGroups(searchTitle, page, pageSize));
    } else if (successUpdateIsConsulting) {
      toastComponent.showToast(
        t("toasts.professionGroupUpdateIsConsulting"),
        "success",
        3000
      );
      dispatch({ type: PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET });

      dispatch(
        listProfessionGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status
        )
      );
    }

    // USEEFFECT MAIN
    else {
      dispatch(
        listProfessionGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status
        )
      );
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    successUpdateIsConsulting,
    userInfo,
    sortField,
    sortOrder,
    filterParams,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setProfessionGroupId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setProfessionGroupId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setProfessionGroupId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    if (userInfo && userInfo.isAdmin) {
      openEditSidePanelModal(value);
    } else {
      // toastComponent.showToast(
      //   t("toasts.notAuthorized"), // Update this message to a suitable one
      //   "error",
      //   3000
      // );
      console.log("not authorized");
    }
  };

  // LOGS

  // console.log("professionGroups", professionGroups);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.adminProfessionGroups")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminProfessionGroups")}
        />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("professionGroup.createProfessionGroup")}
        noBorder={false}
      >
        <CreateProfessionGroup close={closeSidePanelModal} />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("professionGroup.editProfessionGroup")}
        noBorder={false}
      >
        <EditProfessionGroup
          close={closeEditSidePanelModal}
          professionGroupId={professionGroupId}
        />
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("professionGroup.professionGroups")}
                onChange={onChangeSearchTitle}
                placeholder={t("professionGroup.search")}
                searchName={searchTitle}
                buttonLabel={t("professionGroup.createProfessionGroup")}
                buttonHandler={openSidePanelModal}
                isFixedTitle
                // isFilter
                // filterLabel={t("patient.filter")}
                // filterHandler={handleFilterApply}
                // isFilterActive={isFilterActive}
                // setIsFilterActive={setIsFilterActive}
                // filterConfig={filterConfig}
                // savedFilters={filterParams}
                // savedFiltersKey="filterSettings_professionGroups"
                // isDisabledButton={!isAdmin}
              ></Table.Top>
            </Box>

            {/* <ProfessionGroupsStats
              loading={loading}
              countsByStatus={countsByStatus}
              globalTotalCount={globalTotalCount}
            /> */}

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteProfessionGroupHandler,
                openEditSidePanelModal,
                updateProfessionGroupStatus,
                handleSortChange
              )}
              data={professionGroups}
              // data={filterProfessionGroupsByRole(professionGroups)}
              onRowClick={handleRowClick}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/profession-groups"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noProfessionGroups")}
              noItemsDescription={t("noItems.noProfessionGroupsText")}
              noItemsButtonLabel={t("noItems.noProfessionGroupsButton")}
              sortField={sortField}
              sortOrder={sortOrder}
            />

            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={professionGroups && professionGroups.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ProfessionGroupList;
