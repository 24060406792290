import React from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Icon,
  InputRightElement,
} from "@chakra-ui/react";
import { icons } from "../../../../utils/icons";
import {
  white,
  textPrimary,
  backgroundLight,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaMagnifyingGlass } from "react-icons/fa6";

const SearchBarMain = ({ value, onChange, placeholder, elements }) => {
  const { t } = useTranslation();

  return (
    <InputGroup w="100%" p={["0 1.5rem", "0 1.5rem", "initial"]}>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        size="lg"
        h={["3rem", "3rem", "60px"]}
        bg={white}
        borderRadius="10rem"
        border={[
          `1px solid ${newBorder}`,
          `1px solid ${newBorder}`,
          `0px solid ${newBorder}`,
        ]}
        color={textPrimary}
        fontSize="1rem"
        onChange={onChange}
        fontWeight="500"
        boxShadow={[`none`, `none`, `1px 1px 6px rgba(0, 0, 0, 0.1)`]}
        _focusVisible={{
          zIndex: "1",
          borderColor: white,
          boxShadow: `0 0 0 1px rgba(255, 255, 255, 0.5)`,
        }}
        position="relative"
        top={["initial", "initial", "30px"]}
        _hover={{
          shadow: "1px 1px 12px rgba(0, 0, 0, 0.1)",
        }}
      />
      <InputLeftElement
        h="100%"
        pl={["3rem", "3rem", "1rem"]}
        top={["initial", "initial", "30px"]}
      >
        <Icon as={FaMagnifyingGlass} color={textSecondary} />
      </InputLeftElement>

      <InputRightElement
        h="100%"
        pr="0rem"
        w={["initial", "initial", "400px"]}
        top={["initial", "initial", "40px"]}
        right={["initial", "initial", "-1rem"]}
      >
        {elements}
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchBarMain;
