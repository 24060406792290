import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text, Stack, Icon, Image } from "@chakra-ui/react";
import {
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  white,
} from "../../../../utils/colors";
import { getNewClientsTop } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";
import { FaUser, FaSuitcase } from "react-icons/fa";
import noServices from "../../../../assets/images/empty-box.png";

const TopNewClients = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // REDUX

  const dashboardNewClientsTop = useSelector(
    (state) => state.dashboardNewClientsTop
  );
  const { loading, error, clients } = dashboardNewClientsTop;

  const [empty, setEmpty] = useState([]);

  // USE EFFECT

  useEffect(() => {
    dispatch(getNewClientsTop());
  }, [dispatch]);

  // LOGS

  //   console.log("clients", clients);

  if (loading) {
    return (
      <Flex align="center" justify="center" h="100%" w="100%">
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // LOGS
  console.log("clients", clients);

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Stack gap="0">
          <Link to="/admin/clients">
            <Text
              color={textPrimary}
              fontSize="sm"
              fontWeight="500"
              _hover={{
                color: primary,
              }}
            >
              {t("dashboard.newClientsTitle")}
              <Text as="span" fontWeight="500" color={textSecondary} ml={1}>
                {clients.length}
              </Text>
            </Text>
          </Link>

          <Text color={textSecondary} fontSize="xs" fontWeight="500">
            {t("dashboard.newClientsSubtitle")}
          </Text>
        </Stack>
      </Flex>

      <Flex direction="column" w="100%" p="1rem 0 0">
        {clients.map((client, index) => (
          <Flex
            key={index}
            align="center"
            justify="space-between"
            p="0.75rem 0rem"
            bg={white}
            transition="all 0.2s"
            position="relative"
            borderBottom={`1px solid ${newBorder}`}
            _hover={{
              bg: white,
              color: buttonBlue,
              cursor: "pointer",
              px: "0.75rem",
              borderBottom: `1px solid ${newBorder}`,
              borderRadius: "0rem",
            }}
            _last={{
              borderBottom: `1px solid ${white}`,
            }}
            onClick={() => {
              navigate(`/admin/clients/${client._id}`);
            }}
          >
            <Flex align="center">
              <Flex
                align="center"
                justify="center"
                w="1.75rem"
                h="1.75rem"
                borderRadius="10rem"
                bg={buttonBlue}
                mr={1.5}
              >
                <Icon
                  as={client.clientType === "individual" ? FaUser : FaSuitcase}
                  boxSize={2.5}
                  color={buttonBlueText}
                />
              </Flex>
              {client.clientType === "individual" ? (
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="500"
                  isTruncated
                  lineHeight={1}
                  maxW="100px"
                >
                  {client.firstName} {client.lastName}
                </Text>
              ) : (
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="500"
                  isTruncated
                  lineHeight={1}
                  maxW="100px"
                >
                  {client.companyName}
                </Text>
              )}
            </Flex>
            <Flex
              align="center"
              bg={backgroundLight}
              borderRadius="10rem"
              p="0.25rem 0.5rem"
            >
              <Text
                color={textSecondary}
                fontSize="xs"
                fontWeight="500"
                maxW="100px"
                isTruncated
              >
                {client.employee === null
                  ? "N/A"
                  : client &&
                    client.employee &&
                    client.employee.lastName &&
                    client.employee.firstName &&
                    client.employee.lastName +
                      " " +
                      client.employee.firstName[0] +
                      "."}
              </Text>
            </Flex>
          </Flex>
        ))}

        {clients.length === 0 && (
          <Flex align="center" justify="center" w="100%" p="0rem 0 1rem">
            <Flex
              justify="center"
              align="center"
              direction="column"
              p="1.5rem 0"
            >
              <Image h="3rem" w="auto" m="0 auto" src={noServices} />
              <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                {t("common.noData")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TopNewClients;
