import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Textarea,
  Stack,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  white,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { shade } from "polished";

const ConfirmApproveModal = ({
  report,
  isOpen,
  onClose,
  confirmAction,
  cancelReason,
  setCancelReason,
  isTextArea,
}) => {
  const { t } = useTranslation();

  const cancelReasons = [
    {
      title: t("implantFailureReports.cancelReportReason1Title"),
      text: t("implantFailureReports.cancelReportReason1Text"),
    },
    {
      title: t("implantFailureReports.cancelReportReason2Title"),
      text: t("implantFailureReports.cancelReportReason2Text"),
    },
  ];

  useEffect(() => {
    setCancelReason("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p="0rem 0"
        maxW="600px"
        borderRadius="2rem"
      >
        <ModalHeader
          color={textPrimary}
          borderBottom={`1px solid ${newBorder}`}
          p="1.5rem 2rem"
          w="100%"
        >
          <Text fontSize="1.125rem" fontWeight="600">
            {t("implantFailureReports.approveReportTitle")}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="1rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
          w="100%"
        >
          <Flex w="100%" p="1.5rem 0 0">
            <Flex align="center" direction="column" w="100%">
              <Text fontSize="sm" fontWeight="500" m="0">
                {t(
                  `implantForm.${
                    report &&
                    report.userId &&
                    report.userId.clientType &&
                    report.userId.clientType
                  }`
                )}{" "}
                -{" "}
                {report &&
                report.userId &&
                report.userId.clientType &&
                report.userId.clientType === "individual" ? (
                  <Text as="span" color={primary} fontWeight="600">
                    {report.userId.firstName + " " + report.userId.lastName}
                  </Text>
                ) : (
                  <Text as="span" color={primary} fontWeight="600">
                    {report &&
                      report.userId &&
                      report.userId.companyName &&
                      report.userId.companyName}
                  </Text>
                )}
              </Text>

              <Text fontSize="sm" fontWeight="500" m="0">
                {t("implantFailureReports.cancelReportTrackingNumber")}:{" "}
                <Text as="span" color={primary} fontWeight="600">
                  {report.trackingNumber}
                </Text>
              </Text>

              <Flex
                align="center"
                justify="center"
                border={`1px solid ${newBorder}`}
                borderRadius="1rem"
                p="1.5rem 1.5rem"
                m="0.5rem auto"
              >
                <Stack
                  gap="0rem"
                  mr="0rem"
                  minW="170px"
                  maxW="170px"
                  align="center"
                  justify="center"
                >
                  <Text fontSize="sm" fontWeight="600" color={textPrimary}>
                    {t(`implants.${report.fixture}`)}
                    {report.core === null ? null : (
                      <>
                        {report.core === "regular" ||
                        report.core === "special" ||
                        report.core === "deep" ? (
                          <Text
                            as="span"
                            fontSize="sm"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({t(`coreSimple.${report.core}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="sm"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            - (C{report.core})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                    {" "}
                    Ø{report.diameter}mm/L{report.length}{" "}
                  </Text>
                </Stack>

                <Flex
                  align="center"
                  gap="1rem"
                  px="1rem"
                  justify="center"
                  textAlign="center"
                >
                  <Icon
                    as={FaExchangeAlt}
                    fontSize="0.875rem"
                    color={textSecondary}
                    mr={2}
                  />
                </Flex>
                <Stack
                  gap="0rem"
                  mr="0rem"
                  minW="170px"
                  maxW="170px"
                  align="center"
                  justify="center"
                >
                  <Text fontSize="sm" fontWeight="600" color={textPrimary}>
                    {t(`implants.${report.newFixture}`)}
                    {report.newCore === null ? null : (
                      <>
                        {report.newCore === "regular" ||
                        report.newCore === "special" ||
                        report.newCore === "deep" ? (
                          <Text
                            as="span"
                            fontSize="sm"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({t(`coreSimple.${report.newCore}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="sm"
                            color={textPrimary}
                            fontWeight="600"
                          >
                            - (C{report.newCore})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                  <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                    {" "}
                    Ø{report.newDiameter}mm/L{report.newLength}{" "}
                  </Text>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderTop={`1px solid ${newBorder}`}
        >
          <Button
            bg={primary}
            color={white}
            size="lg"
            borderRadius="10rem"
            onClick={confirmAction}
            m={2}
            _hover={{
              bg: shade(0.3, primary),
            }}
          >
            {t("common.yes")}
          </Button>

          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            m={2}
          >
            {t("common.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmApproveModal;
