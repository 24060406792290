import React from "react";
import {
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  Spinner,
} from "@chakra-ui/react";
import {
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { icons } from "../../utils/icons";
import { useMediaQuery } from "react-responsive";

const ArrowBackIcon = () => <img src={icons.arrowBack} alt="back" />;

const SinglePageTopBar = ({
  backFunction,
  title,
  type,
  editFunction,
  children,
  loading,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Flex
      w="100%"
      p="1.5rem 2rem"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`1px solid ${newBorder}`}
    >
      {!loading ? (
        <>
          <Flex
            gap="0"
            align="center"
            justify="space-between"
            w="100%"
            direction={["column", "column", "row"]}
          >
            <HStack gap={0} alignItems="center">
              {!isMobile && (
                <IconButton
                  icon={<ArrowBackIcon />}
                  onClick={backFunction}
                  border={`1px solid ${newBorder}`}
                  borderRadius="10rem"
                  bg="transparent"
                  h="2.5rem"
                  w="2.5rem"
                  mr={4}
                />
              )}
              <Flex
                direction="column"
                gap={["0.25rem", "0.25rem", "initial"]}
                m={["0 0 1rem", "0 0 1rem", "0 0 0"]}
              >
                <Heading
                  fontSize={["1.125rem", "1.125rem", "1.125rem"]}
                  fontWeight="600"
                  color={textPrimary}
                >
                  {title}
                </Heading>
                <Text
                  fontSize="0.875rem"
                  fontWeight={["500", "500", "500"]}
                  color={textSecondary}
                >
                  {type}
                </Text>
              </Flex>
            </HStack>
            <HStack gap={0}>{children}</HStack>
          </Flex>
        </>
      ) : (
        <Spinner size="lg" color={primary} />
      )}
    </Flex>
  );
};

export default SinglePageTopBar;
