// ToothButton.jsx
import React from "react";
import { Flex } from "@chakra-ui/react";
import {
  white,
  textPrimary,
  primary,
  backgroundLight,
  textSecondary,
} from "../../../../../utils/colors"; // Adjust the import path as necessary

const ToothButton = ({ toothNumber, onClick, isSelected, minW }) => {
  return (
    <Flex
      bg={isSelected ? primary : toothNumber === 0 ? backgroundLight : "none"}
      onClick={onClick}
      m={0}
      //   size="sm"
      fontSize="13px"
      py="0.5rem"
      borderRadius="0.75rem"
      fontWeight="500"
      minWidth={minW ? minW : "34px"}
      align="center"
      justify="center"
      textAlign="center"
      color={isSelected ? white : toothNumber === 0 ? textPrimary : "#aaaaaa"}
      _hover={
        isSelected
          ? { bg: primary, color: white, cursor: "pointer" }
          : { bg: backgroundLight, color: textSecondary, cursor: "pointer" }
      }
    >
      {toothNumber}
    </Flex>
  );
};

export default ToothButton;
