import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import Select, { components } from 'react-select'; // Import components from react-select
import { useSelector, useDispatch } from 'react-redux';
import { listCourses } from '../../actions/courseActions';
import { white, textSecondary, newBorder } from '../../utils/colors';
import { useTranslation } from 'react-i18next';
import OptionsOutsideSelect from './OptionsOutsideSelect';

const MultiCourseSelectNoCurrent = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  placeholderText,
  title,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const [courseOptions, setCourseOptions] = useState([]);

  useEffect(() => {
    dispatch(listCourses());

    console.log('Courses select loaded', courses);
  }, [dispatch]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      const activeCourses = courses.filter(
        (course) => course.status !== 'completed'
      );
      console.log('courses', courses); // Add this line
      console.log('activeCourses', activeCourses);

      // Transform currentOptions to match options structure
      const transformedCurrentOptions = currentOptions.map((option) => ({
        value: option.course._id,
        label: option.course.title,
        image: option.course.image,
      }));

      console.log('transformedCurrentOptions', transformedCurrentOptions);
      console.log('course Options 3', courseOptions); // Add this line

      // Create options for active speakers, excluding transformedCurrentOptions
      const options = activeCourses
        .filter(
          (s) =>
            !transformedCurrentOptions.some(
              (current) => current.value === s._id
            )
        )
        .map((s) => ({
          value: s._id,
          label: s.title,
          image: s.image,
        }));

      setCourseOptions([...options]);
    }
  }, [courses, currentOptions]);

  console.log('course Options 1', courseOptions); // Add this line

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={courseOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
      </div>
    </>
  );
};

export default MultiCourseSelectNoCurrent;
