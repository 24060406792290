import axios from 'axios';
import {
  CLINIC_LIST_REQUEST,
  CLINIC_LIST_SUCCESS,
  CLINIC_LIST_FAIL,
  CLINIC_DETAILS_REQUEST,
  CLINIC_DETAILS_SUCCESS,
  CLINIC_DETAILS_FAIL,
  CLINIC_UPDATE_REQUEST,
  CLINIC_UPDATE_SUCCESS,
  CLINIC_UPDATE_FAIL,
  CLINIC_CREATE_REQUEST,
  CLINIC_CREATE_SUCCESS,
  CLINIC_CREATE_FAIL,
  CLINIC_DELETE_REQUEST,
  CLINIC_DELETE_SUCCESS,
  CLINIC_DELETE_FAIL,
} from '../constants/clinicContants';
import { logout } from './userActions';

export const listClinics =
  (keyword = '', pageNumber = '', pageSize = '', city = ' ') =>
  async (dispatch) => {
    try {
      dispatch({ type: CLINIC_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/clinics?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&city=${city}`
      );

      dispatch({
        type: CLINIC_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLINIC_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listClinicDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLINIC_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/clinics/${id}`);

    dispatch({
      type: CLINIC_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLINIC_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateClinic = (clinic) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLINIC_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/clinics/${clinic._id}`,
      clinic,
      config
    );

    dispatch({
      type: CLINIC_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: CLINIC_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLINIC_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }

    dispatch({
      type: CLINIC_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createClinic =
  (
    name,
    image,
    phone,
    email,
    profession,
    birthDate,
    clinicId,
    courseId,
    address
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLINIC_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/clinics`,
        {
          name,
          image,
          phone,
          email,
          profession,
          birthDate,
          clinicId,
          courseId,
          address,
        },
        config
      );

      dispatch({
        type: CLINIC_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLINIC_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: CLINIC_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteClinic = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLINIC_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/clinics/${id}`, config);

    dispatch({
      type: CLINIC_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CLINIC_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
};
