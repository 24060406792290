import React from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
} from "@chakra-ui/react";
import { icons } from "../../../utils/icons";
import {
  white,
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";

const SearchIcon = () => <img src={icons.search2} alt="search icon" />;

const SearchBarHeader = () => {
  const { t } = useTranslation();

  return (
    <InputGroup w="330px">
      <Input
        type="text"
        placeholder={t("placeholder.headerSearch")}
        size="lg"
        bg={backgroundLight}
        borderRadius="10rem"
        color={textPrimary}
        fontSize="1rem"
        fontWeight="500"
        border={`1px solid ${newBorder}`}
        _focusVisible={{
          zIndex: "1",
          borderColor: white,
          boxShadow: `0 0 0 1px rgba(255, 255, 255, 0.5)`,
        }}
        isDisabled
      />
      <InputLeftElement h="100%" pl="1rem">
        <IconButton
          h="34px"
          w="34px"
          icon={<SearchIcon />}
          borderRadius="full"
          _hover={{ bgColor: "none" }}
          _focus={{ bgColor: "none" }}
          variant="ghost"
        />
      </InputLeftElement>
    </InputGroup>
  );
};

export default SearchBarHeader;
