import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Icon,
} from "@chakra-ui/react";
import {
  newBorder,
  textSecondary,
  white,
  primary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import {
  updateProduct,
  listProductDetails,
  addProductCategory,
} from "../../../../actions/productActions";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import ImageUpload from "../../../../components/input/ImageUpload";
import CitySelect from "../../../../components/select/CitySelect";
import MiniNoItems from "../../../../components/partials/MiniNoItems";

import { TbPlus } from "react-icons/tb";
import MultiCourseSelectNoCurrent from "../../../../components/select/MultiCourseSelectNoCurrent";
import ProfessionSelect2 from "../../../../components/select/ProfessionSelect2";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import MultiCategorySelect from "../../../../components/select/MultiCategorySelect";
import CategoryList from "./partials/CategoryList";

const EditProduct = ({ productId }) => {
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const tabsData = [
    { label: t("product.tabProductDetails") },
    { label: t("product.tabProductCategories") },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [showAddCategory, setShowAddCategory] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [mpn, setMpn] = useState("");
  const [price, setPrice] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [customCategories, setCustomCategories] = useState([]);
  const [updatedCategories, setUpdatedCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);

  const productDetails = useSelector((state) => state.productDetails);
  const { product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const { success: successUpdate } = productUpdate;

  const handleShowAddCategory = () => {
    setShowAddCategory(!showAddCategory);
  };

  const removeCategoryHandler = (categoryId) => {
    console.log("categoryId", categoryId);
  };

  const addCategoryHandleNew = (values) => {
    console.log("values", values);
    // Get the array of speaker IDs from the values array
    const categoryIds = values.map((course) => course.value);

    console.log("categoryIds", categoryIds);

    // Check if any of the course IDs already exist in the attendees list
    const categoryExists = categoryIds.some((categoryId) =>
      categories.some((a) => a._id === categoryId)
    );

    if (categoryExists) {
      console.log("Category already assigned");
      toastComponent.showToast(t("toasts.courseError"), "error", 3000);
    } else {
      console.log("productId", productId);
      dispatch(addProductCategory(productId, categoryIds));

      const currentCourses = [...categories];

      console.log("currentCategories", currentCategories);

      setUpdatedCategories([...categoryIds]);

      console.log("updatedCategories", updatedCategories);
      toastComponent.showToast(t("toasts.categoryEdit"), "success", 3000);
      // You might need to update the course list after the action is dispatched
    }
  };

  const submitHandler = (values) => {
    dispatch(
      updateProduct({
        _id: productId,
        name: name,
        image: image,
        description: description,
        sku: sku,
        price: price,
        relatedProducts: relatedProducts,
        categories: updatedCategories,
      })
    );
  };

  useEffect(() => {
    console.log("Edit Product loaded", product);

    if (!product.name || product._id !== productId) {
      dispatch(listProductDetails(productId));
    } else {
      setName(product.name);
      setImage(product.image);
      setDescription(product.description);
      setSku(product.sku);
      setPrice(product.price);
      setRelatedProducts(product.relatedProducts);
      setCategories(product.categories);
      setUpdatedCategories(categories);
    }
  }, [dispatch, productId, product]);

  useEffect(() => {
    if (successUpdate) {
      toastComponent.showToast(t("toasts.productUpdate"), "success", 3000);
      dispatch({ type: "PRODUCT_UPDATE_RESET" });
    }
  }, [successUpdate]);

  return (
    <>
      <form onSubmit={submitHandler}>
        <TabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabsData}
          disabledTabs={[]}
          isTitleValid={true}
          isProfessionValid={true}
        >
          <TabPanels m="1.5rem 0 0">
            <TabPanel p={0}>
              <FormControl mb={6}>
                <FormLabel fontSize="sm">{t("product.productImage")}</FormLabel>
                <ImageUpload image={image} setImage={setImage} />
              </FormControl>

              <InputField
                label={t("form.name")}
                value={name}
                name="name"
                placeholder={t("placeholder.name")}
                onChange={(e) => setName(e.target.value)}
              />

              <InputField
                label={t("form.description")}
                value={description}
                name="description"
                placeholder={t("placeholder.description")}
                onChange={(e) => setDescription(e.target.value)}
              />

              <InputField
                label={t("form.sku")}
                value={sku}
                name="sku"
                placeholder={t("placeholder.sku")}
                onChange={(e) => setSku(e.target.value)}
              />

              <InputField
                label={t("form.mpn")}
                value={mpn}
                name="mpn"
                placeholder={t("placeholder.mpn")}
                onChange={(e) => setMpn(e.target.value)}
              />
            </TabPanel>
            {/* 
            <FormControl mt={4}>
              <>
                <FormLabel fontSize="sm">
                  <Flex justify="space-between">
                    {t("product.addNewCategory")}
                  </Flex>
                </FormLabel>

                <Flex w="100%" mb={10} flexDirection="column">
                  <Box w="100%">
                    <MultiCategorySelect
                      currentOptions={categories}
                      value={customCategories}
                      onChange={addCategoryHandleNew}
                      title={t("speaker.addNewCourse")}
                      placeholderText={t("select.course")}
                    />
                  </Box>
                </Flex>
              </>
            </FormControl> */}

            <TabPanel p={0}>
              <FormControl mb={4}>
                {!showAddCategory && (
                  <FormLabel
                    mt={4}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                  >
                    {t("product.courses")}
                    <Text ml={1} color={textSecondary}>
                      ({categories.length})
                    </Text>
                  </FormLabel>
                )}

                {categories.length > 0 ? null : (
                  <Box>
                    {!showAddCategory && (
                      <Box
                        p={0}
                        textAlign="center"
                        border={`1px dashed ${newBorder}`}
                        borderRadius="md"
                      >
                        <MiniNoItems
                          title={t("speaker.noCourses")}
                          text={t("speaker.noCoursesText")}
                          buttonFunction={handleShowAddCategory}
                          buttonLabel={t("speaker.noCoursesButtonLabel")}
                        />
                      </Box>
                    )}
                  </Box>
                )}

                <Box>
                  {categories.length > 0 ? (
                    <Box>
                      {showAddCategory && (
                        <FormLabel
                          mt={4}
                          fontSize="sm"
                          display="flex"
                          alignItems="center"
                        >
                          {t("speaker.courses")}
                          <Text ml={1} color={textSecondary}>
                            ({categories.length})
                          </Text>
                        </FormLabel>
                      )}
                      <CategoryList
                        categories={categories}
                        removeCategoryHandler={removeCategoryHandler}
                      />
                    </Box>
                  ) : null}

                  {categories.length > 0 && !showAddCategory && (
                    <Box mt={1}>
                      <Button
                        variant="ghost"
                        size="md"
                        fontWeight="600"
                        fontSize="0.875rem"
                        color={textSecondary}
                        onClick={handleShowAddCategory}
                      >
                        <Icon
                          as={TbPlus}
                          mr="0.5rem"
                          color={textSecondary}
                          transition="all 0.2s"
                          _hover={{
                            color: textPrimary,
                            cursor: "pointer",
                            transform: "scale(1.2)",
                          }}
                        />
                        {t("speaker.addNewCourse")}
                      </Button>
                    </Box>
                  )}

                  {showAddCategory && (
                    <FormControl mt={4}>
                      <>
                        <Flex w="100%" mb={10} flexDirection="column">
                          <Box w="100%">
                            <MultiCategorySelect
                              currentOptions={categories}
                              value={customCategories}
                              onChange={addCategoryHandleNew}
                              title={t("speaker.addNewCourse")}
                              placeholderText={t("select.course")}
                            />
                          </Box>
                        </Flex>
                      </>
                    </FormControl>
                  )}
                </Box>
              </FormControl>
            </TabPanel>

            <Flex
              mt={4}
              justify="flex-end"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="2rem 0"
            >
              <Flex w="100%" direction="column" p="0 0 1rem"></Flex>
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  //   isDisabled={!isTitleValid || !isProfessionValid}
                >
                  {t("product.editProduct")}
                </Button>

                {activeTab < 2 && (
                  <Button
                    minWidth="10rem"
                    ml={4}
                    bg={backgroundLight}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab + 1)}
                    // isDisabled={!isTitleValid || !isProfessionValid}
                  >
                    {t("common.continue")}
                  </Button>
                )}
              </Flex>
            </Flex>
          </TabPanels>
        </TabsComponent>
      </form>
    </>
  );
};

export default EditProduct;
