import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Spinner,
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  Icon,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
  buttonRed,
  buttonRedText,
  buttonYellowText,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { USER_CANCEL_REPORT_RESET } from "../../../../../constants/userConstants";
import Header from "../../../../landing/layout/Header";
import AccountTabs from "../../partials/AccountTabs";
import Faq from "../../../../general/partials/Faq";
import Footer from "../../../../landing/layout/Footer";
import SinglePageTopBar from "../../../../../components/layout/SinglePageTopBar";
import {
  getUserSingleReport,
  cancelReport,
} from "../../../../../actions/userActions";
import ReportInfo from "./partials/ReportInfo";
import ConfirmCancelModal from "../../../../../components/modals/ConfirmCancelModal";
import StatusButton from "../../../../../components/buttons/StatusButton";
import { useMediaQuery } from "react-responsive";
import { FaArrowLeft } from "react-icons/fa6";

const UserSingleReport = () => {
  // ID
  const reportId = useParams().id;

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // REF
  const lastReportRef = useRef(null);

  // STATE
  const [reports, setReports] = useState([]);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const [isEmployee, setIsEmployee] = useState(false);
  const [tabIndex, setTabIndex] = useState(1); // State to track the active tab index

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userGetSingleReport = useSelector((state) => state.userGetSingleReport);

  const {
    loading: loadingImplantFailureReportDetails,
    error: errorImplantFailureReportDetails,
    report,
  } = userGetSingleReport;

  const userCancelReport = useSelector((state) => state.userCancelReport);

  const {
    loading: loadingCancelReport,
    error: errorCancelReport,
    success: successCancelReport,
  } = userCancelReport;

  // HANDLERS

  const cancelReportHandler = () => {
    dispatch(cancelReport(userInfo._id, reportId, cancelReason));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(tabs[index].path);
  };

  // TABS

  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserSingleReport(userInfo._id, reportId));
    }
  }, [dispatch, userInfo, reportId, successCancelReport, errorCancelReport]);

  useEffect(() => {
    if (errorImplantFailureReportDetails) {
      toast({
        title: "Error",
        description: errorImplantFailureReportDetails,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [errorImplantFailureReportDetails]);

  useEffect(() => {
    if (successCancelReport) {
      toast({
        title: t("implantFailureReports.cancelClientReportSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: USER_CANCEL_REPORT_RESET });
    } else if (errorCancelReport) {
      toast({
        title: t("implantFailureReports.cancelClientReportError"),
        description: errorCancelReport,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [successCancelReport, errorCancelReport]);

  //   useEffect(() => {
  //     return () => {
  //       dispatch({ type: USER_GET_SINGLE_REPORT_RESET });
  //     };
  //   }, []);

  // useEffect(() => {
  //   console.log("Component mounted or updated with report: ", report);
  // }, [report]);

  // LOGS

  // console.log("tabIndex", tabIndex);
  // console.log("report", report);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountReportsSingle")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountReportsSingle")}
        />
      </Helmet>

      <Header />

      {showCancelModal && (
        <ConfirmCancelModal
          report={report}
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          confirmAction={() => {
            cancelReportHandler();
            setShowCancelModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
      )}

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        <AccountTabs tabs={tabs} fixedTabIndex={1} />

        <Flex
          w="100%"
          justify="center"
          gap="2rem"
          p="3rem 0 0"
          bg={backgroundLight}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p="0 0rem 6rem"
            direction="column"
            m="0rem auto 0"
            minH={["80vh", "80vh", "80vh"]}
          >
            {isMobile && (
              <Flex align="center" p="0rem 1.5rem 1rem">
                <Link to="/account/forms">
                  <Button
                    variant="link"
                    size="lg"
                    fontSize="sm"
                    borderRadius="10rem"
                    fontWeight="500"
                    role="group"
                    color={textSecondary}
                    p="0.5rem 1rem"
                    left="-1rem"
                    _hover={{
                      bg: backgroundLight,
                      color: textPrimary,
                    }}
                  >
                    <Icon
                      as={FaArrowLeft}
                      fontSize="0.75rem"
                      mr="0.5rem"
                      _groupHover={{
                        color: textPrimary,
                      }}
                    />
                    {t("common.back")}
                  </Button>
                </Link>
              </Flex>
            )}

            <Flex w="100%" bg={white} direction="column" borderRadius="2rem">
              <SinglePageTopBar
                backFunction={() => navigate(-1)}
                title={t("implantFailureReports.singleTitle")}
                type={report.trackingNumber}
                //   loading={loading}
              >
                <StatusButton
                  isUnclickable
                  currentStatus={report.status}
                  //  onUpdateStatus={handleUpdateStatus}
                  options="report"
                />
                {/* <MoreButton
                  id={report._id}
                  isBig
                  deleteHandler={cancelReportHandler}
                  deleteLabel={t("implantFailureReports.cancelReport")}

                  // editHandler={(e) => {
                  //   e.stopPropagation();
                  //   openEditSidePanel(courseId);
                  // }}
                /> */}
              </SinglePageTopBar>

              {!loadingImplantFailureReportDetails ? (
                <>
                  <Flex direction="column" w="100%">
                    <Flex
                      gap={0}
                      p="2.5rem 2.5rem 1.5rem 2.5rem"
                      alignItems="flex-start"
                      justifyContent="center"
                      w="100%"
                    >
                      <Flex direction="column" w="100%">
                        <ReportInfo data={report} userInfo={userInfo} />
                      </Flex>
                      {/* <SinglePageImageWrapper
                    id={id}
                    borderRadius="2rem"
                    // image={course.image}
                    options="course"
                  /> */}
                    </Flex>

                    <Flex
                      w="100%"
                      p={[
                        "3rem 1.5rem 3rem",
                        "3rem 1.5rem 3rem",
                        "0rem 2.5rem 3rem",
                      ]}
                      direction="column"
                      borderTop={[
                        `1px solid ${newBorder}`,
                        `1px solid ${newBorder}`,
                        `0px solid ${newBorder}`,
                      ]}
                    >
                      <Flex
                        alignItems="center"
                        m="0rem 0 1.5rem"
                        direction={["column", "column", "row"]}
                      >
                        <Heading
                          fontSize="md"
                          fontWeight="600"
                          color={textPrimary}
                          mr="1rem"
                        >
                          {t("implantFailureReports.reportStatusTitle")}
                        </Heading>
                        <Flex p="0.35rem 0" alignItems="center">
                          {report.authorType === "employee" && (
                            <Flex direction="column">
                              <Flex align="center">
                                <Text
                                  fontSize="sm"
                                  color={buttonYellowText}
                                  fontWeight="500"
                                >
                                  {t("implantForm.author")}
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>

                      <Flex
                        border={`1px solid ${newBorder}`}
                        borderRadius="1rem"
                        w="100%"
                        p="2rem 2rem"
                      >
                        <Flex
                          align="flex-start"
                          justify={
                            report.status === "pending"
                              ? "space-between"
                              : "center"
                          }
                          w="100%"
                          gap="2rem"
                        >
                          {report.status === "pending" && (
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                              maxW="50%"
                            >
                              {t("implantFailureReports.reportStatusText")}
                            </Text>
                          )}

                          {report.status === "closed" && (
                            <Stack
                              gap="0.25rem"
                              maxW="100%"
                              justify="center"
                              align="center"
                              textAlign="center"
                            >
                              <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                                isBig
                              />
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                                m="1rem 0 0"
                              >
                                {t("implantFailureReports.closedReportText")} -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                            </Stack>
                          )}

                          {report.status === "approved" && (
                            <Stack
                              gap="0.25rem"
                              maxW="100%"
                              justify="center"
                              align="center"
                              textAlign="center"
                            >
                              <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                                isBig
                              />
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                                m="1rem 0 0"
                              >
                                {t("implantFailureReports.approvedReportText")}{" "}
                                -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                              {/* <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                              >
                                {t(
                                  "implantFailureReports.approvedReportTextNextstep"
                                )}{" "}
                              </Text> */}
                            </Stack>
                          )}

                          {report.status === "rejected" && (
                            <Stack
                              gap="0.25rem"
                              maxW="100%"
                              justify="center"
                              align="center"
                              textAlign="center"
                            >
                              <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                              />
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                                m="1rem 0 0"
                              >
                                {t("implantFailureReports.rejectedReportText")}{" "}
                                -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                              >
                                {t("implantFailureReports.cancelReason")} -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {report.cancelReason}
                                </Text>
                              </Text>
                            </Stack>
                          )}

                          {report.status === "cancelled" && (
                            <Stack
                              gap="0.25rem"
                              maxW="100%"
                              justify="center"
                              align="center"
                              textAlign="center"
                            >
                              <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                              />
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                                m="1rem 0 0"
                              >
                                {t("implantFailureReports.cancelledReportText")}{" "}
                                -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {dayjs(report.statusDate).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </Text>
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                maxW="100%"
                              >
                                {t("implantFailureReports.cancelReason")} -
                                <Text
                                  as="span"
                                  color={textPrimary}
                                  fontWeight="500"
                                  ml={1}
                                  textTransform="capitalize"
                                >
                                  {report.cancelReason}
                                </Text>
                              </Text>
                            </Stack>
                          )}

                          {report.status === "pending" && (
                            <Flex
                              align="center"
                              justify="flex-end"
                              gap="1.5rem"
                            >
                              <Flex align="center" mr="0rem">
                                <Button
                                  size="lg"
                                  borderRadius="10rem"
                                  bg={white}
                                  color={buttonRedText}
                                  fontSize="sm"
                                  fontWeight="500"
                                  border={`1px solid ${newBorder}`}
                                  // fontWeight="500"
                                  onClick={() => setShowCancelModal(true)}
                                  _hover={{
                                    bg: buttonRed,
                                  }}
                                >
                                  {t("implantFailureReports.cancelExchange")}
                                </Button>

                                {/* <StatusButton
                                isUnclickable
                                currentStatus={report.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="report"
                                isBig
                              /> */}
                              </Flex>

                              {/* <Button
                              size="lg"
                              borderRadius="10rem"
                              bg={primary}
                              color={white}
                              fontSize="sm"
                              // fontWeight="500"
                              border={`1px solid ${primary}`}
                              onClick={() => navigate(-1)}
                              _hover={{
                                bg: shade(0.3, primary),
                              }}
                            >
                              {t("implantFailureReports.approveExchange")}
                            </Button>
                            <Button
                              size="lg"
                              borderRadius="10rem"
                              bg={white}
                              color={buttonRedText}
                              fontSize="sm"
                              border={`1px solid ${buttonRedText}`}
                              // fontWeight="500"
                              onClick={() => navigate(-1)}
                              _hover={{
                                bg: buttonRed,
                              }}
                            >
                              {t("implantFailureReports.cancelExchange")}
                            </Button> */}
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex justify="center" align="center" w="100%" p="8rem 2rem">
                  <Spinner size="xl" color={primary} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          bg={white}
          borderTop={`1px solid ${newBorder}`}
          w="100%"
          p="3rem 0"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Flex w="100%" maxW="1200px" m="0 auto">
            <Faq />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default UserSingleReport;
