import React from 'react';
import styled from 'styled-components';

const SidebarContainer = ({ children }) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  padding: 0rem 0rem;
`;

export default SidebarContainer;
