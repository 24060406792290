import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Spinner,
  Flex,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  Icon,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getUserReports } from "../../../../../actions/userActions";
import {
  TbCalendar,
  TbHash,
  TbDentalBroken,
  TbRadioactive,
  TbUser,
} from "react-icons/tb";
import StatusButton from "../../../../../components/buttons/StatusButton";
import { FaExchangeAlt, FaInfoCircle } from "react-icons/fa";
import noServices from "../../../../../assets/images/empty-box.png";
import { USER_CLIENT_GET_REPORTS_RESET } from "../../../../../constants/userConstants";
import { motion } from "framer-motion";
import { shade } from "polished";
import Header from "../../../../landing/layout/Header";
import AccountTabs from "../../partials/AccountTabs";
import { TbSearch } from "react-icons/tb";
import Faq from "../../../../general/partials/Faq";
import Footer from "../../../../landing/layout/Footer";
import { useMediaQuery } from "react-responsive";
import { FaInfo } from "react-icons/fa6";

const UserReports = () => {
  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // REF
  const lastReportRef = useRef(null);

  // STATE

  const [tabIndex, setTabIndex] = useState(1); // State to track the active tab index

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [page, setPage] = useState(1);

  const userClientGetReports = useSelector(
    (state) => state.userClientGetReports
  );

  const {
    loading: loadingReports,
    error: errorReports,
    userReports,
    pages,
    totalReports,
  } = userClientGetReports;

  // TABS

  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // HANDLERS

  const loadMore = () => {
    if (page < pages) {
      setPage((prev) => prev + 1);
    }
  };

  const scrollToLastReport = () => {
    if (lastReportRef.current) {
      lastReportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserReports(userInfo._id, page));
    }
  }, [userInfo, page]);

  useEffect(() => {
    if (page > 1) {
      // Ensure this effect runs only after initial load
      scrollToLastReport();
    }
  }, [page]); // Depend on the length of userReports to trigger scroll

  useEffect(() => {
    if (errorReports) {
      toast({
        title: "Error",
        description: errorReports,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [errorReports]);

  useEffect(() => {
    return () => {
      dispatch({ type: USER_CLIENT_GET_REPORTS_RESET });
    };
  }, []);

  // useEffect(() => {
  //   console.log("Component mounted or updated with reports: ", userReports);
  // }, [userReports]);

  const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
    return (
      <Flex align="center">
        <Flex
          w="1.25rem"
          h="1.25rem"
          align="center"
          justify="center"
          bg={white}
          mr={2}
          borderRadius="50%"
          flexShrink="0"
        >
          <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
        </Flex>
        {text && (
          <Text
            fontSize="sm"
            fontWeight="500"
            textTransform={capitalize ? "capitalize" : "none"}
            opacity={0.6}
            mr={1}
            whiteSpace="nowrap"
          >
            {text}:
          </Text>
        )}

        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          maxW={maxW}
          isTruncated={maxW ? true : false}
        >
          {label}
        </Text>
      </Flex>
    );
  };

  // LOGS
  console.log("userReports", userReports);
  // console.log("pages", pages);
  // console.log("totalReports", totalReports);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountReports")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountReports")}
        />
      </Helmet>

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        <AccountTabs tabs={tabs} />

        <Flex
          w="100%"
          justify="center"
          gap="2rem"
          p="3rem 0 0"
          bg={backgroundLight}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p={["0 1.5rem 3rem", "0 1.5rem 3rem", "0 0rem 3rem"]}
            direction="column"
            m="0rem auto 0"
            minH="80vh"
          >
            <Flex
              w="100%"
              align="center"
              justify="space-between"
              minH={["initial", "initial", "3rem"]}
              direction={["column", "column", "row"]}
            >
              <Flex
                align={["flex-start", "flex-start", "center"]}
                direction={["column", "column", "row"]}
                w={["100%", "100%", "100%"]}
              >
                <Heading
                  fontSize="1.125rem"
                  fontWeight="600"
                  color={textPrimary}
                  mr={["0rem 0 0.5rem", "0rem 0rem 0.5rem", "3rem"]}
                  mb={["1rem", "1rem", "initial"]}
                >
                  {t("clientAccount.myReports")}{" "}
                  <Text as="span" fontWeight="500">
                    ({userClientGetReports.totalReports})
                  </Text>
                </Heading>
                <InputGroup w={["100%", "100%", "500px"]}>
                  <Input
                    type="text"
                    placeholder={t("placeholder.headerSearch")}
                    size="lg"
                    bg={white}
                    borderRadius="10rem"
                    color={textPrimary}
                    fontSize="1rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    _focusVisible={{
                      zIndex: "1",
                      borderColor: white,
                      boxShadow: `0 0 0 1px rgba(255, 255, 255, 0.5)`,
                    }}

                    // isDisabled
                  />
                  <InputLeftElement h="100%" pl="1rem">
                    <IconButton
                      h="34px"
                      w="34px"
                      color={textSecondary}
                      icon={<TbSearch />}
                      borderRadius="full"
                      _hover={{ bgColor: "none" }}
                      _focus={{ bgColor: "none" }}
                      variant="ghost"
                    />
                  </InputLeftElement>
                </InputGroup>
              </Flex>

              <Flex
                align="center"
                gap="1.5rem"
                w={["initial", "initial", "initial"]}
                justify="flex-end"
                display={["none", "none", "flex"]}
              >
                {/* <Button
                  bg={white}
                  color={textSecondary}
                  size="lg"
                  fontSize="sm"
                  borderRadius="10rem"
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                >
                  Filter
                </Button> */}
                <Button
                  bg={primary}
                  color={white}
                  size="lg"
                  fontSize="sm"
                  borderRadius="10rem"
                  onClick={() => {
                    navigate("/implant-fail-form");
                  }}
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                >
                  {t("clientAccount.newReport")}
                </Button>
              </Flex>
            </Flex>
            {!loadingReports ? (
              <>
                <Flex direction="column" w="100%">
                  <Stack gap="1rem" w="100%" m="1rem 0">
                    {userReports.map((report, index) => (
                      <Flex
                        as={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        key={report._id}
                      >
                        <Flex
                          bg={white}
                          w="100%"
                          p="1.5rem 2rem"
                          borderRadius="1rem"
                          align="center"
                          justify="space-between"
                          ref={
                            report._id === userReports.length - 1
                              ? lastReportRef
                              : null
                          }
                          transition="all 0.2s"
                          onClick={() => {
                            navigate(`/account/forms/${report._id}`);
                          }}
                          _hover={{
                            cursor: "pointer",
                            shadow: "sm",
                            transform: "scale(1.02)",
                          }}
                        >
                          <Flex align="center">
                            <Stack
                              gap="0.25rem"
                              mr={["0rem", "0rem", "2rem"]}
                              w={["100px", "100px", "180px"]}
                            >
                              <Heading
                                fontSize="15px"
                                fontWeight="600"
                                textTransform="capitalize"
                              >
                                {dayjs(report.createdAt).format("MMMM D")}
                              </Heading>
                              <Text
                                color={textSecondary}
                                fontSize="xs"
                                fontWeight="500"
                                maxW={["100px", "100px", "initial"]}
                                isTruncated
                              >
                                {report.trackingNumber}
                              </Text>
                            </Stack>

                            <Stack
                              gap="0rem"
                              mr="0rem"
                              minW="170px"
                              maxW="170px"
                              align="center"
                              justify="center"
                              display={["none", "none", "flex"]}
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="600"
                                color={textPrimary}
                              >
                                {t(`implants.${report.fixture}`)}
                                {report.core === null ? null : (
                                  <>
                                    {report.core === "regular" ||
                                    report.core === "special" ||
                                    report.core === "deep" ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color={textPrimary}
                                        fontWeight="500"
                                      >
                                        {" "}
                                        - ({t(`coreSimple.${report.core}`)})
                                      </Text>
                                    ) : (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color={textPrimary}
                                        fontWeight="500"
                                      >
                                        - (C{report.core})
                                      </Text>
                                    )}
                                  </>
                                )}
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                              >
                                {" "}
                                Ø{report.diameter}mm/L{report.length}{" "}
                              </Text>
                            </Stack>

                            <Flex
                              align="center"
                              gap="1rem"
                              px="1rem"
                              display={["none", "none", "flex"]}
                            >
                              <Icon
                                as={FaExchangeAlt}
                                fontSize="0.875rem"
                                color={textSecondary}
                                mr={2}
                              />
                            </Flex>
                            <Stack
                              gap="0rem"
                              mr="6rem"
                              minW="170px"
                              maxW="170px"
                              align="center"
                              justify="center"
                              display={["none", "none", "flex"]}
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="600"
                                color={textPrimary}
                              >
                                {t(`implants.${report.newFixture}`)}
                                {report.newCore === null ? null : (
                                  <>
                                    {report.newCore === "regular" ||
                                    report.newCore === "special" ||
                                    report.newCore === "deep" ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color={textPrimary}
                                        fontWeight="500"
                                      >
                                        {" "}
                                        - ({t(`coreSimple.${report.newCore}`)})
                                      </Text>
                                    ) : (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color={textPrimary}
                                        fontWeight="600"
                                      >
                                        - (C{report.newCore})
                                      </Text>
                                    )}
                                  </>
                                )}
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                              >
                                {" "}
                                Ø{report.newDiameter}mm/L{report.newLength}{" "}
                              </Text>
                            </Stack>

                            <Stack
                              gap="0.25rem"
                              display={["none", "none", "flex"]}
                            >
                              <Flex align="center">
                                <Flex
                                  align="center"
                                  justify="center"
                                  w="20px"
                                  h="20px"
                                  borderRadius="10rem"
                                  bg={backgroundLight}
                                  mr={1}
                                  flexShrink={0}
                                >
                                  <Icon
                                    as={TbDentalBroken}
                                    boxSize={2.5}
                                    color={shade(0.3, backgroundLight)}
                                  />
                                </Flex>
                                <Text
                                  fontSize="sm"
                                  color={textPrimary}
                                  fontWeight="500"
                                  isTruncated
                                  noOfLines={1}
                                  whiteSpace="normal"
                                  overflow="hidden"
                                  w="100%"
                                  textTransform="capitalize"
                                >
                                  {report.tooth ? `${report.tooth}` : ""}
                                </Text>
                              </Flex>

                              <Flex align="center">
                                {report.reason === "Other" ? (
                                  <Text
                                    fontSize="xs"
                                    color={textSecondary}
                                    fontWeight="500"
                                    isTruncated
                                    noOfLines={1}
                                    whiteSpace="normal"
                                    overflow="hidden"
                                    maxW="160px"
                                    w="100%"
                                    textTransform="capitalize"
                                  >
                                    {report.reasonText}
                                  </Text>
                                ) : (
                                  <Text
                                    fontSize="xs"
                                    color={textSecondary}
                                    fontWeight="500"
                                    isTruncated
                                    noOfLines={1}
                                    whiteSpace="normal"
                                    overflow="hidden"
                                    maxW="160px"
                                    w="100%"
                                    textTransform="capitalize"
                                  >
                                    {t(`implantForm.${report.reason}`)}
                                  </Text>
                                )}
                              </Flex>
                            </Stack>
                          </Flex>

                          <Flex align="center" gap="1rem">
                            <Tooltip
                              p="1rem 1rem"
                              label={
                                <Flex direction="column" gap="0.5rem">
                                  <TooltipEntry
                                    label={dayjs(report.createdAt).format(
                                      "MMMM D, YYYY"
                                    )}
                                    icon={TbCalendar}
                                    capitalize
                                  />
                                  <TooltipEntry
                                    label={report.trackingNumber}
                                    icon={TbHash}
                                  />
                                  <Flex
                                    w="100%"
                                    h="1px"
                                    bg={textSecondary}
                                    m="0.25rem 0"
                                  />
                                  {report.reason &&
                                  report.reasonText &&
                                  report.reason === "Other" ? (
                                    <TooltipEntry
                                      label={report.reasonText}
                                      icon={TbDentalBroken}
                                      maxW="160px"
                                      text={t("implantForm.reason")}
                                    />
                                  ) : (
                                    <TooltipEntry
                                      label={t(`implantForm.${report.reason}`)}
                                      icon={TbDentalBroken}
                                      maxW="160px"
                                      text={t("implantForm.reason")}
                                    />
                                  )}

                                  <TooltipEntry
                                    label={report.tooth}
                                    icon={TbDentalBroken}
                                    text={t("implantForm.teeth")}
                                  />

                                  <TooltipEntry
                                    label={
                                      report.surgeryDate === null
                                        ? "-"
                                        : dayjs(report.surgeryDate).format(
                                            "YYYY-MM-DD"
                                          )
                                    }
                                    icon={TbDentalBroken}
                                    text={t("implantForm.surgeryDate")}
                                  />

                                  <TooltipEntry
                                    label={
                                      report.failureDate !== null
                                        ? dayjs(report.failureDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        : "-"
                                    }
                                    icon={TbDentalBroken}
                                    text={t("implantForm.failureDateAlt")}
                                  />

                                  <TooltipEntry
                                    label={
                                      report.beforeXrayImage
                                        ? t("implantForm.exists")
                                        : t("implantForm.noExists")
                                    }
                                    icon={TbRadioactive}
                                    text={t("implantForm.beforeImage")}
                                  />
                                  <TooltipEntry
                                    label={
                                      report.afterXrayImage
                                        ? t("implantForm.exists")
                                        : t("implantForm.noExists")
                                    }
                                    icon={TbRadioactive}
                                    text={t("implantForm.afterImage")}
                                  />
                                  <TooltipEntry
                                    label={
                                      report.surgeonFirstName &&
                                      report.surgeonLastName &&
                                      report.surgeonFirstName +
                                        " " +
                                        report.surgeonLastName
                                    }
                                    icon={TbUser}
                                    text={t("implantForm.surgeon")}
                                  />

                                  <TooltipEntry
                                    label={
                                      report.documentNumber
                                        ? report.documentNumber
                                        : "-"
                                    }
                                    icon={TbHash}
                                    maxW="250px"
                                    text={t("form.documentNumber")}
                                  />
                                </Flex>
                              }
                              borderRadius="1rem"
                            >
                              <Button
                                h="2.5rem"
                                w="2.5rem"
                                bg={backgroundLight}
                                border={`1px solid ${newBorder}`}
                                borderRadius="0.875rem"
                                display={["none", "none", "flex"]}
                              >
                                <Icon
                                  as={FaInfo}
                                  fontSize="0.75rem"
                                  color={textSecondary}
                                />
                              </Button>
                            </Tooltip>
                            <StatusButton
                              isUnclickable
                              currentStatus={
                                report && report.status && report.status
                              }
                              options="report"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </Stack>
                  {page < pages && (
                    <Flex align="center" justify="center" w="100%" p="1rem 0">
                      <Button
                        onClick={loadMore}
                        isLoading={loadingReports}
                        size="lg"
                        m="0 auto"
                        borderRadius="0.875rem"
                        fontSize="sm"
                      >
                        {t("common.loadMore")}
                      </Button>
                    </Flex>
                  )}
                </Flex>

                {userReports && userReports.length === 0 && (
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    p="6rem 0"
                    bg={white}
                    borderRadius="1rem"
                    direction="column"
                    border={`0px solid ${newBorder}`}
                  >
                    <Flex
                      justify="center"
                      align="center"
                      direction="column"
                      p="3rem 0"
                    >
                      <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                      <Text
                        fontSize="sm"
                        color={textSecondary}
                        fontWeight="500"
                        m="1rem auto 1rem"
                        maxW="300px"
                        textAlign="center"
                      >
                        {t("clientAccount.noReportsText")}
                      </Text>
                      <Button
                        bg={primary}
                        color={white}
                        size="lg"
                        fontSize="sm"
                        borderRadius="10rem"
                        onClick={() => {
                          navigate("/implant-fail-form");
                        }}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                      >
                        {t("clientAccount.noReportsButton")}
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </>
            ) : (
              <Flex
                w="100%"
                align="center"
                justify="center"
                bg={white}
                borderRadius="1rem"
                p="8rem 0"
                m="1rem 0 0"
              >
                <Spinner size="lg" color={primary} />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          bg={white}
          borderTop={`1px solid ${newBorder}`}
          w="100%"
          p="3rem 0"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Flex w="100%" maxW={["100%", "100%", "1200px"]} m="0 auto">
            <Faq />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default UserReports;
