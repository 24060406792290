import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listProducts,
  createProduct,
  deleteProduct,
} from "../../../../actions/productActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, Button, Flex } from "@chakra-ui/react";
import {
  textSecondary,
  white,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/ProductsColumns"; // Adjust the path according to your file structure
// import CreateProduct from "../sidepanels/CreateProduct";
import EditProduct from "../sidepanels/EditProduct";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";

const Products = () => {
  const dispatch = useDispatch();

  const toast = useToast();
  const toastComponent = ToastComponent();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translate = (key) => t(key);

  const [productId, setProductId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const pageSizes = [4, 10, 25, 100];

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const { success: successDelete } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const deleteProductHandler = (productId) => {
    dispatch(deleteProduct(productId));
    toastComponent.showToast(t("toasts.productDelete"), "success", 3000);
    dispatch({ type: "PRODUCT_DELETE_RESET" });
    if (products.length === 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
    if (successCreate) {
      toastComponent.showToast(t("toasts.productCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch(listProducts(searchTitle, page, pageSize));
    } else {
      dispatch(listProducts(searchTitle, page, pageSize));
    }
    if (errorCreate) {
      toast({
        title: "Error",
        description: errorCreate,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [
    dispatch,
    toast,
    userInfo,
    navigate,
    searchTitle,
    page,
    pageSize,

    successCreate,
    errorCreate,
    successDelete,
    createdProduct,
  ]);

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setProductId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setProductId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setProductId("");
    document.body.classList.remove("no-scroll");
  };

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  console.log("products", products);

  return (
    <>
      <Helmet>
        <title>Admin Products</title>
        <meta
          name="description"
          content="Megagen SMT Tool administration products list"
        />
      </Helmet>
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("product.createProduct")}
        noBorder={true}
      >
        {/* <CreateProduct /> */}
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("product.editProduct")}
        noBorder={true}
      >
        <EditProduct productId={productId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("product.products")}
                onChange={onChangeSearchTitle}
                placeholder={t("product.search")}
                searchName={searchTitle}
                buttonLabel={t("product.buttonLabel")}
                buttonHandler={openSidePanelModal}
              >
                <Button
                  leftIcon={<FilterIcon />}
                  bg={white}
                  color={textSecondary}
                  border={`1px solid ${isFilterVisible ? primary : newBorder}`}
                  variant="solid"
                  size="lg"
                  borderRadius="10rem"
                  mr={6}
                  fontSize="16px"
                  onClick={() => setIsFilterVisible(!isFilterVisible)}
                >
                  {t("common.filter")}
                </Button>
              </Table.Top>
            </Box>

            <Table.Container
              columns={columns(
                openEditSidePanelModal,
                translate,
                deleteProductHandler,
                page,
                pageSize
              )}
              data={products}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/admin/stock/products"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noProducts")}
              noItemsDescription={t("noItems.noProductsText")}
              noItemsButtonLabel={t("noItems.noProductsButton")}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Products;
