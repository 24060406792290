import React, { useState, useMemo, useEffect, Component } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import {
  Stack,
  Spinner,
  Flex,
  Text,
  FormControl,
  Icon,
  Avatar,
  background,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { listAttendeesSimple } from "../../actions/attendeeActions";
import dayjs from "dayjs";
import StatusBadge from "../../components/badges/StatusBadge";
import { tint } from "polished";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  buttonBlue,
  buttonBlueText,
  backgroundLight,
  buttonGreenText,
  buttonGreen,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaUser, FaSuitcase, FaCheckCircle } from "react-icons/fa";

const height = 48;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    const dynamicHeight = Math.min(children.length, 4) * height;

    return (
      <List
        height={dynamicHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <Flex
            h={`${height}px`}
            style={style}
            borderBottom={`1px solid ${newBorder}`}
            _last={{
              borderBottom: "none",
            }}
          >
            {children[index]}
          </Flex>
        )}
      </List>
    );
  }
}

const FormControlAttendeeSelect = ({
  onChange,
  isError,
  isRequired,
  isTouched,
  w,
  placeholder,
  client,
  setAttendee,
  currentAttendees,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const attendeeListSimple = useSelector((state) => state.attendeeListSimple);

  const { loading, error, attendees } = attendeeListSimple;

  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu open/close

  useEffect(() => {
    if (!client) {
      dispatch(listAttendeesSimple());
    } else {
      setIsMenuOpen(false);
    }
  }, [dispatch, client]);

  // useEffect(() => {
  //   if (attendees && attendees.length > 0) {
  //     setAttendee(attendees[0]);
  //   } else {
  //     setAttendee(null);
  //   }
  // }, [attendees]);

  // Update to handle closing the menu upon selection
  const handleOnChange = (selectedOption) => {
    onChange(selectedOption); // Assuming onChange prop is used to handle selection outside
    setIsMenuOpen(false); // Close the menu after selection
  };
  const filteredOptions = useMemo(() => {
    const normalizedInput = inputValue.trim().toLowerCase();
    const currentAttendeeEmails = new Set(
      currentAttendees.map((att) => att.attendeeEmail)
    );
    return (
      attendees
        ?.filter((attendee) =>
          `${attendee.firstNameNormalized} ${attendee.lastNameNormalized} ${attendee.companyName}`
            .toLowerCase()
            .includes(normalizedInput)
        )
        .map((attendee) => ({
          value: attendee._id,
          label: `${attendee.firstName} ${attendee.lastName}`,
          isRegistered: currentAttendeeEmails.has(attendee.email),
          email: attendee.email,
          ...attendee,
        })) || []
    );
  }, [inputValue, attendees, currentAttendees]);

  // Custom rendering for options
  const formatOptionLabel = ({
    attendeeType,
    firstName,
    lastName,
    companyName,
    accountType,
    profession,
    status,
    email,
    isRegistered,
  }) => (
    <Flex align="center" justify="space-between" w="100%">
      <Flex align="center">
        <Avatar h="2rem" w="2rem" name={firstName && lastName} mr={1.5} />
        <Stack gap={0.5} mr="1.5rem">
          <Text
            fontSize="13px"
            // color={textPrimary}
            fontWeight="500"
            maxW="120px"
            isTruncated
            lineHeight="1"
          >
            {lastName} {firstName}
          </Text>
          <Text
            fontSize="xs"
            // color={textSecondary}
            fontWeight="500"
            lineHeight={1}
            maxW="120px"
            isTruncated
          >
            {email}
          </Text>
        </Stack>

        <Flex
          align="center"
          justify="center"
          w="1.25rem"
          h="1.25rem"
          borderRadius="10rem"
          bg={backgroundLight}
          mr={1.5}
        >
          <Icon
            as={attendeeType === "individual" ? FaUser : FaSuitcase}
            boxSize={2}
            color={textSecondary}
          />
        </Flex>
        {attendeeType === "individual" ? (
          <Flex align="center">
            <Text fontSize="xs" fontWeight="500" maxW="100px" isTruncated>
              {firstName} {lastName}
            </Text>
          </Flex>
        ) : (
          <Text fontSize="xs" fontWeight="500" maxW="100px" isTruncated>
            {companyName}
          </Text>
        )}

        {/* <Text ml={2} fontSize="xs">
          {(profession && profession.name && profession.name) || "-"}
        </Text> */}
      </Flex>
      <Flex align="center" justify="flex-end">
        {!isRegistered ? (
          <Text
            ml={2}
            fontSize="xs"
            bg={backgroundLight}
            p="0.25rem 0.5rem"
            borderRadius="10rem"
            color={textSecondary}
            fontWeight="500"
            maxW="140px"
            isTruncated
          >
            {(profession && profession.name && profession.name) || "-"}
          </Text>
        ) : (
          <Flex
            bg={buttonGreen}
            color={buttonGreenText}
            p="0.25rem 0.5rem"
            borderRadius="10rem"
          >
            <Text fontSize="xs" fontWeight="500" maxW="140px" isTruncated>
              <Icon
                as={FaCheckCircle}
                color={buttonGreenText}
                boxSize={2.5}
                mr={1.5}
                position="relative"
                top="1px"
              />
              {t("status.registered")}
            </Text>
          </Flex>
        )}
      </Flex>
      {/* <StatusBadge isSmall options="active" status={status} ml={2} /> */}
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Flex w="100%" align="center" p="1rem 0">
        <Spinner size="lg" color={primary} />
      </Flex>
    );

  // console.log("currentAttendees:", currentAttendees);
  // console.log("attendees IN FORM CONTROL:", attendees);

  console.log("filteredOptions:", filteredOptions);

  return (
    <FormControl isRequired={isRequired} isInvalid={isTouched && isError} w={w}>
      <Select
        // isSearchable
        autoFocus={client ? false : true}
        // components={{ MenuList }}

        // menuIsOpen={isMenuOpen}
        value={filteredOptions.find((option) => option.value === inputValue)}
        onChange={(selectedOption) => onChange(selectedOption)}
        onInputChange={(newInputValue, { action }) => {
          if (action === "input-change") {
            setInputValue(newInputValue);
          }
        }}
        options={filteredOptions}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={(option) => option.isRegistered}
        // ignoreAccents={false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textPrimary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          menu: (provided) => ({
            ...provided,
            position: "relative",
            zIndex: 9999,
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: `0 0 0 1px ${newBorder}`,
            border: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            borderRadius: "1rem",

            // maxHeight: "10rem",
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : textSecondary, // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            // backgroundColor: state.isSelected
            //   ? primary
            //   : state.data.isRegistered
            //   ? "#f0f0f0"
            //   : white,
            opacity: state.data.isRegistered ? 0.6 : 1, // Add opacity for disabled (registered) options
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: "white",
              cursor: "pointer",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("common.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlAttendeeSelect;
