import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  textSecondary,
  white,
  primary,
  newBorder,
} from "../../../../../utils/colors";
import {
  listSpeakerCourses,
  addSpeakerCourse,
  removeSpeakerCourse,
} from "../../../../../actions/speakerActions";
import Table from "../../../../../components/shared/Table";
import { columns as speakerCoursesColumns } from "../../columns/SpeakerCoursesColumns";
import { icons } from "../../../../../utils/icons";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import MultiCourseSelect3 from "../../../../../components/select/MultiCourseSelect3";
import SelectCourseMain from "../../../../../components/select/SelectCourseMain";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  SPEAKER_ADD_COURSE_RESET,
  SPEAKER_REMOVE_COURSE_RESET,
  SPEAKER_DETAILS_RESET,
} from "../../../../../constants/speakerConstants";

const TabSpeakerCourses = ({ title }) => {
  const speakerId = useParams().id;
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);
  const toastComponent = ToastComponent();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const speakerCoursesList = useSelector((state) => state.speakerCoursesList);
  const { loading, error, courses } = speakerCoursesList;

  const speakerAddCourse = useSelector((state) => state.speakerAddCourse);
  const { success: successAddCourse } = speakerAddCourse;

  const speakerRemoveCourse = useSelector((state) => state.speakerRemoveCourse);
  const { success: successRemoveCourse } = speakerRemoveCourse;

  const [addCourseSidePanelOpen, setAddCourseSidePanelOpen] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchCourseName, setSearchCourseName] = useState("");
  const [courseName] = useState("");
  const [keyword, setKeyword] = useState("");

  const [customCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState([]);

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listSpeakerCourses(speakerId, keyword, sortField, sortOrder));
  };

  // HANDLERS

  const addCourseHandleNew = (values) => {
    const courseIds = values.map((course) => course.value);
    const courseExists = courseIds.some((courseId) =>
      courses.some((a) => a._id === courseId)
    );
    if (courseExists) {
      console.log("COURSE EXISTS");
      toast({
        title: "Error",
        description: "This course is already added to the speaker.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      dispatch(addSpeakerCourse(speakerId, courseIds));
      setUpdatedCourses([...updatedCourses, ...courseIds]);

      closeAddCourseSidePanel();
    }
  };

  const removeCourseHandler = (courseId) => {
    dispatch(removeSpeakerCourse(speakerId, courseId));
  };

  useEffect(() => {
    if (successAddCourse) {
      toastComponent.showToast(t("toasts.courseAdd"), "success", 3000);

      dispatch({ type: SPEAKER_ADD_COURSE_RESET });
      dispatch({ type: SPEAKER_DETAILS_RESET });
      dispatch(listSpeakerCourses(speakerId));
    } else if (successRemoveCourse) {
      toastComponent.showToast(t("toasts.courseRemove"), "success", 3000);
      dispatch({ type: SPEAKER_REMOVE_COURSE_RESET });
      dispatch({ type: SPEAKER_DETAILS_RESET });
    } else {
      dispatch(listSpeakerCourses(speakerId, keyword, sortField, sortOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, speakerId, keyword, successAddCourse, successRemoveCourse]);

  // console.log("attendee single tab courses", courses);

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  const openAddCourseSidePanel = () => {
    setAddCourseSidePanelOpen(true);
  };

  const closeAddCourseSidePanel = () => {
    setAddCourseSidePanelOpen(false);
  };

  // console.log("courseName", courseName);

  return (
    <>
      <SidePanel
        open={addCourseSidePanelOpen}
        close={closeAddCourseSidePanel}
        title={t("speaker.addCourse")}
      >
        <FormControl mt={4}>
          <>
            <FormLabel fontSize="sm">
              <Flex justify="space-between">{t("select.course")}</Flex>
            </FormLabel>

            <Flex w="100%" mb={10} flexDirection="column">
              <Box w="100%">
                <SelectCourseMain
                  currentOptions={courses}
                  value={customCourses}
                  onChange={addCourseHandleNew}
                  isSelectedHidden={true}
                  placeholderText={t("course.search")}
                  noteText={t("select.speakerCourseNote")}
                />
              </Box>
            </Flex>
          </>
        </FormControl>
      </SidePanel>

      <Box>
        <Flex w="100%" direction="column">
          <Table.Top
            title={t("attendee.tabCourses")}
            subtitle={title}
            onChange={(e) => setSearchCourseName(e.target.value)}
            placeholder={t("course.search")}
            searchName={searchCourseName}
            isFixedTitle
          >
            <Button
              leftIcon={<FilterIcon />}
              bg={white}
              color={textSecondary}
              border={`1px solid ${isFilterVisible ? primary : newBorder}`}
              variant="solid"
              size="lg"
              borderRadius="10rem"
              fontSize="16px"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
              {t("common.filter")}
            </Button>
            <Button
              bg={primary}
              color={white}
              size="lg"
              borderRadius="10rem"
              fontSize="1rem"
              onClick={openAddCourseSidePanel}
            >
              {t("speaker.addCourse")}
            </Button>
          </Table.Top>
          <Box borderRadius="0.75rem" overflow="hidden" p="0 0 2rem">
            <Table.Container
              loading={loading}
              data={courses}
              error={error}
              columns={speakerCoursesColumns(
                handleSortChange,
                translate,
                navigate,
                removeCourseHandler
              )}
              noItemsTitle={t("speaker.noCourses")}
              noItemsDescription={t("speaker.noCoursesText")}
              noItemsButtonLabel={t("speaker.noCoursesButtonLabel")}
              noItemsButtonHandler={openAddCourseSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabSpeakerCourses;
