import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Box,
  Avatar,
  Flex,
  Tooltip,
  Progress,
  Icon,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  newBorder,
  buttonBlue,
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  white,
  newDarkBlue,
  buttonRedNew,
  greenVivid,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";
import StatusBadgeAlt from "../../../../components/badges/StatusBadgeAlt";
import TooltipStatusEntry from "../../../../components/partials/TooltipStatusEntry";
import { TbUser } from "react-icons/tb";

// CUSTOM
const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
  return (
    <Flex align="center">
      <Flex
        w="1.25rem"
        h="1.25rem"
        align="center"
        justify="center"
        bg={white}
        mr={2}
        borderRadius="50%"
        flexShrink="0"
      >
        <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
      </Flex>
      {text && (
        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          opacity={0.6}
          mr={1}
          whiteSpace="nowrap"
        >
          {text}:
        </Text>
      )}

      <Text
        fontSize="sm"
        fontWeight="500"
        textTransform={capitalize ? "capitalize" : "none"}
        maxW={maxW}
        isTruncated={maxW ? true : false}
      >
        {label}
      </Text>
    </Flex>
  );
};

export const columns = (
  navigate,
  translate,
  removeCourseHandler,
  updateAttendeeCourseStatusHandler,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original.course;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("courseDate")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.courseDate")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "courseDate",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { startDate, endDate } = row.original.course;
        const currentYear = dayjs().year();

        if (
          startDate &&
          endDate &&
          dayjs(startDate).isValid() &&
          dayjs(endDate).isValid()
        ) {
          const startYear = dayjs(startDate).year();
          const endYear = dayjs(endDate).year();
          const displayYear =
            startYear !== currentYear || endYear !== currentYear
              ? ` ${startYear}`
              : "";

          if (dayjs(startDate).isSame(endDate, "day")) {
            return (
              <Stack gap={0} maxWidth="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                >
                  {dayjs(startDate).format("DD MMMM")}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          } else {
            return (
              <Stack gap={0} maxWidth="100px" w="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                  w="100px"
                >
                  {`${dayjs(startDate).format("DD MMM")} - ${dayjs(
                    endDate
                  ).format("DD MMM")}`}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          }
        } else if (startDate && dayjs(startDate).isValid()) {
          // Display only the startDate
          const startYear = dayjs(startDate).year();
          const displayYear = startYear !== currentYear ? ` ${startYear}` : "";

          return (
            <Stack gap={0}>
              <Text
                fontSize="13px"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(startDate).format("DD MMM")}
              </Text>
              <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                {displayYear}
              </Text>
            </Stack>
          );
        } else {
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              -
            </Text>
          );
        }
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("title")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.course")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "title",
      disableSortBy: true,
      Cell: ({ row }) => {
        const course = row.original.course;

        return (
          <HStack gap={2}>
            {course.image ? (
              <Image
                w="2.5rem"
                h="2.5rem"
                src={course.image}
                alt={course.title}
                borderRadius="0.875rem"
                objectFit="cover"
              />
            ) : (
              <Avatar
                w="2.5rem"
                h="2.5rem"
                name={course.title}
                borderRadius="0.875rem"
              />
            )}
            <Stack gap={0}>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                maxWidth="180px"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
              >
                {course.title}
              </Text>
              <Box>
                <StatusBadgeAlt
                  status={course.status}
                  options="course"
                  isSmall
                />
              </Box>
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { address } = row.original.course;

        const city = address && address[0] ? address[0].city : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "-"}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("speaker")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.speaker")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "speaker",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { course } = row;

        const speakers = row.original.course.speakers;

        return (
          <div>
            {speakers.length > 1 ? (
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                position="relative"
                pl="1rem"
              >
                {speakers.map((speaker) => (
                  <Box marginLeft="-0.75rem" key={speaker._id}>
                    <Tooltip label={speaker.firstName} key={speaker._id}>
                      <Avatar
                        w="2rem"
                        h="2rem"
                        src={speaker.image || ""}
                        name={speaker.firstName}
                        border={`1px solid ${newBorder}`}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Flex>
            ) : speakers.length === 1 ? (
              <Flex alignItems="center">
                <Avatar
                  h="2rem"
                  w="2rem"
                  src={speakers[0].image || ""}
                  name={speakers[0].firstName}
                  border={`1px solid ${newBorder}`}
                />
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textSecondary}
                  ml={2}
                  isTruncated // This will add ellipsis
                  noOfLines={1} // This will limit the text to two lines
                  whiteSpace="normal"
                >
                  {speakers[0].firstName}
                </Text>
              </Flex>
            ) : (
              // You can render a "No Data" component or Lottie animation here
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                -
              </Text>
            )}
          </div>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("totalAttendees")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.attendees")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "totalAttendees",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { course } = row.original;

        const attendees = course.attendees || [];
        const maxAttendees = course.maximumAttendees || 0;

        const attendeesCount = attendees.length;
        const registeredCount = attendees.filter(
          (attendee) => attendee.status === "registered"
        ).length;

        const attendedCount = attendees.filter(
          (attendee) => attendee.status === "attended"
        ).length;

        const cancelledCount = attendees.filter(
          (attendee) => attendee.status === "cancelled"
        ).length;

        const confirmedCount = attendees.filter(
          (attendee) => attendee.status === "confirmed"
        ).length;

        const notAttendedCount = attendees.filter(
          (attendee) => attendee.status === "notAttended"
        ).length;

        return (
          <Tooltip
            p={4}
            borderRadius="1rem"
            bg={newDarkBlue}
            label={
              <Flex direction="column" justify="flex-start" align="flex-start">
                <TooltipStatusEntry
                  bg={buttonBlue}
                  color={textPrimary}
                  label={translate("status.registered")}
                  count={registeredCount}
                  icon="FaRegCircleDot"
                />

                <TooltipStatusEntry
                  bg={buttonGreen}
                  color={buttonGreenText}
                  label={translate("status.confirmed")}
                  count={confirmedCount}
                  icon="FaCircleCheck"
                />

                <TooltipStatusEntry
                  bg={greenVivid}
                  color={textPrimary}
                  label={translate("status.attended")}
                  count={attendedCount}
                  icon="FaRegCircleCheck"
                />

                <TooltipStatusEntry
                  bg={buttonRed}
                  color={buttonRedText}
                  label={translate("status.cancelled")}
                  count={cancelledCount}
                  icon="FaCircleXmark"
                />
                <TooltipStatusEntry
                  bg={buttonRedNew}
                  color={white}
                  label={translate("status.notAttended")}
                  count={notAttendedCount}
                  icon="FaCircleExclamation"
                />
              </Flex>
            }
            aria-label="Attendees"
          >
            <Flex direction="column">
              <Flex fontSize="sm" mb="0.25rem">
                <Text fontWeight="500" color={textPrimary}>
                  {attendeesCount}
                </Text>
                {maxAttendees > 0 ? (
                  <Text fontWeight="500" color={textSecondary}>
                    /{maxAttendees}
                  </Text>
                ) : (
                  <Text fontWeight="500" color={textSecondary}>
                    /-
                  </Text>
                )}
              </Flex>
              {attendeesCount > 0 && maxAttendees > 0 && (
                <Progress
                  className="attendeesTotal"
                  value={attendeesCount}
                  max={maxAttendees}
                  height="2px"
                  borderRadius="0.5rem"
                  maxWidth="40px"
                />
              )}
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("price")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.price")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "price",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { price } = row.original;

        // console.log("row", row.original);

        // console.log("price", price);

        return (
          <>
            {price ? (
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {price} €
              </Text>
            ) : (
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                -
              </Text>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { course, status, cancelReason, cancelledAt } = row.original;

        const courseId = course._id;

        const handleUpdateStatus = (newStatus) => {
          updateAttendeeCourseStatusHandler(courseId, newStatus);
        };

        return (
          <HStack gap={0} justify="flex-end">
            <Tooltip
              label={
                <Flex direction="column" align="flex-start" gap="0.5rem">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={white}
                    textAlign="center"
                  >
                    {translate("common.cancelledByUser")}
                  </Text>
                  <Flex w="100%" h="1px" bg={textSecondary} m="0rem 0" />

                  <TooltipEntry
                    label={translate(`status.${status}`)}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("clientAccount.attendeeStatus")}
                  />
                  <TooltipEntry
                    label={dayjs(cancelledAt).format("YYYY-MM-DD, HH:mm")}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("common.statusUpdatedAt")}
                  />
                  <TooltipEntry
                    label={cancelReason || "-"}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("implantFailureReports.cancelReason")}
                  />
                </Flex>
              }
              p="0.5rem 1rem"
              borderRadius="1rem"
              isDisabled={cancelReason === null}
            >
              <Flex>
                <StatusButton
                  currentStatus={status}
                  isUnclickable={cancelReason !== null}
                  onUpdateStatus={handleUpdateStatus}
                  options="attendees"
                  isUserCancelled={cancelReason !== null}
                />
              </Flex>
            </Tooltip>

            <MoreButton
              id={courseId}
              deleteHandler={removeCourseHandler}
              deleteLabel={translate("course.removeAttendee")}
              viewId={courseId}
              viewLabel={translate("button.viewCourse")}
              viewHandler={(e) => {
                navigate(`/admin/academy/courses/${courseId}`);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
