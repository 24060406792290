import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  backgroundLight,
} from "../../../utils/colors";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Faq = () => {
  const { t } = useTranslation("");
  const control = useAnimation();
  const [ref, inView] = useInView();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const faqs = [
    { id: "1", questionKey: "faq.question1", answerKey: "faq.answer1" },
    { id: "2", questionKey: "faq.question2", answerKey: "faq.answer2" },
    { id: "3", questionKey: "faq.question3", answerKey: "faq.answer3" },
    { id: "4", questionKey: "faq.question4", answerKey: "faq.answer4" },
    { id: "5", questionKey: "faq.question5", answerKey: "faq.answer5" },
    { id: "6", questionKey: "faq.question6", answerKey: "faq.answer6" },
  ];

  return (
    <Flex w="100%" direction="column">
      <Flex
        w="100%"
        p={["2rem 0rem", "2rem 0rem", "3rem 0rem"]}
        justify="space-between"
        direction={["column ", "column", "row"]}
      >
        <Flex
          direction="column"
          p={["0rem 1.5rem 2rem", "0rem 1.5rem 2rem", "0rem 0"]}
          w={["100%", "100%", "30%"]}
          minW={["100%", "100%", "30%"]}
        >
          <Stack gap="0.5rem">
            <Heading
              fontSize="2.5rem"
              fontWeight="700"
              color={textPrimary}
              m="0"
            >
              {t("faq.title")}
            </Heading>
            <Text fontSize="1rem" fontWeight="400" color={textSecondary}>
              {t("faq.subtitle")}
            </Text>
          </Stack>
        </Flex>
        <Flex
          align="center"
          justify="center"
          direction="column"
          p="0rem 0"
          w="100%"
        >
          <Accordion
            w="100%"
            p={["0rem 1.5rem", "0rem 1.5rem", "0rem 0rem"]}
            allowToggle
          >
            {faqs.map((faq) => (
              <AccordionItem key={faq.id} w="100%">
                <AccordionButton
                  w="100%"
                  p={["1rem 0rem", "1rem 0rem", "1rem 1.5rem"]}
                  borderRadius="1rem"
                  transition="all 0.2s"
                  color={textSecondary}
                  _hover={{
                    color: textPrimary,
                    p: isMobile ? "1rem 0rem" : "1rem 1.5rem 1rem 2.5rem",
                  }}
                  _expanded={{
                    color: textPrimary,
                    _hover: { p: isMobile ? "1rem 0rem" : "1rem 1.5rem" },
                  }}
                >
                  <Box flex="1" textAlign="left" w="100%">
                    <Text fontSize={["sm", "sm", "md"]} fontWeight="500">
                      {t(faq.questionKey)}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel
                  p={["1rem 0rem 2rem", "1rem 0rem 2ren", "2rem 1.5rem"]}
                >
                  <Text
                    fontWeight="500"
                    lineHeight={["1.8", "1.8", "1.6"]}
                    color={textSecondary}
                    fontSize={["sm", "sm", "md"]}
                  >
                    {t(faq.answerKey)}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Faq;
