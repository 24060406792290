// HEADER ICONS
import logo from '../assets/icons/logo.svg';
import logonew from '../assets/icons/logonew.svg';
import logoDark from '../assets/icons/logoDark.svg';
import search from '../assets/icons/search.svg';
import search2 from '../assets/icons/search2.svg';
import cart from '../assets/icons/cart.svg';
import notifications from '../assets/icons/notifications.svg';
import noImage from '../assets/icons/no-image.svg';
import upload from '../assets/icons/upload.svg';
import close from '../assets/icons/close.svg';
import filter from '../assets/icons/filter.svg';
import sortUp from '../assets/icons/sort-up.svg';
import sortDown from '../assets/icons/sort-down.svg';
import marker from '../assets/icons/marker.svg';
import arrowBack from '../assets/icons/arrow-back.svg';
import profile from '../assets/icons/profile.svg';
import email from '../assets/icons/email.svg';
import birthday from '../assets/icons/birthday.svg';
import phone from '../assets/icons/phone.svg';
import address from '../assets/icons/address.svg';
import city from '../assets/icons/city.svg';
import miniSliderArrowBack from '../assets/icons/mini-slider-arrow-left.svg';
import miniSliderArrowForward from '../assets/icons/mini-slider-arrow-right.svg';
import paginationArrowLeftDouble from '../assets/icons/pagination-arrow-left-double.svg';
import paginationArrowLeft from '../assets/icons/pagination-arrow-left.svg';
import paginationArrowRight from '../assets/icons/pagination-arrow-right.svg';
import paginationArrowRightDouble from '../assets/icons/pagination-arrow-right-double.svg';
import attendeeStats from '../assets/icons/attendeeStats.svg';

export const icons = {
  logo,
  logonew,
  logoDark,
  search,
  search2,
  notifications,
  cart,
  noImage,
  upload,
  close,
  filter,
  sortUp,
  sortDown,
  marker,
  arrowBack,
  profile,
  email,
  birthday,
  phone,
  address,
  city,
  miniSliderArrowBack,
  miniSliderArrowForward,
  paginationArrowLeftDouble,
  paginationArrowLeft,
  paginationArrowRight,
  paginationArrowRightDouble,
  attendeeStats,
};
