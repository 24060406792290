import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Button,
  Box,
  Progress,
  IconButton,
  Avatar,
  Flex,
  VStack,
} from "@chakra-ui/react";
import {
  white,
  primary,
  textSecondary,
  textPrimary,
  newBorder,
  buttonBlue,
  buttonBlueHover,
  buttonBlueText,
} from "../../../../utils/colors";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";

export const columns = (translate, removeHandler, handleSortChange) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        // console.log("row", row);

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.attendee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { firstName, lastName, image, email } = row.original;

        const name = firstName + " " + lastName;

        return (
          <Stack spacing={1}>
            <HStack gap={2}>
              {image ? (
                <Image
                  w="2.5rem"
                  h="2.5rem"
                  src={image}
                  alt={name}
                  borderRadius="10rem"
                  objectFit="cover"
                />
              ) : (
                <Avatar
                  w="2.5rem"
                  h="2.5rem"
                  name={name}
                  // bg="#dceeff" color="#3a74a9"
                />
              )}
              <Stack spacing={0}>
                <Text
                  fontSize="md"
                  color={textPrimary}
                  fontWeight="600"
                  isTruncated
                  maxWidth="200px"
                >
                  {name}
                </Text>
                {email && (
                  <Text
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    maxWidth="200px"
                  >
                    {email}
                  </Text>
                )}
              </Stack>
            </HStack>
          </Stack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { address } = row.original;
        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {(address && address[0] && address[0].city && address[0].city) ||
              "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnPhone")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row;
        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {phone || "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateCourseStatus(_id, newStatus));
        };

        // console.log("status", status);

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              // onUpdateStatus={handleUpdateStatus}
              options="active"
            />
            <MoreButton
              id={_id}
              deleteHandler={removeHandler}
              deleteLabel={translate("course.removeSpeaker")}
            />
          </HStack>
        );
      },
    },
    // {
    //   Header: 'Status',
    //   accessor: (row) => {
    //     const { _id } = row;

    //     return (
    //       <HStack gap={0} justify="flex-end">
    //         <Button
    //           display="flex"
    //           alignItems="center"
    //           justifyContent="center"
    //           bg={buttonBlue}
    //           color={buttonBlueText}
    //           _hover={{
    //             bg: buttonBlueHover,
    //           }}
    //           minWidth="140px"
    //           borderRadius="0.75rem"
    //           onClick={() => {
    //             navigateFunction(_id);
    //           }}
    //         >
    //           View profile
    //         </Button>
    //         <MoreButton />
    //       </HStack>
    //     );
    //   },
    // },
  ];
};
