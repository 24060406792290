import React, { Children } from "react";
import styled from "@emotion/styled";
import {
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Button,
  HStack,
  Box,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { icons } from "../../../utils/icons";
import {
  white,
  primary,
  border,
  textBlack,
  bgLight,
  placeholderColor,
  newBorder,
  textSecondary,
} from "../../../utils/colors";
import { shade } from "polished";

const SearchIcon = () => <img src={icons.search2} alt="search icon" />;

const TableTop = ({
  title,
  subtitle,
  children,
  className,
  searchName,
  onChange,
  onClick,
  placeholder,
  buttonLabel,
  buttonHandler,
  buttonDisabled,
  isFixedTitle,
  isSmallTitle,
}) => {
  return (
    <Top>
      <HStack>
        <Box pr={4} w={isFixedTitle ? "180px" : "initial"}>
          <Stack gap={1}>
            <Heading
              fontSize={isSmallTitle ? "1.125rem" : "lg"}
              fontWeight="600"
            >
              {title}
            </Heading>
            {subtitle && (
              <Text
                fontSize="sm"
                color={textSecondary}
                isTruncated
                maxWidth="200px"
              >
                {subtitle}
              </Text>
            )}
          </Stack>
        </Box>
        <InputGroup w="350px">
          <Input
            type="text"
            placeholder={placeholder}
            size="lg"
            bg={white}
            borderRadius="10rem"
            border={`1px solid ${newBorder}`}
            color={textBlack}
            value={searchName}
            onChange={onChange}
            height="3rem"
            fontSize="0.938rem"
            fontWeight="500"
            _placeholder={{ color: placeholderColor }}
            _focusVisible={{
              zIndex: "1",
              borderColor: primary,
              boxShadow: `0 0 0 1px ${primary}`,
            }}
          />
          <InputLeftElement h="100%" pl={2}>
            <IconButton
              h="34px"
              w="34px"
              icon={<SearchIcon />}
              borderRadius="full"
              _hover={{ bgColor: "none" }}
              _focus={{ bgColor: "none" }}
              variant="ghost"
            />
          </InputLeftElement>
        </InputGroup>
      </HStack>

      <HStack>
        {children}

        {buttonLabel && (
          <Button
            bg={primary}
            color={white}
            variant="solid"
            onClick={buttonHandler}
            size="lg"
            borderRadius="10rem"
            fontSize="md"
            lineHeight="1"
            isDisabled={buttonDisabled}
            _hover={{ bg: shade(0.3, primary) }}
          >
            {buttonLabel}
          </Button>
        )}
      </HStack>
    </Top>
  );
};

const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
`;

export default TableTop;
