import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Circle,
  HStack,
  Image,
} from "@chakra-ui/react";
import {
  white,
  primary,
  newBorder,
  backgroundSky,
  backgroundLight,
  textPrimary,
  textSecondary,
} from "../../../../../utils/colors";
import {
  listCourseDetails,
  updateCoursePricing,
} from "../../../../../actions/courseActions";
import { listProfessionGroups } from "../../../../../actions/professionGroupActions";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import noServices from "../../../../../assets/images/empty-box.png";

const CustomButton = ({ primaryText, secondaryText, isActive, onClick }) => {
  return (
    <Button
      onClick={onClick}
      w="50%"
      borderRadius="0.75rem"
      p="2.25rem 1rem"
      bg={isActive ? backgroundSky : white}
      border={isActive ? `1px solid ${primary}` : `1px dashed ${newBorder}`}
      _hover={{
        bg: backgroundSky,
        border: isActive ? `1px solid ${primary}` : `1px dashed ${newBorder}`,
      }}
    >
      <Flex align="center" justify="flex-start" w="100%">
        <Circle
          h="1rem"
          w="1rem "
          bg={white}
          border={isActive ? `1px solid ${primary}` : `1px solid ${newBorder}`}
        >
          {isActive && <Circle h="0.5rem" w="0.5rem" bg={primary} />}
        </Circle>
        <Flex direction="column" justify="flex-start" textAlign="left" pl={3}>
          <Text fontSize="md" color={textPrimary} fontWeight="500" pb={1}>
            {primaryText}
          </Text>
          <Text fontSize="sm" color={textSecondary} fontWeight="400">
            {secondaryText}
          </Text>
        </Flex>
      </Flex>
    </Button>
  );
};

const TabCoursePricing = ({ title }) => {
  // ID
  const courseId = useParams().id;

  // TOAST
  const toastComponent = ToastComponent();

  // TRANSLATION
  const { t } = useTranslation();

  // STATE
  const [free, setFree] = useState("");
  const [prices, setPrices] = useState([]);
  const [initialPrices, setInitialPrices] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const courseDetails = useSelector((state) => state.courseDetails);
  const { course } = courseDetails;

  const professionGroupList = useSelector((state) => state.professionGroupList);
  const { professionGroups } = professionGroupList;

  const courseUpdatePricing = useSelector((state) => state.courseUpdatePricing);
  const { success: successUpdatePricing, error: errorUpdatePricing } =
    courseUpdatePricing;

  // HANDLERS

  const handleSave = () => {
    // Convert prices state to match the backend expectation
    const formattedPrices = prices.map((price) => ({
      professionGroup: price.professionGroupId, // Assuming your prices state has professionGroupId
      price: price.price,
    }));

    // Dispatch the updateCoursePricing action
    dispatch(updateCoursePricing(courseId, free, formattedPrices))
      .then(() => {
        toastComponent.showToast(
          t("toasts.coursePricingUpdated"),
          "success",
          3000
        );
      })
      .catch((error) => {
        console.error("Error updating course pricing: ", error);
        toastComponent.showToast(
          t("toasts.coursePricingError"),
          "success",
          3000
        );
      });
    // After successful save operation
    setHasChanges(false); // Reset changes tracking
  };

  const toggleFreeState = (isFree) => {
    setFree(isFree);
    setHasChanges(true); // Mark as having changes
    if (!isFree) {
      // Restore initial prices or initialize if toggling to not free
      if (initialPrices.length > 0) {
        setPrices(initialPrices);
      } else {
        initializePricesWithActiveProfessionGroups();
      }
    }
  };

  const updatePrice = (professionGroupId, price) => {
    setHasChanges(true); // Mark as having changes
    const newPrices = prices.map((p) => {
      if (p.professionGroupId && p.professionGroupId === professionGroupId) {
        return { ...p, price };
      }
      return p;
    });
    setPrices(newPrices);
  };

  const getPriceForProfessionGroup = (professionGroupId) => {
    const price = prices.find((p) => p.professionGroupId === professionGroupId);
    return price ? price.price : 0;
  };

  // FUNCTIONS
  const activeProfessionGroups = professionGroups.filter(
    (professionGroup) => professionGroup.status === "active"
  );

  // USE EFFECT

  useEffect(() => {
    if (successUpdatePricing) {
      dispatch(listCourseDetails(courseId));
    }
  }, [successUpdatePricing, dispatch, courseId]);

  useEffect(() => {
    if (!course) {
      dispatch(listCourseDetails(courseId));
    } else {
      // Set free status
      setFree(course.free);

      if (!course.free && course.prices) {
        // If the course is not free and has existing prices, transform and set them
        const existingPrices = course.prices
          .filter((price) => price.professionGroup) // Exclude prices with missing profession groups
          .map((price) => ({
            professionGroupId:
              price.professionGroup._id || price.professionGroup, // Assuming price.professionGroup might already be populated with the ID
            price: price.price,
          }));
        setPrices(existingPrices);
        // Also set these as initial prices
        setInitialPrices(existingPrices);
        // After fetching course details and initializing state
        setHasChanges(false); // Assume no changes at initial load
      } else if (course.free) {
        // Initialize with active profession groups if no prices are set yet
        initializePricesWithActiveProfessionGroups();
      }
    }

    if (!professionGroupList.professionGroups.length) {
      dispatch(listProfessionGroups());
    }
  }, [courseId, dispatch, course, professionGroupList.professionGroups.length]);

  const initializePricesWithActiveProfessionGroups = () => {
    const activeGroupsPrices = professionGroups
      .filter((pg) => pg.status === "active")
      .map((pg) => ({ professionGroupId: pg._id, price: 0 }));
    setPrices(activeGroupsPrices);
  };

  // LOGS

  // console.log("professionGroups", professionGroups);

  // console.log("free", free);
  // console.log("prices", prices);

  return (
    <>
      <Flex align="center" justify="space-between" p="2rem 0">
        <Stack gap={1}>
          <Heading fontSize="xl" fontWeight="600">
            {t("course.coursePricing")}
          </Heading>
          <Text color={textSecondary} fontSize="sm">
            {title}
          </Text>
        </Stack>

        <Button
          bg={primary}
          color={white}
          size="lg"
          borderRadius="10rem"
          fontSize="1rem"
          onClick={handleSave}
          isDisabled={!hasChanges}
        >
          {t("button.save")}
        </Button>
      </Flex>

      <Flex
        p="1.5rem 2rem"
        border={`0px solid ${newBorder}`}
        borderRadius="1rem"
        bg={backgroundLight}
      >
        <Flex direction="column" w="80%" mb={4}>
          <FormControl w="100%">
            <FormLabel fontSize="sm">{t("course.courseType")}</FormLabel>
            <Flex mb={4} w="100%" align="center" justify="space-between">
              <HStack gap={4} w="80%">
                <CustomButton
                  onClick={() => toggleFreeState(true)}
                  isActive={free}
                  primaryText={t("course.courseFree")}
                  secondaryText={t("course.courseFreeText")}
                />
                <CustomButton
                  onClick={() => toggleFreeState(false)}
                  isActive={!free}
                  primaryText={t("course.coursePaid")}
                  secondaryText={t("course.coursePaidText")}
                />
              </HStack>
            </Flex>
          </FormControl>

          {free && (
            <Flex
              border={`1px dashed ${newBorder}`}
              p="1rem"
              w="80%"
              borderRadius="1rem"
            >
              <Flex
                justify="center"
                align="center"
                direction="column"
                p="3rem 0"
                bg={white}
                borderRadius="1rem"
                w="100%"
              >
                <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                  {t("course.courseFreeText")}
                </Text>
              </Flex>
            </Flex>
          )}

          {!free && (
            <Box>
              <FormControl>
                <FormLabel fontSize="sm">{t("course.coursePrices")}</FormLabel>
                {free && (
                  <Flex
                    w="80%"
                    h="100%"
                    bg="rgba(0,0,0,0.2)"
                    position="absolute"
                    borderRadius="1rem"
                    align="center"
                    justify="center"
                  >
                    <Box
                      bg="rgba(255,255,255,0.9)"
                      w="200px"
                      h="100px"
                      borderRadius="1rem"
                    >
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        h="100%"
                      >
                        <Text
                          fontSize="lg"
                          color={textPrimary}
                          fontWeight="600"
                        >
                          {t("course.courseFree")}
                        </Text>
                        <Text
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="400"
                        >
                          {t("course.courseFreeText")}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                )}
                <Flex mb={0} direction="column">
                  {activeProfessionGroups.map((professionGroup) => (
                    <Flex
                      key={professionGroup._id}
                      align="center"
                      justify="space-between"
                      mb={4}
                      border={`0px solid ${newBorder}`}
                      borderRadius="0.75rem"
                      bg={white}
                      h="58px"
                      w="80%"
                      _last={{ mb: 0 }}
                    >
                      <Flex
                        align="center"
                        justify="flex-start"
                        borderRadius="0.75rem"
                        h="100%"
                        w="40%"
                        p="0 1rem"
                        border={`0px solid ${newBorder}`}
                        fontWeight="500"
                        fontSize="0.875rem"
                        color={textSecondary}
                        mr={4}
                      >
                        {professionGroup.name}
                      </Flex>

                      <Flex justify="flex-end" align="center" w="110px" mr={2}>
                        <Text
                          fontSize="xs"
                          color={textSecondary}
                          fontWeight="500"
                          mr={2}
                        >
                          €
                        </Text>

                        <InputGroup h="42px">
                          <Input
                            textAlign="center"
                            bg={backgroundLight}
                            borderRadius="0.75rem"
                            border="none"
                            h="100%"
                            size="md"
                            fontWeight="500"
                            fontSize="1rem"
                            type="number"
                            placeholder="0"
                            isDisabled={free} // Disable the input when the course is free
                            value={getPriceForProfessionGroup(
                              professionGroup._id
                            )}
                            onChange={(e) =>
                              updatePrice(
                                professionGroup._id,
                                parseInt(e.target.value)
                              )
                            }
                            _focusVisible={{
                              bg: white,
                              border: `1px solid ${primary}`,
                            }}
                          />
                        </InputGroup>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </FormControl>
            </Box>
          )}
          <Flex w="80%" justify="flex-end" mt={8}>
            <Button
              minWidth="10rem"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              onClick={handleSave}
              isDisabled={!hasChanges}
            >
              {t("button.save")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TabCoursePricing;
