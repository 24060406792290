import React from "react";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { white, primary } from "../../../../../utils/colors";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import FormControlFixture from "../components/FormControlFixture";
import FormControlRefCode from "../components/FormControlRefCode";
import FormControlDiameter from "../components/FormControlDiameter";
import FormControlLength from "../components/FormControlLength";
import FormControlCore from "../components/FormControlCore";
import { useTranslation } from "react-i18next";
import { shade } from "polished";

const Step1 = ({
  fixtureOptions,
  setActiveStep,
  fixture,
  setFixture,
  core,
  setCore,
  diameter,
  setDiameter,
  length,
  setLength,
  refCode,
  setRefCode,
  lotNumber,
  setLotNumber,
  setNewFixture,
  setNewCore,
  setNewDiameter,
  setNewLength,
  isValid,
}) => {
  // GENERAL
  const { t } = useTranslation();

  //VALIDATION

  const isCoreValid = core !== null && core !== "";
  const isDiameterValid = diameter !== "" && diameter !== null;
  const isLengthValid = length !== "" && length !== null;
  const isLotNumberValid = lotNumber !== "" && lotNumber !== null;

  const isContinueDisabled =
    !isDiameterValid || !isLengthValid || !isLotNumberValid;

  // CHECK
  const isCoreNeeded =
    fixture && fixture
      ? fixtureOptions.find((f) => f.value === fixture).core
      : false;

  const isFixtureValid = fixture !== null && fixture !== "";

  // LOGS

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <Flex w="100%" align="center" justify="space-between" gap="1rem">
          <FormControlFixture
            isRequired
            options={fixtureOptions}
            label={t("implantForm.fixture")}
            value={fixture}
            // onChange={(selectedOption) => {
            //   setFixture(selectedOption.value);
            // }}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setFixture(selectedOption.value);
                setCore(null);
                setDiameter(null);
                setLength(null);
                setNewFixture(null);
                setNewCore(null);
                setNewDiameter(null);
                setNewLength(null);
                setLotNumber("");
              } else {
                setFixture(null); // or any default value you prefer
                setCore(null);
                setDiameter(null);
                setLength(null);
                setLotNumber("");
              }
            }}
            placeholder={t("implantForm.placeholderFixture")}
            w={isCoreNeeded ? "70%" : "100%"}
          />
          {isCoreNeeded && (
            <FormControlCore
              isRequired
              fixture={fixture}
              core={core}
              setValue={setCore}
              label={t("form.core")}
              value={core}
              // onChange={handleFixtureChange}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setCore(selectedOption.value);
                  setDiameter(null);
                  setLength(null);
                } else {
                  setCore(null); // or any default value you prefer
                  setDiameter(null);
                  setLength(null);
                }
              }}
              // onChange={(selectedOption) => {
              //   setCore(selectedOption.value);
              // }}
              placeholder={t("placeholder.core")}
              w="30%"
              errorMessage={t("formError.coreRequired")}
              isDisabled={!fixture}
            />
          )}
        </Flex>

        {/* STEP 1 START */}
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            <FormControlDiameter
              isRequired
              fixture={fixture}
              setFixture={setFixture}
              core={core}
              setCore={setCore}
              setValue={setDiameter}
              label={t("form.diameter")}
              value={diameter}
              diameter={diameter}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setDiameter(selectedOption.value);
                  setLength(null);
                } else {
                  setDiameter(null); // or any default value you prefer
                  setLength(null);
                }
              }}
              placeholder={t("placeholder.diameter")}
              w="100%"
              errorMessage={t("formError.diameterRequired")}
              isDisabled={!isFixtureValid}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FormControlLength
              isRequired
              fixture={fixture}
              core={core}
              setCore={setCore}
              diameter={diameter}
              setValue={setLength}
              label={t("form.length")}
              value={length}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setLength(selectedOption.value);
                } else {
                  setLength(null); // or any default value you prefer
                }
              }}
              //   onChange={(selectedOption) => setLength(selectedOption.value)}
              placeholder={t("placeholder.length")}
              w="100%"
              errorMessage={t("formError.lengthRequired")}
              isDisabled={!isDiameterValid}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FormControlRefCode
              isRequired
              fixture={fixture}
              diameter={diameter}
              core={core}
              length={length}
              label={t("implantForm.refCode")}
              value={refCode}
              onChange={(e) => {
                if (e.target.value) {
                  setRefCode(e.target.value);
                } else {
                  setRefCode(""); // or any default value you prefer
                }
              }}
              w="100%"
              errorMessage={t("implantForm.refCodeRequired")}
              setValue={setRefCode}
              isDisabled={true}
            />
          </GridItem>
        </Grid>

        <FormControlDefault
          type="text"
          isRequired
          label={t("implantForm.lotNumber")}
          value={lotNumber}
          placeholder={t("implantForm.placeholderLotNumber")}
          onChange={(e) => {
            setLotNumber(e.target.value);
            //   setFirstNameTouched(true);
          }}
          // onBlur={() => setFirstNameTouched(true)}
          // isTouched={firstNameTouched}
          // isError={isFirstNameError}
          w="100%"
          errorMessage={t("implantForm.lotNumberRequired")}
          isDisabled={!isLengthValid}
        />

        {/* STEP 1 END */}
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        {/* <Tooltip
          label={
            <Flex direction="column" align="center">
              {!fixture && (
                <Text fontWeight="500">{t("formError.selectFixture")}</Text>
              )}

              {fixture && !isDiameterValid && (
                <Text fontWeight="500">{t("formError.selectDiameter")}</Text>
              )}
              {fixture && isDiameterValid && !isLengthValid && (
                <Text fontWeight="500">{t("formError.selectLength")}</Text>
              )}
              {fixture &&
                isDiameterValid &&
                isLengthValid &&
                !isLotNumberValid && (
                  <Text fontWeight="500">{t("formError.selectLotNumber")}</Text>
                )}
            </Flex>
          }
          bg={buttonRed}
          color={buttonRedText}
          placement="top"
          p="0.5rem 1rem"
          borderRadius="1rem"
          fontSize="xs"
          isDisabled={isValid}
        >
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={() => {
              setActiveStep(2);
            }}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!isValid}
          >
            {t("common.continue")}
          </Button>
        </Tooltip> */}
        <Button
          size="lg"
          fontSize="md"
          bg={primary}
          color={white}
          fontWeight="500"
          borderRadius="10rem"
          minW="8rem"
          onClick={() => {
            setActiveStep(2);
          }}
          _hover={{
            bg: shade(0.3, primary),
          }}
          isDisabled={!isValid}
        >
          {t("common.continue")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Step1;
