import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { icons } from "../../../utils/icons";
import { primary, white } from "../../../utils/colors";
import { HStack, Text } from "@chakra-ui/react";

const SidebarHeader = ({ children }) => {
  return (
    <>
      <Top>
        <LogoWrapper>
          <NavLink to="/">
            <HStack gap={0} alignItems="flex-end">
              {/* <Logo src={icons.logonew} /> */}
              {/* <Text ml={2} lineHeight="1" color={white} fontWeight="700">
                Admin
              </Text> */}
            </HStack>
          </NavLink>
        </LogoWrapper>
      </Top>
    </>
  );
};

const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  background: ${primary};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 26px;
`;

export default SidebarHeader;
