import React from "react";
import styled from "styled-components";

import { white } from "../../../utils/colors";

const SidebarHeader = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 55%;
  max-width: 280px;
  min-width: 80px;
  background: ${white};
  color: #000;
  position: relative; // Toggler
  transition: 0.2s ease-in all;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 10;
  height: calc(100% - 80px);
  overflow-y: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

export default SidebarHeader;
