import { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { white, primary, newBorder } from "../../../utils/colors";
import { divTransition } from "../../../assets/animations/divTransition";
import { Flex, Heading, Text, Button, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import logonew2 from "../../../assets/icons/logonew2.svg";

const Footer = () => {
  const { t } = useTranslation("");
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Flex
      ref={ref}
      variants={divTransition}
      initial="hidden"
      animate={control}
      w="100%"
      p={["3rem 0 4.5rem", "3rem 0 4.5rem", "4rem 0"]}
      position="relative"
      bg={white}
    >
      <Flex
        direction="column"
        maxW={["100%", "100%", "1200px"]}
        w="100%"
        m="0 auto"
        align="center"
        justify="space-beween"
        textAlign="center"
      >
        <Flex direction="column">
          <Link to="/">
            <Image src={logonew2} h="1.75rem" />
          </Link>
          {/* <Text
            fontSize="1.125rem"
            color={primary}
            m="1rem auto 0"
            maxW="1200px"
          >
            {t("landingMegagen.footerText")}
          </Text> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
