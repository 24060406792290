// ToastComponent.js
import { useToast } from '@chakra-ui/react';

const ToastComponent = () => {
  const toast = useToast();

  const showToast = (message, status = 'success', duration = 2000) => {
    toast({
      title: message,
      status,
      duration,
      isClosable: true,
    });
  };

  return {
    showToast, // Return the showToast function
  };
};

export default ToastComponent;
