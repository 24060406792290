import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { listClients, deleteClient } from "../../../../actions/clientActions";
import { getUsersClients, deleteUser } from "../../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, Text, Button } from "@chakra-ui/react";
import {
  textSecondary,
  white,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/ClientsColumns"; // Adjust the path according to your file structure
import CreateClient from "../sidepanels/CreateClient";
import EditClient from "../sidepanels/EditClient";
import CitySelect from "../../../../components/select/CitySelect";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import {
  CLIENT_UPDATE_RESET,
  CLIENT_CREATE_RESET,
  CLIENT_DETAILS_RESET,
} from "../../../../constants/clientConstants";
import { use } from "i18next";

const Clients = () => {
  const navigate = useNavigate();
  const toastComponent = ToastComponent();

  // STATE

  const [clientId, setClientId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const pageSizes = [4, 10, 25, 100];
  const [isFilterActive, setIsFilterActive] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // REDUX

  const usersGetClients = useSelector((state) => state.usersGetClients);
  const { loading, error, clients, pages } = usersGetClients;

  // const clientDelete = useSelector((state) => state.clientDelete);
  // const { success: successDelete } = clientDelete;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const clientCreate = useSelector((state) => state.clientCreate);
  const { error: errorCreate, success: successCreate } = clientCreate;

  const clientUpdate = useSelector((state) => state.clientUpdate);
  const { error: errorUpdate, success: successUpdate } = clientUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      getUsersClients(searchTitle, 1, pageSize, sortField, sortOrder, "")
    );
  };

  // USE EFFECT

  useEffect(() => {
    if (!userInfo) {
      console.log("No user info");
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (successCreate) {
      toastComponent.showToast(t("toasts.clientCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: CLIENT_CREATE_RESET });

      dispatch(
        getUsersClients(searchTitle, page, pageSize, sortField, sortOrder, "")
      );
    } else if (successUpdate) {
      toastComponent.showToast(t("toasts.clientUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: CLIENT_UPDATE_RESET });
      dispatch({ type: CLIENT_DETAILS_RESET });

      dispatch(
        getUsersClients(searchTitle, page, pageSize, sortField, sortOrder, "")
      );
    } else {
      dispatch(
        getUsersClients(searchTitle, page, pageSize, sortField, sortOrder, "")
      );
    }
    if (errorCreate) {
      toast({
        title: "Error",
        description: errorCreate,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (errorUpdate) {
      toast({
        title: "Error",
        description: errorUpdate,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    toast,
    searchTitle,
    page,
    pageSize,
    successCreate,
    errorCreate,
    errorUpdate,
    successDelete,
    successUpdate,
    sortField,
    sortOrder,
  ]);

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const deleteClientHandler = (speakerId) => {
    console.log("click");
    dispatch(deleteUser(speakerId));
    toastComponent.showToast(t("toasts.clientDelete"), "success", 3000);
    dispatch({ type: "CLIENT_DELETE_RESET" });
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setClientId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setClientId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setClientId("");
    document.body.classList.remove("no-scroll");
  };

  const handleCitySelectChange = (selectedOption) => {
    const selectedCityValue = selectedOption.value; // Get the selected city value
    dispatch(listClients(searchTitle, 1, pageSize, selectedCityValue)); // Set page to 1
    setIsFilterActive(true); // Update filter state
  };

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  const handleRowClick = (value) => {
    navigate(`/admin/clients/${value}`);
  };

  //LOGS
  // console.log("clients", clients);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.adminClients")}</title>
        <meta name="description" content={t("pageDescription.adminClients")} />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("client.createClient")}
        noBorder={true}
      >
        <CreateClient />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("client.editClient")}
        noBorder={true}
      >
        <EditClient clientId={clientId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("client.clients")}
                onChange={onChangeSearchTitle}
                placeholder={t("client.search")}
                searchName={searchTitle}
                // buttonLabel={t("client.createClient")}
                // buttonHandler={openSidePanelModal}
              />
            </Box>

            {userInfo && userInfo.role === "employee" && (
              <>
                <Table.Container
                  columns={columns(
                    openEditSidePanelModal,
                    translate,
                    deleteClientHandler,
                    handleSortChange
                  )}
                  data={clients}
                  loading={loading}
                  error={error}
                  navigate={navigate}
                  navigateTo="/admin/academy/clients"
                  // noItemsButtonHandler={openSidePanelModal}
                  noItemsTitle={t("noItems.noClients")}
                  noItemsDescription={t("noItems.noClientsText")}
                  noItemsButtonLabel={t("noItems.noClientsButton")}
                  onRowClick={handleRowClick}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />
                <Table.Pagination
                  page={page}
                  pages={pages}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  gotoPage={gotoPage}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                  pageSizes={pageSizes}
                  navigate={navigate}
                />
              </>
            )}
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Clients;
