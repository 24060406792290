import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  Avatar,
  HStack,
  Image,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbMapPin,
  TbBuilding,
  TbUsers,
  TbMapPinStar,
  TbWallet,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
  buttonGreenText,
} from "../../../../../../utils/colors";
import { icons } from "../../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import TooltipText from "../../../../../../components/partials/TooltipText";
import { shade } from "polished";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const UserCourseInfo = ({ course, attendee }) => {
  const { t } = useTranslation();

  const [place, setPlace] = useState("");
  const [attendeePlace, setAttendeePlace] = useState("");
  const [attendeeCity, setAttendeeCity] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const dispatch = useDispatch();

  const [courseInfo, setCourseInfo] = useState({});

  useEffect(() => {
    if (courseInfo?.address && courseInfo?.address.length > 0) {
      setPlace(
        courseInfo?.address[0]?.street + `, ` + courseInfo?.address[0]?.zip ||
          ""
      );
      setCity(
        courseInfo?.address[0]?.city + ", " + courseInfo?.address[0]?.country
      );

      setLocation(courseInfo.location);
    }
  }, [courseInfo]);

  useEffect(() => {
    if (attendee?.address && attendee?.address.length > 0) {
      setAttendeePlace(
        attendee?.address[0]?.street + `, ` + attendee?.address[0]?.zip || ""
      );

      setAttendeeCity(attendee?.address[0]?.city);
    }
  }, [attendee]);

  // Define the titles for different slides
  const slideTitles = [
    t("attendee.attendeeDetails"),
    t("course.coursePricing"),
  ];

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  useEffect(() => {
    if (course) {
      setCourseInfo(course.course);
    }
  }, [course]);

  // console.log("course", course);
  //console.log("courseInfo", courseInfo);
  // console.log("attendee", attendee);

  //   console.log("course prices", course.prices);

  //console.log("attendee", attendee);

  const infoArray = [
    {
      label: "Course Date",
      value: (
        <TooltipText
          icon="TbCalendarEvent"
          label={t("form.courseDate")}
          value={
            dayjs(courseInfo && courseInfo.startDate).isSame(
              courseInfo && courseInfo.endDate,
              "day"
            )
              ? dayjs(courseInfo && courseInfo.startDate).format(
                  "D MMMM YYYY, HH:mm"
                )
              : `${dayjs(courseInfo && courseInfo.startDate).format(
                  "D MMMM YYYY, HH:mm"
                )} - ${dayjs(courseInfo && courseInfo.endDate).format(
                  "D MMMM YYYY, HH:mm"
                )}`
          }
        />
      ),
    },
    {
      label: "Location",
      value: (
        <TooltipText
          icon="TbMapPinStar"
          label={t("course.location")}
          value={location || "-"}
        />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={place || "-"}
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={city || "-"}
        />
      ),
    },

    {
      label: "Speaker2",
      value: (
        <Tooltip
          p="0.5rem"
          borderRadius="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbUsers} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="sm" color={shade(0.3, white)} fontWeight="500">
                {t("course.speakers")}:
              </Text>
              {courseInfo &&
              courseInfo.speakers &&
              courseInfo &&
              courseInfo.speakers.length > 0 ? (
                <Flex direction="column">
                  {courseInfo &&
                    courseInfo.speakers.map((speaker, index) => (
                      <Flex key={index} alignItems="center">
                        <Text
                          fontSize="sm"
                          color={white}
                          fontWeight="500"
                          ml={2}
                          textTransform="capitalize"
                        >
                          {speaker && speaker.firstName && speaker.firstName[0]}
                          . {speaker && speaker.lastName && speaker.lastName}
                        </Text>
                      </Flex>
                    ))}
                </Flex>
              ) : (
                <Text fontSize="1rem" color={white} fontWeight="500">
                  -
                </Text>
              )}
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbUsers} mr="0.5rem" color={textSecondary} />
            {courseInfo &&
            courseInfo.speakers &&
            courseInfo &&
            courseInfo.speakers.length > 0 ? (
              courseInfo && courseInfo.speakers.length === 1 ? (
                <>
                  <Avatar
                    size="xs"
                    src={(courseInfo && courseInfo.speakers[0].image) || ""}
                    name={courseInfo && courseInfo.speakers[0].firstName}
                    border={`1px solid ${newBorder}`}
                  />
                  <Text
                    fontSize="sm"
                    color={textPrimary}
                    fontWeight="500"
                    ml={1}
                  >
                    {courseInfo &&
                      courseInfo.speakers[0].firstName +
                        " " +
                        courseInfo.speakers[0].lastName}
                  </Text>
                </>
              ) : (
                courseInfo &&
                courseInfo.speakers.map((speaker, index) => (
                  <Tooltip
                    label={speaker && speaker.firstName && speaker.firstName}
                    key={index}
                  >
                    <Avatar
                      size="xs"
                      src={(speaker && speaker.image && speaker.image) || ""}
                      name={speaker && speaker.firstName && speaker.firstName}
                      border={`1px solid ${newBorder}`}
                      ml={index === 0 ? 0 : "-0.75rem"} // Adjust margin for the first avatar
                    />
                  </Tooltip>
                ))
              )
            ) : (
              <Text fontSize="1rem" color={textPrimary} fontWeight="500">
                -
              </Text>
            )}
          </Flex>
        </Tooltip>
      ),
    },
  ];

  const priceArray = [
    // {
    //   label: "Price2",
    //   value: (
    //     <TooltipText
    //       icon="TbWallet"
    //       label={t("form.city")}
    //       value={price.price || "-"}
    //     />
    //   ),
    // },

    {
      label: "Price",
      value: (
        <Flex alignItems="center" w="100%">
          <Box w="100%">
            {courseInfo && courseInfo.prices && courseInfo.prices.length > 0 ? (
              courseInfo &&
              courseInfo.prices.map((price, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  p="0.35rem 0"
                  w="100%"
                >
                  <HStack gap={0}>
                    <Icon as={TbWallet} mr="0.5rem" color={textSecondary} />
                    <Text fontSize="sm" color={textPrimary} fontWeight="500">
                      {price &&
                        price.professionGroup &&
                        price.professionGroup.name &&
                        price.professionGroup.name}
                      :
                    </Text>
                  </HStack>
                  <Text
                    ml={2}
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                  >
                    &euro; {price.price}
                  </Text>
                </Flex>
              ))
            ) : (
              <Flex>
                <Text fontSize="sm" color={textSecondary} fontWeight="500">
                  {t("course.courseFreeText")}
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      ),
    },
  ];

  const attendeeArray = [
    // {
    //   label: "Birthdate",
    //   value: (
    //     <>
    //       <TooltipText
    //         icon="TbCalendarEvent"
    //         label={t("form.birthDate")}
    //         value={
    //           attendee.birthDate
    //             ? `${dayjs(attendee.birthDate).format(
    //                 "D MMMM YYYY"
    //               )} (${dayjs().diff(attendee.birthDate, "years")}m.)`
    //             : "-"
    //         }
    //       />
    //     </>
    //   ),
    // },
    {
      label: "Firstname",
      value: (
        <>
          <TooltipText
            icon="TbUser"
            label={t("form.birthDate")}
            value={
              course.attendeeFirstName + " " + course.attendeeLastName || "-"
            }
          />
        </>
      ),
    },

    // {
    //   label: "Phone",
    //   value: (
    //     <TooltipText
    //       icon="TbDeviceMobile"
    //       label={t("form.phone")}
    //       value={attendee.phone || "-"}
    //     />
    //   ),
    // },
    {
      label: "Email",
      value: (
        <TooltipText
          icon="TbMail"
          label={t("form.email")}
          value={course.attendeeEmail || "-"}
        />
      ),
    },
    {
      label: "Role",
      value: (
        <TooltipText
          icon="TbUserBolt"
          label={t("form.profession")}
          // value={course.profession?.name || "-"}
          value={course.attendeeProfession?.name || "-"}
        />
      ),
    },
    {
      label: "Price",
      value: (
        <TooltipText
          icon="TbWallet"
          label={t("form.price")}
          value={`${course.price + "€" || "-"}`}
        />
      ),
    },
    // {
    //   label: "Address",
    //   value: (
    //     <TooltipText
    //       icon="TbMapPin"
    //       label={t("form.address")}
    //       value={attendeePlace || "-"}
    //     />
    //   ),
    // },
    // {
    //   label: "City",
    //   value: (
    //     <TooltipText
    //       icon="TbBuilding"
    //       label={t("form.city")}
    //       value={attendeeCity || "-"}
    //     />
    //   ),
    // },
  ];

  //   console.log("address", course.address);

  return (
    <Flex w="100%" direction={["column", "column", "row"]}>
      <Box
        w={["100%%", "100%%", "65%"]}
        borderRight={[
          `0px solid ${newBorder}`,
          `0px solid ${newBorder}`,
          `1px solid ${newBorder}`,
        ]}
        pr={["0rem", "0rem", "2.5rem"]}
      >
        <Heading
          fontSize="md"
          fontWeight="600"
          color={textPrimary}
          m={["0rem 0 1rem", "0rem 0 1rem", "0rem 0 1.5rem"]}
        >
          {t("course.eventDetails")}
        </Heading>
        <Flex
          alignItems="center"
          gap="2rem"
          w={["100%", "100%", "initial"]}
          direction={["column", "column", "row"]}
        >
          <Flex
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            align="center"
            justify="center"
            w={["100%", "100%", "180px"]}
            h={["200px", "200px", "180px"]}
            flexShrink="0"
          >
            <Image
              src={courseInfo && courseInfo.image}
              alt={courseInfo && courseInfo.title}
              w={["20%", "20%", "30%"]}
              m="0 auto"
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Flex
              align="center"
              direction={["column", "column", "row"]}
              w={["100%", "100%", "initial"]}
            >
              <Flex direction="column" w="100%">
                {infoArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>

              <Flex direction="column" w="100%">
                {/* {newFixtureArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))} */}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box w={["100%%", "100%%", "35%"]} pl={["0rem", "0rem", "2.5rem"]}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading fontSize="md" fontWeight="600" color={textPrimary}>
            {slideTitles[currentSlide]}
          </Heading>
          <Box>
            <IconButton
              className="custom-swiper-button custom-swiper-button-prev"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowBackIcon />}
              borderRadius="10.5rem"
              opacity="0.8"
              _hover={{
                background: backgroundLight,
              }}
            />
            <IconButton
              className="custom-swiper-button custom-swiper-button-next"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowForwardIcon />}
              borderRadius="10.5rem"
              ml="0.5rem"
              opacity="0.5"
              _hover={{
                background: backgroundLight,
              }}
            />
          </Box>
        </Flex>
        <Box>
          <Swiper
            navigation={{
              prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
              nextEl: ".custom-swiper-button-next", // Selector for your custom next button
            }}
            slidesPerView={1}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Navigation]}
            onSlideChange={handleSlideChange}
            className="courseInfo && courseInfoSlider"
          >
            <SwiperSlide>
              <Box>
                {attendeeArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {courseInfo && !courseInfo.free ? (
                <Box>
                  {priceArray.map((item, index) => (
                    <Flex key={index} alignItems="center" w="100%">
                      {item.value && <Flex w="100%">{item.value}</Flex>}
                    </Flex>
                  ))}
                </Box>
              ) : (
                <Box>
                  <Flex alignItems="center" w="100%">
                    <Box w="100%">
                      <Flex
                        align="center"
                        justify="space-between"
                        p="0.35rem 0"
                        w="100%"
                      >
                        <HStack gap={0}>
                          <Icon
                            as={TbWallet}
                            mr="0.5rem"
                            color={textSecondary}
                          />
                          <Text
                            fontSize="sm"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {t("course.courseFreeText")}
                          </Text>
                        </HStack>
                        <Text
                          ml={2}
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="500"
                        >
                          &euro; 0
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              )}
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Flex>
  );
};

export default UserCourseInfo;
