import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  useToast,
  HStack,
  Text,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  newBorder,
  textSecondary,
  white,
  primary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";

import { getUserDetails, updateUser } from "../../../../actions/userActions";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";

import { shade } from "polished";

const EditUser = ({ userId }) => {
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;

  // Determine if the Continue button should be disabled
  const isContinueDisabled = !isFirstNameValid || !isLastNameValid;

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUser({
        _id: userId,
        firstName: firstName,
        lastName: lastName,
        birthDate: birthDate,
        phone: phone,
        street: street,
        zip: zip,
        city: city,

        state: state,
        country: country,
      })
    );
  };

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  useEffect(() => {
    console.log("Edit User loaded", user); // Add this line

    if (!user.firstName || user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setBirthDate(user.birthDate);
      setPhone(user.phone);

      if (user.address) {
        setCity(user.address[0]?.city || "");
        setStreet(user.address[0]?.street || "");
        setZip(user.address[0]?.zip || "");
        setCountry(user.address[0]?.country || "");
        setState(user.address[0]?.state || "");
      }
    }
  }, [dispatch, userId, user]);

  // LOGS
  console.log("user", user);

  return (
    <>
      <form onSubmit={submitHandler}>
        <HStack gap={4} w="100%">
          <FormControlDefault
            type="text"
            isRequired
            label={t("form.firstName")}
            value={firstName}
            placeholder={t("placeholder.firstName")}
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameTouched(true);
            }}
            onBlur={() => setFirstNameTouched(true)}
            isTouched={firstNameTouched}
            isError={isFirstNameError}
            w="50%"
            errorMessage={t("formError.firstNameRequired")}
          />

          <FormControlDefault
            type="text"
            isRequired
            label={t("form.lastName")}
            value={lastName}
            placeholder={t("placeholder.lastName")}
            onChange={(e) => {
              setLastName(e.target.value);
              setLastNameTouched(true);
            }}
            onBlur={() => setLastNameTouched(true)}
            isTouched={lastNameTouched}
            isError={isLastNameError}
            w="50%"
            errorMessage={t("formError.lastNameRequired")}
          />
        </HStack>

        <FormControlDefault
          type="email"
          label={t("form.email")}
          isRequired
          value={email}
          placeholder={t("placeholder.email")}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          w="100%"
          errorMessage={t("formError.emailInvalid")}
          isDisabled
        />

        <HStack gap={4} w="100%">
          <FormControlDatePicker
            value={birthDate}
            type="date"
            isRequired={false}
            label={t("form.birthDate")}
            placeholder={t("placeholder.birthDate")}
            onChange={(newValue) => {
              setBirthDate(newValue);
            }}
            onOpen={() => {}}
            w="50%"
            errorMessage={t("formError.birthDateRequired")}
          />

          <FormControlPhone
            label={t("form.phone")}
            value={phone}
            onChange={(newPhone) => {
              setPhone(newPhone);
            }}
            errorMessage={t("formError.phoneRequired")}
            w="50%"
            defaultCountry="lt"
          />
        </HStack>

        <HStack gap={4} w="100%">
          <FormControlAddress
            currentValue={street}
            label={t("form.address")}
            onAddressSelect={handleAddressSelect}
            isRequired={false}
            errorMessage={t("formError.addressRequired")}
            w="100%"
          />

          <FormControlDefault
            type="text"
            isRequired={false}
            label={t("form.zip")}
            value={zip}
            placeholder={t("placeholder.zip")}
            onChange={(e) => {
              setZip(e.target.value);
            }}
            w="40%"
            errorMessage={t("formError.zipRequired")}
          />
        </HStack>

        <HStack gap={4} w="100%">
          <FormControlDefault
            type="text"
            isRequired={false}
            label={t("form.city")}
            value={city}
            placeholder={t("placeholder.city")}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            w="50%"
            errorMessage={t("formError.cityRequired")}
          />

          <FormControlCountry
            label={t("form.country")}
            value={country}
            onChange={(selectedOption) => {
              setCountry(selectedOption ? selectedOption.value : "");
            }}
            isRequired={false}
            w="50%"
            errorMessage={t("formError.countryRequired")}
          />
        </HStack>

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="flex-end">
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isFirstNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.firstNameRequired")}
                    </Text>
                  )}
                  {isLastNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.lastNameRequired")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isContinueDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                isDisabled={isContinueDisabled}
                _hover={{
                  bg: shade(0.1, primary),
                }}
                ml={4}
              >
                {t("attendee.editAttendee")}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default EditUser;
