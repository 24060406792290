import React, { useEffect, useState, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Button,
  useToast,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  newBorder,
  textSecondary,
  white,
  primary,
  backgroundLight,
} from "../../../../utils/colors";
import {
  updateAttendee,
  listAttendeeDetails,
  addAttendeeCourse,
  removeAttendeeCourse,
} from "../../../../actions/attendeeActions";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
// import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlAddressFull from "../../../../components/input/FormControlAddressFull";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import { shade } from "polished";
import { GoogleApiContext } from "../../../../contexts/GoogleApiContext";

const EditAttendee = ({ attendeeId }) => {
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const toast = useToast();
  const { t } = useTranslation();

  // GOOGLE API
  const { isApiLoaded } = useContext(GoogleApiContext);

  // STATE
  const [activeTab, setActiveTab] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [client, setClient] = useState("");
  // const [city, setCity] = useState("");
  // const [zip, setZip] = useState("");
  // const [street, setStreet] = useState("");
  // const [country, setCountry] = useState("");
  // const [state, setState] = useState("");

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [displayAddress, setDisplayAddress] = useState("");
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  const [courses, setCourses] = useState([]);

  const attendeeDetails = useSelector((state) => state.attendeeDetails);
  const { attendee } = attendeeDetails;

  // const attendeeAddCourse = useSelector((state) => state.attendeeAddCourse);
  // const { success: successAddCourse } = attendeeAddCourse;

  // const attendeeRemoveCourse = useSelector(
  //   (state) => state.attendeeRemoveCourse
  // );
  // const { success: successRemoveCourse } = attendeeRemoveCourse;

  const [showAddCourse, setShowAddCourse] = useState(false);
  const [updatedCourses, setUpdatedCourses] = useState([]);

  const [selectedProfession, setSelectedProfession] = useState(null);

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";
  const isProfessionError = profession === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  // const isProfessionValid = profession.length >= 1;

  // console.log("isProfessionValid", isProfessionValid);
  console.log("selectedProfession", selectedProfession);

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid || !isLastNameValid || !isEmailValid;

  const handleShowAddCourse = () => setShowAddCourse(!showAddCourse);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateAttendee({
        _id: attendeeId,
        firstName: firstName,
        lastName: lastName,
        image: image,
        email: email,
        birthDate: birthDate,
        client: client,
        phone: phone,
        city: address.city,
        zip: address.zip,
        street: address.street,
        country: address.country,
        state: address.state,
        courses: updatedCourses,
        profession: profession,
      })
    );
  };

  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
    // setHasChanged(true);
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
  };

  const removeCourseHandler = (courseId) => {
    console.log("courseId", courseId);
    dispatch(removeAttendeeCourse(attendeeId, courseId));

    console.log("courses before", courses);
    setCourses(courses.filter((course) => course.course._id !== courseId));
    console.log("courses after", courses);

    console.log("updatedCourses before", updatedCourses);
    setUpdatedCourses(updatedCourses.filter((course) => course !== courseId));
    console.log("updatedCourses after", updatedCourses);
    toastComponent.showToast(t("toasts.courseRemove"), "success", 3000);
  };

  const addCourseHandleNew = (values) => {
    console.log("values", values);
    // Get the array of speaker IDs from the values array
    const courseIds = values.map((course) => course.value);

    console.log("courseIds", courseIds);

    // Check if any of the course IDs already exist in the attendees list
    const courseExists = courseIds.some((courseId) =>
      courses.some((a) => a._id === courseId)
    );

    if (courseExists) {
      console.log("Course already assigned");
      toastComponent.showToast(t("toasts.courseError"), "error", 3000);
    } else {
      console.log("attendeeId", attendeeId);
      dispatch(addAttendeeCourse(attendeeId, courseIds));

      const currentCourses = [...courses];

      console.log("currentCourses", currentCourses);

      setUpdatedCourses([...courseIds]);

      console.log("updatedCourses", updatedCourses);
      toastComponent.showToast(t("toasts.courseEdit"), "success", 3000);
      // You might need to update the course list after the action is dispatched
    }
  };

  useEffect(() => {
    console.log("Edit Attendee loaded", attendee); // Add this line

    if (!attendee.firstName || attendee._id !== attendeeId) {
      dispatch(listAttendeeDetails(attendeeId));
    } else {
      setFirstName(attendee.firstName);
      setLastName(attendee.lastName);
      setEmail(attendee.email);
      setBirthDate(attendee.birthDate);
      setClient(attendee.client && attendee.client._id);
      setPhone(attendee.phone);
      setImage(attendee.image);
      setProfession(attendee.profession);

      // if (attendee.address) {
      //   setCity(attendee.address[0]?.city || "");
      //   setStreet(attendee.address[0]?.street || "");
      //   setZip(attendee.address[0]?.zip || "");
      //   setCountry(attendee.address[0]?.country || "");
      //   setState(attendee.address[0]?.state || "");
      // }

      if (attendee?.address.length > 0) {
        setAddress({
          street: attendee.address[0]?.street || "",
          city: attendee.address[0]?.city || "",
          zip: attendee.address[0]?.zip || "",
          country: attendee.address[0]?.country || "",
          state: attendee.address[0]?.state || "",
          display: "",
        });

        const parts = [
          attendee.address[0]?.street,
          attendee.address[0]?.city,
          attendee.address[0]?.state,
          attendee.address[0]?.zip,
          attendee.address[0]?.country,
        ].filter(Boolean);
        const displayString = parts.join(", ");
        setDisplayAddress(displayString);
      } else {
        setAddress({
          street: "",
          city: "",
          zip: "",
          country: "",
          state: "",
          display: "",
        });
      }

      setCourses(attendee.courses);
      setUpdatedCourses(attendee.courses.map((course) => course.course._id));
    }
  }, [dispatch, attendeeId, attendee]);

  // useEffect(() => {
  //   if (successAddCourse) {
  //     dispatch(listAttendeeDetails(attendeeId));
  //     setCourseId("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successAddCourse, attendeeId, dispatch]);

  // useEffect(() => {
  //   if (successRemoveCourse) {
  //     dispatch(listAttendeeDetails(attendeeId));
  //   }
  // }, [successRemoveCourse, attendeeId, dispatch]);

  // const formattedCourses = courses;
  const formattedCourses = courses.map((course) => ({
    value: course.course._id, // Assuming _id can be used as a unique identifier
    label: course.course.title, // Display the attendee's name as the label
    image: course.course.image, // You might want to add the image here if needed
    status: course.course.status,
    startDate: course.course.startDate,
    endDate: course.course.endDate,
  }));

  const isTitleValid = firstName.length >= 3;

  // console.log('attendee', attendee);
  // console.log('selected profession', selectedProfession);
  // console.log("profession", profession);
  // console.log("client", client);
  // console.log('isProfessionValid', isProfessionValid);
  // console.log('isTitleValid', isTitleValid);

  // console.log('courses', courses);
  // console.log('custom courses', customCourses);

  console.log("address", address);

  return (
    <>
      <form onSubmit={submitHandler}>
        {/* <FormControl mb={6}>
          <FormLabel fontSize="sm">{t("course.courseImage")}</FormLabel>
          <ImageUpload image={image} setImage={setImage} />
        </FormControl> */}

        <HStack gap={4} w="100%">
          <FormControlDefault
            type="text"
            isRequired
            label={t("form.firstName")}
            value={firstName}
            placeholder={t("placeholder.firstName")}
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameTouched(true);
            }}
            onBlur={() => setFirstNameTouched(true)}
            isTouched={firstNameTouched}
            isError={isFirstNameError}
            w="50%"
            errorMessage={t("formError.firstNameRequired")}
          />

          <FormControlDefault
            type="text"
            isRequired
            label={t("form.lastName")}
            value={lastName}
            placeholder={t("placeholder.lastName")}
            onChange={(e) => {
              setLastName(e.target.value);
              setLastNameTouched(true);
            }}
            onBlur={() => setLastNameTouched(true)}
            isTouched={lastNameTouched}
            isError={isLastNameError}
            w="50%"
            errorMessage={t("formError.lastNameRequired")}
          />
        </HStack>

        <FormControlDefault
          type="email"
          label={t("form.email")}
          isRequired
          value={email}
          placeholder={t("placeholder.email")}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailTouched(true);
          }}
          onBlur={() => setEmailTouched(true)}
          isTouched={emailTouched}
          isError={!isEmailValid}
          w="100%"
          errorMessage={t("formError.emailInvalid")}
          isDisabled
          noDisabledOpacity
        />
        <FormControlProfession
          label={t("form.profession")}
          value={profession._id}
          placeholder={t("select.profession")}
          onChange={handleProfessionChange}
          setIsTouched={() => setProfessionTouched(true)}
          isRequired={true}
          isTouched={professionTouched}
          isError={isProfessionError}
          errorMessage={t("formError.professionRequired")}
          w="100%"
          onProfessionChange={handleProfessionChange}
        />

        <HStack gap={4} w="100%">
          <FormControlDatePicker
            value={birthDate}
            type="date"
            isRequired={false}
            label={t("form.birthDate")}
            placeholder={t("placeholder.birthDate")}
            onChange={(newValue) => {
              setBirthDate(newValue);
            }}
            onOpen={() => {}}
            w="50%"
            errorMessage={t("formError.birthDateRequired")}
          />

          <FormControlPhone
            label={t("form.phone")}
            value={phone}
            onChange={(newPhone) => {
              setPhone(newPhone);
            }}
            errorMessage={t("formError.phoneRequired")}
            w="50%"
            defaultCountry="lt"
          />
        </HStack>

        {!manualAddressVisible && (
          <Flex direction="column" w="100%" p="0rem">
            {isApiLoaded && (
              <FormControlAddressFull
                label={t("form.address")}
                placeholder={t("placeholder.googleAddress")}
                onAddressSelect={handleAddressSelect}
                currentValue={displayAddress}
                w="100%"
                isApiLoaded={isApiLoaded}
              />
            )}
            <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
              <Flex
                position="relative"
                m="0rem 0 0"
                justify="flex-end"
                w="100%"
              >
                <Button
                  bg="rgba(255,255,255,0.3)"
                  onClick={toggleManualAddressVisible}
                  color={primary}
                  variant="ghost"
                  fontSize="xs"
                  size="xs"
                  fontWeight="500"
                  p="0.25rem 0.75rem"
                  borderRadius="10rem"
                  opacity="0.8"
                  transition="all 0.3s"
                  _hover={{
                    // bg: backgroundLight,
                    opacity: "1",
                  }}
                >
                  {t("onboarding.enterAddressManual")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}

        {manualAddressVisible && (
          <>
            <Flex direction="column" align="center" justify="center" p="0rem">
              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  label={t("form.street")}
                  value={address.street}
                  placeholder={t("placeholder.street")}
                  onChange={(e) => {
                    setAddress({ ...address, street: e.target.value });
                  }}
                  w={["100%", "50%", "50%"]}
                />

                <FormControlDefault
                  type="text"
                  label={t("form.zip")}
                  value={address.zip}
                  placeholder={t("placeholder.zip")}
                  onChange={(e) => {
                    setAddress({ ...address, zip: e.target.value });
                  }}
                  w={["100%", "50%", "50%"]}
                />
              </HStack>

              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  label={t("form.city")}
                  value={address.city}
                  placeholder={t("placeholder.city")}
                  onChange={(e) => {
                    setAddress({ ...address, city: e.target.value });
                  }}
                  w={["100%", "50%", "50%"]}
                  errorMessage={t("formError.cityRequired")}
                />

                <FormControlCountry
                  label={t("form.country")}
                  value={address.country}
                  onChange={(selectedOption) => {
                    setAddress({ ...address, country: selectedOption.value });
                  }}
                  w={["100%", "50%", "50%"]}
                  errorMessage={t("formError.countryRequired")}
                />
              </HStack>
              <Flex align="center" justify="center" gap="1rem">
                <Flex position="relative" m="0rem 0 0" justify="center">
                  <Button
                    bg="rgba(255,255,255,0.3)"
                    onClick={toggleManualAddressVisible}
                    color={primary}
                    variant="link"
                    fontSize="xs"
                    fontWeight="500"
                    p="0.5rem 0.75rem"
                    borderRadius="10rem"
                    _hover={{
                      bg: "rgba(255,255,255,1)",
                    }}
                  >
                    {t("onboarding.enterAddressGoogle")}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}

        {/* <HStack gap={4} w="100%">
          <FormControlAddress
            currentValue={street}
            label={t("form.address")}
            onAddressSelect={handleAddressSelect}
            isRequired={false}
            errorMessage={t("formError.addressRequired")}
            w="100%"
          />

          <FormControlDefault
            type="text"
            isRequired={false}
            label={t("form.zip")}
            value={zip}
            placeholder={t("placeholder.zip")}
            onChange={(e) => {
              setZip(e.target.value);
            }}
            w="40%"
            errorMessage={t("formError.zipRequired")}
          />
        </HStack>

        <HStack gap={4} w="100%">
          <FormControlDefault
            type="text"
            isRequired={false}
            label={t("form.city")}
            value={city}
            placeholder={t("placeholder.city")}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            w="50%"
            errorMessage={t("formError.cityRequired")}
          />

          <FormControlCountry
            label={t("form.country")}
            value={country}
            onChange={(selectedOption) => {
              setCountry(selectedOption ? selectedOption.value : "");
            }}
            isRequired={false}
            w="50%"
            errorMessage={t("formError.countryRequired")}
          />
        </HStack> */}
        {/* 
        <FormControlClient
          value={client}
          label={t("attendee.client")}
          placeholder={t("select.client")}
          onChange={(selectedOption) => setClient(selectedOption.value)}
          isRequired={false}
          w="100%"
        /> */}

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="flex-end">
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isFirstNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.firstNameRequired")}
                    </Text>
                  )}
                  {isLastNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.lastNameRequired")}
                    </Text>
                  )}
                  {!isEmailValid && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.emailInvalid")}
                    </Text>
                  )}
                  {isProfessionError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.professionRequired")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isContinueDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                isDisabled={isContinueDisabled}
                _hover={{
                  bg: shade(0.1, primary),
                }}
                ml={4}
              >
                {t("attendee.editAttendee")}
              </Button>
            </Tooltip>

            <Flex align="center">
              {activeTab > 0 && (
                <Button
                  minWidth="8rem"
                  mr={0}
                  bg={backgroundLight}
                  border={`1px solid ${newBorder}`}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  fontWeight="500"
                  borderRadius="10rem"
                  onClick={() => setActiveTab(activeTab - 1)}
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.1, backgroundLight),
                  }}
                >
                  {t("common.back")}
                </Button>
              )}

              {activeTab < 0 && (
                <Tooltip
                  bg={white}
                  borderRadius="1rem"
                  p="1rem"
                  label={
                    <Flex
                      direction="column"
                      p="0rem"
                      justify="center"
                      textAlign="center"
                    >
                      {isFirstNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.firstNameRequired")}
                        </Text>
                      )}
                      {isLastNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.lastNameRequired")}
                        </Text>
                      )}
                      {!isEmailValid && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.emailInvalid")}
                        </Text>
                      )}
                      {isProfessionError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.professionRequired")}
                        </Text>
                      )}
                    </Flex>
                  }
                  isDisabled={!isContinueDisabled}
                  hasArrow
                  placement="top"
                  fontSize="md"
                >
                  <Button
                    minWidth="8rem"
                    ml={4}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab + 1)}
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                  >
                    {t("common.continue")}
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default EditAttendee;
