export const slideFromRightTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, y: 0, x: 40, skewX: 0, skewY: 0 },
};
