import axios from "axios";
import {
  ATTENDEE_LIST_REQUEST,
  ATTENDEE_LIST_SUCCESS,
  ATTENDEE_LIST_FAIL,
  ATTENDEE_LIST_SIMPLE_REQUEST,
  ATTENDEE_LIST_SIMPLE_SUCCESS,
  ATTENDEE_LIST_SIMPLE_FAIL,
  ATTENDEE_DETAILS_REQUEST,
  ATTENDEE_DETAILS_SUCCESS,
  ATTENDEE_DETAILS_FAIL,
  ATTENDEE_UPDATE_REQUEST,
  ATTENDEE_UPDATE_SUCCESS,
  ATTENDEE_UPDATE_FAIL,
  ATTENDEE_CREATE_REQUEST,
  ATTENDEE_CREATE_SUCCESS,
  ATTENDEE_CREATE_FAIL,
  ATTENDEE_DELETE_REQUEST,
  ATTENDEE_DELETE_SUCCESS,
  ATTENDEE_DELETE_FAIL,
  ATTENDEE_ADD_COURSE_REQUEST,
  ATTENDEE_ADD_COURSE_SUCCESS,
  ATTENDEE_ADD_COURSE_FAIL,
  ATTENDEE_REMOVE_COURSE_REQUEST,
  ATTENDEE_REMOVE_COURSE_SUCCESS,
  ATTENDEE_REMOVE_COURSE_FAIL,
  ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST,
  ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS,
  ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL,
  ATTENDEE_COURSES_LIST_REQUEST,
  ATTENDEE_COURSES_LIST_SUCCESS,
  ATTENDEE_COURSES_LIST_FAIL,
  ATTENDEE_COURSE_STATUS_UPDATE_REQUEST,
  ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS,
  ATTENDEE_COURSE_STATUS_UPDATE_FAIL,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL,
  ATTENDEE_UPDATE_IMAGE_REQUEST,
  ATTENDEE_UPDATE_IMAGE_SUCCESS,
  ATTENDEE_UPDATE_IMAGE_FAIL,
} from "../constants/attendeeConstants";
import { logout } from "./userActions";

export const listAttendees =
  (keyword = "", pageNumber = "", pageSize = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: ATTENDEE_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/attendees?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );

      dispatch({
        type: ATTENDEE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listAttendeesSimple = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ATTENDEE_LIST_SIMPLE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/attendees/simple`, config);

    dispatch({
      type: ATTENDEE_LIST_SIMPLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ATTENDEE_LIST_SIMPLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAttendeeDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: ATTENDEE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/attendees/${id}`);

    dispatch({
      type: ATTENDEE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ATTENDEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAttendee = (attendee) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ATTENDEE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/attendees/${attendee._id}`,
      attendee,
      config
    );

    dispatch({
      type: ATTENDEE_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: ATTENDEE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ATTENDEE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }

    dispatch({
      type: ATTENDEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createAttendee =
  (
    firstName,
    lastName,
    // image,
    phone,
    email,
    birthDate,
    client,
    courses,
    street,
    zip,
    city,
    country,
    state,
    profession
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ATTENDEE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/attendees`,
        {
          firstName,
          lastName,
          // image,
          phone,
          email,
          birthDate,
          client: client !== "" ? client : undefined,
          courses,
          street,
          zip,
          city,
          country,
          state,
          profession,
        },
        config
      );

      dispatch({
        type: ATTENDEE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: ATTENDEE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteAttendee = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ATTENDEE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/attendees/${id}`, config);

    dispatch({
      type: ATTENDEE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ATTENDEE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const addAttendeeCourse =
  (attendeeId, courseId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATTENDEE_ADD_COURSE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/attendees/${attendeeId}/courses`,
        { courseId },
        config
      );

      dispatch({
        type: ATTENDEE_ADD_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeAttendeeCourse =
  (attendeeId, courseId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATTENDEE_REMOVE_COURSE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/attendees/${attendeeId}/courses/${courseId}`,
        config
      );

      dispatch({
        type: ATTENDEE_REMOVE_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_REMOVE_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAttendeeStatus =
  (attendeeId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/attendees/${attendeeId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listAttendeeCourses =
  (
    id,
    keyword = "",
    sortField = "", // New parameter for sort field
    sortOrder = "" // New parameter for sort order
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ATTENDEE_COURSES_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string with new sorting parameters
      let query = `/api/attendees/${id}/courses?keyword=${encodeURIComponent(
        keyword
      )}`;
      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: ATTENDEE_COURSES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_COURSES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAttendeeCourseStatus =
  (attendeeId, courseId, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATTENDEE_COURSE_STATUS_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `/api/attendees/${attendeeId}/courses/${courseId}/status`,
        { status },
        config
      );

      dispatch({
        type: ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_COURSE_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAttendeeCoursePaidStatus =
  (attendeeId, courseId, paidStatus) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `/api/attendees/${attendeeId}/courses/${courseId}/paidStatus`,
        { paidStatus },
        config
      );

      dispatch({
        type: ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAttendeeImage =
  (attendeeId, image) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ATTENDEE_UPDATE_IMAGE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/attendees/${attendeeId}/image`,
        { image },
        config
      );

      dispatch({
        type: ATTENDEE_UPDATE_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATTENDEE_UPDATE_IMAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
