import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Heading,
  IconButton,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  FormLabel,
  FormControl,
  Button,
  Icon,
  HStack,
  useToast,
  Divider,
  Box,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  white,
  backgroundLight,
} from "../../../../utils/colors";
import { FaArrowLeft, FaXmark } from "react-icons/fa6";
import EventSummary from "./EventSummary";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlProfessionNew from "../../../../components/input/FormControlProfessionNew";
import {
  COURSE_ADD_ATTENDEE_PUBLIC_RESET,
  COURSE_VERIFY_ATTENDEE_EMAIL_RESET,
} from "../../../../constants/courseConstants";
import { TbCalendarPlus } from "react-icons/tb";
import SuccessRegister from "./SuccessRegister";
import {
  addPublicCourseAttendee,
  verifyAttendeeEmail,
} from "../../../../actions/courseActions";
import { shade } from "polished";
import {
  registerUserClientEmployeeAttendeeCourse,
  getUserClientDetails,
} from "../../../../actions/userActions";
import FormControlAttendeeEmployeeSelect from "../../../../components/input/FormControlAttendeeEmployeeSelect";
import { USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET } from "../../../../constants/userConstants";
import VerifyAttendeeEmail from "./VerifyAttendeeEmail";

const MotionModalContent = motion(ModalContent);

const EventCheckout = ({ isOpen, onClose, course }) => {
  // ID
  // const { tenantId } = useParams();

  // GENERAL
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  // STEPS STATE
  const [stepZero, setStepZero] = useState(true);
  const [stepSuccess, setStepSuccess] = useState(false);
  const [stepVerifyEmail, setStepVerifyEmail] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false); // DELETE AFTER

  // STATE
  const [attendee, setAttendee] = useState(null);
  const [existingAttendee, setExistingAttendee] = useState(false);
  // const [price, setPrice] = useState(course.prices[0]);
  const [price, setPrice] = useState(0);
  const [userId, setUserId] = useState(null);

  const [verificationCode, setVerificationCode] = useState("");
  const [attendeeId, setAttendeeId] = useState("");

  const [professionGroupId, setProfessionGroupId] = useState("");

  // ATTENDEE STATE
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [profession, setProfession] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [attendeeType, setAttendeeType] = useState("business");

  const [companyName, setCompanyName] = useState(""); // DELETE AFTER TESTING
  const [companyCode, setCompanyCode] = useState(""); // DELETE AFTER TESTING
  const [representativeOfCompany, setRepresentativeOfCompany] = useState("");

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    display: "",
  }); // DELETE AFTER TESTING

  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const courseAddAttendeePublic = useSelector(
    (state) => state.courseAddAttendeePublic
  );

  const {
    loading: loadingAddAttendee,
    error: errorAddAttendee,
    success: successAddAttendee,
  } = courseAddAttendeePublic;

  const userClientEmployeeAttendeeCourseRegister = useSelector(
    (state) => state.userClientEmployeeAttendeeCourseRegister
  );

  const {
    loading: loadingAddEmployee,
    error: errorAddEmployee,
    success: successAddEmployee,
  } = userClientEmployeeAttendeeCourseRegister;

  const userGetClientDetails = useSelector(
    (state) => state.userGetClientDetails
  );

  const {
    loading: loadingClientDetails,
    error: errorClientDetails,
    client,
  } = userGetClientDetails;

  const courseVerifyAttendeeEmail = useSelector(
    (state) => state.courseVerifyAttendeeEmail
  );

  const {
    loading: loadingVerify,
    error: errorVerify,
    success: successVerify,
  } = courseVerifyAttendeeEmail;

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  const isProfessionValid = selectedProfession.length >= 1;
  // const isCompanyNameValid = companyName.length >= 1;
  const isCompanyCodeValid = companyCode.length >= 1;

  // HANDLERS
  const handleVerificationSubmit = () => {
    // console.log("handleVerificationSubmit");

    // const verificationCode = verificationCode;

    // console.log("courseId", courseId);
    // console.log("attendeeId", attendeeId);
    // console.log("verificationCode", verificationCode);

    dispatch(verifyAttendeeEmail(verificationCode, course._id, email));
  };

  const registerNewHandler = () => {
    setStepZero(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setProfession("");
  };

  const handleRegisterPublic = () => {
    dispatch(
      addPublicCourseAttendee(
        course._id, // Ensure courseId is passed directly as a string
        firstName,
        lastName,
        email,
        profession,
        companyName,
        price
      )
    );
  };

  const handleRegisterEmployee = () => {
    dispatch(
      registerUserClientEmployeeAttendeeCourse(
        course._id,
        firstName,
        lastName,
        email,
        profession,
        attendeeType,
        userInfo._id,
        price,
        companyName,
        existingAttendee
      )
    );
    // console.log(
    //   "dispatched",
    //   course._id,
    //   firstName,
    //   lastName,
    //   email,
    //   profession,
    //   attendeeType,
    //   userInfo._id,
    //   price,
    //   companyName,
    //   existingAttendee
    // );
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
    // setProfessionGroupId(selectedOption.professionGroupId);
  };

  const backHandler = () => {
    setStepZero(true);
    setAttendee(null);
  };

  const closeHandler = () => {
    console.log("closeHandler");
    // resetForm
    setStepZero(true);
    setStepSuccess(false);
    onClose();
  };

  // ANIMATION VARIANTS
  const modalVariants = {
    hidden: {
      y: 60,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    exit: {
      y: 60,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  const bodyVariants = {
    hidden: {
      y: 30,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      y: 30,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  // USE EFFECT

  useEffect(() => {
    if (successAddEmployee) {
      toast({
        title: t("publicCourses.registerSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      //   setStepVerifyEmail(true);
      setStepSuccess(true);

      // console.log("successAddEmployee", successAddEmployee);
    }

    if (errorAddEmployee) {
      console.log("errorAddEmployee", errorAddEmployee);
      toast({
        title: t("publicCourses.registerError"),
        description: errorAddEmployee,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    dispatch({ type: USER_CLIENT_EMPLOYEE_ATTENDEE_COURSE_REGISTER_RESET });
  }, [successAddEmployee, errorAddEmployee]);

  useEffect(() => {
    if (successAddAttendee) {
      toast({
        title: t("publicCourses.sendVerificationCode"),
        description: email,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      setStepVerifyEmail(true);
      // setStepSuccess(true);

      // console.log("successAddAttendee", successAddAttendee);
    }

    if (errorAddAttendee) {
      console.log("errorAddAttendee", errorAddAttendee);
      toast({
        title: t("publicCourses.registerError"),
        description: errorAddAttendee,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    dispatch({ type: COURSE_ADD_ATTENDEE_PUBLIC_RESET });
  }, [successAddAttendee, errorAddAttendee]);

  useEffect(() => {
    if (successVerify) {
      toast({
        title: t("publicCourses.verifySuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setStepSuccess(true);
      setStepVerifyEmail(false);

      // console.log("successVerify", successVerify);
    }

    if (errorVerify) {
      console.log("errorVerify", errorVerify);
      toast({
        title: t("publicCourses.verifyError"),
        description: errorVerify,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      // setStepVerifyEmail(false);
    }

    dispatch({ type: COURSE_VERIFY_ATTENDEE_EMAIL_RESET });
  }, [successVerify, errorVerify]);

  // useEffect(() => {
  //   if (userInfo) {
  //     setEmail(userInfo.email);
  //     setUserId(userInfo._id);
  //   }
  // }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserClientDetails(userInfo._id));
    }
  }, [userInfo]);

  useEffect(() => {
    if (client && client.clientEmployees && client.clientEmployees.length > 0) {
      setStepZero(true);
    } else {
      setStepZero(false);
    }
  }, [client]);

  useEffect(() => {
    if (client && client.clientType === "individual") {
      setFirstName(client.firstName);
      setLastName(client.lastName);
      setEmail(client.email);
      setProfession(client.profession._id);
      setCompanyName("");
      setAttendeeType("individual");
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setProfession("");
      setCompanyName(client.companyName);
      setAttendeeType("business");
    }
  }, [client]);

  useEffect(() => {
    if (attendee) {
      setFirstName(attendee.firstName);
      setLastName(attendee.lastName);
      setEmail(attendee.email);
      setProfession(attendee.profession._id);
      setRepresentativeOfCompany(userInfo ? userInfo.companyName : "");

      setExistingAttendee(true);
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setProfession("");

      setExistingAttendee(false);
    }
  }, [attendee]);

  // useEffect(() => {
  //   if (attendees && attendees.length > 0) {
  //     setAttendee(attendees[0]);
  //   } else {
  //     setAttendee(null);
  //   }
  // }, [attendees]);
  useEffect(() => {
    if (professionGroupId && course && course.prices) {
      const matchingPrice = course.prices.find(
        (price) =>
          price.professionGroup &&
          price.professionGroup._id === professionGroupId
      );
      if (matchingPrice) {
        setPrice(matchingPrice.price);
      } else {
        setPrice(0); // or some default/fallback price
      }
    }
  }, [professionGroupId, course]);

  const isPublicRegisterDisabled =
    !isFirstNameValid ||
    !isLastNameValid ||
    !isEmailValid ||
    !isProfessionValid;

  // LOGS
  // console.log("course", course);
  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("email", email);
  // console.log("profession", profession);
  // console.log("selectedProfession", selectedProfession);
  // console.log("isBusiness", isBusiness ? "business" : "individual");
  // console.log("companyName", companyName);
  // console.log("companyCode", companyCode);
  // console.log("address", address);
  // console.log("userInfo ? userInfo._id", userInfo ? userInfo._id : null);

  // console.log("professionGroupId", professionGroupId);
  // console.log("price  ", price);

  // console.log("courseId", course._id);
  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("email", email);
  // console.log("profession", profession);
  // console.log("attendeeType", attendeeType);
  // console.log("client", userInfo._id);
  // console.log("price", price);
  // console.log("companyName", companyName);

  // console.log("userInfo", userInfo);
  // console.log("client", client);

  // console.log("existingAttendee", existingAttendee);

  // console.log("stepZero", stepZero);
  // console.log("stepSuccess", stepSuccess);
  // console.log("stepVerifyEmail", stepVerifyEmail);

  // console.log("verificationCode", verificationCode);
  // console.log("courseId", course._id);
  // console.log("email", email);

  // console.log("course", course);
  // console.log("attendee", attendee);

  // console.log("attendeeId", attendeeId);

  // console.log("course._id", course._id);
  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("email", email);
  // console.log("profession", profession);
  // console.log("attendeeType", attendeeType);
  // console.log("userInfo._id", userInfo._id);
  // console.log("price", price);
  // console.log("companyName", companyName);
  // console.log("existingAttendee", existingAttendee);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <MotionModalContent
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        w="100%"
        maxW="900px"
        borderRadius="2rem"
        p="0 0 0rem"
      >
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          borderBottom={`1px solid ${newBorder}`}
          p="1.5rem 2rem"
        >
          <Flex align="center">
            <Flex
              align="center"
              justify="center"
              bg={primary}
              borderRadius="50%"
              p="0.5rem"
              mr="1rem"
            >
              <Icon as={TbCalendarPlus} color={white} fontSize="1rem" />
            </Flex>
            <Heading fontSize="1.125rem" fontWeight="600" color={textPrimary}>
              {t("publicCourses.checkoutTitle")}
            </Heading>
          </Flex>
          <IconButton
            autoFocus={false}
            size="sm"
            icon={<FaXmark />}
            variant="ghost"
            onClick={closeHandler}
          />
        </Flex>

        <Flex
          as={motion.div}
          w="100%"
          p="2rem 2rem"
          variants={bodyVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Flex direction="column" w="100%">
            {stepVerifyEmail && (
              <VerifyAttendeeEmail
                onVerify={handleVerificationSubmit}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                email={email}
              />
            )}

            {!stepSuccess && !stepVerifyEmail && (
              <Flex w="100%" justify="space-between" gap="2rem">
                <Flex direction="column" w="100%">
                  {stepZero && userInfo && (
                    <Flex w="100%" direction="column">
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel fontSize="sm">
                            {t("publicCourses.selectClientEmployee")}
                          </FormLabel>
                          <FormControlAttendeeEmployeeSelect
                            onChange={(selectedOption) => {
                              setAttendee(selectedOption);
                              setStepZero(false);
                            }}
                            placeholder={t("placeholder.selectEmployee")}
                            employee={attendee}
                            attendees={course.attendees}
                            mb={0}
                            setAttendee={setAttendee}
                            employerId={userInfo ? userInfo._id : null}
                          />
                        </FormControl>

                        <Box position="relative" padding="2rem 0">
                          <Divider />
                          <AbsoluteCenter bg="white" px="4">
                            <Text color={textSecondary} fontSize="xs">
                              {t("publicCourses.orRegisterNew")}
                            </Text>
                          </AbsoluteCenter>
                        </Box>

                        <Button
                          size="lg"
                          fontSize="sm"
                          bg={white}
                          border={`1px solid ${newBorder}`}
                          color={textSecondary}
                          fontWeight="500"
                          borderRadius="0.875rem"
                          onClick={registerNewHandler}
                          _hover={{
                            bg: backgroundLight,
                            color: textPrimary,
                          }}
                        >
                          {t("publicCourses.registerNew")}
                        </Button>
                      </Flex>
                    </Flex>
                  )}
                  {!stepZero && (
                    <Flex
                      border={`0px solid ${newBorder}`}
                      w="100%"
                      borderRadius="1rem"
                      bg={white}
                      // p="1.5rem"
                      direction="column"
                    >
                      <>
                        <HStack gap={4} w="100%">
                          <FormControlDefault
                            type="text"
                            isRequired
                            label={t("form.firstName")}
                            value={firstName}
                            placeholder={t("placeholder.firstName")}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            w="50%"
                            errorMessage={t("formError.firstNameRequired")}
                            isDisabled={
                              (client && client.clientType === "individual") ||
                              attendee
                                ? true
                                : false
                            }
                            noDisabledOpacity
                          />

                          <FormControlDefault
                            type="text"
                            isRequired
                            label={t("form.lastName")}
                            value={lastName}
                            placeholder={t("placeholder.lastName")}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            w="50%"
                            isDisabled={
                              (client && client.clientType === "individual") ||
                              attendee
                                ? true
                                : false
                            }
                            noDisabledOpacity
                          />
                        </HStack>

                        <HStack gap={4} w="100%">
                          <FormControlDefault
                            type="email"
                            label={t("form.email")}
                            isRequired
                            value={email}
                            placeholder={t("placeholder.email")}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            isError={!isEmailValid}
                            w="50%"
                            errorMessage={t("formError.emailInvalid")}
                            isDisabled={
                              (client && client.clientType === "individual") ||
                              attendee
                                ? true
                                : false
                            }
                            noDisabledOpacity
                          />
                          <FormControlProfessionNew
                            label={t("form.specialty")}
                            value={profession}
                            placeholder={t("select.specialty")}
                            onChange={handleProfessionChange}
                            isRequired={true}
                            w="50%"
                            onProfessionChange={handleProfessionChange}
                            isDisabled={
                              (client && client.clientType === "individual") ||
                              attendee
                                ? true
                                : false
                            }
                            noDisabledOpacity
                            setProfessionGroupId={setProfessionGroupId}
                          />
                        </HStack>

                        <FormControlDefault
                          type="text"
                          // isRequired
                          label={t("form.representingCompany")}
                          value={companyName}
                          placeholder={t("placeholder.representingCompany")}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                          w="100%"
                          isDisabled={
                            client && client.clientType === "business"
                              ? true
                              : false
                          }
                          noDisabledOpacity
                        />
                      </>
                    </Flex>
                  )}
                </Flex>

                <EventSummary
                  course={course}
                  isFirstNameValid={isFirstNameValid}
                  isLastNameValid={isLastNameValid}
                  isEmailValid={isEmailValid}
                  isProfessionValid={isProfessionValid}
                  // isCompanyNameValid={isCompanyNameValid}
                  isCompanyCodeValid={isCompanyCodeValid}
                  isBusiness={isBusiness}
                  price={price}
                />
              </Flex>
            )}

            {stepSuccess && (
              <SuccessRegister
                course={course}
                firstName={firstName}
                lastName={lastName}
                closeHandler={closeHandler}
                price={price}
              />
            )}
          </Flex>
        </Flex>
        {!stepZero && !stepSuccess && !stepVerifyEmail && (
          <Flex
            w="100%"
            m="0rem 0 0"
            p="1.5rem 2rem"
            align="center"
            justify="space-between"
            borderTop={`1px solid ${newBorder}`}
          >
            <Flex>
              {client &&
                client.clientEmployees &&
                client.clientEmployees.length > 0 && (
                  <IconButton
                    icon={<FaArrowLeft />}
                    onClick={backHandler}
                    border={`1px solid ${newBorder}`}
                    color={textSecondary}
                    borderRadius="10rem"
                    fontSize="0.75rem"
                    bg="transparent"
                    h="2.5rem"
                    w="2.5rem"
                    mr={4}
                  />
                )}

              {!userInfo && (
                <Flex align="center">
                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    mr="0.25rem"
                  >
                    {t("publicCourses.haveAccount")}
                  </Text>
                  <Button
                    size="sm"
                    fontSize="xs"
                    variant="link"
                    color={primary}
                    fontWeight="500"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {t("publicCourses.login")}
                  </Button>
                </Flex>
              )}
            </Flex>

            <Flex>
              <Button
                bg={primary}
                color={white}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                w="100%"
                // isDisabled={isDisabledButton}
                onClick={
                  userInfo ? handleRegisterEmployee : handleRegisterPublic
                }
                _hover={{ bg: shade(0.3, primary) }}
                px="3rem"
                isDisabled={!userInfo ? isPublicRegisterDisabled : false}
                isLoading={loadingAddEmployee || loadingAddAttendee}
              >
                {t("publicCourses.register")}
              </Button>
            </Flex>
          </Flex>
        )}
      </MotionModalContent>
    </Modal>
  );
};

export default EventCheckout;
