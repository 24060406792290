import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabList, Tab, Button, Icon } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  primary,
  newBorder,
  white,
  backgroundLight,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { TbSettings } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

const AccountTabs = ({ tabs, noCurrentTab, fixedTabIndex }) => {
  // GENERAL
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // REF
  const swiperRef = useRef(null);

  // SWIPER
  const [currentSlide, setCurrentSlide] = useState(0);

  // Determine the active tab index based on the current route
  const getTabIndex = () => {
    const matchingTab = tabs.findIndex((tab) => tab.path === currentPath);
    return matchingTab >= 0 ? matchingTab : 0;
  };

  const [tabIndex, setTabIndex] = useState(getTabIndex());

  // USE EFFECT
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(tabIndex);
    }
  }, [tabIndex]);

  // Update tab index when path changes
  useEffect(() => {
    if (noCurrentTab) {
      setTabIndex(-1);
    } else if (fixedTabIndex !== undefined) {
      setTabIndex(fixedTabIndex);
    } else {
      setTabIndex(getTabIndex());
    }
  }, [currentPath, noCurrentTab, fixedTabIndex]);

  const handleSettingsTabChange = () => {
    navigate("/account/settings");
  };

  // Handle clicking a tab
  const handleTabChange = (index) => {
    const tabPath = tabs[index].path;
    navigate(tabPath);
  };

  // console.log("tabIndex", tabIndex);
  // console.log("tabs", tabs);

  return (
    <Flex
      w="100%"
      maxW={["100%", "100%", "1200px"]}
      m="0 auto"
      justify={["flex-start", "flex-start", "space-between"]}
    >
      {!isMobile ? (
        <Tabs index={tabIndex} onChange={handleTabChange} colorScheme="blue">
          <TabList>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                isDisabled={tab.disabled}
                color={textSecondary}
                borderBottom={`2px solid ${white}`}
                p="1rem 2rem"
                fontWeight="500"
                borderTopLeftRadius="1rem"
                borderTopRightRadius="1rem"
                _hover={{
                  color: textPrimary,
                }}
                _selected={{
                  color: textPrimary,
                  borderBottom: `2px solid ${primary}`,

                  _focus: { boxShadow: "none" },
                }}
              >
                {tab.title}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      ) : (
        <Flex w="100%" p="1rem 0rem 0.5rem 0rem">
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            navigation={{
              prevEl: ".custom-swiper-button-prev",
              nextEl: ".custom-swiper-button-next",
            }}
            slidesPerView={3.5}
            // centeredSlides={true}
            spaceBetween={16}
            // pagination={{
            //   dynamicBullets: true,
            // }}
            modules={[Pagination, Navigation]}
            onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
            className="accountTabsSlider"
          >
            {tabs.map((tab, index) => (
              <SwiperSlide key={index}>
                <Flex
                  isDisabled={tab.disabled}
                  color={tabIndex === index ? white : textSecondary}
                  borderBottom={`2px solid ${white}`}
                  p="0.5rem 0.5rem"
                  fontWeight="500"
                  borderRadius="1rem"
                  bg={tabIndex === index ? primary : backgroundLight}
                  whiteSpace="nowrap"
                  align="center"
                  justify="center"
                  fontSize="sm"
                  // display="inline-block"
                  onClick={() => handleTabChange(index)}
                  _hover={{
                    color: textPrimary,
                  }}
                >
                  {tab.title}
                </Flex>
              </SwiperSlide>
            ))}

            {/* <Flex className="custom-swiper-button-prev">asf</Flex>
            <Flex>asfsfa</Flex> */}
          </Swiper>
        </Flex>
      )}

      <Flex
        align="center"
        justify="flex-end"
        display={["none", "none", "flex"]}
      >
        <Button
          size="lg"
          variant="ghost"
          color={noCurrentTab ? textPrimary : textSecondary}
          fontSize="md"
          fontWeight="500"
          borderRadius="10rem"
          onClick={handleSettingsTabChange}
          border={`1px solid ${white}`}
          m="0 0 0.5rem"
          transition="all 0.2s"
          position="relative"
          _hover={{
            color: textPrimary,
            border: `1px solid ${white}`,
          }}
          _focus={{
            boxShadow: "none",
            bg: "none",
          }}
          _focus_visible={{
            boxShadow: "none",
            bg: "none",
          }}
        >
          <Icon as={TbSettings} mr="0.5rem" fontSize="1.125rem" />
          {t("clientAccount.mySettings")}
          {noCurrentTab && (
            <Flex
              h="2px"
              w="100%"
              bg={primary}
              position="absolute"
              bottom="-9px"
            />
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default AccountTabs;
