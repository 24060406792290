import React from "react";
import {
  Flex,
  Box,
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
} from "@chakra-ui/react";
import { textSecondary, textPrimary } from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";

export const columns = (
  dispatch,
  translate,
  handleSortChange,
  updateUserStatus,
  openEditSidePanelModal,
  deleteUserHandler
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("user.columnName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { firstName, lastName, email, image } = row.original;

        const name = `${lastName} ${firstName}`;

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                w="3rem"
                h="3rem"
                src={image}
                alt={name}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <Avatar
                name={name}
                // bg="#dceeff" color="#3a74a9"
              />
            )}
            <Stack gap={0} maxWidth="200px" overflow="hidden">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated
                maxWidth="200px"
              >
                {name}
              </Text>
              {email && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="200px"
                >
                  {email.toLowerCase()}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnPhone")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row.original;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {phone}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("createdAt")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("user.columnCreatedAt")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { createdAt } = row.original;

        // Use dayjs to format the date
        const formattedDate = dayjs(createdAt).format("MMMM D, YYYY, HH:mm");

        return (
          <HStack gap={2}>
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              textTransform="capitalize"
            >
              {formattedDate}
            </Text>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("role")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("user.columnRole")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "role",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { isAdmin } = row.original;

        // console.log("isAdmin", isAdmin);

        return (
          <HStack gap={2}>
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              {isAdmin ? translate("common.admin") : translate("common.user")}
            </Text>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateUserStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteUserHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
