import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  useToast,
  Spinner,
  Image,
  Icon,
} from "@chakra-ui/react";
import {
  listCourseDetails,
  updateCourseStatus,
  deleteCourse,
} from "../../../../actions/courseActions";
import Panel from "../../../../components/shared/Panel";
import {
  primary,
  newBorder,
  backgroundLight,
  textSecondary,
  white,
} from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditClient from "../sidepanels/EditClient";
import { useTranslation } from "react-i18next";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import ToastComponent from "../../../../components/partials/ToastComponent";
import Info from "./partials/Info";
import { getUserClientDetails } from "../../../../actions/userActions";
import SinglePagePieChart from "../../../../components/charts/SinglePagePieChart";
import { TbPhotoOff } from "react-icons/tb";
import TabClientReports from "./tabs/TabClientReports";
import ClientCharts from "./partials/ClientCharts";
import UserPopover from "../../../../components/popovers/UserPopover";
import {
  listUsers,
  updateUserClientEmployee,
} from "../../../../actions/userActions";
import { USER_CLIENT_UPDATE_EMPLOYEE_RESET } from "../../../../constants/userConstants";

const ClientSingle = () => {
  const clientId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("clientAccount.tabReports") },
    { label: t("clientAccount.tabEvents") },
    { label: t("clientAccount.tabStock") },
  ];

  // STATE
  const [clientReports, setClientReports] = useState([]);

  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [status, setStatus] = useState("");

  const [title, setTitle] = useState("");
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [prices, setPrices] = useState([]);

  const [employees, setEmployees] = useState([]);

  const [employee, setEmployee] = useState(null);

  // REDUX

  const userGetClientDetails = useSelector(
    (state) => state.userGetClientDetails
  );

  const { loading, error, client } = userGetClientDetails;

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users, pages } = userList;

  const userClientUpdateEmployee = useSelector(
    (state) => state.userClientUpdateEmployee
  );

  const { success: successUpdateEmployee, error: errorUpdateEmployee } =
    userClientUpdateEmployee;

  // HANDLERS

  const deleteCourseHandler = (id) => {
    // dispatch(deleteCourse(id));
    toastComponent.showToast(t("toasts.courseDelete"), "success", 3000);
  };

  const handleUpdateStatus = (status) => {
    dispatch(updateCourseStatus(clientId, status));
  };

  const handleEmployeeChange = (clientId, employeeId) => {
    dispatch(updateUserClientEmployee(clientId, employeeId));
  };

  // USE EFFECT

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!client || client._id !== clientId) {
      dispatch(getUserClientDetails(clientId));
    } else {
      setStatus(client.status);
      setEmployee(client.employee);
    }
  }, [dispatch, clientId, client]);

  useEffect(() => {
    if (client && client.reports) {
      setClientReports(client.reports);
    } else {
      setClientReports([]);
    }
  }, [client]);

  useEffect(() => {
    dispatch(
      listUsers({
        pageSize: 100,
      })
    );
    setEmployees(employees);
  }, [dispatch, employees]);

  useEffect(() => {
    if (successUpdateEmployee) {
      toastComponent.showToast(
        t("toasts.employeeUpdateSuccess"),
        "success",
        3000
      );
    } else if (errorUpdateEmployee) {
      toastComponent.showToast(t("toasts.employeeUpdateError"), "error", 3000);
    }
    dispatch({ type: USER_CLIENT_UPDATE_EMPLOYEE_RESET });
  }, [successUpdateEmployee, errorUpdateEmployee]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  console.log("client", client);
  console.log("employee", employee);
  console.log("client.employee", client.employee);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.adminClientsSingle")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminClientsSingle")}
        />
      </Helmet>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={
                client.clientType === "individual"
                  ? client.firstName
                  : client.companyName
              }
              type={`${t(`implantForm.${client.clientType}`)} ${t("|")} ${t(
                `implantForm.${client.accountType}`
              )}`}
              loading={loading}
            >
              <Flex align="center" mr="1.5rem">
                <UserPopover
                  loading={loadingUsers}
                  initialUser={client.employee}
                  options={users}
                  onChange={(selectedEmployee) =>
                    handleEmployeeChange(
                      clientId,
                      selectedEmployee ? selectedEmployee._id : null
                    )
                  }
                />
              </Flex>
              <StatusButton
                currentStatus={client.status}
                onUpdateStatus={handleUpdateStatus}
                options="active"
              />
              <MoreButton
                id={clientId}
                isBig
                deleteHandler={deleteCourseHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(clientId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 0rem 0rem 2rem"
                  alignItems="flex-start"
                  justifyContent="center"
                  w="100%"
                >
                  {/* <SinglePageImageWrapper
                    id={clientId}
                    borderRadius="2rem"
                    image={client.image}
                    options="course"
                  /> */}
                  <Flex
                    bg={backgroundLight}
                    border={`0px solid ${newBorder}`}
                    borderRadius="2rem"
                    align="center"
                    justify="center"
                    p="3rem 0"
                    w="260px"
                    maxW="260px"
                    h="260px"
                    maxH="260px"
                  >
                    {client && client.image ? (
                      <Flex
                        w="10rem"
                        h="10rem"
                        borderRadius="1.75rem"
                        overflow="hidden"
                      >
                        <Image
                          w="100%"
                          h="100%"
                          objectFit="cover"
                          src={client.image}
                          alt={client.firstName}
                          border={`1px solid ${newBorder}`}
                        />
                      </Flex>
                    ) : (
                      <Flex
                        w="10rem"
                        h="10rem"
                        bg={white}
                        align="center"
                        justify="center"
                        borderRadius="2rem"
                        overflow="hidden"
                        border={`0px solid ${newBorder}`}
                      >
                        <Icon
                          as={TbPhotoOff}
                          fontSize="1.75rem"
                          fontWeight="500"
                          color={textSecondary}
                        />
                      </Flex>
                    )}
                  </Flex>

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <Info data={client} />

                      <ClientCharts data={client} />
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                isProfessionValid={true}
                disabledTabs={[2]}
                isSinglePage
              >
                <TabPanels p="0 0 2rem">
                  <TabPanel pb={4} px={0}>
                    <TabClientReports
                      title={
                        client.clientType === "individual"
                          ? client.firstName + " " + client.lastName
                          : client.companyName
                      }
                      loading={loading}
                      error={error}
                      reports={clientReports}
                    />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    {/* <TabCourseSpeakers title={course.title} /> */}
                  </TabPanel>
                </TabPanels>
              </TabsComponent>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ClientSingle;
