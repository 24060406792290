import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/shared/Panel";
import { Flex, Heading, Text, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  backgroundLight,
} from "../../../utils/colors";
import ReportsStatusCount from "./partials/ReportsStatusCount";
import AttendeesStatusCount from "./partials/AttendeesStatusCount";
import ClientsStatusCount from "./partials/ClientsStatusCount";
import TopNewClients from "./partials/TopNewClients";
import EmployeeClients from "./partials/EmployeeClients";
import TopReportsFixture from "./partials/TopReportsFixture";
import EmployeeClientsReports from "./partials/EmployeeClientsReports";

const Dasbhoard = () => {
  // GENERAL
  const navigate = useNavigate();
  const { t } = useTranslation();

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // USEEFFECT

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  // SIDEPANEL HANDLERS

  // LOGS

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.adminDashboard")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminDashboard")}
        />
      </Helmet>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Flex w="100%" p="0rem" direction="column" minH="60vh">
              <Flex
                w="100%"
                p="1.5rem 2.5rem"
                borderBottom={`1px solid ${newBorder}`}
              >
                <Stack gap="0rem">
                  <Text
                    fontSize="1.5rem"
                    fontWeight="500"
                    color={textPrimary}
                    m="0 0 0rem"
                  >
                    {t("dashboard.welcome")}{" "}
                    <Text as="span" fontWeight="700" color={textPrimary}>
                      {userInfo.firstName}
                    </Text>
                  </Text>

                  <Text fontSize="sm" color={textSecondary}>
                    {t("dashboard.description")}
                  </Text>
                </Stack>
              </Flex>

              <Flex
                w="100%"
                // align="center"
                // justify="flex-start"
                gap="2rem"
                p="2rem 2.5rem 6rem"
              >
                <Flex w="100%" gap="2rem" direction="column">
                  <Flex
                    border={`1px solid ${newBorder}`}
                    p="1.5rem 1.5rem 32px"
                    borderRadius="2rem"
                    gap="2rem"
                    w="100%"
                    minH="256px"
                  >
                    <Flex>
                      <ReportsStatusCount />
                    </Flex>
                    <Flex w="1px" h="100%" bg={newBorder} />
                    <Flex>
                      <AttendeesStatusCount />
                    </Flex>
                    <Flex w="1px" h="100%" bg={newBorder} />

                    <Flex>
                      <ClientsStatusCount />
                    </Flex>
                  </Flex>
                  <Flex
                    border={`1px solid ${newBorder}`}
                    p="1.5rem 1.5rem 1rem"
                    borderRadius="2rem"
                    w="100%"
                  >
                    <EmployeeClients />
                  </Flex>
                  <Flex
                    border={`1px solid ${newBorder}`}
                    p="1.5rem 1.5rem 1rem"
                    borderRadius="2rem"
                    w="100%"
                  >
                    <EmployeeClientsReports />
                  </Flex>
                </Flex>
                <Flex direction="column" gap="2rem">
                  <Flex
                    w="300px"
                    minW="300px"
                    maxW="300px"
                    direction="column"
                    border={`1px solid ${newBorder}`}
                    borderRadius="2rem"
                    p="1.5rem 1.5rem 1rem"
                    minH="256px"
                    // bg={backgroundLight}
                  >
                    <TopNewClients />
                  </Flex>
                  <Flex
                    w="300px"
                    minW="300px"
                    maxW="300px"
                    direction="column"
                    border={`1px solid ${newBorder}`}
                    borderRadius="2rem"
                    p="1.5rem 1.5rem 1rem"
                    minH="256px"
                    // bg={backgroundLight}
                  >
                    <TopReportsFixture />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Dasbhoard;
