import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbMapPin,
  TbBuilding,
  TbUsers,
  TbMapPinStar,
  TbWallet,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const ProductInfo = ({ product }) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const [sku, setSku] = useState("");
  const [mpn, setMpn] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [core, setCore] = useState("");

  useEffect(() => {
    if (product) {
      setSku(product.sku);
    }
  }, []);

  // Define the titles for different slides
  const slideTitles = [
    t("product.productDetails"),
    t("product.productPricing"),
  ];

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  console.log("product", product);

  const infoArray = [
    {
      label: "Size",
      value: (
        <Flex alignItems="center">
          <Text>Size:</Text>
          <Text
            fontSize="1rem"
            color={textPrimary}
            fontWeight="500"
            isTruncated
            noOfLines={2}
            whiteSpace="normal"
            overflow="hidden"
          >
            Ø{product.width}/{product.length}
          </Text>
        </Flex>
      ),
    },
    {
      label: "Core",
      value: (
        <Flex alignItems="center">
          <Text>Core:</Text>
          <Text
            fontSize="1rem"
            color={textPrimary}
            fontWeight="500"
            isTruncated
            noOfLines={2}
            whiteSpace="normal"
            overflow="hidden"
          >
            C {product.core}
          </Text>
        </Flex>
      ),
    },
    {
      label: "Price",
      value: (
        <Flex alignItems="center">
          <Text>Price:</Text>
          <Text
            fontSize="1rem"
            color={textPrimary}
            fontWeight="500"
            isTruncated
            noOfLines={2}
            whiteSpace="normal"
            overflow="hidden"
          >
            {product.price} €
          </Text>
        </Flex>
      ),
    },
    {
      label: "Sku",
      value: (
        <Flex alignItems="center">
          <Text>Sku:</Text>
          <Text
            fontSize="1rem"
            color={textPrimary}
            fontWeight="500"
            isTruncated
            noOfLines={2}
            whiteSpace="normal"
            overflow="hidden"
          >
            {sku || "N/A"}
          </Text>
        </Flex>
      ),
    },
    {
      label: "Implant type",
      value: (
        <Flex alignItems="center">
          <Text>Implant type:</Text>
          <Text
            fontSize="1rem"
            color={textPrimary}
            fontWeight="500"
            isTruncated
            noOfLines={2}
            whiteSpace="normal"
            overflow="hidden"
          >
            {product.implantType}
          </Text>
        </Flex>
      ),
    },
  ];

  return (
    <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {slideTitles[currentSlide]}
        </Heading>
        <Box>
          <IconButton
            className="custom-swiper-button custom-swiper-button-prev"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              background: backgroundLight,
            }}
          />
          <IconButton
            className="custom-swiper-button custom-swiper-button-next"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box>
        <Swiper
          navigation={{
            prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
            nextEl: ".custom-swiper-button-next", // Selector for your custom next button
          }}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Navigation]}
          onSlideChange={handleSlideChange}
          className="courseInfoSlider"
        >
          <SwiperSlide>
            <Box>
              {infoArray.map((item, index) => (
                <Flex key={index} alignItems="center">
                  {item.value && (
                    <Flex p="0.35rem 0" alignItems="center">
                      {item.value}
                    </Flex>
                  )}
                </Flex>
              ))}
            </Box>
          </SwiperSlide>
          <SwiperSlide>2</SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  );
};

export default ProductInfo;
