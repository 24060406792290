import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Icon,
  Divider,
  HStack,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import lt from "dayjs/locale/lt";
import {
  newBorder,
  textSecondary,
  textPrimary,
  primary,
  white,
  buttonRed,
  buttonRedText,
  secondary,
  backgroundLight,
} from "../../../../utils/colors";
import {
  TbCalendarEvent,
  TbCalendarTime,
  TbMapPin,
  TbMapPin2,
  TbUserStar,
  TbWallet,
  TbInfoCircleFilled,
  TbCalendarPlus,
  TbCalendarCheck,
  TbPhotoCancel,
  TbEye,
} from "react-icons/tb";
import { t } from "i18next";
import { shade } from "polished";
import { useMediaQuery } from "react-responsive";

const CoursesPublicList = ({ courses, language, openCheckout }) => {
  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // GENERAL
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortedMonths = Object.keys(courses).sort((a, b) => {
    // Compare the date objects for sorting
    return courses[a].date - courses[b].date;
  });

  // console.log("Courses", courses);
  // console.log("Sorted months", sortedMonths);

  useEffect(() => {
    if (language === "lt") {
      dayjs.locale(lt);
    } else {
      dayjs.locale("en");
    }
  }, [language]);

  return (
    <>
      <Flex
        direction="column"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
        p={["0 1.5rem", "0 1.5rem", "1rem 2rem 0"]}
        w="100%"
        // bg={white}
        // borderRadius="2rem"
      >
        {sortedMonths.map((monthYear) => (
          <Box key={monthYear} w="100%">
            <Flex
              align="center"
              w="100%"
              p={["0rem 0 1rem", "0rem 0 1rem", "0rem 0 2rem"]}
            >
              <Text
                fontSize="sm"
                fontWeight="500"
                whiteSpace="nowrap"
                color={textPrimary}
                pr="1rem"
              >
                {monthYear.toUpperCase()}
              </Text>
              <Divider orientation="horizontal" />
            </Flex>

            {courses[monthYear].courses.map((course) => {
              const isSameDay = dayjs(course.startDate).isSame(
                course.endDate,
                "day"
              );

              const sortedPrices = course.prices.sort(
                (a, b) => a.price - b.price
              );
              const lowestPrice = sortedPrices[0]?.price;

              const isPastEvent = dayjs().isAfter(dayjs(course.startDate));

              const isStatusCancelled = course.status === "cancelled";

              // const maximumCapacityReached =
              //   course.maximumAttendees === course.totalAttendees;

              // console.log("Course", course);
              // console.log("Maximum capacity reached", maximumCapacityReached);
              // console.log("isPastEvent", isPastEvent);

              return (
                <Flex
                  key={course._id}
                  border={`0px solid ${newBorder}`}
                  align="center"
                  justify="space-between"
                  w="100%"
                  m="0 0 3rem"
                  bg={white}
                  borderRadius="2rem"
                  p={["1.5rem", "1.5rem", "2rem"]}
                  direction={["column-reverse", "column-reverse", "row"]}
                  onClick={
                    isMobile ? () => navigate(`/events/${course._id}`) : null
                  } // HERE
                >
                  {!isMobile ? (
                    <>
                      <Flex flex="1" w="100%">
                        <Flex
                          direction="column"
                          minW="100px"
                          mr={["0rem", "0rem", "2rem"]}
                        >
                          <Text
                            fontWeight="500"
                            fontSize="xs"
                            color={textSecondary}
                          >
                            {dayjs(course.startDate)
                              .format("MMMM")
                              .toUpperCase()}{" "}
                            {/* Weekday name */}
                          </Text>

                          <Text
                            fontWeight="600"
                            fontSize="lg"
                            color={textPrimary}
                          >
                            {dayjs(course.startDate).format("D")}{" "}
                            <Text
                              as="span"
                              fontSize="xs"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              (
                              {dayjs(course.startDate)
                                .format("ddd")
                                .toUpperCase()}
                              )
                            </Text>{" "}
                            {/* Day of the month */}
                          </Text>
                        </Flex>
                        <Flex direction="column">
                          <Text fontWeight="bold" fontSize="1.25rem">
                            {course.title}
                          </Text>
                          <HStack align="center" mt="2">
                            <Icon
                              as={TbCalendarEvent}
                              color={textSecondary}
                              fontSize="1rem"
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("publicCourses.date")}:{" "}
                            </Text>
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {isSameDay
                                ? dayjs(course.startDate).format("L")
                                : `${dayjs(course.startDate).format(
                                    "L"
                                  )} - ${dayjs(course.endDate).format("L")}`}
                            </Text>
                          </HStack>

                          <HStack align="center" mt="2">
                            <Icon
                              as={TbCalendarTime}
                              color={textSecondary}
                              fontSize="1rem"
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("publicCourses.startTime")}:{" "}
                            </Text>
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {isSameDay
                                ? `${dayjs(course.startDate).format("LT")}`
                                : `${dayjs(course.startDate).format("LT")}`}
                            </Text>
                          </HStack>

                          <HStack align="center" mt="2">
                            <Icon
                              as={TbMapPin}
                              color={textSecondary}
                              fontSize="1rem"
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("publicCourses.location")}:{" "}
                            </Text>
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {course.location}
                            </Text>
                          </HStack>

                          <HStack align="center" mt="2">
                            <Icon
                              as={TbMapPin2}
                              color={textSecondary}
                              fontSize="1rem"
                            />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("publicCourses.address")}:{" "}
                            </Text>
                            {course.address && course.address.length > 0 ? (
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                              >
                                {course.address[0].street &&
                                  course.address[0].street}
                                ,{" "}
                                {course.address[0].city &&
                                  course.address[0].city}
                                ,{" "}
                                {course.address[0].zip && course.address[0].zip}
                                ,{" "}
                                {course.address[0].country &&
                                  course.address[0].country}
                              </Text>
                            ) : (
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                              >
                                -
                              </Text>
                            )}
                          </HStack>

                          <HStack align="center" mt="2">
                            <Icon as={TbUserStar} color={textSecondary} />
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("course.speaker")}:{" "}
                            </Text>

                            {course.speakers && course.speakers.length > 0 ? (
                              course.speakers.length === 1 ? (
                                <>
                                  <Text
                                    fontSize="sm"
                                    color={textSecondary}
                                    fontWeight="500"
                                  >
                                    {course.speakers[0].firstName &&
                                      course.speakers[0].firstName}{" "}
                                    {course.speakers[0].lastName &&
                                      course.speakers[0].lastName}
                                  </Text>
                                </>
                              ) : (
                                course.speakers.map((speaker, index) => (
                                  <Tooltip
                                    label={speaker.firstName + speaker.lastName}
                                    key={index}
                                  >
                                    <Avatar
                                      size="xs"
                                      bg={backgroundLight}
                                      color={textSecondary}
                                      src={speaker.image || ""}
                                      name={speaker.lastName}
                                      border={`1px solid ${newBorder}`}
                                      ml={index === 0 ? 0 : "-0.75rem"} // Adjust margin for the first avatar
                                    />
                                  </Tooltip>
                                ))
                              )
                            ) : (
                              <Text
                                fontSize="sm"
                                color={textSecondary}
                                fontWeight="500"
                              >
                                -
                              </Text>
                            )}
                          </HStack>

                          <HStack align="center" mt="2">
                            <Icon
                              as={TbWallet}
                              color={textSecondary}
                              fontSize="1rem"
                            />

                            {!course.free ? (
                              <Tooltip
                                color={white}
                                p="0.5rem 1rem"
                                borderRadius="1rem"
                                label={
                                  <Flex direction="column">
                                    {sortedPrices.map((p) => (
                                      <Flex align="center" key={p._id}>
                                        <Text
                                          key={p._id}
                                          fontSize="sm"
                                          fontWeight="400"
                                          color={shade(0.2, white)}
                                        >
                                          {p.professionGroup &&
                                            p.professionGroup.name &&
                                            p.professionGroup.name}
                                          :
                                          <Text
                                            as="span"
                                            opacity="1"
                                            ml={1}
                                            fontWeight="500"
                                            color={white}
                                          >
                                            {p.price} €
                                          </Text>
                                        </Text>
                                      </Flex>
                                    ))}
                                  </Flex>
                                }
                                placement="top"
                              >
                                <Flex align="center">
                                  {lowestPrice === 0 ? (
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      mr={1}
                                    >
                                      {t("common.priceDiffers")}
                                    </Text>
                                  ) : (
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color={textSecondary}
                                      mr={1}
                                    >
                                      {t("common.priceFrom")}{" "}
                                      {lowestPrice
                                        ? `${lowestPrice} 
                                €
                                `
                                        : t("common.free")}
                                    </Text>
                                  )}
                                  <Icon
                                    as={TbInfoCircleFilled}
                                    color={textSecondary}
                                    position="relative"
                                    top="-1px"
                                  />
                                </Flex>
                              </Tooltip>
                            ) : (
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                              >
                                {course.free && t("common.free")}
                              </Text>
                            )}
                          </HStack>

                          <Flex w="100%" gap="1rem">
                            <Button
                              m="1.5rem 0 0"
                              bg={backgroundLight}
                              color={textSecondary}
                              border={`0px solid ${newBorder}`}
                              size="lg"
                              fontWeight="500"
                              fontSize="sm"
                              borderRadius="10rem"
                              onClick={() => navigate(`/events/${course._id}`)}
                              minW="12rem"
                            >
                              <Icon
                                as={TbEye}
                                color={textSecondary}
                                fontSize="1rem"
                                mr="0.5rem"
                              />
                              {t("publicCourses.aboutEventButton")}{" "}
                            </Button>

                            {isStatusCancelled && (
                              <Button
                                m="1.5rem 0 0"
                                bg={buttonRed}
                                color={buttonRedText}
                                size="lg"
                                fontSize="sm"
                                fontWeight="500"
                                borderRadius="10rem"
                                // minW="280px"
                                _hover={{
                                  bg: shade(0.1, buttonRed),
                                }}
                                role="group"
                                onClick={() => openCheckout(course)}
                                minW="12rem"
                                isDisabled
                              >
                                {/* <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="0.5rem"
                            >
                              <Icon
                                as={TbCalendarPlus}
                                color={primary}
                                fontSize="1rem"
                                _groupHover={{
                                  color: secondary,
                                }}
                              />
                            </Flex> */}
                                {t("status.cancelled")}
                              </Button>
                            )}

                            {!isPastEvent && !isStatusCancelled && (
                              <Button
                                m="1.5rem 0 0"
                                bg={primary}
                                color={white}
                                size="lg"
                                fontSize="sm"
                                borderRadius="10rem"
                                // minW="280px"
                                _hover={{
                                  bg: secondary,
                                }}
                                role="group"
                                onClick={() => openCheckout(course)}
                              >
                                <Flex
                                  align="center"
                                  justify="center"
                                  w="1.75rem"
                                  h="1.75rem"
                                  borderRadius="50%"
                                  bg="white"
                                  mr="0.5rem"
                                >
                                  <Icon
                                    as={TbCalendarPlus}
                                    color={primary}
                                    fontSize="1rem"
                                    _groupHover={{
                                      color: secondary,
                                    }}
                                  />
                                </Flex>
                                {t("publicCourses.register")}
                              </Button>
                            )}

                            {isPastEvent && !isStatusCancelled && (
                              <Button
                                m="1.5rem 0 0"
                                bg="#C6EE51"
                                color={textPrimary}
                                size="lg"
                                fontSize="sm"
                                borderRadius="10rem"
                                onClick={() => alert("Registration clicked")}
                                minW="12rem"
                                _hover={{
                                  bg: shade(0.1, "#C6EE51"),
                                }}
                                isDisabled={true}
                              >
                                <Flex
                                  align="center"
                                  justify="center"
                                  w="1.75rem"
                                  h="1.75rem"
                                  borderRadius="50%"
                                  bg="white"
                                  mr="1rem"
                                >
                                  <Icon
                                    as={TbCalendarCheck}
                                    color={primary}
                                    fontSize="1rem"
                                  />
                                </Flex>
                                {t("publicCourses.registerPast")}
                              </Button>
                            )}

                            {/* {maximumCapacityReached && (
                          <Button
                            m="1.5rem 0 0"
                            bg={buttonRed}
                            color={buttonRedText}
                            size="lg"
                            fontSize="sm"
                            borderRadius="10rem"
                            onClick={() => alert("Registration clicked")}
                            minW="280px"
                            isDisabled={true}
                          >
                            <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="1rem"
                            >
                              <Icon
                                as={TbCalendarCancel}
                                color={buttonRedText}
                                fontSize="1rem"
                              />
                            </Flex>
                            {t("publicCourses.registerMaxCapacity")}
                          </Button>
                        )} */}
                          </Flex>
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Flex direction="column" w="100%" p="1.5rem 1rem 0.5rem">
                      <Text
                        fontWeight="bold"
                        fontSize="1.25rem"
                        maxW="100%"
                        isTruncated
                      >
                        {course.title}
                      </Text>
                      <HStack align="center" mt="2">
                        <Icon
                          as={TbCalendarEvent}
                          color={textSecondary}
                          fontSize="1rem"
                        />
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {t("publicCourses.date")}:{" "}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {isSameDay
                            ? dayjs(course.startDate).format("DD MMMM, YYYY")
                            : `${dayjs(course.startDate).format("L")} - ${dayjs(
                                course.endDate
                              ).format("L")}`}
                        </Text>
                      </HStack>

                      <HStack align="center" mt="2">
                        <Icon
                          as={TbCalendarTime}
                          color={textSecondary}
                          fontSize="1rem"
                        />
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {t("publicCourses.startTime")}:{" "}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {isSameDay
                            ? `${dayjs(course.startDate).format("LT")}`
                            : `${dayjs(course.startDate).format("LT")}`}
                        </Text>
                      </HStack>

                      <HStack align="center" mt="2">
                        <Icon
                          as={TbMapPin}
                          color={textSecondary}
                          fontSize="1rem"
                        />
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {t("publicCourses.location")}:{" "}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                          maxW="80%"
                          isTruncated
                        >
                          {course.location}
                        </Text>
                      </HStack>

                      <HStack align="center" mt="2">
                        <Icon
                          as={TbMapPin2}
                          color={textSecondary}
                          fontSize="1rem"
                        />
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {t("publicCourses.address")}:{" "}
                        </Text>
                        {course.address && course.address.length > 0 ? (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color={textSecondary}
                            maxW="80%"
                            isTruncated
                          >
                            {course.address[0].street &&
                              course.address[0].street}
                            , {course.address[0].city && course.address[0].city}
                            , {course.address[0].zip && course.address[0].zip},{" "}
                            {course.address[0].country &&
                              course.address[0].country}
                          </Text>
                        ) : (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color={textSecondary}
                          >
                            -
                          </Text>
                        )}
                      </HStack>

                      <Flex w="100%" gap="1rem">
                        {/* <Button
                          m="1.5rem 0 0"
                          bg={backgroundLight}
                          color={textSecondary}
                          border={`0px solid ${newBorder}`}
                          size="lg"
                          fontWeight="500"
                          fontSize="sm"
                          borderRadius="10rem"
                          onClick={() => navigate(`/events/${course._id}`)}
                          minW="12rem"
                        >
                          <Icon
                            as={TbEye}
                            color={textSecondary}
                            fontSize="1rem"
                            mr="0.5rem"
                          />
                          {t("publicCourses.aboutEventButton")}{" "}
                        </Button> */}

                        {isStatusCancelled && (
                          <Button
                            m="1.5rem 0 0"
                            bg={buttonRed}
                            color={buttonRedText}
                            size="lg"
                            fontSize="sm"
                            fontWeight="500"
                            borderRadius="10rem"
                            w="100%"
                            // minW="280px"
                            _hover={{
                              bg: shade(0.1, buttonRed),
                            }}
                            role="group"
                            onClick={() => openCheckout(course)}
                            minW="100%"
                            isDisabled
                          >
                            {/* <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="0.5rem"
                            >
                              <Icon
                                as={TbCalendarPlus}
                                color={primary}
                                fontSize="1rem"
                                _groupHover={{
                                  color: secondary,
                                }}
                              />
                            </Flex> */}
                            {t("status.cancelled")}
                          </Button>
                        )}

                        {!isPastEvent && !isStatusCancelled && (
                          <Button
                            m="1.5rem 0 0"
                            bg={primary}
                            color={white}
                            w="100%"
                            size="lg"
                            fontSize="sm"
                            borderRadius="10rem"
                            // minW="280px"
                            _hover={{
                              bg: secondary,
                            }}
                            role="group"
                            onClick={() => openCheckout(course)}
                          >
                            <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="0.5rem"
                            >
                              <Icon
                                as={TbCalendarPlus}
                                color={primary}
                                fontSize="1rem"
                                _groupHover={{
                                  color: secondary,
                                }}
                              />
                            </Flex>
                            {t("publicCourses.register")}
                          </Button>
                        )}

                        {isPastEvent && !isStatusCancelled && (
                          <Button
                            m="1.5rem 0 0"
                            bg="#C6EE51"
                            color={textPrimary}
                            size="lg"
                            fontSize="sm"
                            borderRadius="10rem"
                            onClick={() => alert("Registration clicked")}
                            minW="100%"
                            _hover={{
                              bg: shade(0.1, "#C6EE51"),
                            }}
                            isDisabled={true}
                          >
                            <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="1rem"
                            >
                              <Icon
                                as={TbCalendarCheck}
                                color={primary}
                                fontSize="1rem"
                              />
                            </Flex>
                            {t("publicCourses.registerPast")}
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  )}

                  <Flex
                    h={["100%", "100%", "280px"]}
                    minH={["280px", "280px", "280px"]}
                    w={["100%", "100%", "280px"]}
                    overflow="hidden"
                    borderRadius={["1.5rem", "1.5rem", "1rem"]}
                  >
                    {course.image ? (
                      <Image
                        w="100%"
                        h="100%"
                        objectFit="cover"
                        borderRadius="1rem"
                        src={course.image}
                        alt={`${course.title}`}
                        transition="all 0.2s"
                        overflow="hidden"
                        onClick={() => navigate(`/events/${course._id}`)}
                        _hover={{
                          transform: "scale(1.05)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <Flex
                        h={["100%", "100%", "280px"]}
                        w={["100%", "100%", "280px"]}
                        align="center"
                        justify="center"
                        bg={newBorder}
                        borderRadius="1rem"
                      >
                        <Icon
                          as={TbPhotoCancel}
                          color={textSecondary}
                          fontSize="2rem"
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        ))}
      </Flex>

      {/* <Flex direction="column" maxW="1200px" m="auto" p="4" bg="white">
      <Text fontSize="2xl" fontWeight="bold" mb="4">
        Renginiai
      </Text>

      {courses.map((course) => (
        <Flex
          key={course._id}
          p="5"
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
          mb="4"
          align="center"
        >
          <Image
            borderRadius="full"
            boxSize="100px"
            src={course.image}
            alt={`Image of ${course.title}`}
            mr="4"
          />
          <Box flex="1">
            <Text fontWeight="bold" fontSize="lg">
              {course.title}
            </Text>
            <Text>{course.description || "No description available"}</Text>
            <Stack isInline align="center" mt="2">
              <Icon as={FaCalendarDay} />
              <Text>
                {new Date(course.startDate).toLocaleDateString()} -{" "}
                {new Date(course.endDate).toLocaleDateString()}
              </Text>
            </Stack>
            <Stack isInline align="center">
              <Icon as={FaMapMarkerAlt} />
              <Text>{course.location}</Text>
            </Stack>
            <Stack isInline align="center">
              <Icon as={FaClock} />
              <Text>{`${new Date(
                course.startDate
              ).toLocaleTimeString()} - ${new Date(
                course.endDate
              ).toLocaleTimeString()}`}</Text>
            </Stack>
            <Badge colorScheme={course.free ? "green" : "red"}>
              {course.free ? "Free" : "Paid"}
            </Badge>
          </Box>
          <Button
            colorScheme="blue"
            onClick={() => alert("Registration clicked")}
          >
            Registration
          </Button>
        </Flex>
      ))}
    </Flex> */}
    </>
  );
};

export default CoursesPublicList;
