import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HStack, Button, Flex, Icon, Text } from "@chakra-ui/react";
import {
  white,
  buttonGreen,
  buttonGreenText,
  primary,
  buttonRedText,
} from "../../../../../utils/colors";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { shade } from "polished";
import FormControlCountry from "../../../../../components/input/FormControlCountry";
import FormControlAddressFull from "../../../../../components/input/FormControlAddressFull";
import { GoogleApiContext } from "../../../../../contexts/GoogleApiContext";

const Step0 = ({
  setActiveStep,
  userInfo,
  clientType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  companyName,
  setCompanyName,
  companyCode,
  setCompanyCode,
  email,
  setEmail,
  isValid,
  isLoggedIn,
  isEmployee,
  address,
  setAddress,
  displayAddress,
  setDisplayAddress,
  manualAddressVisible,
  toggleManualAddressVisible,
  handleAddressSelect,
}) => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();

  // GOOGLE API
  const { isApiLoaded } = useContext(GoogleApiContext);

  // CUSTOM
  const iconElement = (
    <Flex
      p="0"
      mr="0.5rem"
      h="100%"
      position="absolute"
      bottom="2px"
      top="40px"
      right="0.5rem"
      opacity="0.6"
      pointerEvents="none"
    >
      <Flex
        w="1.5rem"
        h="1.5rem"
        justify="center"
        align="center"
        bg={buttonGreen}
        borderRadius="50%"
      >
        <Icon as={FaUserCircle} color={buttonGreenText} fontSize="0.875rem" />
      </Flex>
    </Flex>
  );

  const tooltipLabel = (
    <Flex align="center">
      {clientType === "individual" ? (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInIndividual")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {firstName} {lastName}
          </Text>
        </Text>
      ) : (
        <Text fontSize="sm" fontWeight="500">
          {t("implantForm.loggedInCompany")}
          <Text as="span" ml="0.25rem" fontWeight="600">
            {companyName}
          </Text>
        </Text>
      )}
    </Flex>
  );

  // LOGS

  // RENDER
  return (
    <Flex w="100%" maxW={["100%", "100%", "600px"]} direction="column">
      {clientType === "individual" ? (
        <>
          <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
            <FormControlDefault
              type="text"
              isRequired
              label={t("form.firstName")}
              value={firstName}
              placeholder={t("placeholder.firstName")}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              w="50%"
              isDisabled={
                userInfo && userInfo.firstName && userInfo.firstName
                  ? true
                  : true
              }
              rightElement={userInfo ? iconElement : null}
              isTooltip={userInfo}
              tooltipLabel={tooltipLabel}
              noDisabledOpacity
            />

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.lastName")}
              value={lastName}
              placeholder={t("placeholder.lastName")}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              w="50%"
              isDisabled={
                userInfo && userInfo.lastName && userInfo.lastName ? true : true
              }
              rightElement={userInfo ? iconElement : null}
              isTooltip={userInfo}
              tooltipLabel={tooltipLabel}
              noDisabledOpacity
            />
          </Flex>

          <FormControlDefault
            type="email"
            label={t("form.email")}
            isRequired
            value={email}
            placeholder={t("placeholder.email")}
            onChange={(e) => {
              setEmail(e.target.value);
              // setEmailTouched(true);
            }}
            // onBlur={() => setEmailTouched(true)}
            // isTouched={emailTouched}
            // isError={!isEmailValid}
            w="100%"
            // errorMessage={t("formError.emailInvalid")}
            isDisabled={
              userInfo && userInfo.email && userInfo.email ? true : true
            }
            rightElement={userInfo ? iconElement : null}
            isTooltip={userInfo}
            tooltipLabel={tooltipLabel}
            noDisabledOpacity
          />

          <FormControlDefault
            type="text"
            label={t("form.representingCompany")}
            value={companyName}
            placeholder={t("placeholder.representingCompany")}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            // onBlur={() => setCompanyNameTouched(true)}
            // isTouched={companyNameTouched}
            // isError={!isCompanyNameValid}
            w="100%"
            // errorMessage={t("formError.companyNameRequired")}
            isDisabled={!userInfo}
          />
        </>
      ) : (
        <>
          {/* <Flex m="0 0 1rem" maxW="80%" w="100%">
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              You are logged in as company{" "}
              <Text as="span" color={primary}>
                {userInfo.companyName}
              </Text>{" "}
              . The form will be sent authorizes as this account.
            </Text>
          </Flex> */}
          <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
            <FormControlDefault
              type="text"
              isRequired
              label={t("form.companyName")}
              value={companyName}
              placeholder={t("placeholder.companyName")}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              w={["100%", "100%", "50%"]}
              isDisabled={
                (userInfo && userInfo.role === "employee") ||
                (userInfo && userInfo.companyName && userInfo.companyName)
                  ? true
                  : false
              }
              rightElement={userInfo ? iconElement : null}
              isTooltip={userInfo && userInfo.role !== "employee"}
              tooltipLabel={tooltipLabel}
              noDisabledOpacity
            />

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.companyCode")}
              value={companyCode}
              placeholder={t("placeholder.companyCode")}
              onChange={(e) => {
                setCompanyCode(e.target.value);
              }}
              w={["100%", "100%", "50%"]}
              isDisabled={
                (userInfo && userInfo.role === "employee") ||
                (userInfo && userInfo.companyCode && userInfo.companyCode)
                  ? true
                  : false
              }
              rightElement={userInfo ? iconElement : null}
              isTooltip={userInfo && userInfo.role !== "employee"}
              tooltipLabel={tooltipLabel}
              noDisabledOpacity
              mb="1rem"
            />
          </Flex>

          <FormControlDefault
            type="email"
            label={t("form.email")}
            isRequired
            value={email}
            placeholder={t("placeholder.email")}
            onChange={(e) => {
              setEmail(e.target.value);
              // setEmailTouched(true);
            }}
            // onBlur={() => setEmailTouched(true)}
            // isTouched={emailTouched}
            // isError={!isEmailValid}
            w="100%"
            // errorMessage={t("formError.emailInvalid")}
            isDisabled={
              userInfo && userInfo.email && userInfo.email ? true : false
            }
            rightElement={userInfo ? iconElement : null}
            isTooltip={userInfo && userInfo.role !== "employee"}
            tooltipLabel={tooltipLabel}
            noDisabledOpacity
            mb="1rem"
          />
          <Flex gap={4} w="100%" direction={["column", "column", "row"]}>
            <FormControlDefault
              type="text"
              label={t("form.repFirstName")}
              value={firstName}
              placeholder={t("placeholder.repFirstName")}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              w={["100%", "100%", "50%"]}
              isDisabled={
                userInfo && userInfo.role === "employee" ? true : false
              }
            />

            <FormControlDefault
              type="text"
              label={t("form.repLastName")}
              value={lastName}
              placeholder={t("placeholder.repLastName")}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              w={["100%", "100%", "50%"]}
              isDisabled={
                userInfo && userInfo.role === "employee" ? true : false
              }
            />
          </Flex>
        </>
      )}

      {!manualAddressVisible && (
        <Flex direction="column" w="100%" p="0rem">
          {isApiLoaded && (
            <FormControlAddressFull
              isRequired
              label={t("form.workAddress")}
              placeholder={t("placeholder.googleAddress")}
              onAddressSelect={handleAddressSelect}
              currentValue={displayAddress}
              w="100%"
              isApiLoaded={isApiLoaded}
            />
          )}
          <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
            <Flex position="relative" m="0rem 0 0" justify="flex-end" w="100%">
              <Button
                bg="rgba(255,255,255,0.3)"
                onClick={toggleManualAddressVisible}
                color={primary}
                variant="ghost"
                fontSize="xs"
                size="xs"
                fontWeight="500"
                p="0.25rem 0.75rem"
                borderRadius="10rem"
                opacity="0.8"
                transition="all 0.3s"
                _hover={{
                  // bg: backgroundLight,
                  opacity: "1",
                }}
              >
                {t("onboarding.enterAddressManual")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      {manualAddressVisible && (
        <>
          <Flex direction="column" align="center" justify="center" p="0rem">
            <HStack gap={4} w="100%">
              <FormControlDefault
                isRequired
                type="text"
                label={t("form.street")}
                value={address.street}
                placeholder={t("placeholder.street")}
                onChange={(e) => {
                  setAddress({ ...address, street: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />

              <FormControlDefault
                isRequired
                type="text"
                label={t("form.zip")}
                value={address.zip}
                placeholder={t("placeholder.zip")}
                onChange={(e) => {
                  setAddress({ ...address, zip: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <FormControlDefault
                isRequired
                type="text"
                label={t("form.city")}
                value={address.city}
                placeholder={t("placeholder.city")}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.cityRequired")}
              />

              <FormControlCountry
                isRequired
                label={t("form.country")}
                value={address.country}
                onChange={(selectedOption) => {
                  setAddress({ ...address, country: selectedOption.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.countryRequired")}
              />
            </HStack>
            <Flex align="center" justify="center" gap="1rem">
              <Flex position="relative" m="0rem 0 0" justify="center">
                <Button
                  bg="rgba(255,255,255,0.3)"
                  onClick={toggleManualAddressVisible}
                  color={primary}
                  variant="link"
                  fontSize="xs"
                  fontWeight="500"
                  p="0.5rem 0.75rem"
                  borderRadius="10rem"
                  _hover={{
                    bg: "rgba(255,255,255,1)",
                  }}
                >
                  {t("onboarding.enterAddressGoogle")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        {isLoggedIn ? (
          <>
            {isEmployee ? (
              <Flex align="center" justify="flex-end">
                <Text
                  fontSize="xs"
                  fontWeight="500"
                  mr="1rem"
                  color={buttonRedText}
                >
                  {t("implantForm.isEmployee")}
                </Text>
                <Button
                  size="lg"
                  fontSize="md"
                  bg={primary}
                  color={white}
                  fontWeight="500"
                  borderRadius="10rem"
                  minW="8rem"
                  onClick={() => {
                    navigate("/admin/reports/implant-failure-reports");
                  }}
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                >
                  {t("landingMegagen.menuAdmin")}
                </Button>
              </Flex>
            ) : (
              <Button
                size="lg"
                fontSize="md"
                bg={primary}
                color={white}
                fontWeight="500"
                borderRadius="10rem"
                minW="8rem"
                onClick={() => {
                  setActiveStep(1);
                }}
                _hover={{
                  bg: shade(0.3, primary),
                }}
                isDisabled={!isValid}
              >
                {t("common.continue")}
              </Button>
            )}
          </>
        ) : (
          <Flex align="center" justify="flex-end">
            <Text
              fontSize="sm"
              color={buttonRedText}
              fontWeight="500"
              mr="1.5rem"
            >
              {t("implantForm.registerNotLoggedIn")}
            </Text>
            <Button
              size="lg"
              fontSize="md"
              bg={primary}
              color={white}
              fontWeight="500"
              borderRadius="10rem"
              minW="8rem"
              onClick={() => {
                navigate("/register");
              }}
              _hover={{
                bg: shade(0.3, primary),
              }}
            >
              {t("implantForm.register")}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Step0;
