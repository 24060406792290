import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Spinner,
  Flex,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { getUserEmployees } from "../../../../../actions/userActions";
import StatusButton from "../../../../../components/buttons/StatusButton";
import noServices from "../../../../../assets/images/empty-box.png";
import { USER_GET_CLIENT_EMPLOYEES_RESET } from "../../../../../constants/userConstants";
import { motion } from "framer-motion";
import { shade } from "polished";
import Header from "../../../../landing/layout/Header";
import AccountTabs from "../../partials/AccountTabs";
import { TbSearch } from "react-icons/tb";
import Faq from "../../../../general/partials/Faq";
import Footer from "../../../../landing/layout/Footer";
import { useMediaQuery } from "react-responsive";

const UserEmployees = () => {
  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // REF
  const lastReportRef = useRef(null);

  // STATE
  // const [employees, setEmplpyees] = useState([]);

  const [tabIndex, setTabIndex] = useState(1); // State to track the active tab index

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [loadingCourses, setLoadingCourses] = React.useState(false);
  const [page, setPage] = useState(1);

  const userClientGetEmployees = useSelector(
    (state) => state.userClientGetEmployees
  );

  const {
    loading: loadingEmployees,
    error: errorEmployees,
    employees,
    pages,
    totalEmployees,
  } = userClientGetEmployees;

  // TABS

  // Adjusting tabs based on the client type
  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // HANDLERS

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(tabs[index].path);
  };

  const loadMore = () => {
    if (page < pages) {
      setPage((prev) => prev + 1);
    }
  };

  const scrollToLastReport = () => {
    if (lastReportRef.current) {
      lastReportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserEmployees(userInfo._id, page));
    }
  }, [userInfo, page]);

  useEffect(() => {
    if (page > 1) {
      // Ensure this effect runs only after initial load
      scrollToLastReport();
    }
  }, [page]); // Depend on the length of userReports to trigger scroll

  useEffect(() => {
    if (errorEmployees) {
      toast({
        title: "Error",
        description: errorEmployees,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [errorEmployees]);

  useEffect(() => {
    return () => {
      dispatch({ type: USER_GET_CLIENT_EMPLOYEES_RESET });
    };
  }, []);

  useEffect(() => {
    console.log("Component mounted or updated with employees: ", employees);
  }, [employees]);

  // LOGS
  console.log("employees", employees);
  console.log("pages", pages);
  console.log("totalEmployees", totalEmployees);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountEmployees")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountEmployees")}
        />
      </Helmet>

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        <AccountTabs tabs={tabs} />

        <Flex
          w="100%"
          justify="center"
          gap="2rem"
          p={["3rem 0 0rem", "3rem 0 0rem", "3rem 0 0"]}
          bg={backgroundLight}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p={["0 0rem 3rem", "0 0rem 3rem", "0 0rem 3rem"]}
            direction="column"
            m="0rem auto 0"
            minH={["initial", "initial", "80vh"]}
          >
            <Flex
              w="100%"
              align="center"
              justify="space-between"
              minH={["initial", "initial", "3rem"]}
            >
              <Flex
                align={["flex-start", "flex-start", "center"]}
                direction={["column", "column", "row"]}
                w={["100%", "100%", "initial"]}
                p={["0 1.5rem", "0 1.5rem", "0"]}
              >
                <Heading
                  fontSize="1.125rem"
                  fontWeight="600"
                  color={textPrimary}
                  mr={["initial", "initial", "3rem"]}
                  mb={["1rem", "1rem", "initial"]}
                >
                  {t("clientAccount.myEmployees")}{" "}
                  <Text as="span" fontWeight="500">
                    ({userClientGetEmployees.totalEmployees})
                  </Text>
                </Heading>
                <InputGroup w={["100%", "100%", "500px"]}>
                  <Input
                    type="text"
                    placeholder={t("placeholder.headerSearch")}
                    size="lg"
                    bg={white}
                    borderRadius="10rem"
                    color={textPrimary}
                    fontSize="1rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    _focusVisible={{
                      zIndex: "1",
                      borderColor: white,
                      boxShadow: `0 0 0 1px rgba(255, 255, 255, 0.5)`,
                    }}
                    // isDisabled
                  />
                  <InputLeftElement h="100%" pl="1rem">
                    <IconButton
                      h="34px"
                      w="34px"
                      color={textSecondary}
                      icon={<TbSearch />}
                      borderRadius="full"
                      _hover={{ bgColor: "none" }}
                      _focus={{ bgColor: "none" }}
                      variant="ghost"
                    />
                  </InputLeftElement>
                </InputGroup>
              </Flex>

              <Flex
                align="center"
                gap="1.5rem"
                display={["none", "none", "flex"]}
              >
                <Button
                  bg={primary}
                  color={white}
                  size="lg"
                  fontSize="sm"
                  borderRadius="10rem"
                  onClick={() => {
                    navigate("/implant-fail-form");
                  }}
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                  isDisabled
                >
                  {t("clientAccount.newEmployee")}
                </Button>
              </Flex>
            </Flex>
            {!loadingEmployees ? (
              <>
                <Flex direction="column" w="100%">
                  <Stack
                    gap="1rem"
                    w="100%"
                    m="1rem 0"
                    bg={[white, white, "transparent"]}
                  >
                    {employees.map((employee, index) => (
                      <Flex
                        as={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        key={employee._id}
                      >
                        <Flex
                          bg={white}
                          w="100%"
                          p={["1.5rem 1rem", "1.5rem 1rem", "1.5rem 2rem"]}
                          borderRadius={["0rem", "0rem", "1rem"]}
                          borderBottom={
                            isMobile ? `1px solid ${newBorder}` : "0"
                          }
                          align="center"
                          justify="space-between"
                          ref={
                            employee._id === employees.length - 1
                              ? lastReportRef
                              : null
                          }
                          transition="all 0.2s"
                          // onClick={() => {
                          //   navigate(`/account/forms/${employee._id}`);
                          // }}
                          _hover={{
                            // cursor: "pointer",
                            shadow: "sm",
                            transform: "scale(1.02)",
                          }}
                        >
                          <Flex align="center">
                            <Flex align="center">
                              <Avatar
                                h={["3rem", "48px"]}
                                w={["3rem", "48px"]}
                                name={
                                  employee.firstName + " " + employee.lastName
                                }
                                mr={2}
                              />

                              <Stack gap={0} mr={["0rem", "0rem", "6rem"]}>
                                <Text
                                  fontSize="md"
                                  color={textPrimary}
                                  fontWeight="600"
                                  isTruncated
                                  maxWidth={["150px", "150px", "200px"]}
                                >
                                  {employee.lastName} {employee.firstName}
                                </Text>
                                <Text
                                  fontSize={["xs", "xs", "sm"]}
                                  color={textSecondary}
                                  fontWeight="500"
                                  isTruncated
                                  maxWidth={["150px", "150px", "200px"]}
                                >
                                  {employee.email &&
                                    employee.email.toLowerCase()}
                                </Text>
                              </Stack>

                              {/* <Stack gap={0} mr="6rem">
                                <Text
                                  fontSize="sm"
                                  color={textSecondary}
                                  fontWeight="500"
                                  isTruncated
                                  maxWidth="200px"
                                >
                                  {t("profession.profession")}
                                </Text>
                                <Text
                                  fontSize="sm"
                                  fontWeight="600"
                                  color={textPrimary}
                                >
                                  {employee.profession.name}
                                </Text>
                              </Stack> */}
                              <Text
                                fontSize="sm"
                                color={textSecondary}
                                fontWeight="500"
                                isTruncated
                                maxWidth="200px"
                                mr="6rem"
                                display={["none", "none", "block"]}
                              >
                                {employee.profession.name}
                              </Text>
                              <Text
                                fontSize="sm"
                                color={textSecondary}
                                fontWeight="500"
                                isTruncated
                                maxWidth="200px"
                                display={["none", "none", "block"]}
                              >
                                {employee.phone}
                              </Text>
                            </Flex>
                          </Flex>

                          <Flex align="center" gap="1rem">
                            <StatusButton
                              isUnclickable
                              currentStatus={employee.status}
                              options="active"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </Stack>
                  {page < pages && (
                    <Flex align="center" justify="center" w="100%" p="1rem 0">
                      <Button
                        onClick={loadMore}
                        isLoading={loadingEmployees}
                        size="lg"
                        m="0 auto"
                        borderRadius="0.875rem"
                        fontSize="sm"
                      >
                        {t("common.loadMore")}
                      </Button>
                    </Flex>
                  )}
                </Flex>

                {employees && employees.length === 0 && (
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    p="6rem 0"
                    bg={white}
                    borderRadius="1rem"
                    direction="column"
                    border={`0px solid ${newBorder}`}
                  >
                    <Flex
                      justify="center"
                      align="center"
                      direction="column"
                      p="3rem 0"
                    >
                      <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                      <Text
                        fontSize="sm"
                        color={textSecondary}
                        fontWeight="500"
                        m="1rem auto 1rem"
                        maxW="300px"
                        textAlign="center"
                      >
                        {t("clientAccount.noEmployeesText")}
                      </Text>
                      <Button
                        bg={primary}
                        color={white}
                        size="lg"
                        fontSize="sm"
                        borderRadius="10rem"
                        // onClick={() => {
                        //   navigate("/create-employee");
                        // }}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                      >
                        {t("clientAccount.noEmployeesButton")}
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </>
            ) : (
              <Flex
                w="100%"
                align="center"
                justify="center"
                bg={white}
                borderRadius="1rem"
                p="8rem 0"
                m="1rem 0 0"
              >
                <Spinner size="lg" color={primary} />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          bg={white}
          borderTop={`1px solid ${newBorder}`}
          w="100%"
          p="3rem 0"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Flex w="100%" maxW="1200px" m="0 auto">
            <Faq />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default UserEmployees;
