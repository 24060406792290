import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  buttonRedText,
  textPrimary,
  purple,
  textWhite,
  buttonGreenText,
  buttonGreen,
} from "../../utils/colors";
import { mobile } from "../../utils/breakpoints";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import RegisterPromo from "./partials/RegisterPromo";
import Header from "../landing/layout/Header";
import { shade } from "polished";
import { forgotPassword } from "../../actions/userActions";
import { USER_FORGOT_PASSWORD_RESET } from "../../constants/userConstants";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  // MOBILE
  const isMobile = useMediaQuery({ query: mobile });

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { error, loading, success } = userForgotPassword;

  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email));
  };

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (success) {
      console.log("success", success);
      setShowSuccess(true); // Set local state to true

      toast({
        title: t("toasts.forgotPasswordSuccess"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset the Redux state after a delay to prevent re-triggering
      const timer = setTimeout(() => {
        dispatch({ type: USER_FORGOT_PASSWORD_RESET });
      }, 500); // Short delay before reset

      return () => clearTimeout(timer); // Cleanup the timeout on component unmount or re-render
    }

    // Reset local success state when the component unmounts or the global state is reset
    return () => {
      setShowSuccess(false);
    };
  }, [success, dispatch, t, toast]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
      toast({
        title: t("toasts.forgotPasswordError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      dispatch({ type: USER_FORGOT_PASSWORD_RESET });
    }
  }, [error, t, toast]);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isEmailValid = isValidEmail(email);

  // LOGS

  console.log("showSuccess", showSuccess);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.forgotPassword")}</title>
        <meta
          name="description"
          content={t("pageDescription.forgotPassword")}
        />
      </Helmet>

      <Flex
        w="100%"
        h="100%"
        minH="100vh"
        align="stretch"
        position="relative"
        justify="flex-start"
        bg={white}
        p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
      >
        <Header bg="transparent" isRegister />

        <Flex display={["none", "none", "flex"]}>
          <RegisterPromo />
        </Flex>

        <Flex
          w={["100%", "100%", "70%"]}
          h="100%"
          align="flex-start"
          justify="center"
          ml={["0", "0", "30%"]}
          direction="column"
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          p={["80px 0 0", "80px 0 0", "200px 0 0 100px"]}
          bg={[purple, purple, "initial"]}
          position={["fixed", "fixed", "relative"]}
          top={["0", "0", "initial"]}
          minH={["100vh", "100vh", "initial"]}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              maxWidth: isMobile ? "100%" : "500px",
              height: "100%",
              overflow: "auto",
            }}
          >
            {/* {!loadingRegister && ( */}

            <Flex
              direction="column"
              p={["0 0 0rem", "0 0 0rem", "0 0 6rem"]}
              h={["100%", "100%", "initial"]}
            >
              <Stack gap="0.5rem" p={["1.5rem 1.5rem", "0rem", "0rem"]}>
                <Heading
                  fontSize="1.875rem"
                  fontWeight="700"
                  color={[white, white, textPrimary]}
                >
                  {!showSuccess
                    ? t("landingMegagen.forgotPasswordTitle")
                    : t("landingMegagen.forgotPasswordTitleSuccess")}
                  {/* {t("landingMegagen.forgotPasswordTitle")} */}
                </Heading>

                <Text
                  fontSize="1rem"
                  color={[textWhite, textWhite, textSecondary]}
                  fontWeight="500"
                >
                  {!showSuccess
                    ? t("landingMegagen.forgotPasswordText")
                    : t("landingMegagen.forgotPasswordTextSuccess")}
                  {/* {t("landingMegagen.forgotPasswordText")} */}
                  {showSuccess && (
                    <Text as="span" color={primary}>
                      {" "}
                      {email}
                    </Text>
                  )}
                </Text>
              </Stack>

              <Flex
                mt={[0, 0, 12]}
                w="100%"
                bg={[purple, purple, white]}
                h="100%"
                p={["1.5rem 1.5rem 6rem", "1.5rem 1.5rem 6rem", "0rem"]}
                direction="column"
              >
                <FormControl isRequired m="0 0 1rem">
                  <FormLabel
                    fontSize={isMobile ? "sm" : "0.875rem"}
                    htmlFor="email"
                    color={isMobile ? white : textPrimary}
                  >
                    {t("form.email")}
                  </FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    bg={white}
                    onChange={
                      (e) => setEmail(e.target.value)
                      // setEmailValid(validateEmail(e.target.value))
                    }
                    required
                    size="lg"
                    h="3.5rem"
                    fontSize="0.938rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="1rem"
                    placeholder={t("placeholder.email")}
                    isDisabled={showSuccess}
                  />
                </FormControl>

                {!showSuccess && (
                  <Stack m="2rem 0">
                    <Button
                      type="submit"
                      size="lg"
                      h="3.5rem"
                      borderRadius="10rem"
                      fontSize="1rem"
                      bg={showSuccess ? buttonGreen : primary}
                      color={showSuccess ? buttonGreenText : white}
                      _hover={{
                        bg: showSuccess
                          ? shade(0.3, buttonGreen)
                          : shade(0.3, primary),
                      }}
                      isDisabled={!isEmailValid}
                      onClick={showSuccess ? () => navigate("/login") : null}
                      isLoading={loading}
                    >
                      {showSuccess
                        ? "Ok"
                        : t("landingMegagen.forgotPasswordButton")}
                    </Button>
                  </Stack>
                )}

                {/* {showSuccess && (
                  <Text>
                    {t("landingMegagen.forgotPasswordSuccessMessage")}
                  </Text> // Display success message
                )} */}
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  );
};

export default ForgotPassword;
