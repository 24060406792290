import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { login } from "../../actions/userActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { PasswordField } from "../../components/partials/PasswordField";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  buttonRedText,
  textPrimary,
  purple,
  textWhite,
} from "../../utils/colors";
import { mobile } from "../../utils/breakpoints";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import RegisterPromo from "./partials/RegisterPromo";
import Header from "../landing/layout/Header";
import { shade } from "polished";

const Login = () => {
  const { i18n, t } = useTranslation();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isMobile = useMediaQuery({ query: mobile });

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect")
    ? searchParams.get("redirect")
    : "/admin/academy/speakers";

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "employee") {
        navigate("/admin/dashboard");
      } else {
        navigate("/account");
      }
    }
  }, [userInfo, redirect, navigate]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (error) {
      console.log("error", error);

      toast({
        title: t("toasts.loginError"),
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error]);

  const isEmailValid = email.length > 0;
  const isPasswordValid = password.length > 0;

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.login")}</title>
        <meta name="description" content={t("pageDescription.login")} />
      </Helmet>

      <Flex
        w="100%"
        h="100%"
        minH="100vh"
        align="stretch"
        position="relative"
        justify="flex-start"
        bg={white}
        p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
      >
        <Header bg="transparent" isRegister />

        <Flex display={["none", "none", "flex"]}>
          <RegisterPromo />
        </Flex>

        <Flex
          w={["100%", "100%", "70%"]}
          h="100%"
          align="flex-start"
          justify="center"
          ml={["0", "0", "30%"]}
          direction="column"
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          p={["80px 0 0", "80px 0 0", "200px 0 0 100px"]}
          bg={[purple, purple, "initial"]}
          position={["fixed", "fixed", "relative"]}
          top={["0", "0", "initial"]}
          minH={["100vh", "100vh", "initial"]}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              maxWidth: isMobile ? "100%" : "500px",
              height: "100%",
              overflow: "auto",
            }}
          >
            {/* {!loadingRegister && ( */}

            <Flex
              direction="column"
              p={["0 0 0rem", "0 0 0rem", "0 0 6rem"]}
              h={["100%", "100%", "initial"]}
            >
              <Stack gap="0.5rem" p={["1.5rem 1.5rem", "0rem", "0rem"]}>
                <Heading
                  fontSize="1.875rem"
                  fontWeight="700"
                  color={[white, white, textPrimary]}
                >
                  {isMobile
                    ? t("landingMegagen.loginTitle")
                    : t("landingMegagen.loginTitle")}
                </Heading>

                <Text
                  fontSize="1rem"
                  color={[textWhite, textWhite, textSecondary]}
                  fontWeight="500"
                >
                  {t("landingMegagen.loginText")}
                </Text>
              </Stack>

              <Flex
                mt={[0, 0, 12]}
                w="100%"
                bg={[purple, purple, white]}
                h="100%"
                p={["1.5rem 1.5rem 6rem", "1.5rem 1.5rem 6rem", "0rem"]}
                direction="column"
              >
                <FormControl isRequired m="0 0 1rem">
                  <FormLabel
                    fontSize={isMobile ? "sm" : "0.875rem"}
                    htmlFor="email"
                    color={isMobile ? white : textPrimary}
                  >
                    {t("form.email")}
                  </FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    bg={white}
                    onChange={handleEmailChange}
                    required
                    size="lg"
                    h="3.5rem"
                    fontSize="0.938rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="1rem"
                    placeholder={t("placeholder.email")}
                  />
                </FormControl>
                <PasswordField
                  bg={white}
                  value={password}
                  onChange={handlePasswordChange}
                  size="lg"
                  h="3.5rem"
                  fontSize="0.938rem"
                  fontWeight="500"
                  color={isMobile ? white : textPrimary}
                  border={`1px solid ${newBorder}`}
                  borderRadius="0.75rem"
                  placeholder={t("placeholder.password")}
                />

                <HStack align="center" w="100%" justify="flex-end">
                  {/* <Checkbox color={textSecondary} defaultChecked size="sm">
                  {t("common.remember")}
                </Checkbox> */}
                  <Button
                    variant="text"
                    fontSize="0.875rem"
                    fontWeight="500"
                    color={textSecondary}
                    onClick={() => navigate("/forgot-password")}
                    _hover={{ color: textPrimary }}
                  >
                    {t("common.forgotPassword")}
                  </Button>
                </HStack>

                <Stack m="2rem 0">
                  <Button
                    type="submit"
                    size="lg"
                    h="3.5rem"
                    borderRadius="10rem"
                    fontSize="1rem"
                    bg={[white, white, primary]}
                    color={[textPrimary, textPrimary, white]}
                    _hover={{ bg: shade(0.3, primary) }}
                    isDisabled={!isEmailValid || !isPasswordValid}
                  >
                    {t("common.signin")}
                  </Button>
                  {error && (
                    <Box
                      textAlign="center"
                      bg={white}
                      p="1rem 0rem 0"
                      borderRadius="1rem"
                    >
                      <Text
                        fontSize="0.875rem"
                        fontWeight="500"
                        color={buttonRedText}
                      >
                        {t("common.invalidCredentials")}
                      </Text>
                    </Box>
                  )}
                  {/* <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                      or continue with
                    </Text>
                    <Divider />
                  </HStack>
                  <AuthButtonGroup /> */}
                </Stack>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  );
};

export default Login;
