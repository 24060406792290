import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createProfession } from "../../../../actions/professionActions";

import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Select,
  HStack,
  Switch,
  Box,
  Heading,
  Checkbox,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import ProfessionGroupSelect from "../../../../components/select/ProfessionGroupSelect";
import dayjs from "dayjs";
// import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlProfessionGroup from "../../../../components/input/FormControlProfessionGroup";
import FormControlDuration from "../../../../components/input/FormControlDuration";
import { PROFESSION_CREATE_RESET } from "../../../../constants/professionConstants";
import { shade } from "polished";

const CreateProfession = ({ close }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  const [name, setName] = useState("");
  const [professionGroup, setProfessionGroup] = useState();
  const [status, setStatus] = useState("inactive");

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: PROFESSION_CREATE_RESET });
  };

  // CUSTOM COURSES

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProfession(name, professionGroup, status));
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [professionGroupTouched, setProfessionGroupTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";
  const isProfessionGroupError = professionGroup === "";

  const isNameValid = name !== "";

  const isCreateDisabled = !isNameValid;

  // LOGS

  console.log("name", name);
  console.log("professionGroup", professionGroup);
  console.log("status", status);

  return (
    <form onSubmit={submitHandler}>
      <FormControlDefault
        type="text"
        isRequired
        label={t("form.professionName")}
        value={name}
        placeholder={t("placeholder.professionName")}
        onChange={(e) => {
          setName(e.target.value);
          setNameTouched(true);
        }}
        isTouched={nameTouched}
        isError={isNameError}
        w="100%"
        errorMessage={t("formError.professionNameRequired")}
      />
      <FormControlProfessionGroup
        label={t("form.professionGroup")}
        value={professionGroup}
        onChange={(selectedOption) => {
          setProfessionGroup(selectedOption.value);
          setProfessionGroupTouched(true);
        }}
        isTouched={professionGroupTouched}
        isError={isProfessionGroupError}
        errorMessage={t("formError.professionGroupRequired")}
        w="100%"
      />

      <Flex
        mt={4}
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="2rem 0 0"
      >
        <Flex justify="flex-end">
          <Tooltip
            bg={white}
            borderRadius="1rem"
            p="1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                {isNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.nameRequired")}
                  </Text>
                )}
              </Flex>
            }
            isDisabled={!isCreateDisabled}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isCreateDisabled}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
            >
              {t("profession.createProfession")}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </form>
  );
};

export default CreateProfession;
