import axios from "axios";
import {
  REPORT_IMPLANT_FAILURE_LIST_REQUEST,
  REPORT_IMPLANT_FAILURE_LIST_SUCCESS,
  REPORT_IMPLANT_FAILURE_LIST_FAIL,
  REPORT_IMPLANT_FAILURE_DETAILS_REQUEST,
  REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS,
  REPORT_IMPLANT_FAILURE_DETAILS_FAIL,
  REPORT_IMPLANT_FAILURE_CREATE_REQUEST,
  REPORT_IMPLANT_FAILURE_CREATE_SUCCESS,
  REPORT_IMPLANT_FAILURE_CREATE_FAIL,
  REPORT_IMPLANT_FAILURE_DELETE_REQUEST,
  REPORT_IMPLANT_FAILURE_DELETE_SUCCESS,
  REPORT_IMPLANT_FAILURE_DELETE_FAIL,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL,
} from "../constants/reportConstants";

import { logout } from "./userActions";

export const listImplantFailureReports =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = ""
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: REPORT_IMPLANT_FAILURE_LIST_REQUEST });

      // Constructing the query string
      let query = `/api/reports/implant-failure?keyword=${encodeURIComponent(
        keyword
      )}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      //  if (status) query += `&status=${encodeURIComponent(status)}`;

      console.log("Query:", query);

      const { data } = await axios.get(query);

      // const { data } = await axios.get(
      //   `/api/reports/implant-failure?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      // );

      dispatch({
        type: REPORT_IMPLANT_FAILURE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getImplantFailureReportById =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: REPORT_IMPLANT_FAILURE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/reports/implant-failure/${id}`,
        config
      );

      dispatch({
        type: REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createImplantFailureReport =
  (
    userId,
    clientType,
    email,
    firstName,
    lastName,
    companyName,
    companyCode,
    fixture,
    core,
    diameter,
    length,
    refCode,
    lotNumber,
    tooth,
    surgeonFirstName,
    surgeonLastName,
    surgeryDate,
    failureDate,
    beforeXrayImage,
    afterXrayImage,
    newFixture,
    newCore,
    newDiameter,
    newLength,
    newRefCode,
    reason,
    reasonText,
    author,
    authorType,
    address
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_CREATE_REQUEST,
      });

      const { data } = await axios.post(`/api/reports/implant-failure`, {
        userId,
        clientType,
        email,
        firstName,
        lastName,
        companyName,
        companyCode,
        fixture,
        core,
        diameter,
        length,
        refCode,
        lotNumber,
        tooth,
        surgeonFirstName,
        surgeonLastName,
        surgeryDate,
        failureDate,
        beforeXrayImage,
        afterXrayImage,
        newFixture,
        newCore,
        newDiameter,
        newLength,
        newRefCode,
        reason,
        reasonText,
        author,
        authorType,
        address,
      });

      console.log("data:", data);

      dispatch({
        type: REPORT_IMPLANT_FAILURE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      dispatch({
        type: REPORT_IMPLANT_FAILURE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteImplantFailureReport =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`/api/reports/implant-failure/${id}`, config);

      dispatch({
        type: REPORT_IMPLANT_FAILURE_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateImplantFailureReportStatus =
  (id, userId, cancelReason, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/reports/implant-failure/${id}/status`,
        { userId, cancelReason, status }, // Pass cancelReason and status in the request body
        config
      );

      dispatch({
        type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };
