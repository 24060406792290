import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ColumnContainer from "../../../../../components/kanban/ColumnContainer";
import { Box, Flex, Button, Stack, Heading, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  listCourseTasks,
  updateCourseTasksPositions,
  removeCourseTask,
} from "../../../../../actions/courseActions";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "../../../../../components/kanban/TaskCard";
import {
  backgroundLight,
  buttonYellow,
  buttonYellowText,
  buttonBlue,
  buttonBlueText,
  buttonGreen,
  buttonGreenText,
  white,
  primary,
  textSecondary,
  textPrimary,
  newBorder,
  greenVivid,
  buttonAltGreen,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import CreateCourseTask from "./sidepanels/CreateCourseTask";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  COURSE_TASK_ADD_RESET,
  COURSE_TASK_REMOVE_RESET,
  COURSE_TASK_SUBTASK_ADD_RESET,
} from "../../../../../constants/courseConstants";
import RemoveAreaModal from "../../../../../components/modals/RemoveAreaModal";

const TabCourseKanban = ({ title, courseId, maxAttendees }) => {
  // ID
  const id = useParams();

  // TOAST

  const toastComponent = ToastComponent();

  // TRANSLATION
  const { t } = useTranslation();

  const [removeAreaModalOpen, setRemoveAreaModalOpen] = useState(false);

  // STATE
  const defaultCols = [
    {
      id: "todo",
      title: t("course.tasksTodo"),
      bg: buttonBlue,
      color: buttonBlueText,
    },
    {
      id: "pending",
      title: t("course.tasksPending"),
      bg: buttonYellow,
      color: buttonYellowText,
    },
    {
      id: "done",
      title: t("course.tasksDone"),
      bg: buttonGreen,
      color: buttonGreenText,
    },
  ];
  const [addTaskSidePanelOpen, setAddTaskSidePanelOpen] = useState(false);
  const [columns, setColumns] = useState(defaultCols);
  const [columnId, setColumnId] = useState(null);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const [activeColumn, setActiveColumn] = useState(null);
  const [activeTask, setActiveTask] = useState(null);
  const [tasks, setTasks] = useState([]);

  const [hasChanges, setHasChanges] = useState(false);
  const [savingStatus, setSavingStatus] = useState("idle"); // 'idle', 'saving', 'saved'

  // REDUX

  const dispatch = useDispatch();
  const courseTasksList = useSelector((state) => state.courseTasksList);
  const { loading, error, tasks: currentTasks } = courseTasksList;

  const courseTasksPositionsUpdate = useSelector(
    (state) => state.courseTasksPositionsUpdate
  );
  const { success: successUpdate } = courseTasksPositionsUpdate;

  const courseTaskAdd = useSelector((state) => state.courseTaskAdd);
  const { success: successAdd } = courseTaskAdd;

  const courseTaskRemove = useSelector((state) => state.courseTaskRemove);
  const { success: successRemove } = courseTaskRemove;

  const courseTaskSubtaskAdd = useSelector(
    (state) => state.courseTaskSubtaskAdd
  );
  const { success: successSubtaskAdd } = courseTaskSubtaskAdd;

  // HANDLERS

  const saveTaskPositions = async () => {
    setSavingStatus("saving");
    console.log("tasks before", tasks);
    // Simulate an asynchronous save operation
    dispatch(updateCourseTasksPositions(courseId, tasks))
      .then(() => {
        setSavingStatus("saved");
        setHasChanges(false); // Reset changes tracking after saving
        setTimeout(() => setSavingStatus("idle"), 2000); // Reset saving status after a delay
      })
      .catch((error) => {
        console.error("Save error:", error);
        setSavingStatus("idle");
      });
    console.log("tasks after", tasks);
  };

  const openAddTaskSidePanel = () => {
    setAddTaskSidePanelOpen(true);
    document.body.classList.add("no-scroll");
    setColumnId("todo");
  };

  const openAddTaskSidePanelWithColumnId = (columnId) => {
    setAddTaskSidePanelOpen(true);
    document.body.classList.add("no-scroll");
    setColumnId(columnId);
  };

  const closeAddTaskSidePanel = () => {
    setAddTaskSidePanelOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const removeTask = (id) => {
    dispatch(removeCourseTask(courseId, id));
  };

  const handleSubtaskAdded = (taskId, newSubtask) => {
    setTasks((prevTasks) => {
      return prevTasks.map((task) => {
        if (task._id === taskId) {
          return {
            ...task,
            subtasks: [...task.subtasks, newSubtask], // Add the new subtask
          };
        }
        return task;
      });
    });
  };

  // SENSORS

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  // USE EFFECT
  useEffect(() => {
    dispatch(listCourseTasks(courseId));
  }, [dispatch, courseId]);

  useEffect(() => {
    if (currentTasks && currentTasks.length > 0) {
      setTasks(currentTasks);
    } else {
      setTasks([]);
    }
  }, [currentTasks]);

  useEffect(() => {
    if (successAdd) {
      dispatch(listCourseTasks(courseId));
      toastComponent.showToast(t("toasts.addCourseTask"), "success", 3000);
      dispatch({ type: COURSE_TASK_ADD_RESET });
    } else if (successRemove) {
      toastComponent.showToast(t("toasts.removeCourseTask"), "success", 3000);
      dispatch(listCourseTasks(courseId));
      dispatch({ type: COURSE_TASK_REMOVE_RESET });
    } else if (successSubtaskAdd) {
      toastComponent.showToast(t("toasts.addSubtask"), "success", 3000);
      // dispatch(listCourseTasks(courseId));
      dispatch({ type: COURSE_TASK_SUBTASK_ADD_RESET });
      // dispatch(listCourseTasks(courseId));
    }
  }, [successAdd, successRemove, successSubtaskAdd]);

  // LOGS

  // console.log("tasks", tasks);

  return (
    <>
      <SidePanel
        open={addTaskSidePanelOpen}
        close={closeAddTaskSidePanel}
        title={t("course.createTask")}
        subtitle={title}
      >
        <CreateCourseTask
          courseId={courseId}
          close={closeAddTaskSidePanel}
          fromColumnId={columnId}
        />
      </SidePanel>

      <Box p="0rem 0rem 0">
        <Flex align="center" justify="space-between" p="2rem 0 0rem">
          <Flex align="center">
            <Stack gap={1}>
              <Heading fontSize="xl" fontWeight="600">
                {t("course.tasks")}
              </Heading>
              <Text color={textSecondary} fontSize="sm">
                {title}
              </Text>
            </Stack>
          </Flex>

          <Flex align="center" justify="flex-end" p="0rem 0 0rem">
            {/* <Flex align="center" mr={8}>
              <Stack>
                <Text fontSize="sm" color={textSecondary}>
                  asfsfa
                </Text>
                <Flex align="center" bg={backgroundLight} h="4px" w="300px">
                  <Flex align="center" bg={primary} w="100%" h="100%" />
                </Flex>
              </Stack>
            </Flex> */}

            {/* <Flex align="center" mr={4}>
              <Text fontSize="sm" color={textSecondary}>
                Planuojamos islaidos:
                <Text as="span" fontWeight="500" color={textPrimary} ml={1}>
                  3000 €
                </Text>
              </Text>
            </Flex>

            <Flex align="center" mr={4}>
              <Text fontSize="sm" color={textSecondary}>
                Patirtos islaidos:
                <Text as="span" fontWeight="500" color={textPrimary} ml={1}>
                  3000 €
                </Text>
              </Text>
            </Flex> */}

            {/* <Flex align="center">
              <Text fontSize="sm" color={textSecondary}>
                Planuojamos islaidos:
                <Text as="span">3000</Text>
              </Text>
            </Flex> */}

            <Button
              bg={primary}
              color={white}
              size="lg"
              borderRadius="10rem"
              fontSize="1rem"
              onClick={openAddTaskSidePanel}
            >
              {t("course.createTask")}
            </Button>
          </Flex>
        </Flex>

        <Flex
          p="1.5rem 0rem"
          border={`0px dashed ${newBorder}`}
          borderRadius="1rem"
          direction="column"
        >
          <Flex
            m="auto"
            minH="screen"
            w="100%"
            bg={backgroundLight}
            alignItems="center"
            overflowX="auto"
            overflowY="hidden"
            p="1rem 1rem"
            borderRadius="1rem"
          >
            <DndContext
              sensors={sensors}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
            >
              <Flex direction="column" w="100%" gap="4" justify="center">
                <>
                  <Flex m="auto" gap="4" justify="center" w="100%">
                    <SortableContext items={columnsId}>
                      {columns.map((col) => (
                        <ColumnContainer
                          key={col.id}
                          column={col}
                          deleteColumn={deleteColumn}
                          updateColumn={updateColumn}
                          createTask={openAddTaskSidePanelWithColumnId}
                          deleteTask={removeTask}
                          updateTask={updateTask}
                          tasks={tasks.filter(
                            (task) => task.columnId === col.id
                          )}
                          courseId={courseId}
                          onSubtaskAdded={handleSubtaskAdded}
                          maxAttendees={maxAttendees}
                        />
                      ))}
                    </SortableContext>
                  </Flex>

                  {createPortal(
                    <DragOverlay>
                      {activeColumn && (
                        <ColumnContainer
                          column={activeColumn}
                          deleteColumn={deleteColumn}
                          updateColumn={updateColumn}
                          createTask={openAddTaskSidePanelWithColumnId}
                          deleteTask={deleteTask}
                          updateTask={updateTask}
                          tasks={tasks.filter(
                            (task) => task.columnId === activeColumn.id
                          )}
                          courseId={courseId}
                          onSubtaskAdded={handleSubtaskAdded}
                          maxAttendees={maxAttendees}
                        />
                      )}
                      {activeTask && (
                        <TaskCard
                          key={activeTask.id}
                          task={activeTask}
                          deleteTask={deleteTask}
                          updateTask={updateTask}
                          courseId={courseId}
                          onSubtaskAdded={handleSubtaskAdded}
                          maxAttendees={maxAttendees}
                        />
                      )}
                    </DragOverlay>,
                    document.body
                  )}
                  {tasks.length > 0 && (
                    <RemoveAreaModal isOpen={removeAreaModalOpen} />
                  )}
                </>
              </Flex>
            </DndContext>
          </Flex>
        </Flex>
      </Box>
    </>
  );

  function deleteTask(id) {
    const newTasks = tasks.filter((task) => task.id !== id);
    setTasks(newTasks);
  }

  function updateTask(id, title) {
    const newTasks = tasks.map((task) => {
      if (task.id !== id) return task;
      return { ...task, title };
    });

    setTasks(newTasks);
  }

  function deleteColumn(id) {
    const filteredColumns = columns.filter((col) => col.id !== id);
    setColumns(filteredColumns);

    const newTasks = tasks.filter((t) => t.columnId !== id);
    setTasks(newTasks);
  }

  function updateColumn(id, title) {
    const newColumns = columns.map((col) => {
      if (col.id !== id) return col;
      return { ...col, title };
    });

    setColumns(newColumns);
  }

  function onDragStart(event) {
    setRemoveAreaModalOpen(true);
    document.body.classList.add("no-scroll");

    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragEnd(event) {
    setActiveColumn(null);
    setActiveTask(null);
    setHasChanges(true);
    setRemoveAreaModalOpen(false);
    document.body.classList.remove("no-scroll");

    saveTaskPositions();

    const { active, over } = event;
    if (!over) return;

    if (over && over.id === "trash-can") {
      // Assuming `active.id` is the task's unique identifier
      removeTask(active.id);
      setRemoveAreaModalOpen(false); // Hide the remove area
    } else {
      const activeId = active.id;
      const overId = over.id;

      if (activeId === overId) return;

      const isActiveAColumn = active.data.current?.type === "Column";
      if (!isActiveAColumn) return;

      console.log("DRAG END");

      setColumns((columns) => {
        const activeColumnIndex = columns.findIndex(
          (col) => col.id === activeId
        );

        const overColumnIndex = columns.findIndex((col) => col.id === overId);

        return arrayMove(columns, activeColumnIndex, overColumnIndex);
      });
    }
    setRemoveAreaModalOpen(false); // Hide the remove area
  }

  function onDragOver(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t._id === activeId);
        const overIndex = tasks.findIndex((t) => t._id === overId);

        if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
          // Fix introduced after video recording
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t._id === activeId);

        tasks[activeIndex].columnId = overId;
        console.log("DROPPING TASK OVER COLUMN", { activeIndex });
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }
};

export default TabCourseKanban;
