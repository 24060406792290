import axios from "axios";
import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_CREATE_REQUEST,
  COURSE_CREATE_SUCCESS,
  COURSE_CREATE_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
  COURSE_ATTENDEES_LIST_REQUEST,
  COURSE_ATTENDEES_LIST_SUCCESS,
  COURSE_ATTENDEES_LIST_FAIL,
  COURSE_ATTENDEE_ADD_REQUEST,
  COURSE_ATTENDEE_ADD_SUCCESS,
  COURSE_ATTENDEE_ADD_FAIL,
  COURSE_ATTENDEE_REMOVE_REQUEST,
  COURSE_ATTENDEE_REMOVE_SUCCESS,
  COURSE_ATTENDEE_REMOVE_FAIL,
  COURSE_SPEAKERS_LIST_REQUEST,
  COURSE_SPEAKERS_LIST_SUCCESS,
  COURSE_SPEAKERS_LIST_FAIL,
  COURSE_SPEAKER_ADD_REQUEST,
  COURSE_SPEAKER_ADD_SUCCESS,
  COURSE_SPEAKER_ADD_FAIL,
  COURSE_SPEAKER_REMOVE_REQUEST,
  COURSE_SPEAKER_REMOVE_SUCCESS,
  COURSE_SPEAKER_REMOVE_FAIL,
  COURSE_STATUS_UPDATE_REQUEST,
  COURSE_STATUS_UPDATE_SUCCESS,
  COURSE_STATUS_UPDATE_FAIL,
  COURSE_ATTENDEE_STATUS_UPDATE_REQUEST,
  COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS,
  COURSE_ATTENDEE_STATUS_UPDATE_FAIL,
  COURSE_DESCRIPTION_UPDATE_REQUEST,
  COURSE_DESCRIPTION_UPDATE_SUCCESS,
  COURSE_DESCRIPTION_UPDATE_FAIL,
  COURSE_TASKS_LIST_REQUEST,
  COURSE_TASKS_LIST_SUCCESS,
  COURSE_TASKS_LIST_FAIL,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_REQUEST,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_SUCCESS,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_FAIL,
  COURSE_TASK_ADD_REQUEST,
  COURSE_TASK_ADD_SUCCESS,
  COURSE_TASK_ADD_FAIL,
  COURSE_TASK_UPDATE_REQUEST,
  COURSE_TASK_UPDATE_SUCCESS,
  COURSE_TASK_UPDATE_FAIL,
  COURSE_TASKS_POSITIONS_UPDATE_REQUEST,
  COURSE_TASKS_POSITIONS_UPDATE_SUCCESS,
  COURSE_TASKS_POSITIONS_UPDATE_FAIL,
  COURSE_UPDATE_PRICING_REQUEST,
  COURSE_UPDATE_PRICING_SUCCESS,
  COURSE_UPDATE_PRICING_FAIL,
  COURSE_UPDATE_IMAGE_REQUEST,
  COURSE_UPDATE_IMAGE_SUCCESS,
  COURSE_UPDATE_IMAGE_FAIL,
  COURSE_TASK_REMOVE_REQUEST,
  COURSE_TASK_REMOVE_SUCCESS,
  COURSE_TASK_REMOVE_FAIL,
  COURSE_TASK_SUBTASK_ADD_REQUEST,
  COURSE_TASK_SUBTASK_ADD_SUCCESS,
  COURSE_TASK_SUBTASK_ADD_FAIL,
  COURSE_TASK_SUBTASK_REMOVE_REQUEST,
  COURSE_TASK_SUBTASK_REMOVE_SUCCESS,
  COURSE_TASK_SUBTASK_REMOVE_FAIL,
  COURSE_ATTENDEE_PRICE_UPDATE_REQUEST,
  COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS,
  COURSE_ATTENDEE_PRICE_UPDATE_FAIL,
  COURSE_PUBLIC_LIST_REQUEST,
  COURSE_PUBLIC_LIST_SUCCESS,
  COURSE_PUBLIC_LIST_FAIL,
  COURSE_PUBLIC_DETAILS_REQUEST,
  COURSE_PUBLIC_DETAILS_SUCCESS,
  COURSE_PUBLIC_DETAILS_FAIL,
  COURSE_ADD_ATTENDEE_PUBLIC_REQUEST,
  COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS,
  COURSE_ADD_ATTENDEE_PUBLIC_FAIL,
  COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST,
  COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS,
  COURSE_VERIFY_ATTENDEE_EMAIL_FAIL,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS,
  COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL,
} from "../constants/courseConstants";
import { logout } from "./userActions";

export const listCourses =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    status = ""
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: COURSE_LIST_REQUEST });

      // Constructing the query string
      let query = `/api/courses?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;

      const { data } = await axios.get(query);

      dispatch({
        type: COURSE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/courses/${id}`);

    dispatch({
      type: COURSE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCourse = (course) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COURSE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/courses/${course._id}`,
      course,
      config
    );

    dispatch({
      type: COURSE_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: COURSE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: COURSE_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createCourse =
  (
    title,
    description,
    image,
    location,
    startDate,
    endDate,
    maximumAttendees,
    attendees,
    speakers,
    city,
    zip,
    street,
    country,
    state,
    prices,
    free
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const payload = {
        title,
        description,
        image,
        location,
        startDate,
        endDate,
        maximumAttendees,
        attendees,
        speakers,
        city,
        zip,
        street,
        country,
        state,
        prices,
        free,
      };

      console.log("Creating course with payload:", payload);

      const { data } = await axios.post(`/api/courses`, payload, config);

      dispatch({
        type: COURSE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: COURSE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteCourse = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COURSE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/courses/${id}`, config);

    dispatch({
      type: COURSE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCourseAttendees =
  (
    courseId,
    keyword = "",
    sortField = "", // New parameter for sort field
    sortOrder = "" // New parameter for sort order
    // isVerified
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ATTENDEES_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string with new sorting parameters
      let query = `/api/courses/${courseId}/attendees?keyword=${encodeURIComponent(
        keyword
      )}`;
      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      // if (typeof isVerified !== "undefined")
      // query += `&isVerified=${isVerified}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: COURSE_ATTENDEES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCourseAttendee =
  (courseId, attendeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ATTENDEE_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/courses/${courseId}/attendees`,
        { attendeeId },
        config
      );

      dispatch({
        type: COURSE_ATTENDEE_ADD_SUCCESS,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCourseAttendee =
  (courseId, attendeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ATTENDEE_REMOVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/courses/${courseId}/attendees/${attendeeId}`,
        config
      );

      dispatch({
        type: COURSE_ATTENDEE_REMOVE_SUCCESS,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCourseAttendeePublic =
  (courseId, attendeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ATTENDEE_REMOVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/courses/${courseId}/attendees/${attendeeId}/public`,
        config
      );

      dispatch({
        type: COURSE_ATTENDEE_REMOVE_SUCCESS,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listCourseSpeakers =
  (
    courseId,
    keyword = "",
    sortField = "", // New parameter for sort field
    sortOrder = "" // New parameter for sort order
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_SPEAKERS_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string with new sorting parameters
      let query = `/api/courses/${courseId}/speakers?keyword=${encodeURIComponent(
        keyword
      )}`;
      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: COURSE_SPEAKERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_SPEAKERS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCourseSpeaker =
  (courseId, speakerId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_SPEAKER_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/courses/${courseId}/speakers`,
        { speakerId },
        config
      );

      dispatch({
        type: COURSE_SPEAKER_ADD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: COURSE_SPEAKER_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCourseSpeaker =
  (courseId, speakerId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_SPEAKER_REMOVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/courses/${courseId}/speakers/${speakerId}`,
        config
      );

      dispatch({
        type: COURSE_SPEAKER_REMOVE_SUCCESS,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_SPEAKER_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseStatus =
  (courseId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: COURSE_STATUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseAttendeeStatus =
  (courseId, publicAttendeeId, attendeeId, newStatus) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_ATTENDEE_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("ACTION BODY", {
        publicAttendeeId,
        attendeeId,
        newStatus,
      });

      const { data } = await axios.put(
        `/api/courses/${courseId}/attendees/status`,
        { publicAttendeeId, attendeeId, status: newStatus }, // Send the new status in the request body
        config
      );

      console.log("ACTION DATA", data);

      dispatch({
        type: COURSE_ATTENDEE_STATUS_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseDescription =
  (courseId, newDescription) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_DESCRIPTION_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/description`,
        { description: newDescription }, // Send the new description in the request body
        config
      );

      dispatch({
        type: COURSE_DESCRIPTION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_DESCRIPTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listCourseTasks = (courseId) => async (dispatch, getState) => {
  try {
    dispatch({ type: COURSE_TASKS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/courses/${courseId}/tasks`, config);

    dispatch({
      type: COURSE_TASKS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_TASKS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCourseAttendeePaidStatus =
  (courseId, attendeeId, newStatus) => async (dispatch, getState) => {
    console.log("Dispatching updateCourseAttendeePaidStatus action...");

    try {
      dispatch({
        type: COURSE_ATTENDEE_PAID_STATUS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/attendees/paid`,
        { attendeeId, paid: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: COURSE_ATTENDEE_PAID_STATUS_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch(listCourseAttendees(courseId));
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_PAID_STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCourseTask =
  (courseId, title, columnId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_TASK_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const payload = {
        title,
        columnId,
      };

      const { data } = await axios.post(
        `/api/courses/${courseId}/tasks`,
        payload,
        config
      );

      dispatch({
        type: COURSE_TASK_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_TASK_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseTask =
  (courseId, taskId, title, status, position) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_TASK_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const taskData = {
        title,
        status,
        position,
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/tasks/${taskId}`,
        taskData,
        config
      );

      dispatch({
        type: COURSE_TASK_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_TASK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseTasksPositions =
  (courseId, tasks) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COURSE_TASKS_POSITIONS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Wrap the tasks array in an object with a "tasks" property
      const body = {
        tasks: tasks,
      };

      console.log("Updating tasks positions", body);

      const { data } = await axios.put(
        `/api/courses/${courseId}/tasks/updatePositions`,
        body, // Pass the adjusted body here
        config
      );

      dispatch({
        type: COURSE_TASKS_POSITIONS_UPDATE_SUCCESS,
        payload: data,
      });

      console.log("Tasks positions updated", data);
    } catch (error) {
      dispatch({
        type: COURSE_TASKS_POSITIONS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCoursePricing =
  (courseId, free, prices) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_UPDATE_PRICING_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/pricing`,
        { free, prices },
        config
      );

      dispatch({
        type: COURSE_UPDATE_PRICING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_UPDATE_PRICING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseImage =
  (courseId, image) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_UPDATE_IMAGE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/image`,
        { image },
        config
      );

      dispatch({
        type: COURSE_UPDATE_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_UPDATE_IMAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCourseTask =
  (courseId, taskId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_TASK_REMOVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`/api/courses/${courseId}/tasks/${taskId}`, config);

      dispatch({
        type: COURSE_TASK_REMOVE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: COURSE_TASK_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCourseTaskSubtask =
  (courseId, taskId, title, price, quantity) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_TASK_SUBTASK_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/courses/${courseId}/tasks/${taskId}/subtasks`,
        { title, price, quantity },
        config
      );

      dispatch({
        type: COURSE_TASK_SUBTASK_ADD_SUCCESS,
        payload: data,
      });

      console.log("Subtask added successfully", data);
    } catch (error) {
      dispatch({
        type: COURSE_TASK_SUBTASK_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCourseSubtask =
  (courseId, taskId, subtaskId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_TASK_SUBTASK_REMOVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/courses/${courseId}/tasks/${taskId}/subtasks/${subtaskId}`,
        config
      );

      dispatch({
        type: COURSE_TASK_SUBTASK_REMOVE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: COURSE_TASK_SUBTASK_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCourseAttendeePrice =
  (courseId, attendeeId, price) => async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ATTENDEE_PRICE_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/courses/${courseId}/attendees/${attendeeId}/price`,
        { price },
        config
      );

      dispatch({
        type: COURSE_ATTENDEE_PRICE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_ATTENDEE_PRICE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPublicCourses = () => async (dispatch) => {
  try {
    dispatch({ type: COURSE_PUBLIC_LIST_REQUEST });

    const { data } = await axios.get(`/api/courses/public`);

    dispatch({
      type: COURSE_PUBLIC_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_PUBLIC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPublicCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_PUBLIC_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/courses/public/${id}`);

    dispatch({
      type: COURSE_PUBLIC_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_PUBLIC_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addPublicCourseAttendee =
  (courseId, firstName, lastName, email, profession, companyName, price) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COURSE_ADD_ATTENDEE_PUBLIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      // Set headers conditionally based on whether the user is logged in or not
      const config = userInfo
        ? {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };

      console.log("body", {
        courseId,
        firstName,
        lastName,
        email,
        profession,
        companyName,
        price,
      });

      const { data } = await axios.post(
        `/api/courses/public/register-attendee`,
        {
          courseId,
          firstName,
          lastName,
          email,
          profession,
          companyName,
          price,
        },
        config
      );

      console.log("Attendee added successfully", data);

      dispatch({
        type: COURSE_ADD_ATTENDEE_PUBLIC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_ADD_ATTENDEE_PUBLIC_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const verifyAttendeeEmail =
  (verificationCode, courseId, email) => async (dispatch) => {
    try {
      dispatch({ type: COURSE_VERIFY_ATTENDEE_EMAIL_REQUEST });

      console.log("action body", { verificationCode, courseId, email });

      const { data } = await axios.post(
        `/api/courses/public/verify-attendee-email`,
        {
          verificationCode,
          courseId,
          email,
        }
      );

      console.log("Attendee email verified successfully", data);

      dispatch({
        type: COURSE_VERIFY_ATTENDEE_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_VERIFY_ATTENDEE_EMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const verifyAttendeeEmailToken =
  (token, courseId) => async (dispatch) => {
    try {
      dispatch({
        type: COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_REQUEST,
      });

      // console.log("received token", token);

      // Change to use query parameters
      const { data } = await axios.post(
        `/api/courses/public/verify-attendee-email-token?token=${token}&courseId=${courseId}`
      );

      dispatch({
        type: COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_SUCCESS,
        payload: data,
      });

      // console.log("after data", data);
    } catch (error) {
      dispatch({
        type: COURSE_VERIFY_ATTENDEE_EMAIL_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
