import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Image, Tooltip } from "@chakra-ui/react";
import {
  backgroundLight,
  newBorder,
  newDarkBlue,
  white,
} from "../../utils/colors";
import SidePanel from "../sidepanel/SidePanel";
import EditImage from "../../components/sidepanel/EditImage";
import { useTranslation } from "react-i18next";
import EditImageSpeaker from "../sidepanel/EditImageSpeaker";

import { icons } from "../../utils/icons";

const SinglePageImageWrapper = ({ id, image, borderRadius, options }) => {
  const { t } = useTranslation();
  const [editImageSidePanelOpen, setEditImageSidePanelOpen] = useState(false);

  const openEditImageSidePanel = () => {
    setEditImageSidePanelOpen(true);
  };

  const closeEditImageSidePanel = () => {
    setEditImageSidePanelOpen(false);
  };

  return (
    <>
      <SidePanel
        open={editImageSidePanelOpen}
        close={closeEditImageSidePanel}
        title={image ? t("common.editImage") : t("common.addImage")}
        noBorder={true}
      >
        <EditImage
          id={id}
          currentImage={image}
          close={closeEditImageSidePanel}
          options={options}
        />
      </SidePanel>

      <Flex
        align="center"
        justify="center"
        onClick={openEditImageSidePanel}
        transition="all 0.3s ease"
        _hover={{
          cursor: "pointer",
          transition: "all 0.3s ease",
        }}
        role="group"
      >
        <Tooltip
          bg={newDarkBlue}
          p={4}
          borderRadius="1rem"
          placement="top"
          label={
            <Text fontSize="sm" fontWeight="500" color={white}>
              {image ? t("common.editImage") : t("common.addImage")}
            </Text>
          }
        >
          {image ? (
            <Box
              h="210px"
              w="210px"
              borderRadius={borderRadius ? borderRadius : "100rem"}
              overflow="hidden"
              p="0.5rem"
              border={`1px solid ${newBorder}`}
              mt={2}
            >
              <Image
                src={image}
                objectFit="cover"
                h="100%"
                w="100%"
                borderRadius={borderRadius ? borderRadius : "100rem"}
                overflow="hidden"
              />
            </Box>
          ) : (
            <Box
              h="210px"
              w="210px"
              borderRadius={borderRadius ? borderRadius : "100rem"}
              overflow="hidden"
              p="0.5rem"
              border={`1px solid ${newBorder}`}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg={backgroundLight}
              transition="all 0.3s ease"
              mt={2}
              _groupHover={{
                bg: "white",
                transition: "all 0.3s ease",
              }}
            >
              <Image
                src={icons.noImage}
                transition="all 0.3s ease"
                h="2rem"
                _groupHover={{
                  height: "2.5rem",
                  transition: "all 0.3s ease",
                }}
              />
            </Box>
          )}
        </Tooltip>
      </Flex>
    </>
  );
};

export default SinglePageImageWrapper;
