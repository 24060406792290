import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Panel from "../../components/shared/Panel";
import { Flex, Heading, Box, Text, Button } from "@chakra-ui/react";
import { textSecondary, white, primary, textPrimary } from "../../utils/colors";
import Lottie from "lottie-react";
import noItemsFound from "../../assets/lottie/no-items-found.json";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.notFound")}</title>
        <meta name="description" content={t("pageDescription.notFound")} />
      </Helmet>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              margin="0 auto 4rem"
            >
              {/* <Image src={images.noData} alt="no Data" /> */}
              <Box maxWidth="60%" margin="0 auto">
                <Lottie animationData={noItemsFound} loop={true} />
              </Box>

              <Heading
                fontSize="1.75rem"
                fontWeight="600"
                color={textPrimary}
                margin="1rem 0 0rem"
              >
                {t("noItems.page")}
              </Heading>
              <Text
                my={2}
                fontSize="1rem"
                color={textSecondary}
                fontWeight="500"
              >
                {t("noItems.pageText")}
              </Text>
              <Button
                mt={2}
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                onClick={() =>
                  navigate(
                    localStorage.getItem("userInfo")
                      ? "/admin/academy/courses"
                      : "/login"
                  )
                }
              >
                {t("noItems.pageButton")}
              </Button>
            </Flex>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default NotFound;
