import React from 'react';
import Select from 'react-select';
import cities from '../../data/citiesLT.json';
import { useTranslation } from 'react-i18next';
import { primary, white } from '../../utils/colors';

const CitySelect = ({ value, onChange, newBorder, textSecondary, white }) => {
  const { t } = useTranslation();

  const cityOptions = [
    { value: '', label: t('placeholder.allCities') }, // Adjust the placeholder text
    ...cities.map((city) => ({
      value: city.city,
      label: city.city,
    })),
  ];

  return (
    <Select
      isSearchable
      value={cityOptions.find((option) => option.value === value)}
      onChange={onChange}
      options={cityOptions}
      placeholder={t('placeholder.allCities')}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? newBorder : newBorder,
          color: textSecondary,
          borderRadius: '1rem',
          backgroundColor: `${white}`,
          border: `1px solid ${newBorder}`,
          fontSize: '0.938rem',
          fontWeight: '500',
          height: '3rem',
          padding: '0 0.25rem',
          ':focus-visible': {
            borderColor: primary,
            boxShadow: `0 0 0 1px ${newBorder}`,
          },
        }),

        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';

          // check if the value is null (placeholder)
          const color =
            state.data.value === '' ? 'desired color' : 'default color';

          return { ...provided, opacity, transition, color };
        },
      }}
    />
  );
};

export default CitySelect;
