import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";
import successAnimation from "../../../../../assets/lottie/success.json";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Text,
  Heading,
  Button,
  Tooltip,
  Image,
  Icon,
  Stack,
  background,
} from "@chakra-ui/react";
import { FaCcStripe } from "react-icons/fa";
import dayjs from "dayjs";
import {
  textPrimary,
  textSecondary,
  primary,
  white,
  newBorder,
  secondary,
  buttonRed,
  buttonRedText,
  backgroundLight,
} from "../../../../../utils/colors";
import { shade } from "polished";

const StepSuccess = ({
  course,
  setActiveStep,
  userTrackingNumber,
  newFormHandler,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showAnimation, setShowAnimation] = useState(true);

  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  // const isSameDay = dayjs(course.startDate).isSame(
  //   dayjs(course.endDate),
  //   "day"
  // );

  console.log("course", course);

  return (
    <>
      <Flex w="100%" maxW="600px" direction="column">
        <Flex w="100%" direction="column">
          <Flex
            w="100%"
            align="center"
            justify="center"
            gap="1rem"
            textAlign="center"
          >
            <Stack gap="0rem" m="2rem auto 2rem" maxW="100%" textAlign="center">
              {showAnimation && (
                <Flex m="0 auto" maxW="80px">
                  {View}
                </Flex>
              )}
              <Heading
                fontSize="1.75rem"
                fontWeight="600"
                color={textPrimary}
                m="1rem 0 0.5rem"
              >
                {t("implantForm.stepSuccessTitle")}
              </Heading>

              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {t("implantForm.stepSuccessText")}
              </Text>
            </Stack>
          </Flex>

          <Flex
            direction="column"
            minW="300px"
            maxW="80%"
            align="center"
            justify="center"
            w="100%"
            m="0rem auto 0"
          >
            <Flex
              w="100%"
              p="1.5rem 1.5rem"
              border={`1px solid ${newBorder}`}
              borderRadius="1rem"
            >
              <Flex
                w="100%"
                align="center"
                justify="center"
                direction="column"
                textAlign="center"
              >
                <Text
                  color={textSecondary}
                  fontWeight="500"
                  fontSize="sm"
                  lineHeight="1.2"
                  m="0 0 0.75rem"
                >
                  {t("implantForm.formRefCode")}
                </Text>
                <Text
                  color={textPrimary}
                  fontWeight="600"
                  fontSize="lg"
                  lineHeight="1.2"
                >
                  {userTrackingNumber}
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify="center"
              align="center"
              m="3rem auto 3rem"
              w="100%"
              gap="1rem"
            >
              <Button
                bg={backgroundLight}
                color={textSecondary}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                px="3rem"
                _hover={{ bg: shade(0.1, backgroundLight) }}
                onClick={newFormHandler}
              >
                {t("implantForm.startNewForm")}
              </Button>

              <Button
                bg={primary}
                color={white}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                px="3rem"
                _hover={{ bg: shade(0.3, primary) }}
                onClick={() => {
                  navigate("/account/forms");
                  setActiveStep(0);
                }}
              >
                {t("implantForm.goToAccountFormList")}
              </Button>
            </Flex>
            <Flex w="100%" justify="center" m="0rem 0 3rem">
              <Flex align="center" justify="flex-start" m="0 auto">
                <Text
                  fontSize="xs"
                  color={textSecondary}
                  textAlign="center"
                  fontWeight="500"
                  maxW="500px"
                  m="0 auto"
                >
                  {t("common.successFormTerms")}{" "}
                  {/* <Link to="/terms" isDisabled>
                <Text as="span" color={textPrimary}>
                  {" "}
                  {t("common.acceptTermsOfService")}
                </Text>
              </Link>{" "} */}
                  <Text
                    as="span"
                    color={textPrimary}
                    isDisabled
                    opacity="0.8"
                    transition="all 0.3s"
                    _hover={{
                      cursor: "not-allowed",
                      opacity: 0.8,
                    }}
                  >
                    {" "}
                    {t("common.acceptTermsOfService")}{" "}
                  </Text>
                  {t("common.and")}{" "}
                  {/* <Link to="/terms" isDisabled>
                <Text as="span" color={textPrimary}>
                  {" "}
                  {t("common.privacyPolicy")}
                </Text>
              </Link> */}
                  <Text
                    as="span"
                    color={textPrimary}
                    isDisabled
                    opacity="0.8"
                    transition="all 0.3s"
                    _hover={{
                      cursor: "not-allowed",
                      opacity: 0.8,
                    }}
                  >
                    {t("common.privacyPolicy")}
                  </Text>
                  .
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default StepSuccess;
