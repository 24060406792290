import React, { useEffect } from "react";
import { mobile } from "../../../utils/breakpoints";
import {
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../utils/colors";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Heading, Text, Button, Flex, Image } from "@chakra-ui/react";

const slideFromLeftTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, y: 0, x: -40, skewX: 0, skewY: 0 },
};

const slideFromRightTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, y: 0, x: 40, skewX: 0, skewY: 0 },
};

export const Feature = ({
  title,
  subtitle,
  image,
  text,
  className,
  buttonText,
  buttonHandler,
  isReverse,
}) => {
  const isMobile = useMediaQuery({ query: mobile });
  const control = useAnimation();
  const [ref, inView] = useInView();
  let { t } = useTranslation("translations");

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Flex
      p="4rem 0"
      align="center"
      w="100%"
      h="100%"
      direction={["column", "column", isReverse ? "row-reverse" : "row"]}
    >
      {isMobile ? (
        <Flex
          as={motion.div}
          ref={ref}
          variants={
            isReverse ? slideFromLeftTransition : slideFromRightTransition
          }
          initial="hidden"
          animate={control}
          w={["100%", "100%", "50%"]}
          position="relative"
          z-index="2"
          align="center"
          justify="flex-start"
        >
          <Image
            w={["80%", "80%", "80%"]}
            h="auto"
            objectFit="cover"
            borderRadius={["1rem", "1rem", "2.5rem"]}
            src={image}
            m={["0 auto", "0 auto", "initial"]}
          />
        </Flex>
      ) : (
        <Flex
          as={motion.div}
          ref={ref}
          variants={
            className === "reverse"
              ? slideFromLeftTransition
              : slideFromRightTransition
          }
          initial="hidden"
          animate={control}
          w={["100%", "100%", "50%"]}
          position="relative"
          z-index="2"
          align="center"
          justify="flex-start"
        >
          <Image
            w={["80%", "80%", "80%"]}
            h="auto"
            objectFit="cover"
            borderRadius={["1rem", "1rem", "2.5rem"]}
            src={image}
            m={["0 auto", "0 auto", "initial"]}
          />
        </Flex>
      )}

      <Flex
        w={["100%", "100%", "50%"]}
        p={["3rem 1.5rem", "3rem 1.5rem", "0 6rem 0 0rem"]}
        m="0 auto 0px"
        position="relative"
        align={isReverse ? "flex-end" : "flex-start"}
        justify="center"
        direction="column"
        as={motion.div}
        ref={ref}
        variants={
          isReverse ? slideFromRightTransition : slideFromLeftTransition
        }
        initial="hidden"
        animate={control}
        textAlign={["left", "left", "left"]}
      >
        <Text
          fontSize="sm"
          m="0 0 1.5rem"
          textTransform="uppercase"
          color={primary}
          fontWeight="600"
        >
          {subtitle}
        </Text>
        <Heading fontSize={["2rem", "2rem", "2.5rem"]}>{title}</Heading>
        <Text
          maxWidth={["100%", "100%", "86%"]}
          fontSize="1rem"
          color={textSecondary}
          fontWeight="500"
          m="1.5rem 0 3rem"
        >
          {text}
        </Text>

        <Flex w="100%">
          <Button
            size="lg"
            bg={primary}
            color={white}
            borderRadius="10rem"
            px="3rem"
            fontSize="md"
            onClick={buttonHandler && buttonHandler}
            w={["100%", "100%", "initial"]}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Feature;
