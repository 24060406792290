import React, { useState, useContext } from "react";
import dayjs from "dayjs";
// import { Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { createSpeaker } from "../../../../actions/speakerActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlAddressFull from "../../../../components/input/FormControlAddressFull";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import { shade } from "polished";
import { GoogleApiContext } from "../../../../contexts/GoogleApiContext";

const CreateSpeaker = () => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // GOOGLE API
  const { isApiLoaded } = useContext(GoogleApiContext);

  // STATE

  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [courses, setCourses] = useState([]);
  const [birthDate, setBirthDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [displayAddress, setDisplayAddress] = useState("");
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid || !isLastNameValid || !isEmailValid;

  // CUSTOM COURSES

  const [customCourses, setCustomCourses] = useState([]);

  // FUNCTIONS

  // const handleAddressSelect = ({ street, city, zip, country, state }) => {
  //   setStreet(street);
  //   setCity(city);
  //   setZip(zip);
  //   setCountry(country);
  //   setState(state);
  // };

  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
    // setHasChanged(true);
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  // SUBMIT

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const street = manualAddressVisible ? address.street : address.street;
    const city = manualAddressVisible ? address.city : address.city;
    const zip = manualAddressVisible ? address.zip : address.zip;
    const country = manualAddressVisible ? address.country : address.country;
    const state = manualAddressVisible ? address.state : address.state;

    try {
      // Use startDateTime and endDateTime in your createCourse action as needed
      await dispatch(
        createSpeaker(
          firstName,
          lastName,
          image,
          phone,
          email,
          birthDate,
          street,
          zip,
          city,
          country,
          state,
          courses
        )
      );
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  // LOGS

  // console.log("isFirstNameValid", isFirstNameValid);
  // console.log("isLastNameValid", isLastNameValid);

  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("firstNameTouched", firstNameTouched);
  // console.log("lastNameTouched", lastNameTouched);
  // console.log("isFirstNameError", isFirstNameError);
  // console.log("isLastNameError", isLastNameError);

  console.log("courses", courses);
  console.log("customCourses", customCourses);

  return (
    <form onSubmit={submitHandler}>
      <FormControl mb={4}>
        <FormLabel fontSize="sm">{t("speaker.speakerImage")}</FormLabel>
        <ImageUpload image={image} setImage={setImage} />
      </FormControl>

      <HStack gap={4} w="100%">
        <FormControlDefault
          type="text"
          isRequired
          label={t("form.firstName")}
          value={firstName}
          placeholder={t("placeholder.firstName")}
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameTouched(true);
          }}
          onBlur={() => setFirstNameTouched(true)}
          isTouched={firstNameTouched}
          isError={isFirstNameError}
          w="50%"
          errorMessage={t("formError.firstNameRequired")}
        />

        <FormControlDefault
          type="text"
          isRequired
          label={t("form.lastName")}
          value={lastName}
          placeholder={t("placeholder.lastName")}
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameTouched(true);
          }}
          onBlur={() => setLastNameTouched(true)}
          isTouched={lastNameTouched}
          isError={isLastNameError}
          w="50%"
          errorMessage={t("formError.lastNameRequired")}
        />
      </HStack>

      <FormControlDefault
        type="email"
        isRequired
        label={t("form.email")}
        value={email}
        placeholder={t("placeholder.email")}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailTouched(true);
        }}
        onBlur={() => setEmailTouched(true)}
        isTouched={emailTouched}
        isError={!isEmailValid}
        w="100%"
        errorMessage={t("formError.emailInvalid")}
      />

      <HStack gap={4} w="100%">
        <FormControlDatePicker
          type="date"
          isRequired={false}
          label={t("form.birthDate")}
          placeholder={t("placeholder.birthDate")}
          onChange={(newValue) => {
            setBirthDate(newValue);
          }}
          onOpen={() => {}}
          w="50%"
          errorMessage={t("formError.birthDateRequired")}
        />

        <FormControlPhone
          label={t("form.phone")}
          value={phone}
          onChange={(newPhone) => {
            setPhone(newPhone);
          }}
          errorMessage={t("formError.phoneRequired")}
          w="50%"
          defaultCountry="lt"
        />
      </HStack>

      {!manualAddressVisible && (
        <Flex direction="column" w="100%" p="0rem">
          {isApiLoaded && (
            <FormControlAddressFull
              label={t("form.address")}
              placeholder={t("placeholder.googleAddress")}
              onAddressSelect={handleAddressSelect}
              currentValue={displayAddress}
              w="100%"
              isApiLoaded={isApiLoaded}
            />
          )}
          <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
            <Flex position="relative" m="0rem 0 0" justify="flex-end" w="100%">
              <Button
                bg="rgba(255,255,255,0.3)"
                onClick={toggleManualAddressVisible}
                color={primary}
                variant="ghost"
                fontSize="xs"
                size="xs"
                fontWeight="500"
                p="0.25rem 0.75rem"
                borderRadius="10rem"
                opacity="0.8"
                transition="all 0.3s"
                _hover={{
                  // bg: backgroundLight,
                  opacity: "1",
                }}
              >
                {t("onboarding.enterAddressManual")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      {manualAddressVisible && (
        <>
          <Flex direction="column" align="center" justify="center" p="0rem">
            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.street")}
                value={address.street}
                placeholder={t("placeholder.street")}
                onChange={(e) => {
                  setAddress({ ...address, street: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />

              <FormControlDefault
                type="text"
                label={t("form.zip")}
                value={address.zip}
                placeholder={t("placeholder.zip")}
                onChange={(e) => {
                  setAddress({ ...address, zip: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.city")}
                value={address.city}
                placeholder={t("placeholder.city")}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.cityRequired")}
              />

              <FormControlCountry
                label={t("form.country")}
                value={address.country}
                onChange={(selectedOption) => {
                  setAddress({ ...address, country: selectedOption.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.countryRequired")}
              />
            </HStack>
            <Flex align="center" justify="center" gap="1rem">
              <Flex position="relative" m="0rem 0 0" justify="center">
                <Button
                  bg="rgba(255,255,255,0.3)"
                  onClick={toggleManualAddressVisible}
                  color={primary}
                  variant="link"
                  fontSize="xs"
                  fontWeight="500"
                  p="0.5rem 0.75rem"
                  borderRadius="10rem"
                  _hover={{
                    bg: "rgba(255,255,255,1)",
                  }}
                >
                  {t("onboarding.enterAddressGoogle")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      {/* <HStack gap={4} w="100%">
        <FormControlAddress
          label={t("form.address")}
          onAddressSelect={handleAddressSelect}
          isRequired={false}
          errorMessage={t("formError.addressRequired")}
          w="100%"
        />

        <FormControlDefault
          type="text"
          isRequired={false}
          label={t("form.zip")}
          value={zip}
          placeholder={t("placeholder.zip")}
          onChange={(e) => {
            setZip(e.target.value);
          }}
          w="40%"
          errorMessage={t("formError.zipRequired")}
        />
      </HStack> */}

      {/* <HStack gap={4} w="100%">
        <FormControlDefault
          type="text"
          isRequired={false}
          label={t("form.city")}
          value={city}
          placeholder={t("placeholder.city")}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          w="50%"
          errorMessage={t("formError.cityRequired")}
        />

        <FormControlCountry
          label={t("form.country")}
          value={country}
          onChange={(selectedOption) => {
            setCountry(selectedOption ? selectedOption.value : "");
          }}
          isRequired={false}
          w="50%"
          errorMessage={t("formError.countryRequired")}
        />
      </HStack> */}

      {/* <FormControl mt={4}>
              <>
                <FormLabel fontSize="sm">
                  <Flex justify="space-between">
                    {t("speaker.addNewCourse")}
                  </Flex>
                </FormLabel>

                <Flex w="100%" mb={10} flexDirection="column">
                  <Box w="100%">
                    <MultiCourseSelect
                      onChange={customCoursesHandle}
                      value={customCourses}
                      title={t("course.courses")}
                      placeholderText={t("select.course")}
                    />
                  </Box>
                </Flex>
              </>
            </FormControl> */}

      <Flex
        mt={4}
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="2rem 0 0"
      >
        <Flex justify="flex-end">
          <Tooltip
            bg={white}
            borderRadius="1rem"
            p="1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                {isFirstNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.firstNameRequired")}
                  </Text>
                )}
                {isLastNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.lastNameRequired")}
                  </Text>
                )}
                {!isEmailValid && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.emailInvalid")}
                  </Text>
                )}{" "}
              </Flex>
            }
            isDisabled={!isContinueDisabled}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
            >
              {t("speaker.createSpeaker")}
            </Button>
          </Tooltip>

          {activeTab > 0 && (
            <Button
              minWidth="8em"
              mr={4}
              bg={backgroundLight}
              border={`1px solid ${newBorder}`}
              size="lg"
              color={textSecondary}
              fontSize="md"
              fontWeight="500"
              borderRadius="10rem"
              onClick={() => setActiveTab(activeTab - 1)}
              isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.1, backgroundLight),
              }}
            >
              {t("common.back")}
            </Button>
          )}

          {activeTab < 0 && (
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isFirstNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.firstNameRequired")}
                    </Text>
                  )}
                  {isLastNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.lastNameRequired")}
                    </Text>
                  )}
                  {!isEmailValid && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.emailInvalid")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isContinueDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="8rem"
                ml={4}
                bg={backgroundLight}
                border={`1px solid ${newBorder}`}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={() => setActiveTab(activeTab + 1)}
                isDisabled={isContinueDisabled}
                _hover={{
                  bg: shade(0.1, backgroundLight),
                }}
              >
                {t("common.continue")}
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

export default CreateSpeaker;
