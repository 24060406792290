import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { listProductDetails } from "../../../../actions/productActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import SidePanel from "../../../../components/sidepanel/SidePanel";
// import EdiProduct from '../sidepanels/EditProduct';
import {
  PRODUCT_UPDATE_RESET,
  ATTENDEE_ACTIVE_STATUS_UPDATE_RESET,
} from "../../../../constants/productConstants";
import { useTranslation } from "react-i18next";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";
import ProductInfo from "./partials/ProductInfo";
import ProductCharts from "./partials/ProductCharts";
import TabRelatedProducts from "./tabs/TabRelatedProducts.js";

const StockProduct = () => {
  const productId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const tabsData = [{ label: t("product.tabRelatedProducts") }];

  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [name, setName] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, product } = productDetails;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!product.name || product._id !== productId) {
      dispatch(listProductDetails(productId));
    } else {
      setName(product.name);
      setRelatedProducts(product.relatedProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, productId, product]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  return (
    <>
      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("product.editProduct")}
        noBorder={true}
      >
        {/* <EditProduct productId={productId} /> */}
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={product.name}
              type={t("product.product")}
              loading={loading}
            >
              {/* <StatusButton
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="active"
                isBig
              /> */}
              <MoreButton
                id={productId}
                isBig
                // deleteHandler={deleteProductHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(productId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 2rem 2.5rem"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  w="100%"
                >
                  <SinglePageImageWrapper image={product.image} />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <Stack w="100%" gap="0">
                        <ProductInfo product={product} />
                        <Box>test</Box>
                      </Stack>

                      {/*    <ProductCharts product={product} /> */}
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                disabledTabs={[2, 3]}
                isSinglePage
              >
                <TabPanels>
                  <TabPanel pb={4} px={0}>
                    <TabRelatedProducts products={relatedProducts} />
                  </TabPanel>
                </TabPanels>
              </TabsComponent>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default StockProduct;
