import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createAttendee } from "../../../../actions/attendeeActions";
import { listClinics } from "../../../../actions/clinicActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Button,
  Flex,
  Tooltip,
  HStack,
  Text,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlClientSelect from "../../../../components/input/FormControlClientSelect";
import FormControlAddressFull from "../../../../components/input/FormControlAddressFull";
import { shade } from "polished";
import { GoogleApiContext } from "../../../../contexts/GoogleApiContext";

const CreateAttendee = () => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // GOOGLE API
  const { isApiLoaded } = useContext(GoogleApiContext);

  // STATE

  const [activeTab, setActiveTab] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState("");
  const [client, setClient] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [selectedProfession, setSelectedProfession] = useState(null);
  // const [isProfessionValid, setIsProfessionValid] = useState(false);

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [clinicOptions, setClinicOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  const clinicList = useSelector((state) => state.clinicList);
  const { clinics } = clinicList;

  const [courses, setCourses] = useState([]);
  const [customCourses, setCustomCourses] = useState([]);

  const [displayAddress, setDisplayAddress] = useState("");
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);
  const [clientTouched, setClientTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isProfessionError = profession === "";
  const isClientError = client === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  const isProfessionValid = !!selectedProfession;
  const isClientValid = !!client;

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid ||
    !isLastNameValid ||
    !isEmailValid ||
    !isProfessionValid ||
    !isClientValid;

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    const street = manualAddressVisible ? address.street : address.street;
    const city = manualAddressVisible ? address.city : address.city;
    const zip = manualAddressVisible ? address.zip : address.zip;
    const country = manualAddressVisible ? address.country : address.country;
    const state = manualAddressVisible ? address.state : address.state;

    dispatch(
      createAttendee(
        firstName,
        lastName,
        // image,
        phone,
        email,
        birthDate,
        client,
        courses,
        street,
        zip,
        city,
        country,
        state,
        profession
      )
    );
  };

  // HANDLERS
  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
    // setHasChanged(true);
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
  };

  // Use this useEffect to dispatch listSpeakers only when the component mounts
  useEffect(() => {
    dispatch(listClinics());
  }, [dispatch]);

  // Use this useEffect to update the speakerOptions when the speakers change
  useEffect(() => {
    if (clinics && clinics.length > 0) {
      const options = clinics.map((s) => ({
        value: s._id,
        label: s.name,
        image: s.image, // Assuming image URL is stored in s.image
      }));
      setClinicOptions([...options]);
    }

    if (courses && courses.length > 0) {
      const options = courses.map((s) => ({
        value: s._id,
        label: s.name,
        image: s.image, // Assuming image URL is stored in s.image
      }));
      setCourseOptions([{ value: "", label: "Select course" }, ...options]);
    }
  }, [clinics, courses]);

  console.log("firstName", firstName);
  console.log("lastName", lastName);
  console.log("email", email);
  console.log("profession", profession);
  console.log("selectedProfession", selectedProfession);
  console.log("birthDate", birthDate);
  console.log("phone", phone);
  console.log("street", street);
  console.log("zip", zip);
  console.log("city", city);
  console.log("country", country);
  console.log("state", state);

  console.log("client", client);

  return (
    <form onSubmit={submitHandler}>
      {/* <FormControl mb={4}>
              <FormLabel fontSize="sm">{t('attendee.attendeeImage')}</FormLabel>
              <ImageUpload image={image} setImage={setImage} />
            </FormControl> */}

      <HStack gap={4} w="100%">
        <FormControlDefault
          type="text"
          isRequired
          label={t("form.firstName")}
          value={firstName}
          placeholder={t("placeholder.firstName")}
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameTouched(true);
          }}
          onBlur={() => setFirstNameTouched(true)}
          isTouched={firstNameTouched}
          isError={isFirstNameError}
          w="50%"
          errorMessage={t("formError.firstNameRequired")}
        />

        <FormControlDefault
          type="text"
          isRequired
          label={t("form.lastName")}
          value={lastName}
          placeholder={t("placeholder.lastName")}
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameTouched(true);
          }}
          onBlur={() => setLastNameTouched(true)}
          isTouched={lastNameTouched}
          isError={isLastNameError}
          w="50%"
          errorMessage={t("formError.lastNameRequired")}
        />
      </HStack>

      <FormControlDefault
        type="email"
        label={t("form.email")}
        isRequired
        value={email}
        placeholder={t("placeholder.email")}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailTouched(true);
        }}
        onBlur={() => setEmailTouched(true)}
        isTouched={emailTouched}
        isError={!isEmailValid}
        w="100%"
        errorMessage={t("formError.emailInvalid")}
      />
      <FormControlProfession
        label={t("form.profession")}
        value={profession}
        placeholder={t("select.profession")}
        onChange={handleProfessionChange}
        setIsTouched={() => setProfessionTouched(true)}
        isRequired={true}
        isTouched={professionTouched}
        isError={isProfessionError}
        errorMessage={t("formError.professionRequired")}
        w="100%"
        onProfessionChange={handleProfessionChange}
      />

      <HStack gap={4} w="100%">
        <FormControlDatePicker
          type="date"
          isRequired={false}
          label={t("form.birthDate")}
          placeholder={t("placeholder.birthDate")}
          onChange={(newValue) => {
            setBirthDate(newValue);
          }}
          onOpen={() => {}}
          w="50%"
          errorMessage={t("formError.birthDateRequired")}
        />

        <FormControlPhone
          label={t("form.phone")}
          value={phone}
          onChange={(newPhone) => {
            setPhone(newPhone);
          }}
          errorMessage={t("formError.phoneRequired")}
          w="50%"
          defaultCountry="lt"
        />
      </HStack>

      {/* <FormControlClient
        value={client}
        label={t("attendee.client")}
        placeholder={t("select.client")}
        onChange={(selectedOption) => setClient(selectedOption.value)}
        isRequired={false}
        w="100%"
      /> */}

      <FormControl isRequired>
        <FormLabel fontSize="sm">{t("form.selectClient")}</FormLabel>
        <FormControlClientSelect
          onChange={(selectedOption) => {
            setClient(selectedOption);
          }}
          placeholder={t("placeholder.selectClient")}
          client={client}
        />
      </FormControl>
      {!manualAddressVisible && (
        <Flex direction="column" w="100%" p="0rem">
          {isApiLoaded && (
            <FormControlAddressFull
              label={t("form.address")}
              placeholder={t("placeholder.googleAddress")}
              onAddressSelect={handleAddressSelect}
              currentValue={displayAddress}
              w="100%"
              isApiLoaded={isApiLoaded}
            />
          )}
          <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
            <Flex position="relative" m="0rem 0 0" justify="flex-end" w="100%">
              <Button
                bg="rgba(255,255,255,0.3)"
                onClick={toggleManualAddressVisible}
                color={primary}
                variant="ghost"
                fontSize="xs"
                size="xs"
                fontWeight="500"
                p="0.25rem 0.75rem"
                borderRadius="10rem"
                opacity="0.8"
                transition="all 0.3s"
                _hover={{
                  // bg: backgroundLight,
                  opacity: "1",
                }}
              >
                {t("onboarding.enterAddressManual")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      {manualAddressVisible && (
        <>
          <Flex direction="column" align="center" justify="center" p="0rem">
            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.street")}
                value={address.street}
                placeholder={t("placeholder.street")}
                onChange={(e) => {
                  setAddress({ ...address, street: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />

              <FormControlDefault
                type="text"
                label={t("form.zip")}
                value={address.zip}
                placeholder={t("placeholder.zip")}
                onChange={(e) => {
                  setAddress({ ...address, zip: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.city")}
                value={address.city}
                placeholder={t("placeholder.city")}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.cityRequired")}
              />

              <FormControlCountry
                label={t("form.country")}
                value={address.country}
                onChange={(selectedOption) => {
                  setAddress({ ...address, country: selectedOption.value });
                }}
                w={["100%", "50%", "50%"]}
                errorMessage={t("formError.countryRequired")}
              />
            </HStack>
            <Flex align="center" justify="center" gap="1rem">
              <Flex position="relative" m="0rem 0 0" justify="center">
                <Button
                  bg="rgba(255,255,255,0.3)"
                  onClick={toggleManualAddressVisible}
                  color={primary}
                  variant="link"
                  fontSize="xs"
                  fontWeight="500"
                  p="0.5rem 0.75rem"
                  borderRadius="10rem"
                  _hover={{
                    bg: "rgba(255,255,255,1)",
                  }}
                >
                  {t("onboarding.enterAddressGoogle")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      <Flex
        mt={4}
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="2rem 0 0"
      >
        <Flex justify="flex-end">
          <Tooltip
            bg={white}
            borderRadius="1rem"
            p="1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                {isFirstNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.firstNameRequired")}
                  </Text>
                )}
                {isLastNameError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.lastNameRequired")}
                  </Text>
                )}
                {!isEmailValid && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.emailInvalid")}
                  </Text>
                )}
                {isProfessionError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.professionRequired")}
                  </Text>
                )}
                {isClientError && (
                  <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                    * {t("formError.clientRequired")}
                  </Text>
                )}
              </Flex>
            }
            isDisabled={!isContinueDisabled}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
            >
              {t("attendee.createAttendee")}
            </Button>
          </Tooltip>

          <Flex align="center">
            {activeTab > 0 && (
              <Button
                minWidth="8rem"
                mr={0}
                bg={backgroundLight}
                border={`1px solid ${newBorder}`}
                size="lg"
                color={textSecondary}
                fontSize="md"
                fontWeight="500"
                borderRadius="10rem"
                onClick={() => setActiveTab(activeTab - 1)}
                isDisabled={isContinueDisabled}
                _hover={{
                  bg: shade(0.1, backgroundLight),
                }}
              >
                {t("common.back")}
              </Button>
            )}

            {activeTab < 0 && (
              <Tooltip
                bg={white}
                borderRadius="1rem"
                p="1rem"
                label={
                  <Flex
                    direction="column"
                    p="0rem"
                    justify="center"
                    textAlign="center"
                  >
                    {isFirstNameError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.firstNameRequired")}
                      </Text>
                    )}
                    {isLastNameError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.lastNameRequired")}
                      </Text>
                    )}
                    {!isEmailValid && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.emailInvalid")}
                      </Text>
                    )}
                    {isProfessionError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.professionRequired")}
                      </Text>
                    )}
                  </Flex>
                }
                isDisabled={!isContinueDisabled}
                hasArrow
                placement="top"
                fontSize="md"
              >
                <Button
                  minWidth="8rem"
                  ml={4}
                  bg={backgroundLight}
                  border={`1px solid ${newBorder}`}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  fontWeight="500"
                  borderRadius="10rem"
                  onClick={() => setActiveTab(activeTab + 1)}
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.1, backgroundLight),
                  }}
                >
                  {t("common.continue")}
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default CreateAttendee;
