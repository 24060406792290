import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import Hero from "./sections/Hero";
import About from "./sections/About";
import Features from "./sections/Features";
import UseCasesCarousel from "./sections/UseCasesCarousel";
import Cta from "./sections/Cta";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // useEffect(() => {
  //   if (userInfo) {
  //     console.log("logged in");
  //   } else {
  //     console.log("not logged in");
  //   }
  // }, [userInfo, navigate]);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.landingMain")}</title>
        <meta name="description" content={t("pageDescription.landingMain")} />
      </Helmet>

      <Flex w="100%" direction="column">
        <Header />
        <Hero userInfo={userInfo} />
        <About userInfo={userInfo} />
        <Features />
        <UseCasesCarousel />
        <Cta userInfo={userInfo} />
        <Footer />
      </Flex>
    </>
  );
};

export default Landing;
