import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../../../utils/breakpoints";
import {
  primary,
  textPrimary,
  textSecondary,
  white,
} from "../../../utils/colors";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import {
  Heading,
  Text,
  Button,
  Flex,
  Stack,
  Icon,
  Image,
} from "@chakra-ui/react";
import { TbBellRinging, TbUserHeart, TbStar } from "react-icons/tb";

const slideFromLeftTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, y: 0, x: -40, skewX: 0, skewY: 0 },
};

const slideFromRightTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, y: 0, x: 40, skewX: 0, skewY: 0 },
};

export const FeatureAlt = ({
  title,
  subtitle,
  image,
  text,
  className,
  buttonText,
  buttonHandler,
  isReverse,
}) => {
  const isMobile = useMediaQuery({ query: mobile });
  const control = useAnimation();
  const [ref, inView] = useInView();
  let { t } = useTranslation("");

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Flex
      p={["0rem 0", "0rem 0", "4rem 0"]}
      align="center"
      w="100%"
      h="100%"
      direction={["column", "column", isReverse ? "row-reverse" : "row"]}
    >
      {isMobile ? (
        <Flex
          as={motion.div}
          ref={ref}
          variants={
            isReverse ? slideFromLeftTransition : slideFromRightTransition
          }
          initial="hidden"
          animate={control}
          w={["100%", "100%", "50%"]}
          position="relative"
          z-index="2"
          align="center"
          justify={isReverse ? "flex-end" : "flex-start"}
        >
          <Image
            w={["80%", "80%", "80%"]}
            h="auto"
            objectFit="cover"
            borderRadius={["1rem", "1rem", "2.5rem"]}
            src={image}
            m={["0 auto", "0 auto", "initial"]}
          />
        </Flex>
      ) : (
        <Flex
          as={motion.div}
          ref={ref}
          variants={
            className === "reverse"
              ? slideFromLeftTransition
              : slideFromRightTransition
          }
          initial="hidden"
          animate={control}
          w={["100%", "100%", "50%"]}
          position="relative"
          z-index="2"
          align="center"
          justify={isReverse ? "flex-end" : "flex-start"}
        >
          <Image
            w={["80%", "80%", "80%"]}
            h="auto"
            objectFit="cover"
            borderRadius={["1rem", "1rem", "2.5rem"]}
            src={image}
            m={["0 auto", "0 auto", "initial"]}
          />
        </Flex>
      )}

      <Flex
        w={["100%", "100%", "50%"]}
        p={["3rem 1.5rem 1.5rem", "3rem 1.5rem 1.5rem", "0 0% 0 6em"]}
        m="0 auto 0px"
        position="relative"
        align={isReverse ? "flex-start" : "flex-end"}
        justify="center"
        as={motion.div}
        ref={ref}
        variants={
          isReverse ? slideFromRightTransition : slideFromLeftTransition
        }
        initial="hidden"
        animate={control}
        direction="column"
      >
        <Text
          fontSize="sm"
          m="0 0 1.5rem"
          textTransform="uppercase"
          color={primary}
          fontWeight="600"
        >
          {subtitle}
        </Text>
        <Heading fontSize={["2rem", "2rem", "2.5rem"]}>{title}</Heading>

        <Stack spacing="1.5rem" mt="2rem" w={["100%", "100%", "initial"]}>
          <Flex align="center">
            <Flex
              bg="#8F7DF8"
              w="3rem"
              h="3rem"
              align="center"
              justify="center"
              borderRadius="50%"
              flexShrink={0}
              mr="1rem"
            >
              <Icon as={TbBellRinging} color={white} fontSize="1.25rem" />
            </Flex>
            <Flex direction="column">
              <Text color={textPrimary} fontSize="1.125rem" fontWeight="500">
                {t("landingMegagen.feature2Block1Title")}
              </Text>
              <Text color={textSecondary} fontSize="1rem" fontWeight="500">
                {t("landingMegagen.feature2Block1Text")}
              </Text>
            </Flex>
          </Flex>
          <Flex align="center">
            <Flex
              bg="#2AA4DE"
              w="3rem"
              h="3rem"
              align="center"
              justify="center"
              borderRadius="50%"
              flexShrink={0}
              mr="1rem"
            >
              <Icon as={TbUserHeart} color={white} fontSize="1.25rem" />
            </Flex>
            <Flex direction="column">
              <Text color={textPrimary} fontSize="1.125rem" fontWeight="500">
                {t("landingMegagen.feature2Block2Title")}
              </Text>
              <Text color={textSecondary} fontSize="1rem" fontWeight="500">
                {t("landingMegagen.feature2Block2Text")}
              </Text>
            </Flex>
          </Flex>

          <Flex align="center">
            <Flex
              bg="#F1C142"
              w="3rem"
              h="3rem"
              align="center"
              justify="center"
              borderRadius="50%"
              flexShrink={0}
              mr="1rem"
            >
              <Icon as={TbStar} color={white} fontSize="1.25rem" />
            </Flex>
            <Flex direction="column">
              <Text color={textPrimary} fontSize="1.125rem" fontWeight="500">
                {t("landingMegagen.feature2Block3Title")}
              </Text>
              <Text color={textSecondary} fontSize="1rem" fontWeight="500">
                {t("landingMegagen.feature2Block3Text")}
              </Text>
            </Flex>
          </Flex>
        </Stack>

        <Flex w="100%" m="3rem 0 0">
          <Button
            size="lg"
            bg={primary}
            color={white}
            borderRadius="10rem"
            px="3rem"
            fontSize="md"
            onClick={buttonHandler && buttonHandler}
            w={["100%", "100%", "initial"]}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeatureAlt;
