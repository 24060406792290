// IMPORT START
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createProfessionGroup } from "../../../../actions/professionGroupActions";
import { Flex, Button, Text, Tooltip } from "@chakra-ui/react";
import { primary, white, newBorder } from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
// import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
// import FormControlRolePermission from "../../../../components/input/FormControlRolePermission";
import { PROFESSIONGROUP_CREATE_RESET } from "../../../../constants/professionGroupConstants";
import { shade } from "polished";

const CreateProfessionGroup = ({ close }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // FORM
  const [name, setName] = useState("");

  // HANDLERS
  const closeHandler = () => {
    close();
    dispatch({ type: PROFESSIONGROUP_CREATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProfessionGroup(name));
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";
  const isNameValid = name !== "";

  const isCreateDisabled = !isNameValid;

  // LOGS

  console.log("name", name);

  return (
    <>
      <form onSubmit={submitHandler}>
        <FormControlDefault
          type="text"
          isRequired
          label={t("form.professionGroupName")}
          value={name}
          placeholder={t("placeholder.professionGroupName")}
          onChange={(e) => {
            setName(e.target.value);
            setNameTouched(true);
          }}
          isTouched={nameTouched}
          isError={isNameError}
          w="100%"
          errorMessage={t("formError.professionGroupNameRequired")}
        />

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="flex-end">
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.nameRequired")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isCreateDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                isDisabled={isCreateDisabled}
                _hover={{
                  bg: shade(0.1, primary),
                }}
                ml={4}
              >
                {t("professionGroup.createProfessionGroup")}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default CreateProfessionGroup;
