import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSortable } from "@dnd-kit/sortable";
import { removeCourseSubtask } from "../../actions/courseActions";
import { CSS } from "@dnd-kit/utilities";
import { FaGrip, FaChevronRight } from "react-icons/fa6";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Textarea,
  useDisclosure,
  useBoolean,
} from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  backgroundLight,
  newBorder,
  buttonBlue,
  buttonRed,
  buttonRedText,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

import AddSubtask from "./AddSubtask";
import EditSubtask from "./EditSubtask";
import { TbTrash } from "react-icons/tb";

function TaskCard({
  task,
  deleteTask,
  updateTask,
  courseId,
  onSubtaskAdded,
  maxAttendees,
}) {
  // TRANSLATION
  const { t } = useTranslation();

  // STATE
  const { isOpen, onToggle } = useDisclosure();
  const { isEditOpen, onEditToggle } = useBoolean();
  const [totalPrice, setTotalPrice] = useState(0);

  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [editingSubtaskId, setEditingSubtaskId] = useState(null);

  const [subtasks, setSubtasks] = useState([]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task._id,
    data: {
      type: "Task",
      task,
    },
    disabled: editMode,
  });

  // REDUX

  const dispatch = useDispatch();

  const courseTaskSubtaskAdd = useSelector(
    (state) => state.courseTaskSubtaskAdd
  );
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
    subtask,
  } = courseTaskSubtaskAdd;

  const courseTaskSubtaskRemove = useSelector(
    (state) => state.courseTaskSubtaskRemove
  );
  const { loading, error, success } = courseTaskSubtaskRemove;

  // STYLES

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  // HANDLERS

  const handleDeleteSubtask = (subtaskId) => {
    dispatch(removeCourseSubtask(courseId, task._id, subtaskId));

    setSubtasks((prev) => prev.filter((subtask) => subtask._id !== subtaskId));

    // substrack the price  multiplied by quantity of the subtask from the total price
    const subtaskToDelete = subtasks.find(
      (subtask) => subtask._id === subtaskId
    );
    const priceToDelete = subtaskToDelete.price * subtaskToDelete.quantity;
    setTotalPrice(totalPrice - priceToDelete);
  };

  const calculateTotalPrice = () => {
    const newTotal = task.subtasks.reduce((acc, curr) => {
      // Ensure both accumulated and current prices are treated as numbers
      const accPrice = parseFloat(acc);
      const currPrice = parseFloat(curr.price || 0); // Parse current price as float
      const quantity = parseInt(curr.quantity || 1); // Ensure quantity is treated as an integer, defaulting to 1 if not specified
      return accPrice + currPrice * quantity; // Multiply current price by its quantity before adding to total
    }, 0); // Start accumulation from 0, ensuring it's treated as a number
    setTotalPrice(newTotal);
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
    // setMouseIsOver(false);
  };

  // USE EFFECT
  useEffect(() => {
    setSubtasks(task.subtasks);
  }, [task.subtasks]);

  useEffect(() => {
    setTotalPrice(task.totalPrice);
    if (successAdd) {
      calculateTotalPrice();
    }
  }, [successAdd]);

  if (isDragging) {
    return (
      <Box
        ref={setNodeRef}
        style={style}
        opacity="0.3"
        bg={buttonBlue}
        p="2.5"
        h="60px"
        minH="60px"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        rounded="xl"
        border="0px"
        borderColor={`1px solid ${newBorder}`}
        cursor="grab"
        position="relative"
      />
    );
  }

  if (editMode) {
    return (
      <Flex
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        bg={backgroundLight}
        p="2.5"
        h="60px"
        minH="60px"
        alignItems="center"
        justifyContent="flex-start"
        rounded="xl"
        _hover={{ ring: "2", ringInset: "inset", ringColor: "rose.500" }}
        cursor="grab"
        position="relative"
      >
        <Textarea
          h="90%"
          w="full"
          resize="none"
          border="none"
          rounded="md"
          bg="transparent"
          color={textPrimary}
          focusBorderColor="none"
          placeholder="Task content here"
          onBlur={toggleEditMode}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              toggleEditMode();
            }
          }}
          onChange={(e) => updateTask(task._id, e.target.value)}
          value={task.title}
          autoFocus
        />
      </Flex>
    );
  }
  // LOGS

  // console.log("task", task);
  // console.log("total price", totalPrice);

  return (
    <Accordion allowToggle>
      <AccordionItem
        key={task._id}
        border="none"
        bg={white}
        borderRadius="0.875rem"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              w="100%"
              ref={setNodeRef}
              style={style}
              {...attributes}
              {...listeners}
              bg={white}
              border={`0px solid ${newBorder}`}
              p="1rem 1rem 1rem 1rem"
              alignItems="center"
              justifyContent="space-between"
              rounded="xl"
              onMouseEnter={() => setMouseIsOver(true)}
              onMouseLeave={() => setMouseIsOver(false)}
              _hover={{
                bg: "white",
              }}
            >
              <Flex w="100%" align="center" p="0rem">
                <Icon
                  fontSize="0.75rem"
                  as={FaGrip}
                  color={textSecondary}
                  mr={3}
                />
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textSecondary}
                  noOfLines={1}
                  textAlign="left"
                >
                  {task.title}
                </Text>
              </Flex>
              {/* {mouseIsOver && (
                <IconButton
                  icon={<FaTrash />}
                  onClick={() => deleteTask(task._id)}
                  color={textSecondary}
                  bg="columnBackgroundColor"
                  p="0"
                  size="xs"
                  mr={2}
                  rounded="md"
                  opacity="0.6"
                  _hover={{ opacity: "1" }}
                />
              )} */}
              {totalPrice > 0 && (
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textSecondary}
                  textAlign="left"
                  whiteSpace="nowrap"
                  mr={4}
                >
                  {totalPrice} €
                </Text>
              )}
              <Icon
                fontSize="0.65rem"
                as={FaChevronRight}
                color={textSecondary}
                transform={isExpanded ? "rotate(90deg)" : "rotate(0deg)"}
                transition="transform 0.2s ease-in-out"
              />
            </AccordionButton>
            <AccordionPanel p="0rem 1rem 1rem" bg={white} borderRadius="1rem">
              <Box mb={4}>
                {subtasks.map((subtask) => (
                  <Box
                    key={subtask._id}
                    // onClick={() => setEditingSubtaskId(subtask._id)}
                  >
                    <Flex
                      key={subtask._id}
                      align="center"
                      justify="space-between"
                      role="group"
                      position="relative"
                    >
                      <Flex
                        align="center"
                        justify="space-between"
                        bg={backgroundLight}
                        border={`1px solid ${newBorder}`}
                        mb={1}
                        h="2.5rem"
                        p="0.5rem 1rem"
                        borderRadius="0.875rem"
                        w="100%"
                        transition="all .3s ease"
                        _groupHover={{
                          width: "90%",
                        }}
                      >
                        <Text fontSize="sm" color={textSecondary}>
                          {subtask.title}
                        </Text>
                        <Text fontSize="sm" color={textSecondary}>
                          {subtask.price * subtask.quantity} €
                        </Text>
                      </Flex>
                      <Box
                        position="absolute"
                        w="8%"
                        right="-10px"
                        top="50%"
                        transform="translateY(-50%)"
                        opacity="0"
                        _groupHover={{ opacity: "1", right: "0px" }} // Using _groupHover for hover effect
                        transition="all .3s ease"
                      >
                        <IconButton
                          bg={buttonRed}
                          color={buttonRedText}
                          icon={<TbTrash />}
                          onClick={() => handleDeleteSubtask(subtask._id)}
                          aria-label="Delete subtask"
                          size="sm"
                          borderRadius="0.875rem"
                        />
                      </Box>
                    </Flex>

                    {editingSubtaskId === subtask._id && (
                      <EditSubtask subtask={subtask} />
                    )}
                  </Box>
                ))}

                {/* {task.subtasks.length === 0 && (
                  <>
                    {!isOpen && (
                      <Text
                        fontSize="sm"
                        color={textSecondary}
                        textAlign="center"
                      >
                        {t("course.noSubtask")}
                      </Text>
                    )}
                  </>
                )} */}
              </Box>
              <AddSubtask
                isOpen={isOpen}
                onToggle={onToggle}
                maxAttendees={maxAttendees}
                courseId={courseId}
                taskId={task._id}
                setSubtasks={setSubtasks}
                onSubtaskAdded={onSubtaskAdded}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
              />
              {/* {renderAddSubtaskUI()} */}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export default TaskCard;
