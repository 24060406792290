import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  HStack,
  Text,
  Flex,
  Image,
  Icon,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import SearchBar from "../shared/SearchBar";
import dayjs from "dayjs";
import "dayjs/locale/lt"; // Import the Lithuanian locale
import "dayjs/locale/en";
import "dayjs/locale/ko"; // Import the Korean locale
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import { icons } from "../../utils/icons";
import { primary, textSecondary, white, textPrimary } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import LanguageButton from "../../components/buttons/LanguageButton";
import logonew2 from "../../assets/icons/logonew2.svg";
import { FaCaretDown } from "react-icons/fa";

export const Header = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  console.log("user", userInfo);

  return (
    <>
      <Wrapper>
        <Container>
          <Flex align="center">
            <Box mr="5.5rem">
              <NavLink to="/admin/dashboard">
                <HStack gap={0} alignItems="flex-end">
                  <Image src={logonew2} />
                  <Text
                    ml={2}
                    lineHeight="1"
                    color={textPrimary}
                    fontWeight="600"
                  >
                    Admin
                  </Text>
                </HStack>
              </NavLink>
            </Box>

            <SearchBar.SearchBarHeader />
          </Flex>

          <HStack>
            <HStack ml={8}>
              <LanguageButton
                currentLanguage={currentLanguage}
                languages={["en", "lt", "ko"]}
                onChangeLanguage={handleChangeLanguage}
                onMenuClick={handleMenuClick}
              />
            </HStack>
            <Menu onOpen={handleMenuOpen} onClose={handleMenuClose}>
              <MenuButton borderRadius="full" role="group">
                {userInfo && (
                  <User>
                    <Text
                      color={textSecondary}
                      fontWeight="600"
                      fontSize="sm"
                      mr={4}
                    >
                      {userInfo.firstName + " " + userInfo.lastName[0] + "."}
                    </Text>
                    <Avatar size="sm" src={icons.user} />
                    <Icon
                      as={FaCaretDown}
                      h="0.75rem"
                      w={isOpen ? "0.75rem" : "0rem"}
                      ml={1}
                      color={textSecondary}
                      opacity="0.5"
                      transition="all 0.2s ease"
                      transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                      _groupHover={{
                        opacity: 1,
                        width: "0.75rem",
                        transition: "all 0.2s ease",
                      }}
                    />
                  </User>
                )}
              </MenuButton>

              <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
                {userInfo ? (
                  <>
                    <Link to="/account">
                      <MenuItem p="1rem 1rem" isDisabled>
                        <Text color={textSecondary}>{t("common.profile")}</Text>
                      </MenuItem>
                    </Link>
                    <MenuItem p="1rem 1rem" onClick={logoutHandler}>
                      <Text color={textSecondary}>{t("common.logout")}</Text>
                    </MenuItem>
                  </>
                ) : (
                  <Link to="/login">
                    <MenuItem p="1rem 1rem">
                      <Text color={textSecondary}>
                        <i className="fas fa-user"></i> {t("common.signin")}
                      </Text>
                    </MenuItem>
                  </Link>
                )}
              </MenuList>
            </Menu>
          </HStack>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  height: 80px;
  border-bottom: 1px solid #eef0f4;
  top: 0;
  right: 0;
  background: ${white};
  z-index: 900;

  @media (max-width: 768px) {
    left: 0;
    width: 100%;
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;
