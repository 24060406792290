import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Flex, Text, Image, Button } from "@chakra-ui/react";
import Select, { components } from "react-select"; // Import components from react-select
import { useSelector, useDispatch } from "react-redux";
import { listCategories } from "../../actions/categoryActions";
import { white, textSecondary, newBorder } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import OptionsOutsideSelect from "./OptionsOutsideSelect";

const MultiCategorySelect = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  placeholderText,
  title,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    dispatch(listCategories());

    console.log("Categories select loaded", categories);
  }, [dispatch]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      console.log("categories", categories);

      console.log("currentOptions", currentOptions);

      // Transform currentOptions to match options structure
      const transformedCurrentOptions = currentOptions.map((option) => ({
        value: option._id,
        label: option.name,
        image: option.image,
      }));

      console.log("transformedCurrentOptions", transformedCurrentOptions);
      console.log("category Options 3", categoryOptions); // Add this line

      // Create options for active speakers, excluding transformedCurrentOptions
      const options = categories
        .filter(
          (s) =>
            !transformedCurrentOptions.some(
              (current) => current.value === s._id
            )
        )
        .map((s) => ({
          value: s._id,
          label: s.name,
          image: s.image,
        }));

      setCategoryOptions([...options]);
    }
  }, [categories, currentOptions]);

  console.log("category Options 1", categoryOptions); // Add this line

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={categoryOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
      </div>
    </>
  );
};

export default MultiCategorySelect;
