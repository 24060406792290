import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../../../utils/breakpoints";
import { useMediaQuery } from "react-responsive";
import { images } from "../../../utils/images";
import Feature from "../components/Feature";
import FeatureAlt from "../components/FeatureAlt";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import feature1 from "../../../assets/images/feature1.svg";
import feature2 from "../../../assets/images/feature2.png";
import feature3 from "../../../assets/images/feature3.png";
import { white } from "../../../utils/colors";

const Features = () => {
  let { t } = useTranslation("");
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Flex
      id="features"
      w="100%"
      p={["0 1.5rem 0rem", "0 1.5rem 0rem", "0 0 8rem"]}
      position="relative"
      bg={white}
      overflow="hidden"
    >
      <Flex
        w="100%"
        direction="column"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
      >
        {isMobile ? null : <Gradient src={images.primary_gradient} />}
        <Feature
          title={t("landingMegagen.feature1Title")}
          subtitle={t("landingMegagen.feature1Subtitle")}
          text={t("landingMegagen.feature1Text")}
          image={feature1}
          buttonText={t("landingMegagen.feature1Button")}
          buttonHandler={() => {
            navigate("/implant-fail-form");
          }}
        />

        <FeatureAlt
          isReverse
          title={t("landingMegagen.feature2Title")}
          subtitle={t("landingMegagen.feature2Subtitle")}
          text={t("landingMegagen.feature2Text")}
          image={feature2}
          buttonText={t("landingMegagen.feature2Button")}
          buttonHandler={() => {
            navigate("/events");
          }}
        />
        <Feature
          title={t("landingMegagen.feature3Title")}
          subtitle={t("landingMegagen.feature3Subtitle")}
          text={t("landingMegagen.feature3Text")}
          image={feature3}
          buttonText={t("landingMegagen.feature3Button")}
        />
        {isMobile ? null : <Gradient src={images.primary_gradient} />}
      </Flex>
    </Flex>
  );
};

const Gradient = styled.img`
  width: 39%;
  object-fit: cover;
  position: absolute;
  top: 29%;
  right: -5%;
  z-index: 1;
`;

export default Features;
