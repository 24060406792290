import React from "react";
import styled from "@emotion/styled";
import {
  Flex,
  Text,
  Avatar,
  Box,
  Icon,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  textPrimary,
  textSecondary,
  white,
  newBorder,
  primary,
  buttonBlue,
  buttonBlueText,
} from "../../../../../utils/colors";
import { TbSquareRoundedX } from "react-icons/tb";
import { useTranslation } from "react-i18next";

const CategoryList = ({ categories, removeCategoryHandler }) => {
  const t = useTranslation();

  return (
    <List
      mt="-0.5rem"
      // p="0.5rem 1rem"
      border={`0px dashed ${newBorder}`}
      borderRadius="0rem"
      overflowY="auto"
      p={0}
    >
      {categories.map((category, index) => (
        <ListItemas
          key={category._id}
          p="0.5rem 1rem"
          h="3rem"
          border={`1px solid ${newBorder}`}
          m="0.5rem 0"
          display="flex"
          alignItems="center"
          // bg={backgroundLight}
          borderRadius="0.75rem"
        >
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <HStack gap={0}>
              <Avatar
                src={category.image}
                name={category.name}
                fontSize="xs"
                h="1.75rem"
                w="1.75rem"
                mr={2}
                bg={buttonBlue}
                color={buttonBlueText}
              />
              <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                {category.name}
              </Text>
              {/* <Text
                pl={2}
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(category.startDate).format("DD MMM")} -{" "}
                {dayjs(category.endDate).format("DD MMM")}
              </Text> */}
            </HStack>

            <HStack gap={0}>
              <Icon
                as={TbSquareRoundedX}
                mr="0.5rem"
                color={textSecondary}
                transition="all 0.2s"
                onClick={() => removeCategoryHandler(category._id)}
                _hover={{
                  color: textPrimary,
                  cursor: "pointer",
                  transform: "scale(1.2)",
                }}
              />
            </HStack>
          </Flex>
        </ListItemas>
      ))}
    </List>
  );
};

const ListItemas = styled(ListItem)`
  &.newly-added-attendee {
    /* background-color: #c1e6c8; */

    border: 1px solid #c1e6c8;
  }
`;

export default CategoryList;
