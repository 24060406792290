import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text, Stack, Image } from "@chakra-ui/react";
import {
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  buttonBlue,
  white,
} from "../../../../utils/colors";
import { getTopReportsByFixture } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";
import fixture from "../../../../assets/images/fixture.png";
import noServices from "../../../../assets/images/empty-box.png";

const TopReportsFixture = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // REDUX

  const dashboardTopReportsByFixture = useSelector(
    (state) => state.dashboardTopReportsByFixture
  );
  const { loading, error, reports } = dashboardTopReportsByFixture;

  // USE EFFECT

  useEffect(() => {
    dispatch(getTopReportsByFixture());
  }, [dispatch]);

  // LOGS

  // console.log("reports", reports);

  if (loading) {
    return (
      <Flex align="center" justify="center" h="100%" w="100%">
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  console.log("reports", reports);

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Stack gap="0">
          <Text color={textPrimary} fontSize="sm" fontWeight="500">
            {t("dashboard.topReportsByFixtureTitle")}
            <Text as="span" fontWeight="500" color={textSecondary} ml={1}>
              {reports.length}
            </Text>
          </Text>

          <Text color={textSecondary} fontSize="xs" fontWeight="500">
            {t("dashboard.topReportsByFixtureSubtitle")}
          </Text>
        </Stack>
      </Flex>

      <Flex direction="column" w="100%" p="1rem 0 0">
        {reports.map((report, index) => (
          <Flex
            key={index}
            align="center"
            justify="space-between"
            p="0.75rem 0rem"
            bg={white}
            transition="all 0.2s"
            position="relative"
            borderBottom={`1px solid ${newBorder}`}
            _hover={{
              bg: white,
              color: buttonBlue,

              px: "0.75rem",
              borderBottom: `1px solid ${newBorder}`,
              borderRadius: "0rem",
            }}
            _last={{
              borderBottom: `1px solid ${white}`,
            }}
          >
            <Flex align="center">
              <Flex
                align="center"
                justify="center"
                w="1.75rem"
                h="1.75rem"
                borderRadius="10rem"
                bg={buttonBlue}
                mr={1.5}
              >
                <Image src={fixture} alt="fixture" h="1rem" />
              </Flex>

              <Text
                fontSize="sm"
                color={textPrimary}
                fontWeight="500"
                isTruncated
                lineHeight={1}
                maxW="100px"
              >
                {t(`implants.${report.fixture}`)}
              </Text>
            </Flex>
            <Flex align="center">
              <Text color={textSecondary} fontSize="sm" fontWeight="500">
                {report.count}
              </Text>
            </Flex>
          </Flex>
        ))}

        {reports.length === 0 && (
          <Flex align="center" justify="center" w="100%" p="0rem 0 1rem">
            <Flex justify="center" align="center" direction="column" p="3rem 0">
              <Image h="3rem" w="auto" m="0 auto" src={noServices} />
              <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                {t("common.noData")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TopReportsFixture;
