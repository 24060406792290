import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlClientType = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  const { t } = useTranslation();

  const options = [
    { value: "client", label: t("landingMegagen.registerTypeClinicAlt") },
    { value: "dentist", label: t("landingMegagen.registerTypeDentistAlt") },
    { value: "lab", label: t("landingMegagen.registerTypeLabAlt") },
  ];

  console.log("value:", value); // This should log the value of the selected option ("client", "dentist", or "lab
  const selectedOption = options.find((option) => option.value === value);
  console.log("Selected option:", selectedOption); // This should log the correct object from options

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={options.find((option) => option.value === value)}
        onChange={onChange}
        options={options}
        placeholder={t("placeholder.selectClientType")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlClientType;
