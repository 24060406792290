import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  white,
  primary,
} from "../../utils/colors";
import { updateCourseImage } from "../../actions/courseActions";
import { updateSpeakerImage } from "../../actions/speakerActions";
import { updateAttendeeImage } from "../../actions/attendeeActions";
import MiniNoItems from "../partials/MiniNoItems";
import { TbPlus } from "react-icons/tb";
import ImageUpload from "../input/ImageUpload";
import { useTranslation } from "react-i18next";
import ToastComponent from "../partials/ToastComponent";
import { COURSE_UPDATE_IMAGE_RESET } from "../../constants/courseConstants";
import { SPEAKER_IMAGE_UPDATE_RESET } from "../../constants/speakerConstants";
import { ATTENDEE_UPDATE_IMAGE_RESET } from "../../constants/attendeeConstants";
import { shade } from "polished";

const EditCourseImage = ({ id, currentImage, close, options }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const [image, setImage] = useState(currentImage);

  // REDUX

  const courseUpdateImage = useSelector((state) => state.courseUpdateImage);
  const { success: successCourse } = courseUpdateImage;

  const speakerUpdateImage = useSelector((state) => state.speakerUpdateImage);
  const { success: successSpeaker } = speakerUpdateImage;

  const attendeeUpdateImage = useSelector((state) => state.attendeeUpdateImage);
  const { success: successAttendee } = attendeeUpdateImage;

  const submitHandler = (e) => {
    e.preventDefault();
    if (options === "course") {
      dispatch(updateCourseImage(id, image));
      console.log("course", id, image);
    } else if (options === "speaker") {
      dispatch(updateSpeakerImage(id, image));
      console.log("speaker", id, image);
    } else if (options === "attendee") {
      dispatch(updateAttendeeImage(id, image));
      console.log("attendee", id, image);
    }
  };

  useEffect(() => {
    if (currentImage) {
      setImage(image);
    } else {
      setImage(currentImage);
    }
  }, [currentImage]);

  useEffect(() => {
    if (successCourse) {
      close();
      dispatch({ type: COURSE_UPDATE_IMAGE_RESET });
    } else if (successSpeaker) {
      close();
      dispatch({ type: SPEAKER_IMAGE_UPDATE_RESET });
    } else if (successAttendee) {
      close();
      dispatch({ type: ATTENDEE_UPDATE_IMAGE_RESET });
    }
  }, [successCourse, successSpeaker, successAttendee]);

  console.log("id", id);
  console.log("image", image);

  return (
    <>
      <form onSubmit={submitHandler}>
        <FormControl mb={6}>
          <FormLabel fontSize="sm">{t("common.image")}</FormLabel>
          <ImageUpload image={image} setImage={setImage} />
        </FormControl>

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="space-between">
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              //   isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.3, primary),
              }}
              ml={4}
              onlick={submitHandler}
            >
              {t("course.updateCourseButtonLabel")}
            </Button>

            <Button
              minWidth="8rem"
              ml={4}
              bg={backgroundLight}
              border={`1px solid ${newBorder}`}
              size="lg"
              color={textSecondary}
              fontSize="md"
              fontWeight="500"
              borderRadius="10rem"
              //   onClick={() => setActiveTab(activeTab + 1)}
              //   isDisabled={isContinueDisabled}
              _hover={{
                bg: shade(0.1, backgroundLight),
              }}
            >
              {t("common.continue")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default EditCourseImage;
