import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPublicCourseDetails } from "../../../../actions/courseActions";
import { Helmet } from "react-helmet";
import {
  Flex,
  Heading,
  Text,
  Icon,
  Image,
  Button,
  Tooltip,
  HStack,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  primary,
  secondary,
  backgroundLight,
  buttonRed,
  buttonRedText,
  newBorder,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import Header from "../../../landing/layout/Header";
import {
  TbCalendarEvent,
  TbCalendarTime,
  TbMapPin,
  TbMapPin2,
  TbUserStar,
  TbWallet,
  TbInfoCircleFilled,
  TbCalendarPlus,
  TbCalendarCheck,
  TbCalendarCancel,
  TbPhotoCancel,
} from "react-icons/tb";
import dayjs from "dayjs";
import { shade } from "polished";
import DOMPurify from "dompurify";
import Cta from "../../../landing/sections/Cta";
import Footer from "../../../landing/layout/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import EventCheckout from "../partials/EventCheckout";

const sanitizeHtml = (html) => {
  return { __html: DOMPurify.sanitize(html) };
};

const CourseSinglePublic = () => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ID
  const { id } = useParams();

  // STATE
  const [isEventCheckoutOpen, setIsEventCheckoutOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const coursePublicDetails = useSelector((state) => state.coursePublicDetails);

  const {
    loading: loadingCourse,
    error: errorCourse,
    course,
  } = coursePublicDetails;

  // HANDLERS
  const openCheckout = (course) => {
    setSelectedCourse(course); // Set the selected course
    setIsEventCheckoutOpen(true);
  };
  const closeCheckout = () => {
    setIsEventCheckoutOpen(false);
    setSelectedCourse(null);
  };

  // USE EFFECT

  // USE EFFECT
  useEffect(() => {
    dispatch(listPublicCourseDetails(id));
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  console.log("course", course);

  const isSameDay = dayjs(course.startDate).isSame(course.endDate, "day");

  const sortedPrices =
    course.prices && course.prices.length > 0
      ? course.prices.sort((a, b) => a.price - b.price)
      : [];
  const lowestPrice = sortedPrices.length > 0 ? sortedPrices[0].price : null;

  const isPastEvent = dayjs().isAfter(dayjs(course.startDate));

  const isStatusCancelled = course.status === "cancelled";

  const maximumCapacityReached =
    course.maximumAttendees === course.totalAttendees;

  console.log("course", course);
  console.log("isPastEvent", isPastEvent);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.eventsSingle")}</title>
        <meta name="description" content={t("pageDescription.eventsSingle")} />
      </Helmet>

      {isEventCheckoutOpen && (
        <EventCheckout
          isOpen={isEventCheckoutOpen}
          onClose={closeCheckout}
          course={selectedCourse} // Pass the selected course to EventCheckout
        />
      )}

      <Header />

      <Flex
        w="100%"
        p={["0px 0 0", "0px 0 0", "calc(100px + 3rem) 2rem 0"]}
        maxW={["100%", "100%", "1200px"]}
        m="0rem auto 0"
        position={["fixed", "fixed", "relative"]}
      >
        <Link to="/events">
          <Button
            variant="link"
            size="lg"
            fontSize="sm"
            borderRadius="10rem"
            fontWeight="500"
            role="group"
            color={textSecondary}
            p="0.5rem 1rem"
            left="-1rem"
            _hover={{
              bg: backgroundLight,
              color: textPrimary,
            }}
          >
            <Icon
              as={FaArrowLeft}
              fontSize="0.75rem"
              mr="0.5rem"
              _groupHover={{
                color: textPrimary,
              }}
            />
            {t("publicCourses.backToEvents")}
          </Button>
        </Link>
      </Flex>

      <Flex
        w="100%"
        h={["100%", "100%", "100%"]}
        minH={["100vh", "100%", "100%"]}
        bg={white}
        overflow="hidden"
        position={["relative", "relative", "relative"]}
        direction={["column", "column", "column"]}
        p={["80px 0 0", "80px 0 0", "initial"]}
      >
        {!loadingCourse ? (
          <Flex
            bg={white}
            w="100%"
            maxW={["100%", "100%", "1000px"]}
            m={["0px auto 0", "80px auto", "0px auto"]}
            borderRadius={["0rem", "1rem", "1rem"]}
            p={["3rem 1.5rem 0", "3rem 1.5rem 0", "0rem 2rem"]}
            align="center"
            direction="column"
            overflowX="hidden"
          >
            <Text
              fontSize="sm"
              color={primary}
              fontWeight="600"
              textAlign="center"
              p="0 0 1.5rem"
              textTransform="uppercase"
            >
              {t("publicCourses.event")}
            </Text>
            <Heading
              fontSize={["2rem", "2rem", "2.5rem"]}
              color={textPrimary}
              fontWeight="700"
              textAlign="center"
              p="0 0 1.5rem"
            >
              {course.title}
            </Heading>

            <Flex
              w="100%"
              p={["1.5rem 0rem", "1.5rem 0rem", "0 2rem"]}
              direction="column"
              m={["0rem 0 1.5rem", "0rem 0 1.5rem", "3rem auto 1.5rem"]}
              align="center"
              justify="center"
              maxW={["100%", "100%", "1000px"]}
            >
              <Flex
                key={course._id}
                border={`0px solid ${newBorder}`}
                borderRadius="0rem"
                align="center"
                justify="space-between"
                p="0rem 0rem"
                w="100%"
                m="0 0 3rem"
                onClick={() => navigate(`/events/${course._id}`)}
                direction={["column", "column", "row"]}
              >
                <Flex
                  h={["100%", "100%", "330px"]}
                  w={["100%", "100%", "330px"]}
                  minH="330px"
                >
                  {course.image ? (
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      borderRadius="1rem"
                      src={course.image}
                      alt={`${course.title}`}
                    />
                  ) : (
                    <Flex
                      h="100%"
                      w="100%"
                      align="center"
                      justify="center"
                      bg={newBorder}
                      borderRadius="1rem"
                    >
                      <Icon
                        as={TbPhotoCancel}
                        color={textSecondary}
                        fontSize="2rem"
                      />
                    </Flex>
                  )}
                </Flex>

                <Flex flex="1" w="100%" pl={["0rem", "0rem", "3rem"]}>
                  <Flex
                    direction="column"
                    w={["100%", "100%", "initial"]}
                    p={["1.5rem 0", "1.5rem 0", "initial"]}
                  >
                    <Text fontWeight="bold" fontSize="1.25rem">
                      {course.title}
                    </Text>
                    <HStack align="center" mt="2">
                      <Icon
                        as={TbCalendarEvent}
                        color={textSecondary}
                        fontSize="1rem"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {t("publicCourses.date")}:{" "}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {isSameDay
                          ? dayjs(course.startDate).format("L")
                          : `${dayjs(course.startDate).format("L")} - ${dayjs(
                              course.endDate
                            ).format("L")}`}
                      </Text>
                    </HStack>

                    <HStack align="center" mt="2">
                      <Icon
                        as={TbCalendarTime}
                        color={textSecondary}
                        fontSize="1rem"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {t("publicCourses.startTime")}:{" "}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {isSameDay
                          ? `${dayjs(course.startDate).format("LT")}`
                          : `${dayjs(course.startDate).format("LT")}`}
                      </Text>
                    </HStack>

                    <HStack align="center" mt="2">
                      <Icon
                        as={TbMapPin}
                        color={textSecondary}
                        fontSize="1rem"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {t("publicCourses.location")}:{" "}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                        maxW={["80%", "80%", "initial"]}
                        isTruncated
                      >
                        {course.location}
                      </Text>
                    </HStack>

                    <HStack align="center" mt="2">
                      <Icon
                        as={TbMapPin2}
                        color={textSecondary}
                        fontSize="1rem"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {t("publicCourses.address")}:{" "}
                      </Text>
                      {course.address && course.address.length > 0 ? (
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                          maxW={["80%", "80%", "initial"]}
                          isTruncated
                        >
                          {course.address[0].street && course.address[0].street}
                          , {course.address[0].city && course.address[0].city},{" "}
                          {course.address[0].zip && course.address[0].zip},{" "}
                          {course.address[0].country &&
                            course.address[0].country}
                        </Text>
                      ) : (
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          -
                        </Text>
                      )}
                    </HStack>

                    <HStack align="center" mt="2">
                      <Icon as={TbUserStar} color={textSecondary} />
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                      >
                        {t("course.speaker")}:{" "}
                      </Text>

                      {course.speakers && course.speakers.length > 0 ? (
                        course.speakers.length === 1 ? (
                          <>
                            <Text
                              fontSize="sm"
                              color={textSecondary}
                              fontWeight="500"
                            >
                              {course.speakers[0].firstName &&
                                course.speakers[0].firstName}{" "}
                              {course.speakers[0].lastName &&
                                course.speakers[0].lastName}
                            </Text>
                          </>
                        ) : (
                          course.speakers.map((speaker, index) => (
                            <Tooltip
                              label={speaker.firstName + speaker.lastName}
                              key={index}
                            >
                              <Avatar
                                size="xs"
                                bg={backgroundLight}
                                color={textSecondary}
                                src={speaker.image || ""}
                                name={speaker.lastName}
                                border={`1px solid ${newBorder}`}
                                ml={index === 0 ? 0 : "-0.75rem"} // Adjust margin for the first avatar
                              />
                            </Tooltip>
                          ))
                        )
                      ) : (
                        <Text
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="500"
                        >
                          -
                        </Text>
                      )}
                    </HStack>

                    <HStack align="center" mt="2">
                      <Icon
                        as={TbWallet}
                        color={textSecondary}
                        fontSize="1rem"
                      />

                      {!course.free ? (
                        <Tooltip
                          color={white}
                          p="0.5rem 1rem"
                          borderRadius="1rem"
                          label={
                            <Flex direction="column">
                              {sortedPrices.map((p) => (
                                <Flex align="center" key={p._id}>
                                  <Text
                                    key={p._id}
                                    fontSize="sm"
                                    fontWeight="400"
                                    color={shade(0.2, white)}
                                  >
                                    {p.professionGroup &&
                                      p.professionGroup.name &&
                                      p.professionGroup.name}
                                    :
                                    <Text
                                      as="span"
                                      opacity="1"
                                      ml={1}
                                      fontWeight="500"
                                      color={white}
                                    >
                                      {p.price} €
                                    </Text>
                                  </Text>
                                </Flex>
                              ))}
                            </Flex>
                          }
                          placement="top"
                        >
                          <Flex align="center">
                            {lowestPrice === 0 ? (
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                mr={1}
                              >
                                {t("common.priceDiffers")}
                              </Text>
                            ) : (
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color={textSecondary}
                                mr={1}
                              >
                                {t("common.priceFrom")}{" "}
                                {lowestPrice
                                  ? `${lowestPrice} 
                                €
                                `
                                  : t("common.free")}
                              </Text>
                            )}
                            <Icon
                              as={TbInfoCircleFilled}
                              color={textSecondary}
                              position="relative"
                              top="-1px"
                            />
                          </Flex>
                        </Tooltip>
                      ) : (
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {course.free && t("common.free")}
                        </Text>
                      )}
                    </HStack>

                    <Flex w="100%">
                      {isStatusCancelled && (
                        <Button
                          m="1.5rem 0 0"
                          bg={buttonRed}
                          color={buttonRedText}
                          size="lg"
                          fontSize="sm"
                          fontWeight="500"
                          borderRadius="10rem"
                          // minW="280px"
                          _hover={{
                            bg: shade(0.1, buttonRed),
                          }}
                          role="group"
                          onClick={() => openCheckout(course)}
                          minW="12rem"
                          isDisabled
                        >
                          {/* <Flex
                              align="center"
                              justify="center"
                              w="1.75rem"
                              h="1.75rem"
                              borderRadius="50%"
                              bg="white"
                              mr="0.5rem"
                            >
                              <Icon
                                as={TbCalendarPlus}
                                color={primary}
                                fontSize="1rem"
                                _groupHover={{
                                  color: secondary,
                                }}
                              />
                            </Flex> */}
                          {t("status.cancelled")}
                        </Button>
                      )}

                      {!isStatusCancelled && (
                        <Flex w={["100%", "100%", "initial"]}>
                          {!isPastEvent ? (
                            <Button
                              m="1.5rem 0 0"
                              bg={primary}
                              color={white}
                              size="lg"
                              fontSize="sm"
                              borderRadius="10rem"
                              onClick={() => openCheckout(course)}
                              minW={["100%", "100%", "280px"]}
                              w={["100%", "100%", "initial"]}
                              role="group"
                              _hover={{
                                bg: secondary,
                              }}
                            >
                              <Flex
                                align="center"
                                justify="center"
                                w="1.75rem"
                                h="1.75rem"
                                borderRadius="50%"
                                bg="white"
                                mr="1rem"
                              >
                                <Icon
                                  as={TbCalendarPlus}
                                  color={primary}
                                  fontSize="1rem"
                                  transition="0.2s all"
                                  _groupHover={{
                                    color: secondary,
                                  }}
                                />
                              </Flex>
                              {t("publicCourses.register")}
                            </Button>
                          ) : (
                            <Button
                              m="1.5rem 0 0"
                              bg="#C6EE51"
                              color={textPrimary}
                              size="lg"
                              fontSize="sm"
                              borderRadius="10rem"
                              onClick={() => alert("Registration clicked")}
                              minW="12rem"
                              _hover={{
                                bg: shade(0.1, "#C6EE51"),
                              }}
                              isDisabled={true}
                            >
                              <Flex
                                align="center"
                                justify="center"
                                w="1.75rem"
                                h="1.75rem"
                                borderRadius="50%"
                                bg="white"
                                mr="1rem"
                              >
                                <Icon
                                  as={TbCalendarCheck}
                                  color={primary}
                                  fontSize="1rem"
                                />
                              </Flex>
                              {t("publicCourses.registerPast")}
                            </Button>
                          )}
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                w="100%"
                direction="column"
                border={`0px solid ${newBorder}`}
                m="1rem 0 0"
              >
                <Flex w="100%" borderBottom={`1px solid ${newBorder}`}>
                  <Heading
                    fontSize={["1.25rem", "1.25rem", "1.5rem"]}
                    color={textPrimary}
                    fontWeight="600"
                    textAlign="center"
                    p="0 0 1.5rem"
                  >
                    {t("publicCourses.courseDescription")}
                  </Heading>
                </Flex>
                <Flex w="100%" m="2rem 0 0">
                  <div
                    dangerouslySetInnerHTML={sanitizeHtml(course.description)}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex
            bg={white}
            w="100%"
            maxW="1000px"
            p="8rem 0"
            m="0 auto"
            align="center"
            direction="column"
            overflowX="hidden"
          >
            <Spinner color={primary} size="xl" thickness="3px" />
          </Flex>
        )}
      </Flex>

      <Cta userInfo={userInfo} />

      <Footer />
    </>
  );
};

export default CourseSinglePublic;
