import React, { useEffect } from "react";
import styled from "@emotion/styled";
import {
  Select,
  Box,
  Stack,
  VStack,
  HStack,
  Text,
  Heading,
  Flex,
  Button,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { icons } from "../../../utils/icons";
import {
  primary,
  white,
  secondary,
  secondaryLight,
  textPrimary,
  textSecondary,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";

import {
  TbChevronsLeft,
  TbChevronLeft,
  TbChevronsRight,
  TbChevronRight,
} from "react-icons/tb";

const TablePagination = ({
  page,
  pages,
  nextPage,
  previousPage,
  gotoPage,
  pageSize,
  pageSizes,
  handlePageSizeChange,
}) => {
  const { t } = useTranslation();

  const canPreviousPage = page > 1;

  const canNextPage = page < pages;

  const goToLastPage = () => {
    gotoPage(pages);
  };

  useEffect(() => {
    console.log("Pagination component loaded");
  }, []);
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 1.5rem"
      >
        <Box maxW="70%">
          <Flex alignItems="center">
            <Text color={textSecondary} fontSize="sm" mr={4}>
              {t("common.show")}
            </Text>
            <Select
              variant="filled"
              onChange={handlePageSizeChange}
              value={pageSize}
              size="md"
              borderRadius="10rem"
              fontSize="0.875rem"
              fontWeight="500"
              maxW="5rem"
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <Text
              fontSize="sm"
              color={textSecondary}
              ml={4}
              whiteSpace="nowrap"
            >
              {t("common.itemsPerPage")}
            </Text>
          </Flex>
        </Box>
        <HStack spacing={2}>
          <IconButton
            onClick={() => gotoPage(1)}
            isDisabled={!canPreviousPage}
            size="md"
            borderRadius="10rem"
            fontSize="sm"
          >
            <Icon
              as={TbChevronsLeft}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canPreviousPage ? "auto" : "none",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => previousPage()}
            isDisabled={!canPreviousPage}
            size="md"
            borderRadius="10rem"
            fontSize="sm"
          >
            <Icon
              as={TbChevronLeft}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canPreviousPage ? "auto" : "none",
              }}
            />
          </IconButton>

          {page - 1 > 0 && (
            <Button
              onClick={() => gotoPage(page - 1)}
              isDisabled={!canPreviousPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
              fontSize="sm"
            >
              <Text fontSize="0.875rem">{page - 1}</Text>
            </Button>
          )}

          <Buttonas
            onClick={() => gotoPage(page)}
            className="active"
            size="md"
            borderRadius="10rem"
            color={textSecondary}
            fontSize="sm"
          >
            <Text fontSize="0.875rem">{page}</Text>
          </Buttonas>

          {page < pages && (
            <Buttonas
              onClick={() => gotoPage(page + 1)}
              isDisabled={!canNextPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
              fontSize="sm"
            >
              <Text fontSize="0.875rem">{page + 1}</Text>
            </Buttonas>
          )}

          {page + 2 < pages && (
            <Buttonas
              onClick={() => gotoPage(page + 2)}
              isDisabled={!canNextPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
              fontSize="sm"
            >
              {page + 2}
            </Buttonas>
          )}

          {page + 3 < pages && (
            <Buttonas
              onClick={() => gotoPage(page + 3)}
              isDisabled={!canNextPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
              fontSize="sm"
            >
              {page + 3}
            </Buttonas>
          )}

          <IconButton
            onClick={() => nextPage()}
            isDisabled={!canNextPage}
            size="md"
            borderRadius="10rem"
            fontSize="sm"
          >
            <Icon
              as={TbChevronRight}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canNextPage ? "auto" : "none",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => goToLastPage()}
            isDisabled={!canNextPage}
            size="md"
            borderRadius="10rem"
            fontSize="sm"
          >
            <Icon
              as={TbChevronsRight}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canNextPage ? "auto" : "none",
              }}
            />
          </IconButton>
        </HStack>
      </Flex>
    </>
  );
};

const Buttonas = styled(Button)`
  &.active {
    background: ${primary};
    color: ${white};
  }
`;

export default TablePagination;
