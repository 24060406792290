import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  textSecondary,
  white,
  primary,
  newBorder,
} from "../../../../../utils/colors";
import {
  listAttendeeCourses,
  addAttendeeCourse,
  removeAttendeeCourse,
  updateAttendeeCourseStatus,
} from "../../../../../actions/attendeeActions";
import Table from "../../../../../components/shared/Table";
import { columns as attendeeCoursesColumns } from "../../columns/AttendeeCoursesColumns";
import { icons } from "../../../../../utils/icons";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  ATTENDEE_ADD_COURSE_RESET,
  ATTENDEE_REMOVE_COURSE_RESET,
  ATTENDEE_DETAILS_RESET,
  ATTENDEE_COURSE_STATUS_UPDATE_RESET,
} from "../../../../../constants/attendeeConstants";
import { shade } from "polished";
import SelectCourseMain from "../../../../../components/select/SelectCourseMain";

const TabAttendeeCourses = () => {
  const attendeeId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const attendeeCoursesList = useSelector((state) => state.attendeeCoursesList);
  const { loading, error, courses } = attendeeCoursesList;

  const attendeeAddCourse = useSelector((state) => state.attendeeAddCourse);
  const { success: successAddCourse } = attendeeAddCourse;

  const attendeeRemoveCourse = useSelector(
    (state) => state.attendeeRemoveCourse
  );
  const { success: successRemoveCourse } = attendeeRemoveCourse;

  const attendeeCourseStatusUpdate = useSelector(
    (state) => state.attendeeCourseStatusUpdate
  );
  const { success: successCourseStatusUpdate } = attendeeCourseStatusUpdate;

  const [addCourseSidePanelOpen, setAddCourseSidePanelOpen] = useState(false);

  // STATE
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchCourseName, setSearchCourseName] = useState("");
  const [courseName] = useState("");

  const [customCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState([]);

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listAttendeeCourses(attendeeId, searchCourseName, sortField, sortOrder)
    );
  };

  // HANDLERS

  const addCourseHandleNew = (values) => {
    const courseIds = values.map((course) => course.value);
    const courseExists = courseIds.some((courseId) =>
      courses.some((a) => a._id === courseId)
    );
    if (courseExists) {
      console.log("COURSE EXISTS");
      toastComponent.showToast(t("toasts.courseError"), "error", 3000);
    } else {
      dispatch(addAttendeeCourse(attendeeId, courseIds));
      setUpdatedCourses([...updatedCourses, ...courseIds]);
      closeAddCourseSidePanel();
    }
  };

  const updateAttendeeCourseStatusHandler = (courseId, status) => {
    dispatch(updateAttendeeCourseStatus(attendeeId, courseId, status));
  };

  const removeCourseHandler = (courseId) => {
    dispatch(removeAttendeeCourse(attendeeId, courseId));
  };

  useEffect(() => {
    if (successAddCourse) {
      toastComponent.showToast(t("toasts.courseAdd"), "success", 3000);

      dispatch({ type: ATTENDEE_ADD_COURSE_RESET });
      dispatch({ type: ATTENDEE_DETAILS_RESET });
      dispatch(
        listAttendeeCourses(attendeeId, searchCourseName, sortField, sortOrder)
      );
    } else if (successRemoveCourse) {
      toastComponent.showToast(t("toasts.courseRemove"), "success", 3000);
      dispatch({ type: ATTENDEE_REMOVE_COURSE_RESET });
      dispatch({ type: ATTENDEE_DETAILS_RESET });
    } else if (successCourseStatusUpdate) {
      toastComponent.showToast(
        t("toasts.attendeeCourseStatusUpdate"),
        "success",
        3000
      );
      dispatch({ type: ATTENDEE_COURSE_STATUS_UPDATE_RESET });
      dispatch({ type: ATTENDEE_DETAILS_RESET });
      dispatch(
        listAttendeeCourses(attendeeId, searchCourseName, sortField, sortOrder)
      );
    } else {
      dispatch(
        listAttendeeCourses(attendeeId, searchCourseName, sortField, sortOrder)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    attendeeId,
    searchCourseName,
    successAddCourse,
    successRemoveCourse,
    successCourseStatusUpdate,
    sortField,
    sortOrder,
  ]);

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  const openAddCourseSidePanel = () => {
    setAddCourseSidePanelOpen(true);
  };

  const closeAddCourseSidePanel = () => {
    setAddCourseSidePanelOpen(false);
  };

  console.log("courses", courses);

  return (
    <>
      <SidePanel
        open={addCourseSidePanelOpen}
        close={closeAddCourseSidePanel}
        title={t("speaker.addCourse")}
      >
        <FormControl mt={4}>
          <>
            <FormLabel fontSize="sm">
              <Flex justify="space-between">{t("select.course")}</Flex>
            </FormLabel>

            <Flex w="100%" mb={10} flexDirection="column">
              <Box w="100%">
                {/* <MultiCourseSelect3
                  currentOptions={courses}
                  value={customCourses}
                  onChange={addCourseHandleNew}
                  isSelectedHidden={true}
                /> */}
                <SelectCourseMain
                  currentOptions={courses}
                  value={customCourses}
                  onChange={addCourseHandleNew}
                  title={t("speaker.addNewCourse")}
                  placeholderText={t("select.course")}
                  noteText={t("select.attendeeCourseNote")}
                />
              </Box>
            </Flex>
          </>
        </FormControl>
      </SidePanel>

      <Box>
        <Flex w="100%" direction="column" pb="2rem">
          <Table.Top
            title={t("attendee.tabCourses")}
            onChange={(e) => setSearchCourseName(e.target.value)}
            placeholder={t("course.search")}
            searchName={searchCourseName}
            isFixedTitle
            isSmallTitle
          >
            <Button
              leftIcon={<FilterIcon />}
              bg={white}
              color={textSecondary}
              border={`1px solid ${isFilterVisible ? primary : newBorder}`}
              variant="solid"
              size="lg"
              borderRadius="10.875rem"
              fontSize="md"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
              isDisabled
            >
              {t("common.filter")}
            </Button>
            <Button
              bg={primary}
              color={white}
              size="lg"
              borderRadius="10.875rem"
              fontSize="md"
              onClick={openAddCourseSidePanel}
              _hover={{ bg: shade(0.3, primary) }}
            >
              {t("speaker.addCourse")}
            </Button>
          </Table.Top>
          <Box borderRadius="0.75rem" overflow="hidden">
            <Table.Container
              loading={loading}
              data={courses}
              error={error}
              columns={attendeeCoursesColumns(
                navigate,
                translate,
                removeCourseHandler,
                updateAttendeeCourseStatusHandler,
                handleSortChange
              )}
              noItemsTitle={t("attendee.noCourses")}
              noItemsDescription={t("attendee.noCoursesText")}
              noItemsButtonLabel={t("attendee.noCoursesButtonLabel")}
              noItemsButtonHandler={openAddCourseSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
              className="attendee-courses-table"
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabAttendeeCourses;
