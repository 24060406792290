import axios from "axios";
import {
  PROFESSION_LIST_REQUEST,
  PROFESSION_LIST_SUCCESS,
  PROFESSION_LIST_FAIL,
  PROFESSION_DETAILS_REQUEST,
  PROFESSION_DETAILS_SUCCESS,
  PROFESSION_DETAILS_FAIL,
  PROFESSION_CREATE_REQUEST,
  PROFESSION_CREATE_SUCCESS,
  PROFESSION_CREATE_FAIL,
  PROFESSION_UPDATE_REQUEST,
  PROFESSION_UPDATE_SUCCESS,
  PROFESSION_UPDATE_FAIL,
  PROFESSION_DELETE_REQUEST,
  PROFESSION_DELETE_SUCCESS,
  PROFESSION_DELETE_FAIL,
  PROFESSION_UPDATE_STATUS_REQUEST,
  PROFESSION_UPDATE_STATUS_SUCCESS,
  PROFESSION_UPDATE_STATUS_FAIL,
} from "../constants/professionConstants";

import { logout } from "./userActions";

export const listProfessions =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    fetchAll = false,
    status = "",
    professionGroup = "",
    minPrice = "",
    maxPrice = "",
    minDuration = "",
    maxDuration = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PROFESSION_LIST_REQUEST });

      // Constructing the query string
      let query = `/api/professions?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;
      if (professionGroup && professionGroup !== "")
        query += `&professionGroup=${encodeURIComponent(professionGroup)}`;
      if (minPrice && minPrice !== "")
        query += `&minPrice=${encodeURIComponent(minPrice)}`;
      if (maxPrice && maxPrice !== "")
        query += `&maxPrice=${encodeURIComponent(maxPrice)}`;
      if (minDuration && minDuration !== "")
        query += `&minDuration=${encodeURIComponent(minDuration)}`;
      if (maxDuration && maxDuration !== "")
        query += `&maxDuration=${encodeURIComponent(maxDuration)}`;

      if (fetchAll) query += `&all=${fetchAll}`;

      console.log("Query:", query);

      const { data } = await axios.get(query);

      dispatch({
        type: PROFESSION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProfessionDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROFESSION_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/professions/${id}`, config);

    dispatch({
      type: PROFESSION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFESSION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProfession =
  (name, professionGroup, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSION_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/professions`,
        {
          name,
          professionGroup,
          status,
        },
        config
      );

      dispatch({
        type: PROFESSION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateProfession = (profession) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFESSION_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/professions/${profession._id}`,
      profession,
      config
    );

    dispatch({
      type: PROFESSION_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: PROFESSION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PROFESSION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const deleteProfession = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFESSION_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/professions/${id}`, config);

    dispatch({
      type: PROFESSION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROFESSION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProfessionStatus =
  (professionId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSION_UPDATE_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/professions/${professionId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: PROFESSION_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSION_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
