import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Box,
  Avatar,
  Flex,
  Tooltip,
  Progress,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  newBorder,
  buttonBlue,
  buttonBlueText,
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  backgroundLight,
  white,
  buttonYellowText,
} from "../../../../utils/colors";
import {
  FaRegCircleCheck,
  FaRegCircleXmark,
  FaRegCircleDot,
  FaCircleHalfStroke,
} from "react-icons/fa6";
import dayjs from "dayjs";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";

import StatusBadgeAlt from "../../../../components/badges/StatusBadgeAlt";

export const columns = (
  translate,
  updateAttendeeCoursePaidStatusHandler,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original.course;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("courseDate")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.courseDate")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "courseDate",
      disableSortBy: true,
      Cell: ({ row }) => {
        const rowOriginal = row.original;
        const { startDate, endDate } = rowOriginal.course;
        const currentYear = dayjs().year();

        if (
          startDate &&
          endDate &&
          dayjs(startDate).isValid() &&
          dayjs(endDate).isValid()
        ) {
          const startYear = dayjs(startDate).year();
          const endYear = dayjs(endDate).year();
          const displayYear =
            startYear !== currentYear || endYear !== currentYear
              ? ` ${startYear}`
              : "";

          if (dayjs(startDate).isSame(endDate, "day")) {
            return (
              <Stack gap={0} maxWidth="100px" w="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                  w="100px"
                >
                  {dayjs(startDate).format("DD MMMM")}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          } else {
            return (
              <Stack gap={0} maxWidth="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                >
                  {`${dayjs(startDate).format("DD MMM")} - ${dayjs(
                    endDate
                  ).format("DD MMM")}`}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          }
        } else if (startDate && dayjs(startDate).isValid()) {
          // Display only the startDate
          const startYear = dayjs(startDate).year();
          const displayYear = startYear !== currentYear ? ` ${startYear}` : "";

          return (
            <Stack gap={0}>
              <Text
                fontSize="13px"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(startDate).format("DD MMM")}
              </Text>
              <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                {displayYear}
              </Text>
            </Stack>
          );
        } else {
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              -
            </Text>
          );
        }
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("title")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.course")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "title",
      disableSortBy: true,
      Cell: ({ row }) => {
        const course = row.original.course;

        return (
          <HStack gap={2}>
            {course.image ? (
              <Image
                w="2.5rem"
                h="2.5rem"
                src={course.image}
                alt={course.title}
                borderRadius="0.875rem"
                objectFit="cover"
              />
            ) : (
              <Avatar
                w="2.5rem"
                h="2.5rem"
                name={course.title}
                borderRadius="0.875rem"
              />
            )}
            <Stack gap={0}>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                maxWidth="180px"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
              >
                {course.title}
              </Text>
              <Box>
                <StatusBadgeAlt
                  status={course.status}
                  options="course"
                  isSmall
                />
              </Box>
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("price")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnCoursePrice")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "price",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { price } = row.original.course;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            -
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { course, status, paidStatus } = row.original;

        const courseId = course._id;

        const handleUpdateStatus = (newStatus) => {
          updateAttendeeCoursePaidStatusHandler(courseId, newStatus);
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={paidStatus} // Use the converted string value here
              onUpdateStatus={handleUpdateStatus}
              options="paid" // Assuming you want to pass an array of options
            />
            <MoreButton
              id={courseId}
              deleteLabel={translate("course.removeAttendee")}
              viewId={courseId}
              viewLabel={translate("button.viewCourse")}
            />
          </HStack>
        );
      },
    },
  ];
};
