import React from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  backgroundLight,
  textPrimary,
  textSecondary,
  primary,
  white,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";

const NoUserAccount = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  return (
    <Flex w="100%" minH="100vh" bg={backgroundLight}>
      <Flex
        w="100%"
        maxW="1200px"
        p="0 0rem"
        direction="column"
        m="4rem auto 0"
        bg={backgroundLight}
        align="center"
      >
        <Heading
          fontSize="1.5rem"
          fontWeight="600"
          color={textPrimary}
          m="0 0 0rem"
        >
          {t("clientAccount.isEmployeeTitle")}
        </Heading>
        <Text
          color={textSecondary}
          fontSize="sm"
          fontWeight="500"
          textAlign="center"
          m="0.5rem 0 0"
        >
          {t("clientAccount.isEmployeeText")}
        </Text>
        <Button
          size="lg"
          fontSize="sm"
          borderRadius="10rem"
          bg={primary}
          color={white}
          m="1rem 0 0"
          onClick={() => navigate("/admin/dashboard")}
        >
          {t("clientAccount.isEmployeeButton")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default NoUserAccount;
