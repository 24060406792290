import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import BarChart from "../../../../components/stats/BarChart";
import {
  primary,
  textPrimary,
  textSecondary,
  buttonGreen,
} from "../../../../utils/colors";
import { getClientsStatusCounts } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";

const ClientsStatusCount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { loading, data, error } = useSelector(
    (state) => state.dashboardClientsStatusCounts
  );

  const { total, countsByStatus } = data;

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getClientsStatusCounts(userInfo._id));
    }
  }, [userInfo, dispatch]);

  console.log("total", total);
  console.log("countsByStatus", countsByStatus);

  // CUSTOM
  const statusOrder = ["active", "inactive", "employeeClientCount"];
  const statusColors = {
    Active: buttonGreen,
    Inactive: "#ddd",
    EmployeeClientCount: "#E2D4FF",
  };

  if (loading) {
    return (
      <Flex>
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  console.log("total", total);
  console.log("countsByStatus", countsByStatus);

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Link to="/admin/clients">
          <Text
            color={textPrimary}
            fontSize="sm"
            fontWeight="500"
            _hover={{
              color: primary,
            }}
          >
            {t("dashboard.clientsStatusCountTitle")}
            <Text
              as="span"
              fontWeight="500"
              color={textSecondary}
              ml={1}
              fontSize="sm"
            >
              {total}
            </Text>
          </Text>
        </Link>
      </Flex>

      <BarChart
        data={countsByStatus}
        statusOrder={statusOrder}
        statusColors={statusColors}
        backgroundLight="gray.200"
        textSecondary="gray.500"
        white="white"
      />
    </Flex>
  );
};

export default ClientsStatusCount;
