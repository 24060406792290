import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listProfessionGroupDetails,
  updateProfessionGroup,
} from "../../../../actions/professionGroupActions";
import { backgroundLight } from "../../../../utils/colors";
import { Box, Heading, Button, Flex, HStack } from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
// import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";

// import FormControlRolePermission from "../../../../components/input/FormControlRolePermission";
import { PROFESSIONGROUP_UPDATE_RESET } from "../../../../constants/professionGroupConstants";

const EditProfessionGroup = ({ close, professionGroupId }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  const [name, setName] = useState("");
  const [defaultRolePermission, setDefaultRolePermission] = useState("");

  // REDUX
  const professionGroupDetails = useSelector(
    (state) => state.professionGroupDetails
  );
  const { professionGroup } = professionGroupDetails;

  const professionGroupUpdate = useSelector(
    (state) => state.professionGroupUpdate
  );
  const { success } = professionGroupUpdate;

  // USEEFFECT
  useEffect(() => {
    if (professionGroup && professionGroup._id !== professionGroupId) {
      dispatch(listProfessionGroupDetails(professionGroupId));
    } else {
      setName(professionGroup.name);
      setDefaultRolePermission(professionGroup.defaultRolePermission);
    }
  }, [dispatch, professionGroupId, professionGroup]);

  // HANDLERS
  const closeHandler = () => {
    close();
    dispatch({ type: PROFESSIONGROUP_UPDATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateProfessionGroup({
        _id: professionGroupId,
        name: name,
        defaultRolePermission: defaultRolePermission,
      })
    );
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [defaultRolePermissionTouched, setDefaultRolePermissionTouched] =
    useState(false);

  // ERRORS
  const isNameError = name === "";
  const isDefaultRolePermissionError = defaultRolePermission === "";

  // LOGS

  console.log("name", name);
  console.log("defaultRolePermission", defaultRolePermission);

  return (
    <form onSubmit={submitHandler}>
      <FormControlDefault
        type="text"
        isRequired
        label={t("form.professionGroupName")}
        value={name}
        placeholder={t("placeholder.professionGroupName")}
        onChange={(e) => {
          setName(e.target.value);
          setNameTouched(true);
        }}
        isTouched={nameTouched}
        isError={isNameError}
        w="100%"
        errorMessage={t("formError.professionGroupNameRequired")}
      />

      <Flex
        mt={4}
        justify="center"
        direction="column"
        borderTop={`1px solid ${newBorder}`}
        p="1.5rem 0"
        position="relative"
        zIndex={0}
        bg={white}
        bottom="0"
        w="100%"
      >
        <Flex justify="flex-end">
          <Button
            minWidth="10rem"
            mr={4}
            bg={backgroundLight}
            size="lg"
            color={textSecondary}
            fontSize="md"
            borderRadius="10rem"
            onClick={closeHandler}
          >
            {t("common.cancel")}
          </Button>
          <Button
            minWidth="10rem"
            type="submit"
            size="lg"
            bg={primary}
            color={white}
            fontSize="md"
            borderRadius="10rem"
            // isDisabled={!firstName.trim() || !lastName.trim()}
          >
            {t("professionGroup.editProfessionGroup")}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default EditProfessionGroup;
