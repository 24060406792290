import React, { createContext, useState, useEffect } from "react";

export const GoogleApiContext = createContext();

export const GoogleApiProvider = ({ children, googleApiKey }) => {
  const [isApiLoaded, setApiLoaded] = useState(false);
  const [isApiLoadFailed, setApiLoadFailed] = useState(false);

  useEffect(() => {
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initGoogleApi`;
    script.async = true;
    script.defer = true;

    const scriptPromise = new Promise((resolve, reject) => {
      window.initGoogleApi = () => {
        resolve();
      };
      script.onerror = () => {
        console.error("Failed to load the Google Maps script");
        reject();
        setApiLoadFailed(true);
      };
    });

    document.head.appendChild(script);
    scriptPromise
      .then(() => {
        setApiLoaded(true);
        console.log("Google Maps API fully loaded and initialized");
      })
      .catch((error) => {
        console.error("Error loading Google API", error);
      });

    return () => {
      document.head.removeChild(script);
      delete window.initGoogleApi;
    };
  }, [googleApiKey]);

  return (
    <GoogleApiContext.Provider value={{ isApiLoaded, isApiLoadFailed }}>
      {children}
    </GoogleApiContext.Provider>
  );
};
