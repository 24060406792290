import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { white, primary, newBorder } from "../../../../../utils/colors";
import Table from "../../../../../components/shared/Table";
import { columns as clientReportsColumns } from "../columns/ClientReportsColumns";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import MultiAttendeeSelect2 from "../../../../../components/select/MultiAttendeeSelect2";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import { COURSE_ATTENDEE_PRICE_UPDATE_RESET } from "../../../../../constants/courseConstants";

const TabClientReports = ({ title, loading, error, reports }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);
  const navigate = useNavigate();

  // REDUX
  const dispatch = useDispatch();

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  // SEARCH
  const [keyword, setKeyword] = useState("");

  // HANDLERS

  const handleRowClick = (value) => {
    navigate(`/admin/reports/implant-failure-reports/${value}`);
  };

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    // dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
  };

  return (
    <>
      <Box>
        <Flex w="100%" direction="column">
          <Table.Top
            title={t("clientAccount.tabReports")}
            subtitle={title}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={t("clientAccount.searchReports")}
            searchName={keyword}
            isFixedTitle={false}
          ></Table.Top>
          <Box
            borderRadius="0.75rem"
            overflow="hidden"
            p="0 0 2rem"
            border={`0px solid ${newBorder}`}
          >
            <Table.Container
              loading={loading}
              data={reports && reports}
              error={error}
              columns={clientReportsColumns(
                translate,
                handleSortChange,
                navigate
              )}
              noItemsTitle={t("noItems.noReports")}
              noItemsDescription={t("noItems.noReportsText")}
              // noItemsButtonLabel={t("course.noAttendeesCourseButtonLabel")}
              // noItemsButtonHandler={openAddAttendeeSidePanel}
              onRowClick={handleRowClick}
              sortField={sortField}
              sortOrder={sortOrder}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabClientReports;
