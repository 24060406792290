import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createClient } from "../../../../actions/clientActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  TabPanels,
  TabPanel,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlEmployee from "../../../../components/input/FormControlEmployee";
import { shade } from "polished";
import FormControlClientType from "../../../../components/input/FormControlClientType";
import FormControlAddressFull from "../../../../components/input/FormControlAddressFull";

const CreateClient = () => {
  // REDUX
  const dispatch = useDispatch();

  // TRANSLATION
  const { t } = useTranslation();

  // NEW STATE
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  const [selectedAccountType, setSelectedAccountType] = useState("clinic");

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    display: "",
  });

  // TABS
  const tabsData = [
    { label: t("client.tabDetails") },
    { label: t("client.tabContacts") },
    { label: t("client.tabRepresentatives") },
    // { label: t("client.tabDelivery") },
  ];

  // STATE
  const [activeTab, setActiveTab] = useState(0);

  const [image, setImage] = useState("");
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [vatCode, setVatCode] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [employee, setEmployee] = useState("");

  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("LT");
  const [state, setState] = useState("");

  const [repFirstName, setRepFirstName] = useState("");
  const [repLastName, setRepLastName] = useState("");
  const [repPhone, setRepPhone] = useState("");
  const [repEmail, setRepEmail] = useState("");

  // TOUCHED
  const [clientNameTouched, setClientNameTouched] = useState(false);
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [companyCodeTouched, setCompanyCodeTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [repEmailTouched, setRepEmailTouched] = useState(false);

  // ERRORS
  const isClientNameError = clientName === "";
  const isCompanyNameError = companyName === "";
  const isCompanyCodeError = companyCode === "";
  const isEmailError = email === "";

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isClientNameValid = clientName.length >= 1;
  const isCompanyNameValid = companyName.length >= 1;
  const isCompanyCodeValid = companyCode.length >= 1;
  const isEmailValid = isValidEmail(email);
  const isRepEmailValid = isValidEmail(repEmail);

  // IS CONTINUE DISABLED
  const isContinueDisabled =
    !isClientNameValid || !isCompanyNameValid || !isCompanyCodeValid;

  const isCreateDisabled =
    !isClientNameValid ||
    !isCompanyNameValid ||
    !isCompanyCodeValid ||
    !isEmailValid;

  // HANDLERS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createClient(
        image,
        clientName,
        companyName,
        email,
        phone,
        phoneAlt,
        companyCode,
        vatCode,
        employee,
        website,
        street,
        city,
        zip,
        country,
        state,
        repFirstName,
        repLastName,
        repPhone,
        repEmail
      )
    );
  };

  // NEW HANDLERS
  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  // LOGS
  console.log("selectedAccountType:", selectedAccountType);

  return (
    <form onSubmit={submitHandler}>
      <TabsComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabsData}
        isTitleValid={!isContinueDisabled}
        disabledTabs={[]}
      >
        <TabPanels m="1.5rem 0 0">
          <TabPanel p={0}>
            <FormControlClientType
              label={t("form.clientType")}
              value={selectedAccountType}
              onChange={(selectedOption) => {
                setSelectedAccountType(
                  selectedOption ? selectedOption.value : ""
                );
              }}
              isRequired
              errorMessage={t("formError.clientTypeRequired")}
              w="100%"
            />

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.image")}</FormLabel>
              <ImageUpload image={image} setImage={setImage} />
            </FormControl>

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.companyName")}
              value={companyName}
              placeholder={t("placeholder.companyName")}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setCompanyNameTouched(true);
              }}
              onBlur={() => setCompanyNameTouched(true)}
              isTouched={companyNameTouched}
              isError={isCompanyNameError}
              w="100%"
              errorMessage={t("formError.companyNameRequired")}
            />

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.clientName")}
              value={clientName}
              placeholder={t("placeholder.clientName")}
              onChange={(e) => {
                setClientName(e.target.value);
                setClientNameTouched(true);
              }}
              onBlur={() => setClientNameTouched(true)}
              isTouched={clientNameTouched}
              isError={isClientNameError}
              w="100%"
              errorMessage={t("formError.clientNameRequired")}
            />

            <HStack gap={4}>
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.companyCode")}
                value={companyCode}
                placeholder={t("placeholder.companyCode")}
                onChange={(e) => {
                  setCompanyCode(e.target.value);
                  setCompanyCodeTouched(true);
                }}
                onBlur={() => setCompanyCodeTouched(true)}
                isTouched={companyCodeTouched}
                isError={isCompanyCodeError}
                w="50%"
                errorMessage={t("formError.companyCodeRequired")}
              />
              <FormControlDefault
                type="text"
                label={t("form.vatCode")}
                value={vatCode}
                placeholder={t("placeholder.vatCode")}
                onChange={(e) => {
                  setVatCode(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.vatCodeRequired")}
              />
            </HStack>

            {!manualAddressVisible && (
              <Flex direction="column" w="100%" p="0rem">
                <FormControlAddressFull
                  label={t("form.address")}
                  placeholder={t("placeholder.googleAddress")}
                  onAddressSelect={handleAddressSelect}
                  currentValue={address.display}
                  w="100%"
                />
                <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
                  <Flex
                    position="relative"
                    m="0rem 0 0"
                    justify="flex-end"
                    w="100%"
                  >
                    <Button
                      bg="rgba(255,255,255,0.3)"
                      onClick={toggleManualAddressVisible}
                      color={primary}
                      variant="ghost"
                      fontSize="xs"
                      size="xs"
                      fontWeight="500"
                      p="0.25rem 0.75rem"
                      borderRadius="10rem"
                      opacity="0.8"
                      transition="all 0.3s"
                      _hover={{
                        // bg: backgroundLight,
                        opacity: "1",
                      }}
                    >
                      {t("onboarding.enterAddressManual")}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}

            {manualAddressVisible && (
              <>
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  p="0rem"
                >
                  <HStack gap={4} w="100%">
                    <FormControlDefault
                      type="text"
                      label={t("form.street")}
                      value={address.street}
                      placeholder={t("placeholder.street")}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          street: e.target.value,
                        })
                      }
                      w={["100%", "50%", "50%"]}
                    />

                    <FormControlDefault
                      type="text"
                      label={t("form.zip")}
                      value={address.zip}
                      placeholder={t("placeholder.zip")}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          zip: e.target.value,
                        })
                      }
                      w={["100%", "50%", "50%"]}
                    />
                  </HStack>

                  <HStack gap={4} w="100%">
                    <FormControlDefault
                      type="text"
                      label={t("form.city")}
                      value={address.city}
                      placeholder={t("placeholder.city")}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          city: e.target.value,
                        })
                      }
                      w={["100%", "50%", "50%"]}
                      errorMessage={t("formError.cityRequired")}
                    />

                    <FormControlCountry
                      label={t("form.country")}
                      value={address.country}
                      onChange={(selectedOption) => {
                        setAddress(
                          Object.assign({}, address, {
                            country: selectedOption.value,
                          })
                        );
                      }}
                      w={["100%", "50%", "50%"]}
                      errorMessage={t("formError.countryRequired")}
                    />
                  </HStack>
                  <Flex align="center" justify="center" gap="1rem">
                    <Flex position="relative" m="0rem 0 0" justify="center">
                      <Button
                        bg="rgba(255,255,255,0.3)"
                        onClick={toggleManualAddressVisible}
                        color={primary}
                        variant="link"
                        fontSize="xs"
                        fontWeight="500"
                        p="0.5rem 0.75rem"
                        borderRadius="10rem"
                        _hover={{
                          bg: "rgba(255,255,255,1)",
                        }}
                      >
                        {t("onboarding.enterAddressGoogle")}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </TabPanel>
          <TabPanel p={0}>
            <HStack gap={4} w="100%">
              <FormControlPhone
                label={t("form.clientMobPhoneNumber")}
                value={phone}
                onChange={(newPhone) => {
                  setPhone(newPhone);
                }}
                w="50%"
                defaultCountry="lt"
              />

              <FormControlPhone
                label={t("form.clientPhoneNumber")}
                value={phoneAlt}
                onChange={(newPhone) => {
                  setPhoneAlt(newPhone);
                }}
                w="50%"
                defaultCountry="lt"
              />
            </HStack>

            <FormControlDefault
              type="email"
              label={t("form.email")}
              isRequired
              value={email}
              placeholder={t("placeholder.email")}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailTouched(true);
              }}
              onBlur={() => setEmailTouched(true)}
              isTouched={emailTouched}
              isError={!isEmailValid}
              w="100%"
              errorMessage={t("formError.emailInvalid")}
            />

            <FormControlDefault
              type="text"
              label={t("form.website")}
              value={website}
              placeholder={t("placeholder.website")}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
              w="100%"
            />
          </TabPanel>

          <TabPanel p={0}>
            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.repFirstName")}
                value={repFirstName}
                placeholder={t("placeholder.repFirstName")}
                onChange={(e) => {
                  setRepFirstName(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.repFirstNameRequired")}
              />

              <FormControlDefault
                type="text"
                label={t("form.repLastName")}
                value={repLastName}
                placeholder={t("placeholder.repLastName")}
                onChange={(e) => {
                  setRepLastName(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.repLastNameRequired")}
              />
            </HStack>

            <FormControlPhone
              label={t("form.repPhoneNumber")}
              value={repPhone}
              onChange={(newPhone) => {
                setRepPhone(newPhone);
              }}
              w="100%"
              defaultCountry="lt"
            />

            <FormControlDefault
              type="email"
              label={t("form.repEmail")}
              value={repEmail}
              placeholder={t("placeholder.repEmail")}
              isError={!isRepEmailValid}
              isTouched={repEmailTouched}
              onChange={(e) => {
                setRepEmail(e.target.value);
                setRepEmailTouched(true);
              }}
              w="100%"
              errorMessage={t("formError.emailInvalid")}
            />

            <FormControlEmployee
              label={t("form.clientEmployee")}
              value={employee}
              placeholder={t("placeholder.clientEmployee")}
              onChange={(selectedOption) => {
                setEmployee(selectedOption ? selectedOption.value : "");
              }}
              isRequired={false}
              w="100%"
              errorMessage={t("formError.employeeRequired")}
            />
          </TabPanel>
        </TabPanels>

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="space-between">
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isClientNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.clientNameRequired")}
                    </Text>
                  )}
                  {isCompanyNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.companyNameRequired")}
                    </Text>
                  )}
                  {isCompanyCodeError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.companyCodeRequired")}
                    </Text>
                  )}
                  {!isEmailValid && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.emailInvalid")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isCreateDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                isDisabled={isCreateDisabled}
                _hover={{
                  bg: shade(0.3, primary),
                }}
                ml={4}
              >
                {t("client.createClient")}
              </Button>
            </Tooltip>

            <Flex align="center">
              {activeTab > 0 && (
                <Button
                  minWidth="8rem"
                  mr={0}
                  bg={backgroundLight}
                  border={`1px solid ${newBorder}`}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  fontWeight="500"
                  borderRadius="10rem"
                  onClick={() => setActiveTab(activeTab - 1)}
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.1, backgroundLight),
                  }}
                >
                  {t("common.back")}
                </Button>
              )}

              {activeTab < 2 && (
                <Tooltip
                  bg={white}
                  borderRadius="1rem"
                  p="1rem"
                  label={
                    <Flex
                      direction="column"
                      p="0rem"
                      justify="center"
                      textAlign="center"
                    >
                      {isClientNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.clientNameRequired")}
                        </Text>
                      )}
                      {isCompanyNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.companyNameRequired")}
                        </Text>
                      )}
                      {isCompanyCodeError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.companyCodeRequired")}
                        </Text>
                      )}
                    </Flex>
                  }
                  isDisabled={!isContinueDisabled}
                  hasArrow
                  placement="top"
                  fontSize="md"
                >
                  <Button
                    minWidth="8rem"
                    ml={4}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab + 1)}
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                  >
                    {t("common.continue")}
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </TabsComponent>
    </form>
  );
};

export default CreateClient;
