import React from 'react';
import styled from 'styled-components';
import TabLink from './TabLink';

const TabContainer = ({ tabs }) => {
  return (
    <TabContainerStyled>
      {tabs.map((tab) => (
        <TabLink key={tab.id} {...tab} />
      ))}
    </TabContainerStyled>
  );
};

const TabContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-top: 1rem;
`;

export default TabContainer;
