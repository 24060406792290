import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import newDashboard from "../../assets/icons/new/dashboard.svg";
import newClients from "../../assets/icons/new/clients.svg";
import newEmployees from "../../assets/icons/new/employees.svg";
import newReports from "../../assets/icons/new/reports.svg";
import newSettings from "../../assets/icons/new/settings.svg";
import newStock from "../../assets/icons/new/stock.svg";
import newAcademy from "../../assets/icons/new/academy.svg";

const MenuItems = (isAdmin) => {
  const { t } = useTranslation();

  // console.log("MenuItems component rendered", rolePermission);

  let menuItems = [
    {
      id: 1,
      name: t("menu.dashboard"),
      route: "/admin/dashboard",
      icon: newDashboard,
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },
    {
      id: 2,
      name: t("menu.stockManagement"),
      route: "/admin/stock",
      icon: newStock,
      hasSubMenu: false,
      subMenuItems: [
        {
          name: "Implants",
          route: "/admin/stock/implants",
          isDisabled: false,
        },
        {
          name: "Prosthodontic Components",
          route: "/admin/stock/components",
          isDisabled: false,
        },
      ],
      isDisabled: true,
    },
    // {
    //   id: 3,
    //   name: t("menu.orders"),
    //   route: "/admin/orders",
    //   icon: "/icons/orders.svg",
    //   hasSubMenu: false,
    //   subMenuItems: [],
    //   isDisabled: true,
    // },

    {
      id: 4,
      name: t("menu.academy"),
      route: "/admin/academy",
      icon: newAcademy,
      hasSubMenu: true,
      subMenuItems: [
        {
          name: t("menu.courses"),
          route: "/admin/academy/courses",
          isDisabled: false,
        },
        {
          name: t("menu.speakers"),
          route: "/admin/academy/speakers",
          isDisabled: false,
        },
        {
          name: t("menu.attendees"),
          route: "/admin/academy/attendees",
          isDisabled: false,
        },
        {
          name: t("menu.minec"),
          route: "/admin/academy/minec",
          isDisabled: true,
        },
      ],
    },
    // {
    //   id: 5,
    //   name: t('menu.finance'),
    //   route: '/finance',
    //   icon: '/icons/finance.svg',
    //   hasSubMenu: false,
    //   subMenuItems: [],
    //   isDisabled: true,
    // },
    // {
    //   id: 5,
    //   name: t("menu.loyalty"),
    //   route: "/loyalty",
    //   icon: "/icons/finance.svg",
    //   hasSubMenu: false,
    //   subMenuItems: [],
    //   isDisabled: true,
    // },
    // {
    //   id: 6,
    //   name: t("menu.discountPackages"),
    //   route: "/admin/packages",
    //   icon: "/icons/packages.svg",
    //   hasSubMenu: false,
    //   subMenuItems: [],
    //   isDisabled: true,
    // },
    {
      id: 7,
      name: t("menu.clients"),
      route: "/admin/clients",
      icon: newClients,
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },

    {
      id: 9,
      name: t("menu.employees"),
      route: "/admin/employees",
      icon: newEmployees,
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },
    {
      id: 99,
      name: t("menu.reports"),
      route: "/admin/reports",
      icon: newReports,
      hasSubMenu: true,
      subMenuItems: [
        {
          name: t("menu.implantFailure"),
          route: "/admin/reports/implant-failure-reports",
          isDisabled: false,
        },
      ],
      isDisabled: false,
    },
    {
      id: 8,
      name: t("menu.settings"),
      route: "/admin/settings",
      icon: newSettings,
      hasSubMenu: true,
      subMenuItems: [
        {
          name: t("menu.professions"),
          route: "/admin/settings/professions",
          isDisabled: false,
        },
        {
          name: t("menu.professionGroups"),
          route: "/admin/settings/profession-groups",
          isDisabled: false,
        },
      ],
    },
  ];

  // Filter out settings and appointments menu if user is not an admin
  if (!isAdmin) {
    menuItems = menuItems.filter(
      (menuItem) => menuItem.id !== 10 && menuItem.id !== 2
    );
  }

  return menuItems;
};

export default MenuItems;
