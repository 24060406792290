import axios from "axios";
import {
  GET_NEW_CLIENTS_REQUEST,
  GET_NEW_CLIENTS_FAIL,
  GET_NEW_CLIENTS_SUCCESS,
  GET_NEW_CLIENTS_TOP_REQUEST,
  GET_NEW_CLIENTS_TOP_SUCCESS,
  GET_NEW_CLIENTS_TOP_FAIL,
  GET_REPORT_STATUS_COUNTS_REQUEST,
  GET_REPORT_STATUS_COUNTS_SUCCESS,
  GET_REPORT_STATUS_COUNTS_FAIL,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS,
  GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL,
  GET_CLIENTS_STATUS_COUNTS_REQUEST,
  GET_CLIENTS_STATUS_COUNTS_SUCCESS,
  GET_CLIENTS_STATUS_COUNTS_FAIL,
  GET_TOP_REPORTS_BY_FIXTURE_REQUEST,
  GET_TOP_REPORTS_BY_FIXTURE_SUCCESS,
  GET_TOP_REPORTS_BY_FIXTURE_FAIL,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS,
  GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL,
} from "../constants/dashboardConstants";

/**
 * Fetch new clients with optional filters.
 * @param {string} [employeeId] - The employee ID to filter clients by.
 * @param {string} [limit='all'] - The limit of clients to fetch. Default is 'all'.
 */
export const getNewClients =
  (employeeId, limit = "all") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_NEW_CLIENTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Construct the query string based on available parameters
      let queryString = `/api/dashboard/new-clients?limit=${limit}`;
      if (employeeId) {
        queryString += `&employeeId=${employeeId}`;
      }

      const { data } = await axios.get(queryString, config);

      dispatch({
        type: GET_NEW_CLIENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_NEW_CLIENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getNewClientsTop = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_NEW_CLIENTS_TOP_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/dashboard/new-clients-top`, config);

    dispatch({
      type: GET_NEW_CLIENTS_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_NEW_CLIENTS_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getReportStatusCounts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REPORT_STATUS_COUNTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/dashboard/report-status-counts`,
      config
    );

    dispatch({
      type: GET_REPORT_STATUS_COUNTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_REPORT_STATUS_COUNTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCoursesAttendeesStatusCounts =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/dashboard/courses-attendee-status-counts`,
        config
      );

      dispatch({
        type: GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getClientsStatusCounts =
  (employeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CLIENTS_STATUS_COUNTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const url = employeeId
        ? `/api/dashboard/clients-status-counts?employeeId=${employeeId}`
        : `/api/dashboard/clients-status-counts`;

      const { data } = await axios.get(url, config);

      dispatch({
        type: GET_CLIENTS_STATUS_COUNTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLIENTS_STATUS_COUNTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTopReportsByFixture = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TOP_REPORTS_BY_FIXTURE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },

      params: {
        limit: 5,
      },
    };

    const { data } = await axios.get(
      `/api/dashboard/report-status-counts-by-fixture`,
      config
    );

    dispatch({
      type: GET_TOP_REPORTS_BY_FIXTURE_SUCCESS,
      payload: data,
    });

    // console.log(data);
  } catch (error) {
    dispatch({
      type: GET_TOP_REPORTS_BY_FIXTURE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEmployeeClientsLatestReports =
  (employeeId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },

        params: {
          employeeId,
        },
      };

      const { data } = await axios.get(
        `/api/dashboard/employee-latest-reports`,
        config
      );

      dispatch({
        type: GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
