import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import {
  white,
  primary,
  text,
  secondary,
  backgroundLight,
  border,
  textPrimary,
  textSecondary,
} from "../../../utils/colors";

const TabLink = ({
  route,
  name,
  icon,
  subMenuItems,
  hasSubMenu,
  isDisabled,
}) => {
  const location = useLocation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const [classNamas, setClassNamas] = useState("");
  const isActive = location.pathname === route; // Define and set isActive based on the route

  useEffect(() => {
    if (hasSubMenu && location.pathname.startsWith(route)) {
      setIsAccordionOpen(true);
      setClassNamas("activeLocation");
    } else {
      setIsAccordionOpen(false);
      setClassNamas("");
    }
  }, [location.pathname, route, hasSubMenu]);

  const handleAccordionClick = () => {
    if (hasSubMenu) {
      setIsAccordionOpen((prevState) => !prevState);
    }
  };

  return (
    <>
      {hasSubMenu === false && (
        <StyledTabLink
          className={`${isActive ? "active" : ""} ${
            isDisabled ? "disabled" : ""
          }`}
          to={route}
        >
          {icon && <StyledIcon src={icon} />}
          {name}
        </StyledTabLink>
      )}
      {hasSubMenu === true && (
        <Accordion
          allowToggle
          className={classNamas}
          index={isAccordionOpen ? [0] : []}
        >
          <AccordionItem border="none">
            <AccordionButton
              onClick={handleAccordionClick}
              p="0"
              borderTopLeftRadius="1rem"
              borderBottomLeftRadius="1rem"
              _hover={{
                color: textPrimary,
                background: backgroundLight,
              }}
            >
              <Box flex="1" textAlign="left">
                <StyledTabLink to={route} className="withSubMenu">
                  {icon && <StyledIcon src={icon} />}
                  {name}
                  <IconWrapper isOpen={isAccordionOpen}>
                    <ChevronDownIcon />
                  </IconWrapper>
                </StyledTabLink>
              </Box>
            </AccordionButton>
            <AccordionPanel p="0.5rem 0px 0.5rem 2.5rem">
              <SubMenu>
                {subMenuItems.map((subMenuItem) => (
                  <SubMenuLink
                    key={subMenuItem.name}
                    to={subMenuItem.route}
                    className={
                      subMenuItem.isDisabled
                        ? "disabled"
                        : location.pathname === route
                    }
                  >
                    {subMenuItem.name}
                  </SubMenuLink>
                ))}
              </SubMenu>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
const IconWrapper = styled.div`
  margin-left: auto;
  margin-right: 0rem;
  svg {
    height: 1.25rem;
    width: 1.25rem;
    transition: all 0.2s ease-in-out; /* Add a transition for smooth animation */
    transform: rotate(${(props) => (props.isOpen ? "0deg" : "-90deg")});
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: -12px;
    width: 1px;
    height: calc(100% - 8px);
    background: ${border};
  }
`;

const SubMenuLink = styled(NavLink)`
  color: ${text};
  font-weight: 400;
  height: 50px;
  display: flex;
  font-size: 14px;
  align-items: center;
  background: ${white};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 0 18px;
  letter-spacing: 0px;
  transition: background 0.1s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &.active {
    background: ${backgroundLight};
    color: ${textPrimary};
    font-weight: 500;
  }
  &:hover {
    background: ${backgroundLight};

    font-weight: 500;
    color: ${textSecondary};
    &.active {
      color: ${textPrimary};
      background: ${backgroundLight};
    }
  }
`;

const StyledIcon = styled.img`
  height: 19px;
  margin-right: 1rem;
`;

const StyledTabLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  height: 56px;
  font-weight: 400;
  color: ${text};
  background: none;
  font-family: ${(p) => p.font};
  white-space: nowrap;
  position: relative;
  transition: 0.2s ease-in all;
  border: 0px solid #ddd;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin: 0;
  font-size: 15px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  p {
    margin: 0;
  }

  img {
    filter: invert(0.5);
  }
  &.withSubMenu {
    pointer-events: none;
  }
  &:hover {
    background: #f5f5f5;

    &.active {
      background: ${secondary};
      color: ${white};
    }
  }

  &.active {
    background: ${primary};
    color: ${white};
    opacity: 1;

    img {
      filter: invert(1);
    }

    ${StyledIcon} {
      opacity: 1;
    }
  }
`;

export default TabLink;
