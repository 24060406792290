import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbMapPin,
  TbBuilding,
  TbUsers,
  TbMapPinStar,
  TbWallet,
  TbBriefcase,
  TbHash,
  TbDeviceMobile,
  TbEmail,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import TooltipText from "../../../../../components/partials/TooltipText";
import { shade } from "polished";
import SinglePagePieChartSimple from "../../../../../components/charts/SinglePagePieChartSimple";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const ClientCharts = ({ data }) => {
  const { t } = useTranslation();

  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.address) {
      setPlace(data?.address[0]?.street + `, ` + data?.address[0]?.zip || "");
      setCity(data?.address[0]?.city + `, ` + data?.address[0]?.country || "");
    }
    setLocation(data.location);
  }, [data]);

  // Define the titles for different slides
  const slideTitles = [
    t("clientAccount.clientReportsStatsTitle"),
    t("clientAccount.clientEventsStatsTitle"),
  ];

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  // console.log("data prices", data.prices);

  return (
    <Flex
      w="50%"
      pl="2rem"
      borderLeft={`1px solid ${newBorder}`}
      direction="column"
      align="flex-end"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4} w="100%">
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {slideTitles[currentSlide]}
        </Heading>
        <Box>
          <IconButton
            className="custom-swiper-button custom-swiper-button-prev"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              background: backgroundLight,
            }}
          />
          <IconButton
            className="custom-swiper-button custom-swiper-button-next"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box w="100%">
        <Swiper
          navigation={{
            prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
            nextEl: ".custom-swiper-button-next", // Selector for your custom next button
          }}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Navigation]}
          onSlideChange={handleSlideChange}
          className="dataInfoSlider"
        >
          <SwiperSlide>
            <Flex w="100%" borderLeft={`0px solid ${newBorder}`}>
              <SinglePagePieChartSimple
                label={t("clientAccount.reportsStats")}
                data={data.reports}
                options="report"
                plotLabel={t("stats.totalReports")}
                noTitle
                w="100%"
              />
            </Flex>
          </SwiperSlide>

          <SwiperSlide>
            <Flex w="100%" borderLeft={`0px solid ${newBorder}`}>
              <SinglePagePieChartSimple
                label={t("clientAccount.eventsStats")}
                data={data.reports}
                options="event"
                plotLabel={t("stats.totalEvents")}
                noTitle
                w="100%"
              />
            </Flex>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Flex>
  );
};

export default ClientCharts;
