import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";
import {
  Flex,
  Spinner,
  Button,
  Heading,
  Text,
  Stack,
  Image,
  useToast,
} from "@chakra-ui/react";
import {
  verifyAttendeeEmailToken,
  listPublicCourseDetails,
} from "../../../actions/courseActions";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Header from "../../landing/layout/Header";
// import RegisterPromo from "./partials/RegisterPromo";
import {
  purple,
  white,
  textPrimary,
  textSecondary,
  textWhite,
  primary,
  newBorder,
} from "../../../utils/colors";
import { shade } from "polished";
import successAnimation from "../../../assets/lottie/success.json";
import dayjs from "dayjs";
import StatusButton from "../../../components/buttons/StatusButton";

const VerifyPublicRegisterToken = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  // STATE
  const [showAnimation, setShowAnimation] = useState(true);

  // REDUX

  const coursePublicDetails = useSelector((state) => state.coursePublicDetails);

  const {
    loading: loadingCourse,
    error: errorCourse,
    course,
  } = coursePublicDetails;

  // ANIMATION
  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  // Extract token and courseId from URL query
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const courseId = searchParams.get("courseId");

  // CUSTOM

  const isSameDay = dayjs(course.startDate).isSame(
    dayjs(course.endDate),
    "day"
  );

  // REDUX
  const dispatch = useDispatch();

  const courseVerifyAttendeeEmailToken = useSelector(
    (state) => state.courseVerifyAttendeeEmailToken
  );
  const { loading, error, success, attendee } = courseVerifyAttendeeEmailToken;

  // HANDLERS
  const handleVerification = () => {
    dispatch(verifyAttendeeEmailToken(token, courseId));
  };

  // USE EFFECT

  useEffect(() => {
    if (token) {
      dispatch(verifyAttendeeEmailToken(token, courseId));
    } else {
      navigate("/");
      toast({
        title: "Token error",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [token]);

  useEffect(() => {
    if (courseId) {
      dispatch(listPublicCourseDetails(courseId));
    }
  }, [courseId]);

  useEffect(() => {
    if (success) {
      // toast({
      //   title: t("toasts.successRegisterVerification"),
      //   status: "success",
      //   duration: 5000,
      //   isClosable: true,
      // });
    } else if (error) {
      // toast({
      //   title: t("toasts.errorRegisterVerification"),
      //   description: error,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
    }
  }, [success, error]);

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate("/account");
  //     }
  //   }, [userInfo]);

  // LOGS
  console.log("location", location);
  console.log("token", token);
  console.log("courseId", courseId);
  console.log("course", course);
  console.log("attendee", attendee);

  return (
    <>
      {" "}
      <Flex
        w="100%"
        h="100%"
        minH="100vh"
        align="stretch"
        justify="flex-start"
        bg={white}
        p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
      >
        <Header
          bg="transparent"
          // isRegister
          showPromo={true}
          showForm={true}
          isVerify
        />
        <Flex direction="column" w="100%" align="center" justify="center">
          {showAnimation && (
            <Flex m="0 auto" maxW="80px">
              {View}
            </Flex>
          )}

          <Text
            fontSize="2rem"
            color={textPrimary}
            fontWeight="700"
            m="1rem auto 0.5rem"
            maxW="500px"
            textAlign="center"
          >
            {t("publicCourses.successTitle")}
          </Text>
          {/* <Text
    fontSize="sm"
    color={textSecondary}
    fontWeight="500"
    maxW="400px"
    m="0 auto"
  >
    {t("publicCourses.successText")}
  </Text> */}

          {/* <Flex w="100%" align="center" justify="center">
            <Flex align="center">
              <Text color={textSecondary} fontSize="sm" fontWeight="500">
                {t("attendee.attendee")}:
              </Text>
              <Text color={primary} fontSize="sm" fontWeight="500" ml="0.5rem">
                {attendee && attendee.firstName[0]}.{" "}
                {attendee && attendee.lastName}
              </Text>
            </Flex>
          </Flex> */}

          <Flex
            direction="column"
            minW="300px"
            maxW="500px"
            w="100%"
            m="1rem 0 0"
          >
            <Flex
              w="100%"
              p="1.5rem 1.5rem"
              border={`1px solid ${newBorder}`}
              borderRadius="1rem"
            >
              <Flex align="center" flexShrink={0}>
                <Image
                  src={
                    course.image !== ""
                      ? course.image
                      : "https://bucket.mailersendapp.com/0p7kx4xmrvl9yjre/z3m5jgr1omx4dpyo/images/9c62f12f-85e4-4a7a-bc56-be2b6b12b5af.png"
                  }
                  h="120px"
                  w="120px"
                  objectFit="cover"
                  borderRadius="1rem"
                  mr="1rem"
                />
              </Flex>

              <Stack gap="0.25rem" w="100%">
                <Text
                  color={textPrimary}
                  fontWeight="600"
                  fontSize="sm"
                  lineHeight="1.2"
                >
                  {course.title}
                </Text>

                <Flex justify="space-between" align="center">
                  <Text color={textSecondary} fontWeight="500" fontSize="13px">
                    {t("publicCourses.date")}:
                  </Text>
                  <Text
                    color={textPrimary}
                    fontWeight="600"
                    fontSize="13px"
                    textTransform="capitalize"
                  >
                    {isSameDay
                      ? dayjs(course.startDate).format("D MMMM, YYYY")
                      : `${dayjs(course.startDate).format("D MMM")} - ${dayjs(
                          course.endDate
                        ).format("D MMM, YYYY")}`}
                  </Text>
                </Flex>

                <Flex justify="space-between" align="center">
                  <Text color={textSecondary} fontWeight="500" fontSize="13px">
                    {t("publicCourses.startTime")}:
                  </Text>
                  <Text color={textPrimary} fontWeight="500" fontSize="13px">
                    {dayjs(course.startDate).format("HH:mm")}
                  </Text>
                </Flex>

                <Flex justify="space-between" align="center">
                  <Text color={textSecondary} fontWeight="500" fontSize="13px">
                    {t("attendee.attendee")}:
                  </Text>
                  <Text color={primary} fontWeight="500" fontSize="13px">
                    {attendee && attendee.firstName[0]}.{" "}
                    {attendee && attendee.lastName}
                  </Text>
                </Flex>

                <Flex w="100%">
                  <Flex justify="space-between" align="center" w="100%">
                    <Text
                      color={textSecondary}
                      fontWeight="500"
                      fontSize="13px"
                    >
                      {t("common.status")}:
                    </Text>
                    <Text color={textPrimary} fontWeight="500" fontSize="13px">
                      {t("status.registered")}
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>

            <Flex justify="center" align="center" m="3rem 0 4rem" w="100%">
              <Button
                bg={primary}
                color={white}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                px="3rem"
                _hover={{ bg: shade(0.3, primary) }}
                onClick={() => navigate("/events")}
              >
                {t("publicCourses.backToEvents")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default VerifyPublicRegisterToken;
