import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listCourses } from "../../actions/courseActions";
import { useTranslation } from "react-i18next";
import OptionsOutsideSelect from "./OptionsOutsideSelect";
import { Flex, Text } from "@chakra-ui/react";

const SelectCourseMain = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  title,
  placeholderText,
  noteText,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const [courseOptions, setCourseOptions] = useState([]);

  useEffect(() => {
    dispatch(listCourses());
    console.log("Multi Courses select courses loaded", courses);
  }, [dispatch]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      const activeCourses = courses.filter(
        (course) => course.status !== "completed"
      );

      const options = activeCourses.map((s) => ({
        value: s._id, // Match the property name with value
        label: s.title, // Match the property name with label
        image: s.image,
        status: s.status,
      }));

      // Assuming currentOptions is structured differently, as indicated by your logs
      const currentOptionsFormatted = currentOptions.map((currentOption) => ({
        value: currentOption.course._id,
        label: currentOption.course.title,
        image: currentOption.course.image,
        status: currentOption.course.status,
      }));

      // Filter out the options that match the currentOptions
      const filteredOptions = options.filter(
        (option) =>
          !currentOptionsFormatted.some(
            (currentOption) => currentOption.value === option.value
          )
      );

      setCourseOptions([...filteredOptions]);
    }
  }, [courses, currentOptions]);

  // console.log("currentOptions", currentOptions);
  // console.log("courseOptions", courseOptions);

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={courseOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
        {noteText && (
          <Flex mt={2} align="center" justify="center">
            <Text fontSize="xs" color="gray.500">
              {noteText}
            </Text>
          </Flex>
        )}
      </div>
    </>
  );
};

export default SelectCourseMain;
