import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
  errorRed,
  bg,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const FormControlLength = ({
  fixture,
  core,
  setCore,
  diameter,
  setValue,
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  isDisabled,
}) => {
  const { t } = useTranslation();

  // STATE
  const [options, setOptions] = useState([]);

  // HANDLERS

  // USEEFFECT
  useEffect(() => {
    // setValue(null);

    if (fixture === "mini") {
      setOptions([
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
      ]);
    } else if (fixture === "anyridge" && core === "2.8") {
      setOptions([
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
      ]);
    } else if (
      fixture === "anyridge" &&
      core === "3.3" &&
      (diameter === "4.0" || diameter === "4.5")
    ) {
      setOptions([
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
      ]);
    } else if (fixture === "anyridge" && core === "3.8" && diameter === "4.5") {
      setOptions([
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
      ]);
    } else if (fixture === "anyridge" && core === "4.0" && diameter === "6.0") {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
      ]);
    } else if (fixture === "anyridge" && core === "4.3" && diameter === "6.0") {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
      ]);
    } else if (
      fixture === "anyridge" &&
      core === "4.8" &&
      (diameter === "6.0" ||
        diameter === "6.5" ||
        diameter === "7.0" ||
        diameter === "7.5" ||
        diameter === "8.0")
    ) {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
      ]);
    } else if (fixture === "bluediamond") {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
        { value: "18.0", label: "18.0" },
      ]);
    } else if (fixture === "ezplus") {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.5", label: "13.5" },
        { value: "15.0", label: "15.0" },
      ]);
    } else if (fixture === "rescue" && diameter === "8.5") {
      setOptions([{ value: "11.5", label: "11.5" }]);
    } else if (fixture === "rescue" && diameter === "9.0") {
      setOptions([{ value: "13.0", label: "13.0" }]);
    } else if (fixture === "rescue") {
      setOptions([
        { value: "5.0", label: "5.0" },
        { value: "6.0", label: "6.0" },
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
      ]);
    } else {
      setOptions([
        { value: "7.0", label: "7.0" },
        { value: "8.5", label: "8.5" },
        { value: "10.0", label: "10.0" },
        { value: "11.5", label: "11.5" },
        { value: "13.0", label: "13.0" },
        { value: "15.0", label: "15.0" },
      ]);
    }
  }, [fixture, core, diameter]);

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: "3rem",
      padding: "0 0.25rem",
      pointerEvents: isDisabled ? "none" : "auto",
      opacity: isDisabled ? 0.5 : 1,
      ":hover": {
        cursor: state.isDisabled ? "not-allowed" : "pointer",
      },
      ":focus-visible": {
        borderColor: primary,
        boxShadow: "none",
      },
      ":focus": {
        boxShadow: "none",
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,

      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : white,
      color: state.isFocused ? white : textSecondary,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `0px solid ${newBorder}`,
      bg: white,
      overflow: "hidden",
      padding: 0,
      margin: "0.5rem 0 0",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      bg: white,
    }),
  };

  // LOGS
  // console.log("fixture", fixture);
  // console.log("core", core);
  // console.log("diameter", diameter);
  // console.log("disabled", isDisabled);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>
      <Select
        isSearchable
        value={value ? options.find((option) => option.value === value) : null}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        isDisabled={isDisabled}
      />
      {/* <Tooltip
        label={t("formError.selectFixture")}
        p="0.5rem 1rem"
        borderRadius="1rem"
        placement="top"
        isOpen={showTooltip}
        bg={buttonRed}
        color={buttonRedText}
        w="100%"
      >
        <Flex
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          w="100%"
          minW="100%"
        >
          <Select
            isSearchable
            value={options.find((option) => option.value === value)}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={isDisabled}
          />
        </Flex>
      </Tooltip> */}
    </FormControl>
  );
};

export default FormControlLength;
