import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text, Stack, Icon, Image } from "@chakra-ui/react";
import {
  primary,
  textPrimary,
  textSecondary,
  backgroundLight,
  white,
  newBorder,
  buttonBlue,
  buttonBlueText,
} from "../../../../utils/colors";
import { getEmployeeClientsLatestReports } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";
import { FaSuitcase, FaExchangeAlt } from "react-icons/fa";
import dayjs from "dayjs";
import noServices from "../../../../assets/images/empty-box.png";

const EmployeeClientsReports = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // STATE
  //   const [employeeId, setEmployeeId] = useState(undefined);

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dashboardEmployeeClientsLatestReports = useSelector(
    (state) => state.dashboardEmployeeClientsLatestReports
  );
  const { loading, error, employeeClientsReports } =
    dashboardEmployeeClientsLatestReports;

  // USE EFFECT

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getEmployeeClientsLatestReports(userInfo._id));
    }
  }, [userInfo, dispatch]); // Depend on userInfo directly

  // LOGS

  // console.log("employeeClientsReports", employeeClientsReports);
  // console.log("userInfo", userInfo);

  if (loading) {
    return (
      <Flex align="center" justify="center" h="100%" w="100%" p="6rem 0">
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Stack gap="0">
          <Link to="/admin/reports/implant-failure-reports/">
            <Text
              color={textPrimary}
              fontSize="sm"
              fontWeight="500"
              _hover={{
                color: primary,
              }}
            >
              {t("dashboard.employeeClientReportsTitle")}
              <Text as="span" fontWeight="500" color={textSecondary} ml={1}>
                {/* {employeeClientsReports.length} */}
              </Text>
            </Text>
          </Link>
          <Text color={textSecondary} fontSize="xs" fontWeight="500">
            {t("dashboard.employeeClientReportsSubtitle")}
          </Text>
        </Stack>
      </Flex>

      <Flex direction="column" w="100%" p="1rem 0 0">
        {employeeClientsReports.map((report, index) => (
          <Flex
            key={index}
            align="center"
            justify="space-between"
            bg={white}
            p="0.75rem 0rem"
            transition="all 0.2s"
            borderRadius="0rem"
            position="relative"
            borderBottom={`1px solid ${newBorder}`}
            _hover={{
              bg: white,
              // color: buttonBlue,
              cursor: "pointer",
              px: "1rem",
            }}
            _last={{ borderBottom: "none" }}
            onClick={() => {
              navigate(
                `/admin/reports/implant-failure-reports/${report.details._id}`
              );
            }}
          >
            <Flex align="center">
              <Stack gap="0rem" w="150px">
                <Text fontSize="sm" fontWeight="500" textTransform="capitalize">
                  {dayjs(report.createdAt).format("MMMM D")}
                </Text>
                <Text color={textSecondary} fontSize="xs" fontWeight="500">
                  {report.details.trackingNumber}
                </Text>
              </Stack>

              <Flex align="center">
                <Flex
                  align="center"
                  justify="center"
                  w="2.35rem"
                  h="2.35rem"
                  borderRadius="10rem"
                  bg={buttonBlue}
                  mr={2}
                >
                  <Icon as={FaSuitcase} boxSize={3} color={buttonBlueText} />
                </Flex>
                <Stack gap={0.5} overflow="hidden">
                  {report.details.clientType === "individual" ? (
                    <Text
                      fontSize="xs"
                      color={textPrimary}
                      fontWeight="500"
                      isTruncated
                      lineHeight={1}
                      maxW="120px"
                    >
                      {report.details.firstName} {report.details.lastName}
                    </Text>
                  ) : (
                    <Text
                      fontSize="xs"
                      color={textSecondary}
                      fontWeight="500"
                      isTruncated
                      noOfLines={1}
                      whiteSpace="normal"
                      overflow="hidden"
                      w="100%"
                      maxW="120px"
                    >
                      {report.details.companyName}
                    </Text>
                  )}
                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    maxW="120px"
                  >
                    {t(`implantForm.${report.details.clientType}`)}
                  </Text>
                </Stack>
              </Flex>

              {/* <Text color={textPrimary} fontSize="1rem" fontWeight="500">
                {report.details.trackingNumber}
              </Text> */}
            </Flex>
            <Flex align="center">
              <Flex align="center" borderRadius="1rem" p="0rem 0rem">
                <Stack
                  gap={0}
                  overflow="hidden"
                  w="155px"
                  align="center"
                  justify="center"
                >
                  <Flex align="center" justify="center" textAlign="center">
                    {/* <Text
                    fontSize="13px"
                    color={textPrimary}
                    fontWeight="500"
                    isTruncated
                    lineHeight={1}
                  >
                    {(fixture && t(`implants.${fixture}`)) || "-"}
                  </Text> */}
                    <Text fontSize="xs" fontWeight="600" color={textPrimary}>
                      {t(`implants.${report.details.fixture}`)}
                      {report.details.core === null ? null : (
                        <>
                          {report.details.core === "regular" ||
                          report.details.core === "special" ||
                          report.details.core === "deep" ? (
                            <Text
                              as="span"
                              fontSize="xs"
                              color={textPrimary}
                              fontWeight="500"
                            >
                              {" "}
                              - ({t(`coreSimple.${report.details.core}`)})
                            </Text>
                          ) : (
                            <Text
                              as="span"
                              fontSize="xs"
                              color={textPrimary}
                              fontWeight="500"
                            >
                              - (C{report.details.core})
                            </Text>
                          )}
                        </>
                      )}
                    </Text>
                  </Flex>

                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    textAlign="center"
                  >
                    Ø{report.details.diameter}mm/L{report.details.length}{" "}
                  </Text>
                </Stack>

                <Flex
                  align="center"
                  p="0.5rem"
                  bg={backgroundLight}
                  borderRadius="10rem"
                  m="0 0.5rem"
                >
                  <Icon as={FaExchangeAlt} boxSize={2} color={textSecondary} />
                </Flex>

                <Stack gap={0} overflow="hidden" w="155px">
                  <Flex align="center" justify="center">
                    <Text fontSize="xs" fontWeight="600" color={textPrimary}>
                      {t(`implants.${report.details.newFixture}`)}
                      {report.details.newCore === null ? null : (
                        <>
                          {report.details.newCore === "regular" ||
                          report.details.newCore === "special" ||
                          report.details.newCore === "deep" ? (
                            <Text
                              as="span"
                              fontSize="xs"
                              color={textPrimary}
                              fontWeight="500"
                            >
                              {" "}
                              - ({t(`coreSimple.${report.details.newCore}`)})
                            </Text>
                          ) : (
                            <Text
                              as="span"
                              fontSize="xs"
                              color={textPrimary}
                              fontWeight="500"
                            >
                              - (C{report.details.newCore})
                            </Text>
                          )}
                        </>
                      )}
                    </Text>
                  </Flex>

                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    textAlign="center"
                  >
                    Ø{report.details.newDiameter}mm/L{report.details.newLength}{" "}
                  </Text>
                </Stack>
              </Flex>
              {/* <Text color={textPrimary} fontSize="1rem" fontWeight="500">
                {report.details.clientType}
              </Text> */}
            </Flex>
          </Flex>
        ))}

        {employeeClientsReports.length === 0 && (
          <Flex align="center" justify="center" w="100%" p="0rem 0 1rem">
            <Flex justify="center" align="center" direction="column" p="3rem 0">
              <Image h="3rem" w="auto" m="0 auto" src={noServices} />
              <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                {t("common.noData")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EmployeeClientsReports;
