import React from "react";
import {
  Flex,
  Box,
  Text,
  Image,
  HStack,
  Stack,
  Avatar,
  VStack,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import "dayjs/locale/en"; // Import the locale you want to use (if not already done)
import "dayjs/locale/lt";
import {
  textSecondary,
  textPrimary,
  newBorder,
  buttonBlue,
  buttonBlueText,
  backgroundLight,
} from "../../../../utils/colors";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";
import { FaUser, FaSuitcase } from "react-icons/fa";

export const columns = (
  openEditSidePanelModal,
  translate,
  deleteClientHandler,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="60px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("client")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("implantFailureReports.columnClient")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "client",
      disableSortBy: true,
      Cell: ({ row }) => {
        const {
          firstName,
          lastName,
          companyName,
          email,
          clientType,
          accountType,
        } = row.original;

        const name = `${firstName} ${lastName}`;

        return (
          <HStack gap={2}>
            <Flex
              align="center"
              justify="center"
              w="3rem"
              h="3rem"
              borderRadius="10rem"
              bg={buttonBlue}
            >
              <Icon
                as={clientType === "individual" ? FaUser : FaSuitcase}
                boxSize={3}
                color={buttonBlueText}
              />
            </Flex>

            <Stack gap={0.5} overflow="hidden">
              <Text
                fontSize="13px"
                color={textPrimary}
                fontWeight="500"
                isTruncated
                lineHeight={1}
                maxW="150px"
              >
                {clientType === "individual" ? name : companyName}
              </Text>

              <Text
                fontSize="xs"
                color={textSecondary}
                fontWeight="500"
                isTruncated
                noOfLines={1}
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
                maxW="120px"
              >
                {/* {clientType === "individual" ? companyName : name} */}
                {translate(`clientAccount.${accountType}`)}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        // console.log("row", row.original);

        const city =
          row.original.address && row.original.address.length > 0
            ? row.original.address[0].city
            : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("client.columnPhone")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row.original;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {phone ? phone : "-"}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("email")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("form.email")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "email",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { email } = row.original;

        return (
          <Text fontSize="13px" fontWeight="500" color={textSecondary}>
            {email ? email : "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("employee")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("client.columnEmployee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "employee",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { employee } = row.original;
        // console.log("row", row.original);

        const employeeName =
          employee && employee.lastName + " " + employee.firstName[0] + ".";

        return (
          <Flex alignItems="center">
            {employeeName && (
              <Avatar
                h="1.5rem"
                w="1.5rem"
                src={(employee && employee.image && employee.image) || ""}
                name={employeeName}
                border={`0px solid ${newBorder}`}
                mr={1}
              />
            )}
            <Text
              fontSize="13px"
              fontWeight="500"
              color={textSecondary}
              isTruncated
              maxW="110px"
            >
              {employeeName ? employeeName : "-"}
            </Text>
          </Flex>
          // <Flex
          //   alignItems="center"
          //   bg={backgroundLight}
          //   p="0.25rem 0.5rem"
          //   borderRadius="10rem"
          //   textAlign="center"
          //   align="center"
          //   justify="center"
          //   maxW="120px"
          // >
          //   <Text
          //     fontSize="13px"
          //     fontWeight="500"
          //     color={textPrimary}
          //     isTruncated
          //   >
          //     {employeeName ? employeeName : "-"}
          //   </Text>
          // </Flex>
        );
      },
    },
    // {
    //   Header: translate("client.columnStock"),
    //   accessor: (row) => {
    //     return (
    //       <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //         Priduotas
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: () => (
    //     <Box onClick={() => handleSortChange("representative")}>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>{translate("client.columnRepresentative")}</Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "representative",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     const { employee } = row.original;

    //     // console.log("row", row);
    //     // console.log("employee", employee);

    //     const name =
    //       employee &&
    //       employee.firstName &&
    //       employee.firstName + " " + employee.lastName;

    //     return (
    //       <div>
    //         <Flex alignItems="center">
    //           {employee && (
    //             <Tooltip label={name} key={employee._id}>
    //               <Avatar
    //                 h="1.75rem"
    //                 w="1.75rem"
    //                 src={(employee && employee.image && employee.image) || ""}
    //                 name={
    //                   employee &&
    //                   employee.firstName &&
    //                   employee.firstName + " " + employee.lastName
    //                 }
    //                 border={`1px solid ${newBorder}`}
    //               />
    //             </Tooltip>
    //           )}
    //         </Flex>
    //       </div>
    //     );
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { _id, status, isVerified } = row.original;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateCourseStatus(_id, newStatus));
        };

        console.log("isVerified", isVerified);

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              isUnclickable={!isVerified}
              currentStatus={isVerified ? status : "unverified"}
              onUpdateStatus={handleUpdateStatus}
              options="active"
            />
            {/* <MoreButton
              id={_id}
              deleteHandler={deleteClientHandler}
              // editHandler={(e) => {
              //   e.stopPropagation();
              //   openEditSidePanelModal(_id);
              // }}
            /> */}
          </HStack>
        );
      },
    },
  ];
};
