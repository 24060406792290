import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listClientDetails,
  updateClient,
} from "../../../../actions/clientActions";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  HStack,
  TabPanels,
  TabPanel,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  backgroundLight,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import CitySelect from "../../../../components/select/CitySelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlEmployee from "../../../../components/input/FormControlEmployee";
import { shade } from "polished";

const EditClient = ({ clientId }) => {
  // TRANSLATION
  const { t } = useTranslation();

  // TABS
  const tabsData = [
    { label: t("client.tabDetails") },
    { label: t("client.tabContacts") },
    { label: t("client.tabRepresentatives") },
    // { label: t("client.tabDelivery") },
  ];

  // STATE
  const [activeTab, setActiveTab] = useState(0);

  const [image, setImage] = useState("");
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [vatCode, setVatCode] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [employee, setEmployee] = useState("");

  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("LT");
  const [state, setState] = useState("");

  const [repFirstName, setRepFirstName] = useState("");
  const [repLastName, setRepLastName] = useState("");
  const [repPhone, setRepPhone] = useState("");
  const [repEmail, setRepEmail] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  // REDUX
  const dispatch = useDispatch();

  const clientDetails = useSelector((state) => state.clientDetails);
  const { client } = clientDetails;

  // TOUCHED
  const [clientNameTouched, setClientNameTouched] = useState(false);
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [companyCodeTouched, setCompanyCodeTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  // ERRORS
  const isClientNameError = clientName === "";
  const isCompanyNameError = companyName === "";
  const isCompanyCodeError = companyCode === "";
  const isEmailError = email === "";

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isClientNameValid = clientName.length >= 1;
  const isCompanyNameValid = companyName.length >= 1;
  const isCompanyCodeValid = companyCode.length >= 1;
  const isEmailValid = isValidEmail(email);

  // IS CONTINUE DISABLED
  const isContinueDisabled =
    !isClientNameValid || !isCompanyNameValid || !isCompanyCodeValid;

  const isCreateDisabled =
    !isClientNameValid ||
    !isCompanyNameValid ||
    !isCompanyCodeValid ||
    !isEmailValid;

  // HANDLERS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
    setEmployee(selectedOption.value);
  };

  // SUBMIT
  const submitHandler = (e) => {
    e.preventDefault();

    const updatedClient = {
      _id: clientId,
      image,
      clientName,
      companyName,
      email,
      phone,
      phoneAlt,
      companyCode,
      vatCode,
      employee,
      website,
      street,
      city,
      zip,
      country,
      state,
      repFirstName,
      repLastName,
      repPhone,
      repEmail,
    };

    dispatch(updateClient(updatedClient));
  };

  // USE EFFECT

  useEffect(() => {
    if (!client || !client.clientName || client._id !== clientId) {
      dispatch(listClientDetails(clientId));
    } else {
      setImage(client.image);
      setClientName(client.clientName);
      setCompanyName(client.companyName);
      setCompanyCode(client.companyCode);
      setEmail(client.email);
      setPhone(client.phone);
      setPhoneAlt(client.phoneAlt);
      setVatCode(client.vatCode);
      setEmployee(client.employee);
      setWebsite(client.website);

      // // Assuming this is done where you fetch the client details and set the form state:
      // if (client.employee) {
      //   setEmployee({
      //     value: client.employee._id,
      //     label: client.employee.firstName + " " + client.employee.lastName,
      //   });
      // }

      if (client.address) {
        setCity(client.address[0]?.city || "");
        setStreet(client.address[0]?.street || "");
        setZip(client.address[0]?.zip || "");
        setCountry(client.address[0]?.country || "");
        setState(client.address[0]?.state || "");
      }

      if (client.representative) {
        setRepFirstName(client.representative[0]?.repFirstName);
        setRepLastName(client.representative[0]?.repLastName);
        setRepPhone(client.representative[0]?.repPhone);
        setRepEmail(client.representative[0]?.repEmail);
      }
    }
  }, [dispatch, clientId, client]);

  // LOGS

  console.log("employee", employee);
  console.log("selectedEmployee", selectedEmployee);

  return (
    <form onSubmit={submitHandler}>
      <TabsComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabsData}
        isTitleValid={!isContinueDisabled}
        disabledTabs={[]}
      >
        <TabPanels m="1.5rem 0 0">
          <TabPanel p={0}>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.image")}</FormLabel>
              <ImageUpload image={image} setImage={setImage} />
            </FormControl>

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.companyName")}
              value={companyName}
              placeholder={t("placeholder.companyName")}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setCompanyNameTouched(true);
              }}
              onBlur={() => setCompanyNameTouched(true)}
              isTouched={companyNameTouched}
              isError={isCompanyNameError}
              w="100%"
              errorMessage={t("formError.companyNameRequired")}
            />

            <FormControlDefault
              type="text"
              isRequired
              label={t("form.clientName")}
              value={clientName}
              placeholder={t("placeholder.clientName")}
              onChange={(e) => {
                setClientName(e.target.value);
                setClientNameTouched(true);
              }}
              onBlur={() => setClientNameTouched(true)}
              isTouched={clientNameTouched}
              isError={isClientNameError}
              w="100%"
              errorMessage={t("formError.clientNameRequired")}
            />

            <HStack gap={4}>
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.companyCode")}
                value={companyCode}
                placeholder={t("placeholder.companyCode")}
                onChange={(e) => {
                  setCompanyCode(e.target.value);
                  setCompanyCodeTouched(true);
                }}
                onBlur={() => setCompanyCodeTouched(true)}
                isTouched={companyCodeTouched}
                isError={isCompanyCodeError}
                w="50%"
                errorMessage={t("formError.companyCodeRequired")}
              />
              <FormControlDefault
                type="text"
                label={t("form.vatCode")}
                value={vatCode}
                placeholder={t("placeholder.vatCode")}
                onChange={(e) => {
                  setVatCode(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.vatCodeRequired")}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <FormControlAddress
                currentValue={street}
                label={t("form.address")}
                onAddressSelect={handleAddressSelect}
                isRequired={false}
                errorMessage={t("formError.addressRequired")}
                w="100%"
              />

              <FormControlDefault
                type="text"
                isRequired={false}
                label={t("form.zip")}
                value={zip}
                placeholder={t("placeholder.zip")}
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                w="40%"
                errorMessage={t("formError.zipRequired")}
              />
            </HStack>

            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                isRequired={false}
                label={t("form.city")}
                value={city}
                placeholder={t("placeholder.city")}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.cityRequired")}
              />

              <FormControlCountry
                label={t("form.country")}
                value={country}
                onChange={(selectedOption) => {
                  setCountry(selectedOption ? selectedOption.value : "");
                }}
                isRequired={false}
                w="50%"
                errorMessage={t("formError.countryRequired")}
              />
            </HStack>
          </TabPanel>
          <TabPanel p={0}>
            <HStack gap={4} w="100%">
              <FormControlPhone
                label={t("form.clientMobPhoneNumber")}
                value={phone}
                onChange={(newPhone) => {
                  setPhone(newPhone);
                }}
                w="50%"
                defaultCountry="lt"
              />

              <FormControlPhone
                label={t("form.clientPhoneNumber")}
                value={phoneAlt}
                onChange={(newPhone) => {
                  setPhoneAlt(newPhone);
                }}
                w="50%"
                defaultCountry="lt"
              />
            </HStack>

            <FormControlDefault
              type="email"
              label={t("form.email")}
              isRequired
              value={email}
              placeholder={t("placeholder.email")}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailTouched(true);
              }}
              onBlur={() => setEmailTouched(true)}
              isTouched={emailTouched}
              isError={!isEmailValid}
              w="100%"
              errorMessage={t("formError.emailInvalid")}
            />

            <FormControlDefault
              type="text"
              label={t("form.website")}
              value={website}
              placeholder={t("placeholder.website")}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
              w="100%"
            />
          </TabPanel>

          <TabPanel p={0}>
            <HStack gap={4} w="100%">
              <FormControlDefault
                type="text"
                label={t("form.repFirstName")}
                value={repFirstName}
                placeholder={t("placeholder.repFirstName")}
                onChange={(e) => {
                  setRepFirstName(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.repFirstNameRequired")}
              />

              <FormControlDefault
                type="text"
                label={t("form.repLastName")}
                value={repLastName}
                placeholder={t("placeholder.repLastName")}
                onChange={(e) => {
                  setRepLastName(e.target.value);
                }}
                w="50%"
                errorMessage={t("formError.repLastNameRequired")}
              />
            </HStack>

            <FormControlPhone
              label={t("form.repPhoneNumber")}
              value={repPhone}
              onChange={(newPhone) => {
                setRepPhone(newPhone);
              }}
              w="100%"
              defaultCountry="lt"
            />

            <FormControlDefault
              type="email"
              label={t("form.repEmail")}
              value={repEmail}
              placeholder={t("placeholder.repEmail")}
              onChange={(e) => {
                setRepEmail(e.target.value);
              }}
              w="100%"
            />

            <FormControlEmployee
              label={t("form.clientEmployee")}
              value={employee}
              placeholder={t("placeholder.clientEmployee")}
              onChange={handleEmployeeChange}
              isRequired={false}
              w="100%"
              errorMessage={t("formError.employeeRequired")}
              onEmployeeChange={handleEmployeeChange}
            />
          </TabPanel>
        </TabPanels>

        <Flex
          mt={4}
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0 0"
        >
          <Flex justify="space-between">
            <Tooltip
              bg={white}
              borderRadius="1rem"
              p="1rem"
              label={
                <Flex
                  direction="column"
                  p="0rem"
                  justify="center"
                  textAlign="center"
                >
                  {isClientNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.clientNameRequired")}
                    </Text>
                  )}
                  {isCompanyNameError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.companyNameRequired")}
                    </Text>
                  )}
                  {isCompanyCodeError && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.companyCodeRequired")}
                    </Text>
                  )}
                  {!isEmailValid && (
                    <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                      * {t("formError.emailInvalid")}
                    </Text>
                  )}
                </Flex>
              }
              isDisabled={!isCreateDisabled}
              hasArrow
              placement="top"
              fontSize="md"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                isDisabled={isCreateDisabled}
                _hover={{
                  bg: shade(0.3, primary),
                }}
                ml={4}
              >
                {t("button.save")}
              </Button>
            </Tooltip>

            <Flex align="center">
              {activeTab > 0 && (
                <Button
                  minWidth="8rem"
                  mr={0}
                  bg={backgroundLight}
                  border={`1px solid ${newBorder}`}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  fontWeight="500"
                  borderRadius="10rem"
                  onClick={() => setActiveTab(activeTab - 1)}
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.1, backgroundLight),
                  }}
                >
                  {t("common.back")}
                </Button>
              )}

              {activeTab < 2 && (
                <Tooltip
                  bg={white}
                  borderRadius="1rem"
                  p="1rem"
                  label={
                    <Flex
                      direction="column"
                      p="0rem"
                      justify="center"
                      textAlign="center"
                    >
                      {isClientNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.clientNameRequired")}
                        </Text>
                      )}
                      {isCompanyNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.companyNameRequired")}
                        </Text>
                      )}
                      {isCompanyCodeError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.companyCodeRequired")}
                        </Text>
                      )}
                    </Flex>
                  }
                  isDisabled={!isContinueDisabled}
                  hasArrow
                  placement="top"
                  fontSize="md"
                >
                  <Button
                    minWidth="8rem"
                    ml={4}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab + 1)}
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                  >
                    {t("common.continue")}
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </TabsComponent>
    </form>
  );
};

export default EditClient;
