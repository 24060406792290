import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  updateUserStatus,
  deleteUser,
} from "../../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, useToast } from "@chakra-ui/react";
import { columns } from "../columns/EmployeesColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import CreateEmployee from "../sidepanels/CreateEmployee";
import EditEmployee from "../sidepanels/EditEmployee";
import ToastComponent from "../../../../components/partials/ToastComponent";
import {
  USER_REGISTER_RESET,
  USER_UPDATE_RESET,
  USER_STATUS_UPDATE_RESET,
  USER_DELETE_RESET,
} from "../../../../constants/userConstants";

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  //STATE

  const [userId, setUserId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, pages } = userList;

  const userStatusUpdate = useSelector((state) => state.userStatusUpdate);
  const { success: successStatusUpdate } = userStatusUpdate;

  const userRegister = useSelector((state) => state.userRegister);
  const {
    success: successCreate,
    user: createdUser,
    error: errorCreate,
  } = userRegister;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate } = userUpdate;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listUsers(searchTitle, page, pageSize));
  };

  // PAGINATION
  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  // HANDLERS
  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const deleteUserHandler = (userId) => {
    console.log("click");
    dispatch(deleteUser(userId));
    if (users.length === 1) {
      setPage(page - 1);
    }
  };

  // SIDEPANELS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setUserId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    console.log("close");
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    console.log("sidepanel", sidePanelModalOpen);
    setUserId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setUserId("");
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    } else {
      dispatch(listUsers(searchTitle, 1, pageSize, sortField, sortOrder));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (successCreate) {
      console.log("createdUser", createdUser);
      toastComponent.showToast(t("toasts.userCreate"), "success", 3000);
      dispatch({ type: USER_REGISTER_RESET }); // Resetting right after showing the toast
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
    } else if (errorCreate) {
      toast({
        title: t("toasts.userCreateError"),
        description: errorCreate,
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: USER_REGISTER_RESET });
    }
  }, [dispatch, successCreate, errorCreate]);

  useEffect(() => {
    if (successStatusUpdate) {
      toastComponent.showToast(t("toasts.userStatus"), "success", 3000);
      dispatch({ type: USER_STATUS_UPDATE_RESET });
      document.body.classList.remove("no-scroll");
      dispatch(listUsers(searchTitle, 1, pageSize, sortField, sortOrder));
    } else if (successUpdate) {
      toastComponent.showToast(t("toasts.userEdit"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: USER_UPDATE_RESET });
      dispatch(listUsers(searchTitle, 1, pageSize, sortField, sortOrder));
    } else if (successDelete) {
      toastComponent.showToast(t("toasts.userDelete"), "success", 3000);
      dispatch({ type: USER_DELETE_RESET });
      dispatch(listUsers(searchTitle, 1, pageSize, sortField, sortOrder));
    }
  }, [
    userInfo,
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    sortField,
    sortOrder,
    successUpdate,
    successStatusUpdate,
    successDelete,
  ]);

  console.log("users", users); // eslint-disable-next-line react-hooks/exhaustive-deps
  console.log("sidepanel", sidePanelModalOpen);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.adminEmployees")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminEmployees")}
        />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("user.createUser")}
        noBorder={false}
      >
        <CreateEmployee closeSidePanelModal={closeSidePanelModal} />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("user.editUser")}
        noBorder={false}
      >
        <EditEmployee userId={userId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("user.users")}
                onChange={onChangeSearchTitle}
                placeholder={t("user.search")}
                searchName={searchTitle}
                buttonLabel={t("user.createUser")}
                buttonHandler={openSidePanelModal}
                isFixedTitle
              ></Table.Top>
            </Box>

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                handleSortChange,
                updateUserStatus,
                openEditSidePanelModal,
                deleteUserHandler
              )}
              data={users}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/admin/users"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noUsers")}
              noItemsDescription={t("noItems.noUsersText")}
              noItemsButtonLabel={t("noItems.noUsersButton")}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Employees;
