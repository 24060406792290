import React from "react";
import { Flex, Text, Tooltip, Icon } from "@chakra-ui/react";
import { textPrimary, textSecondary, white } from "../../utils/colors";
import { shade } from "polished";
import * as Icons from "react-icons/tb";

const TooltipText = ({ icon, label, value, iconText, maxW }) => {
  // console.log("icon", icon);
  const IconComponent = Icons[icon];

  if (!IconComponent) {
    console.error(`Icon ${icon} not found in 'react-icons/tb'`);
    return null; // Or handle this case as needed
  }

  return (
    <Tooltip
      p="0.5rem"
      borderRadius="1rem"
      label={
        <Flex alignItems="center">
          {iconText ? (
            <Text
              fontSize="sm"
              color={shade(0.3, white)}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {label}:
            </Text>
          ) : (
            <IconComponent color={shade(0.3, white)} />
          )}
          <Text fontSize="sm" ml={1} color={shade(0.3, white)} fontWeight="500">
            <Text as="span" ml={1} fontSize="sm" color={white} opacity="1">
              {value}
            </Text>
          </Text>
        </Flex>
      }
      placement="top"
    >
      <Flex alignItems="center">
        {iconText ? (
          <Text fontSize="sm" color={textSecondary} fontWeight="500">
            {label}:
          </Text>
        ) : (
          <IconComponent color={textSecondary} />
        )}
        <Text
          fontSize="sm"
          ml={2}
          color={textPrimary}
          fontWeight="500"
          maxW={maxW}
          isTruncated={maxW ? true : false}
        >
          {value}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default TooltipText;
