export const CLINIC_LIST_REQUEST = 'CLINIC_LIST_REQUEST';
export const CLINIC_LIST_SUCCESS = 'CLINIC_LIST_SUCCESS';
export const CLINIC_LIST_FAIL = 'CLINIC_LIST_FAIL';

export const CLINIC_DETAILS_REQUEST = 'CLINIC_DETAILS_REQUEST';
export const CLINIC_DETAILS_SUCCESS = 'CLINIC_DETAILS_SUCCESS';
export const CLINIC_DETAILS_FAIL = 'CLINIC_DETAILS_FAIL';

export const CLINIC_UPDATE_REQUEST = 'CLINIC_UPDATE_REQUEST';
export const CLINIC_UPDATE_SUCCESS = 'CLINIC_UPDATE_SUCCESS';
export const CLINIC_UPDATE_FAIL = 'CLINIC_UPDATE_FAIL';
export const CLINIC_UPDATE_RESET = 'CLINIC_UPDATE_RESET';

export const CLINIC_CREATE_REQUEST = 'CLINIC_CREATE_REQUEST';
export const CLINIC_CREATE_SUCCESS = 'CLINIC_CREATE_SUCCESS';
export const CLINIC_CREATE_FAIL = 'CLINIC_CREATE_FAIL';
export const CLINIC_CREATE_RESET = 'CLINIC_CREATE_RESET';

export const CLINIC_DELETE_REQUEST = 'CLINIC_DELETE_REQUEST';
export const CLINIC_DELETE_SUCCESS = 'CLINIC_DELETE_SUCCESS';
export const CLINIC_DELETE_FAIL = 'CLINIC_DELETE_FAIL';
export const CLINIC_DELETE_RESET = 'CLINIC_DELETE_RESET';
