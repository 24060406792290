import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
  errorRed,
  bg,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const FormControlFixtureExchange = ({
  fixture,

  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   onChange(null); // Reset value to null whenever fixture changes
  // }, [onChange]);

  useEffect(() => {
    if (fixture === "mini") {
      setOptions([
        { value: "mini", label: "Mini", shortCode: "MN", core: false },
        { value: "anyone", label: "AnyOne", shortCode: "AO", core: true },
      ]);
    } else if (fixture === "st") {
      setOptions([{ value: "st", label: "ST", shortCode: "ST", core: false }]);
    } else if (fixture === "anyone") {
      setOptions([
        { value: "anyone", label: "AnyOne", shortCode: "AO", core: true },
      ]);
    } else if (fixture === "anyridge") {
      setOptions([
        { value: "anyridge", label: "AnyRidge", shortCode: "AR", core: true },
        {
          value: "bluediamond",
          label: "Blue Diamond",
          shortCode: "BD",
          core: true,
        },
      ]);
    } else if (fixture === "bluediamond") {
      setOptions([
        {
          value: "bluediamond",
          label: "Blue Diamond",
          shortCode: "BD",
          core: true,
        },
      ]);
    } else if (fixture === "ezplus") {
      setOptions([
        { value: "anyone", label: "AnyOne", shortCode: "AO", core: true },
      ]);
    } else if (fixture === "rescue") {
      setOptions([
        { value: "anyridge", label: "AnyRidge", shortCode: "AR", core: true },
        {
          value: "bluediamond",
          label: "Blue Diamond",
          shortCode: "BD",
          core: true,
        },
      ]);
    }
  }, [fixture]);

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: "3rem",
      padding: "0 0.25rem",
      ":hover": {
        cursor: "pointer",
      },
      ":focus-visible": {
        borderColor: primary,
        boxShadow: "none",
      },
      ":focus": {
        boxShadow: "none",
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,

      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : white,
      color: state.isFocused ? white : textSecondary,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `0px solid ${newBorder}`,
      bg: white,
      overflow: "hidden",
      padding: 0,
      margin: "0.5rem 0 0",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      bg: white,
    }),
  };

  // console.log("fixture options", options);
  console.log("new fixture value", value);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={value ? options.find((option) => option.value === value) : null}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
      />
    </FormControl>
  );
};

export default FormControlFixtureExchange;
