import axios from "axios";
import {
  PROFESSIONGROUP_LIST_REQUEST,
  PROFESSIONGROUP_LIST_SUCCESS,
  PROFESSIONGROUP_LIST_FAIL,
  PROFESSIONGROUP_DETAILS_REQUEST,
  PROFESSIONGROUP_DETAILS_SUCCESS,
  PROFESSIONGROUP_DETAILS_FAIL,
  PROFESSIONGROUP_CREATE_REQUEST,
  PROFESSIONGROUP_CREATE_SUCCESS,
  PROFESSIONGROUP_CREATE_FAIL,
  PROFESSIONGROUP_UPDATE_REQUEST,
  PROFESSIONGROUP_UPDATE_SUCCESS,
  PROFESSIONGROUP_UPDATE_FAIL,
  PROFESSIONGROUP_DELETE_REQUEST,
  PROFESSIONGROUP_DELETE_SUCCESS,
  PROFESSIONGROUP_DELETE_FAIL,
  PROFESSIONGROUP_UPDATE_STATUS_REQUEST,
  PROFESSIONGROUP_UPDATE_STATUS_SUCCESS,
  PROFESSIONGROUP_UPDATE_STATUS_FAIL,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL,
} from "../constants/professionGroupConstants";

import { logout } from "./userActions";

export const listProfessionGroups =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    fetchAll = false,
    status = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PROFESSIONGROUP_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("Received Params in Action:", {
        keyword,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        fetchAll,
        status,
      });

      // Constructing the query string
      let query = `/api/profession-groups?keyword=${encodeURIComponent(
        keyword
      )}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      if (fetchAll) query += `&fetchAll=${fetchAll}`;

      if (status) query += `&status=${status}`;

      console.log("Query:", query);

      const { data } = await axios.get(query, config);

      dispatch({
        type: PROFESSIONGROUP_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProfessionGroupDetails =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROFESSIONGROUP_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/profession-groups/${id}`, config);

      dispatch({
        type: PROFESSIONGROUP_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createProfessionGroup =
  (name, defaultRolePermission) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSIONGROUP_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/profession-groups`,
        { name, defaultRolePermission },
        config
      );

      dispatch({
        type: PROFESSIONGROUP_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateProfessionGroup =
  (professionGroup) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/profession-groups/${professionGroup._id}`,
        professionGroup,
        config
      );

      dispatch({
        type: PROFESSIONGROUP_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: PROFESSIONGROUP_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: PROFESSIONGROUP_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteProfessionGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFESSIONGROUP_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/profession-groups/${id}`, config);

    dispatch({
      type: PROFESSIONGROUP_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROFESSIONGROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProfessionGroupStatus =
  (professionGroupId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/profession-groups/${professionGroupId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: PROFESSIONGROUP_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProfessionGroupIsConsulting =
  (professionGroupId, newIsConsulting) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/profession-groups/${professionGroupId}/isConsulting`,
        { isConsulting: newIsConsulting }, // Send the new status in the request body
        config
      );

      dispatch({
        type: PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
