import React from "react";
import DentalViewer from "./DentalViewer";
import { Flex } from "@chakra-ui/react";

const Testas = () => {
  return (
    <Flex w="100%" p="calc(80px + 3rem) 3rem">
      {/* <DentalViewer /> */}
    </Flex>
  );
};

export default Testas;
