import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import {
  listCourseDetails,
  updateCourseStatus,
  deleteCourse,
} from "../../../../actions/courseActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditCourse from "../sidepanels/EditCourse";
import { useTranslation } from "react-i18next";
import TabCourseAttendees from "./tabs/TabCourseAttendees";
import CourseInfo from "./partials/CourseInfo";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import TabCourseDescription from "./tabs/TabCourseDescription";
import {
  COURSE_UPDATE_RESET,
  COURSE_STATUS_UPDATE_RESET,
} from "../../../../constants/courseConstants";
import ToastComponent from "../../../../components/partials/ToastComponent";
import TabCourseKanban from "./tabs/TabCourseKanban";
import TabCoursePricing from "./tabs/TabCoursePricing";
import TabCourseSpeakers from "./tabs/TabCourseSpeakers";
import SinglePagePieChart from "../../../../components/charts/SinglePagePieChart";

const CourseSingle = () => {
  const courseId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("course.attendees") },
    { label: t("course.speakers") },
    { label: t("course.tabDescription") },
    { label: t("course.tabPreparation") },
    { label: t("course.tabReminders") },
    { label: t("course.tabPricing") },
  ];
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [prices, setPrices] = useState([]);

  const courseDetails = useSelector((state) => state.courseDetails);
  const { loading, course } = courseDetails;

  const courseUpdate = useSelector((state) => state.courseUpdate);
  const { success: successUpdate } = courseUpdate;

  const courseDelete = useSelector((state) => state.courseDelete);
  const { success: successDelete } = courseDelete;

  const courseStatusUpdate = useSelector((state) => state.courseStatusUpdate);
  const { success: successUpdateStatus } = courseStatusUpdate;

  const courseAttendeeAdd = useSelector((state) => state.courseAttendeeAdd);
  const { success: successAddAttendee } = courseAttendeeAdd;

  const courseAttendeeRemove = useSelector(
    (state) => state.courseAttendeeRemove
  );
  const { success: successRemoveAttendee } = courseAttendeeRemove;

  const courseAttendeeStatusUpdate = useSelector(
    (state) => state.courseAttendeeStatusUpdate
  );
  const { success: successUpdateAttendeeStatus } = courseAttendeeStatusUpdate;

  const courseUpdateImage = useSelector((state) => state.courseUpdateImage);
  const { success: successUpdateImage } = courseUpdateImage;

  const deleteCourseHandler = (id) => {
    dispatch(deleteCourse(id));
    toastComponent.showToast(t("toasts.courseDelete"), "success", 3000);
  };

  const handleUpdateStatus = (status) => {
    dispatch(updateCourseStatus(courseId, status));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!course.title || course._id !== courseId) {
      dispatch(listCourseDetails(courseId));
    } else if (successDelete) {
      dispatch({ type: COURSE_UPDATE_RESET });
      navigate("/admin/academy/courses/");
    } else if (successUpdateStatus) {
      dispatch({ type: COURSE_STATUS_UPDATE_RESET });
      dispatch(listCourseDetails(courseId));
    } else if (successUpdateImage) {
      toastComponent.showToast(t("toasts.imageUpdated"), "success", 3000);

      dispatch(listCourseDetails(courseId));
    } else {
      setImage(course.image);
      setStatus(course.status);
      setTitle(course.title);
      setAttendees(course.attendees);
      setPrices(course.prices);

      if (course?.address[0]?.city) {
        setCity(course?.address[0]?.city);
      }

      if (course?.address[0]?.street) {
        setPlace(
          course?.address[0]?.street + `, ` + course?.address[0]?.zip || ""
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    navigate,
    courseId,
    course,
    successDelete,
    successUpdateStatus,
    successUpdateImage,
    successUpdate,
  ]);

  useEffect(() => {
    if (successUpdate) {
      toastComponent.showToast(t("toasts.courseUpdated"), "success", 3000);
      closeEditSidePanel();
    } else if (successDelete) {
      toastComponent.showToast(t("toasts.courseDelete"), "success", 3000);
    }
    dispatch({ type: COURSE_UPDATE_RESET });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdate]);

  useEffect(() => {
    if (successAddAttendee) {
      dispatch(listCourseDetails(courseId));

      dispatch({ type: "COURSE_ATTENDEE_ADD_RESET" });
    } else if (successRemoveAttendee) {
      dispatch(listCourseDetails(courseId));

      dispatch({ type: "COURSE_ATTENDEE_REMOVE_RESET" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, successAddAttendee, successRemoveAttendee]);

  useEffect(() => {
    if (successUpdateAttendeeStatus) {
      dispatch(listCourseDetails(courseId));

      dispatch({ type: "COURSE_ATTENDEE_STATUS_UPDATE_RESET" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, successUpdateAttendeeStatus]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  // console.log("maxAttendees", course.maximumAttendees);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.adminCoursesSingle")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminCoursesSingle")}
        />
      </Helmet>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("course.buttonEditLabel")}
        noBorder={true}
      >
        <EditCourse courseId={courseId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={course.title}
              type={t("course.course")}
              loading={loading}
            >
              <StatusButton
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="course"
              />
              <MoreButton
                id={courseId}
                isBig
                deleteHandler={deleteCourseHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(courseId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 0rem 0rem 2rem"
                  alignItems="flex-start"
                  justifyContent="center"
                  w="100%"
                >
                  <SinglePageImageWrapper
                    id={courseId}
                    borderRadius="2rem"
                    image={course.image}
                    options="course"
                  />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <CourseInfo course={course} />
                      <SinglePagePieChart
                        label={t("course.courseAttendeeStats")}
                        data={course.attendees}
                        options="attendees"
                        plotLabel={t("stats.totalAttendees")}
                      />
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                isProfessionValid={true}
                disabledTabs={[4]}
                isSinglePage
              >
                <TabPanels p="0 0 2rem">
                  <TabPanel pb={4} px={0}>
                    <TabCourseAttendees
                      title={course.title}
                      status={course.status}
                    />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    <TabCourseSpeakers
                      title={course.title}
                      status={course.status}
                    />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    <TabCourseDescription title={course.title} />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    <TabCourseKanban
                      courseId={courseId}
                      title={course.title}
                      maxAttendees={course.maximumAttendees}
                    />
                  </TabPanel>
                  <TabPanel pb={4} px={0}></TabPanel>

                  <TabPanel pb={4} px={0}>
                    <TabCoursePricing title={course.title} />
                  </TabPanel>
                </TabPanels>
              </TabsComponent>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default CourseSingle;
