import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Icon,
  Box,
  Heading,
  IconButton,
  Image,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../../utils/colors";
import { icons } from "../../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from "react-redux";
import TooltipText from "../../../../../../components/partials/TooltipText";
import implant from "../../../../../../assets/images/implant.png";
import { FaExchangeAlt } from "react-icons/fa";
import noServices from "../../../../../../assets/images/empty-box.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const ReportInfo = ({ data, userInfo }) => {
  const { t } = useTranslation();

  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  // Define the base titles for different slides
  let slideTitles = [
    t("implantFailureReports.clientInfoTitle"),
    t("implantFailureReports.procedureInfoTitle"),
    t("implantForm.beforeXrayImage"),
    t("implantForm.afterXrayImage"),
  ];

  const [workAddress, setWorkAddress] = useState("");

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  useEffect(() => {
    if (data && data.address) {
      const { street, city, zip, country } = data.address;
      const addressParts = [street, city, zip, country].filter(Boolean);
      setWorkAddress(addressParts.join(", "));
    } else {
      setWorkAddress("-");
    }
  }, [data]);

  const infoArray = [
    {
      label: "Tooth",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.teeth")}
          value={data.tooth}
          iconText
        />
      ),
    },

    {
      label: "SurgeryDate",
      value: (
        <TooltipText
          icon="TbCalendar"
          label={t("implantForm.surgeryDate")}
          value={
            data.surgeryDate === null
              ? "-"
              : dayjs(data.surgeryDate).format("D MMMM, YYYY")
          }
          iconText
        />
      ),
    },

    {
      label: "FailureDate",
      value: (
        <TooltipText
          icon="TbCalendar"
          label={t("implantForm.failureDate")}
          value={
            data.failureDate === null
              ? "-"
              : dayjs(data.failureDate).format("D MMMM, YYYY")
          }
          iconText
        />
      ),
    },

    {
      label: "Reason",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.reason")}
          value={
            data.reason === "Other"
              ? data.reasonText
              : t(`implantForm.${data.reason}`)
          }
          iconText
          maxW="200px"
        />
      ),
    },

    // {
    //   label: "X-Ray",
    //   value: (
    //     <TooltipText
    //       icon="TbRadioactive"
    //       label={t("implantForm.xray")}
    //       value={
    //         <Text as="span" fontSize="sm" m="0">
    //           {t("implantForm.beforeXrayImageAlt")}:
    //           <Text as="span" ml={1} mr={2} opacity="1" fontWeight="500">
    //             {data.beforeXrayImage ? t("common.yes") : t("common.no")},
    //           </Text>
    //           {t("implantForm.afterXrayImageAlt")}:
    //           <Text as="span" ml={1} opacity="1">
    //             {data.afterXrayImage ? t("common.yes") : t("common.no")}
    //           </Text>
    //         </Text>
    //       }
    //       iconText
    //     />
    //   ),
    // },

    {
      label: "Surgeon",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantForm.surgeon")}
          value={data.surgeonFirstName + " " + data.surgeonLastName}
          iconText
        />
      ),
    },
  ];

  const clientArray = [
    ...(data.clientType === "individual"
      ? [
          {
            label: "ClientType",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("implantForm.clientType")}
                value={t(`implantForm.${data.clientType}`)}
                iconText
              />
            ),
          },
          {
            label: "Client",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.client")}
                value={data.firstName + " " + data.lastName}
                iconText
              />
            ),
          },
          {
            label: "Email",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.email")}
                value={data.email}
                iconText
              />
            ),
          },
          {
            label: "Phone",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.phone")}
                value={userInfo && userInfo.phone ? userInfo.phone : "-"}
                iconText
              />
            ),
          },
          {
            label: "Address",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("form.whereToSend")}
                value={workAddress}
                iconText
              />
            ),
          },
        ]
      : [
          {
            label: "ClientType",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("implantForm.clientType")}
                value={t(`implantForm.${data.clientType}`)}
                iconText
              />
            ),
          },
          {
            label: "CompanyName",
            value: (
              <TooltipText
                icon="TbUsers"
                label={t("form.companyName")}
                value={data.companyName}
                iconText
              />
            ),
          },
          {
            label: "Email",
            value: (
              <TooltipText
                icon="TbCalendar"
                label={t("implantForm.email")}
                value={data.email}
                iconText
              />
            ),
          },
          // {
          //   label: "CompanyCode",
          //   value: (
          //     <TooltipText
          //       icon="TbMapPin"
          //       label={t("form.companyCode")}
          //       value={data.companyCode}
          //       iconText
          //     />
          //   ),
          // },
          {
            label: "Phone",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.phone")}
                value={
                  data.userId && data.userId.phone ? data.userId.phone : "-"
                }
                iconText
              />
            ),
          },
          {
            label: "Representative",
            value: (
              <TooltipText
                icon="TbMapPin"
                label={t("form.representative")}
                value={
                  data.firstName ? data.firstName + " " + data.lastName : "-"
                }
                iconText
              />
            ),
          },
          {
            label: "Address",
            value: (
              <TooltipText
                icon="TbDental"
                label={t("form.whereToSend")}
                value={workAddress}
                iconText
              />
            ),
          },
        ]),
  ];

  const exchangeArray = [
    {
      label: "Fixture",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.fixture")}
          value={t(`implants.${data.fixture}`)}
          iconText
        />
      ),
    },

    // Conditionally add the Core object based on its existence
    ...(data.core
      ? [
          {
            label: "Core",
            value: (
              <TooltipText
                icon="TbMapPinStar"
                label={t("implantForm.core")}
                value={
                  data.core === "regular" ||
                  data.core === "deep" ||
                  data.core === "special"
                    ? t(`core.${data.core}`)
                    : data.core
                }
                iconText
              />
            ),
          },
        ]
      : []),

    {
      label: "Size",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.size")}
          value={"Ø" + data.diameter + "mm / L" + data.length}
          iconText
        />
      ),
    },

    {
      label: "Ref",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantForm.refCode")}
          value={data.refCode}
          iconText
        />
      ),
    },

    {
      label: "Lot",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("implantFailureReports.lotNumberAlt")}
          value={data.lotNumber}
          iconText
          maxW="120px"
        />
      ),
    },
  ];

  const newFixtureArray = [
    {
      label: "Fixture",
      value: (
        <TooltipText
          icon="TbDental"
          label={t("implantForm.fixture")}
          value={t(`implants.${data.newFixture}`)}
          iconText
        />
      ),
    },

    // Conditionally add the Core object based on its existence
    ...(data.newCore
      ? [
          {
            label: "Core",
            value: (
              <TooltipText
                icon="TbMapPinStar"
                label={t("implantForm.core")}
                value={
                  data.newCore === "regular" ||
                  data.newCore === "deep" ||
                  data.newCore === "special"
                    ? t(`core.${data.newCore}`)
                    : data.newCore
                }
                iconText
              />
            ),
          },
        ]
      : []),

    {
      label: "Size",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("implantForm.size")}
          value={"Ø" + data.newDiameter + "mm / L" + data.newLength}
          iconText
        />
      ),
    },

    // {
    //   label: "Ref",
    //   value: (
    //     <TooltipText
    //       icon="TbBuilding"
    //       label={t("implantForm.refCode")}
    //       value={data.newRefCode}
    //       iconText
    //     />
    //   ),
    // },
  ];

  // console.log("data:", data);
  // console.log("workAddress:", workAddress);

  return (
    <Flex w="100%" direction={["column", "column", "row"]}>
      <Box
        w={["100%%", "100%%", "65%"]}
        borderRight={[
          `0px solid ${newBorder}`,
          `0px solid ${newBorder}`,
          `1px solid ${newBorder}`,
        ]}
        pr={["0rem", "0rem", "2.5rem"]}
      >
        <Heading
          fontSize="md"
          fontWeight="600"
          color={textPrimary}
          m={["0rem 0 1rem", "0rem 0 1rem", "0rem 0 1.5rem"]}
        >
          {t("implantFailureReports.exchangeInfoTitle")}
        </Heading>
        <Flex
          alignItems="center"
          gap="2rem"
          w={["100%", "100%", "initial"]}
          direction={["column", "column", "row"]}
        >
          <Flex
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            align="center"
            justify="center"
            w={["100%", "100%", "180px"]}
            h={["200px", "200px", "180px"]}
            flexShrink="0"
          >
            <Image
              src={implant}
              alt="implantas"
              w={["20%", "20%", "30%"]}
              m="0 auto"
            />
          </Flex>
          <Flex direction="column" w="100%">
            <Flex
              align="center"
              direction={["column", "column", "row"]}
              w={["100%", "100%", "initial"]}
            >
              <Flex direction="column" w="100%">
                {exchangeArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>
              <Flex
                align="center"
                p="1rem 1rem"
                m={["2rem auto", "2rem auto", "0 3rem 0 1rem"]}
                bg={backgroundLight}
                borderRadius="10rem"
              >
                <Icon as={FaExchangeAlt} boxSize={3} color={textSecondary} />
              </Flex>
              <Flex direction="column" w="100%">
                {newFixtureArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box w={["100%%", "100%%", "35%"]} pl={["0rem", "0rem", "2.5rem"]}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          m={["3rem 0 0", "3rem 0 0", "0 0 1rem 0"]}
          p={["3rem 0 0", "3rem 0 0 ", "initial"]}
          borderTop={[
            `1px solid ${newBorder}`,
            `1px solid ${newBorder}`,
            `0px solid ${newBorder}`,
          ]}
        >
          <Heading fontSize="md" fontWeight="600" color={textPrimary}>
            {slideTitles[currentSlide]}
          </Heading>
          <Box minW="88px">
            <IconButton
              className="custom-swiper-button custom-swiper-button-prev"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowBackIcon />}
              borderRadius="10.5rem"
              opacity="1"
              _hover={{
                background: backgroundLight,
              }}
            />
            <IconButton
              className="custom-swiper-button custom-swiper-button-next"
              h="2.5rem"
              w="1.5rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              icon={<MiniSliderArrowForwardIcon />}
              borderRadius="10.5rem"
              ml="0.5rem"
              opacity="1"
              _hover={{
                background: backgroundLight,
              }}
            />
          </Box>
        </Flex>
        <Box m={["1.5rem 0 0", "1.5rem 0 0", "initial"]}>
          <Swiper
            navigation={{
              prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
              nextEl: ".custom-swiper-button-next", // Selector for your custom next button
            }}
            slidesPerView={1}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Navigation]}
            onSlideChange={handleSlideChange}
            className="singleReportSlider"
          >
            <SwiperSlide>
              <Box>
                {clientArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Box>
                {infoArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              <Flex direction="column" alignItems="center">
                {data.beforeXrayImage === "" ? (
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    p="1.5rem 1rem"
                  >
                    <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                    <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                      {t("implantFailureReports.noXrayImage")}
                    </Text>
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <Text
                      fontSize="sm"
                      color={textSecondary}
                      fontWeight="500"
                      mb={2}
                    >
                      {t("implantFailureReports.xRayBefore")}
                    </Text>
                    <Flex
                      w="100%"
                      maxH="200px"
                      borderRadius="1rem"
                      overflow="hidden"
                    >
                      <Image
                        w="100%"
                        h="100%"
                        objectFit="cover"
                        src={data.beforeXrayImage}
                        alt="xray"
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </SwiperSlide>

            <SwiperSlide>
              <Flex direction="column" alignItems="center">
                {data.afterXrayImage === "" ? (
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    p="1.5rem 1rem"
                  >
                    <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
                    <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                      {t("implantFailureReports.noXrayImage")}
                    </Text>
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <Text
                      fontSize="sm"
                      color={textSecondary}
                      fontWeight="500"
                      mb={2}
                    >
                      {t("implantFailureReports.xRayAfter")}
                    </Text>
                    <Flex
                      w="100%"
                      maxH="200px"
                      borderRadius="1rem"
                      overflow="hidden"
                    >
                      <Image
                        w="100%"
                        h="100%"
                        objectFit="cover"
                        src={data.afterXrayImage}
                        alt="xray"
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Flex>
  );
};

export default ReportInfo;
