import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import {
  white,
  primary,
  textSecondary,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import {
  listCourseAttendees,
  addCourseAttendee,
  removeCourseAttendee,
  updateCourseAttendeeStatus,
  updateCourseAttendeePaidStatus,
  updateCourseAttendeePrice,
  removeCourseAttendeePublic,
} from "../../../../../actions/courseActions";
import Table from "../../../../../components/shared/Table";
import { columns as courseAttendeesColumns } from "../../columns/CourseAttendeesColumns";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  COURSE_ATTENDEE_PRICE_UPDATE_RESET,
  COURSE_ATTENDEE_STATUS_UPDATE_RESET,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET,
} from "../../../../../constants/courseConstants";
import FormControlAttendeeSelect from "../../../../../components/input/FormControlAttendeeSelect";
import { shade } from "polished";

const TabCourseAttendees = ({ title, status }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);

  const dispatch = useDispatch();

  // STATE
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [profession, setProfession] = useState("");
  // const [companyName, setCompanyName] = useState("");

  const [addAttendeeSidePanelOpen, setAddAttendeeSidePanelOpen] =
    useState(false);

  const [keyword, setKeyword] = useState("");

  const [updatedAttendees, setUpdatedAttendees] = useState([]);

  const [attendee, setAttendee] = useState(null);

  // REDUX
  const courseAttendeesList = useSelector((state) => state.courseAttendeesList);
  const { loading, error, attendees } = courseAttendeesList;

  const courseAttendeePaidStatusUpdate = useSelector(
    (state) => state.courseAttendeePaidStatusUpdate
  );
  const { success: successUpdateAttendeePaidStatus } =
    courseAttendeePaidStatusUpdate;

  const courseAttendeeAdd = useSelector((state) => state.courseAttendeeAdd);
  const { success: successAddAttendee, error: errorAddAttendee } =
    courseAttendeeAdd;

  const courseAttendeeRemove = useSelector(
    (state) => state.courseAttendeeRemove
  );
  const { success: successRemoveAttendee } = courseAttendeeRemove;

  const courseAttendeeStatusUpdate = useSelector(
    (state) => state.courseAttendeeStatusUpdate
  );
  const {
    success: successUpdateAttendeeStatus,
    erorr: errorUpdateAttendeeStatus,
  } = courseAttendeeStatusUpdate;

  const courseAttendeePriceUpdate = useSelector(
    (state) => state.courseAttendeePriceUpdate
  );
  const { success: successUpdateAttendeePrice } = courseAttendeePriceUpdate;

  const courseUpdatePricing = useSelector((state) => state.courseUpdatePricing);
  const { success: successUpdatePricing } = courseUpdatePricing;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
    );
  };

  const addAttendeeHandleNew = (values) => {
    const attendeeIds = values.map((attendee) => attendee.value);
    const attendeeExists = attendeeIds.some((attendeeId) =>
      attendees.some((a) => a._id === attendeeId)
    );
    if (attendeeExists) {
      console.log("ATTENDEE EXISTS");
      toastComponent.showToast(t("toasts.attendeeError"), "error", 3000);
    } else {
      dispatch(addCourseAttendee(courseId, attendeeIds));
      setUpdatedAttendees([...updatedAttendees, ...attendeeIds]);
      // toastComponent.showToast(t('toasts.attendeeAdd'), 'success', 3000);
      closeAddAttendeeSidePanel();
    }

    setAttendee(null);
  };

  const removeAttendeeHandler = (attendeeId) => {
    dispatch(removeCourseAttendee(courseId, attendeeId));
    // toastComponent.showToast(t('toasts.attendeeRemove'), 'success', 3000);
  };

  const removeAttendeeHandlerPublic = (attendeeId) => {
    dispatch(removeCourseAttendeePublic(courseId, attendeeId));
    // toastComponent.showToast(t('toasts.attendeeRemove'), 'success', 3000);
  };

  useEffect(() => {
    if (successAddAttendee) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
      toastComponent.showToast(t("toasts.attendeeAdd"), "success", 3000);

      dispatch({ type: "COURSE_ATTENDEE_ADD_RESET" });
    } else if (successRemoveAttendee) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
      toastComponent.showToast(t("toasts.attendeeRemove"), "success", 3000);
    } else if (successUpdateAttendeeStatus) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
      dispatch({ type: COURSE_ATTENDEE_STATUS_UPDATE_RESET });
      toastComponent.showToast(t("toasts.attendeeStatus"), "success", 3000);
    } else if (successUpdateAttendeePaidStatus) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
      toastComponent.showToast(t("toasts.attendeeStatusPaid"), "success", 3000);
      dispatch({ type: COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET });
    } else if (successUpdateAttendeePrice) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
      toastComponent.showToast(t("toasts.attendeePrice"), "success", 3000);
      dispatch({ type: COURSE_ATTENDEE_PRICE_UPDATE_RESET });
    } else if (successUpdatePricing) {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
    } else {
      dispatch(
        listCourseAttendees(courseId, keyword, sortField, sortOrder, false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    courseId,
    keyword,
    sortField,
    sortOrder,
    successAddAttendee,
    successRemoveAttendee,
    successUpdateAttendeeStatus,
    successUpdateAttendeePaidStatus,
    successUpdateAttendeePrice,
    successUpdatePricing,
  ]);

  useEffect(() => {
    if (errorAddAttendee) {
      toastComponent.showToast(errorAddAttendee, "error", 3000);
    } else if (errorUpdateAttendeeStatus) {
      toastComponent.showToast(errorUpdateAttendeeStatus, "error", 3000);
    }
  }, [errorAddAttendee, errorUpdateAttendeeStatus]);

  const openAddAttendeeSidePanel = () => {
    setAddAttendeeSidePanelOpen(true);
  };

  const closeAddAttendeeSidePanel = () => {
    setAddAttendeeSidePanelOpen(false);
  };

  const updateCourseAttendeeStatusHandler = (
    publicAttendeeId,
    attendeeId,
    status
  ) => {
    dispatch(
      updateCourseAttendeeStatus(courseId, publicAttendeeId, attendeeId, status)
    );
  };

  const updateCourseAttendeePaidStatusHandler = (attendeeId, status) => {
    dispatch(updateCourseAttendeePaidStatus(courseId, attendeeId, status));
  };

  const updateCourseAttendeePriceHandler = (attendeeId, price) => {
    dispatch(updateCourseAttendeePrice(courseId, attendeeId, price));
  };

  // console.log("currentAttendees", currentAttendees);
  // console.log("customAttendees", customAttendees);
  // console.log("updatedAttendees", updatedAttendees);
  // console.log("attendees", attendees);

  // console.log("attendees", attendees);

  const isAddAttendeeError = attendee === null;

  console.log("attendee", attendee);
  return (
    <>
      <SidePanel
        open={addAttendeeSidePanelOpen}
        close={closeAddAttendeeSidePanel}
        title={t("course.addAttendee")}
      >
        <FormControl isRequired>
          <FormLabel fontSize="sm">{t("select.attendee")}</FormLabel>
          <FormControlAttendeeSelect
            onChange={(selectedOption) => {
              setAttendee(selectedOption);
            }}
            placeholder={t("placeholder.selectAttendee")}
            client={attendee}
            setAttendee={setAttendee}
            currentAttendees={attendees}
          />
        </FormControl>

        <Flex>
          {attendee && (
            <Flex align="center" justify="center" m="0.5rem auto 0rem">
              <Text fontSize="xs" color={textSecondary}>
                {t("course.addAttendeeReminder")}
              </Text>
            </Flex>
          )}
        </Flex>

        {!attendee && (
          <Flex m="0.5rem auto 0rem" align="center" justify="center">
            <Text fontSize="xs" color={textSecondary}>
              {t("placeholder.noAttendees")}
            </Text>
          </Flex>
        )}

        <Flex w="100%" align="center" justify="flex-end" m="3rem 0 0">
          <Tooltip
            bg={buttonRed}
            borderRadius="1rem"
            p="0.5rem 1rem"
            label={
              <Flex
                direction="column"
                p="0rem"
                justify="center"
                textAlign="center"
              >
                {isAddAttendeeError && (
                  <Text
                    fontSize="13px"
                    color={buttonRedText}
                    whiteSpace="nowrap"
                  >
                    * {t("placeholder.selectAttendee")}
                  </Text>
                )}
              </Flex>
            }
            isDisabled={!isAddAttendeeError}
            hasArrow
            placement="top"
            fontSize="md"
          >
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={isAddAttendeeError}
              _hover={{
                bg: shade(0.1, primary),
              }}
              ml={4}
              onClick={() => addAttendeeHandleNew([attendee])}
            >
              {t("button.save")}
            </Button>
          </Tooltip>
        </Flex>
      </SidePanel>

      <Box>
        <Flex w="100%" direction="column">
          <Table.Top
            title={t("course.tabAttendees")}
            subtitle={title}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={t("course.search")}
            searchName={keyword}
            isFixedTitle={false}
          >
            {/* <Button
              leftIcon={<FilterIcon />}
              bg={white}
              color={textSecondary}
              border={`1px solid ${isFilterVisible ? primary : newBorder}`}
              variant="solid"
              size="lg"
              borderRadius="10rem"
              fontSize="16px"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
              {t('common.filter')}
            </Button> */}
            <Tooltip
              p="0.5rem 1rem"
              borderRadius="1rem"
              label={t("course.addAttendeeDisabled")}
              placement="top"
              fontSize="sm"
              textAlign="center"
              isDisabled={status === "draft" || status === "upcoming"}
            >
              <Button
                bg={primary}
                color={white}
                size="lg"
                borderRadius="10rem"
                fontSize="1rem"
                onClick={openAddAttendeeSidePanel}
                isDisabled={status === "cancelled" || status === "completed"}
                _hover={{
                  bg: shade(0.3, primary),
                }}
              >
                {t("course.addAttendee")}
              </Button>
            </Tooltip>
          </Table.Top>
          <Box borderRadius="0.75rem" overflow="hidden" p="0 0 2rem">
            <Table.Container
              loading={loading}
              data={attendees}
              error={error}
              columns={courseAttendeesColumns(
                translate,
                removeAttendeeHandler,
                removeAttendeeHandlerPublic,
                updateCourseAttendeeStatusHandler,
                updateCourseAttendeePaidStatusHandler,
                handleSortChange,
                updateCourseAttendeePriceHandler
              )}
              noItemsTitle={t("noItems.noAttendees")}
              noItemsDescription={t("noItems.noAttendeesText")}
              noItemsButtonLabel={t("course.noAttendeesCourseButtonLabel")}
              noItemsButtonHandler={openAddAttendeeSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabCourseAttendees;
