import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Heading,
  Text,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  primary,
  purple,
  textWhite,
} from "../../utils/colors";
import {
  registerUserVerification,
  verifyEmail,
} from "../../actions/userActions";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Header from "../landing/layout/Header";
import Step1Form from "./partials/Step1Form";
import RegisterPromo from "./partials/RegisterPromo";
import { USER_REGISTER_VERIFICATION_RESET } from "../../constants/userConstants";
import StepVerification from "./partials/StepVerification";
import { useMediaQuery } from "react-responsive";

const Register = () => {
  // GENERAL
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // CONSTANTS
  const [show, setShow] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [vatCode, setVatCode] = useState("");

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profession, setProfession] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");

  const [showPromo, setShowPromo] = useState(true);
  const [showForm, setShowForm] = useState(isMobile ? false : true);

  const [displayAddress, setDisplayAddress] = useState("");

  const accountTypes = [
    { type: "clinic", label: t("landingMegagen.registerTypeClinic") },
    { type: "dentist", label: t("landingMegagen.registerTypeDentist") },
    { type: "lab", label: t("landingMegagen.registerTypeLab") },
  ];

  const [selectedAccountType, setSelectedAccountType] = useState("clinic");

  const [clinicName, setClinicName] = useState(""); // Add clinicName state

  const [verificationCode, setVerificationCode] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userRegisterVerification = useSelector(
    (state) => state.userRegisterVerification
  );

  const {
    loading: loadingRegisterVerification,
    error: errorRegisterVerification,
    success: successRegisterVerification,
  } = userRegisterVerification;

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);

  const {
    loading: loadingVerify,
    error: errorVerify,
    success: successVerify,
  } = userVerifyEmail;

  // HANDLERS

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    dispatch(verifyEmail(email, verificationCode));
  };

  const registerHandler = (e) => {
    e.preventDefault();

    if (selectedAccountType === "individual") {
      dispatch(
        registerUserVerification(
          firstName,
          lastName,
          email,
          password,
          selectedAccountType,
          companyName,
          companyCode,
          vatCode,
          phone,
          address.street,
          address.zip,
          address.city,
          address.state,
          address.country,
          profession
        )
      );
    } else {
      dispatch(
        registerUserVerification(
          firstName,
          lastName,
          email,
          password,
          selectedAccountType,
          companyName,
          companyCode,
          vatCode,
          phone,
          address.street,
          address.zip,
          address.city,
          address.state,
          address.country,
          profession
        )
      );
    }
  };

  const handleAccountTypeClick = (type) => {
    setSelectedAccountType(type);
  };

  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
  };

  // STEPS
  const [currentStep, setCurrentStep] = useState(1);

  // USE EFFECT

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      navigate("/account");
    }
  }, [userInfo, navigate]);

  // useEffect(() => {
  //   if (client && client.address) {
  //     const { street, city, zip, country, state } = client.address[0]; // Assuming address[0] exists and is the correct format
  //     const parts = [street, city, state, zip, country].filter(Boolean);
  //     const fullAddress = parts.join(", ");

  //     setAddress({
  //       street: street || "",
  //       city: city || "",
  //       zip: zip || "",
  //       state: state || "",
  //       country: country || "",
  //       display: fullAddress,
  //     });

  //     setDisplayAddress(fullAddress);
  //   }
  // }, [client]);

  useEffect(() => {
    if (successRegisterVerification) {
      // toast({
      //   title: t("toasts.successRegisterVerification"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });

      console.log("successRegisterVerification", successRegisterVerification);

      dispatch({ type: USER_REGISTER_VERIFICATION_RESET });

      setCurrentStep(2);
    } else if (errorRegisterVerification) {
      toast({
        title: t("toasts.errorRegisterVerification"),
        description: errorRegisterVerification,
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.log("errorRegisterVerification", errorRegisterVerification);
    }

    // eslint-disable-next-line
  }, [successRegisterVerification, errorRegisterVerification]);

  useEffect(() => {
    if (successVerify) {
      toast({
        title: t("toasts.verifySuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/account");

      console.log("successVerify", successVerify);
    } else if (errorVerify) {
      toast({
        title: t("toasts.verifyError"),
        description: errorVerify,
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.log("errorVerify", errorVerify);
    }
  }, [successVerify, errorVerify]);

  // HANDLERS

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClinicNameChange = (e) => {
    setClinicName(e.target.value);
  };

  // LOGS
  // console.log("showPromo", showPromo);
  // console.log("showForm", showForm);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.register")}</title>
        <meta name="description" content={t("pageDescription.register")} />
      </Helmet>

      {!show && (
        <Flex
          w="100%"
          h="100%"
          minH="100vh"
          align="stretch"
          justify="flex-start"
          bg={white}
          p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
        >
          <Header
            bg="transparent"
            isRegister
            showPromo={showPromo}
            showForm={showForm}
          />

          {showPromo && (
            <RegisterPromo
              setShowPromo={setShowPromo}
              setShowForm={setShowForm}
            />
          )}

          {showForm && (
            <Flex
              w={["100%", "100%", "70%"]}
              h={["100%", "100%", "100%"]}
              align="flex-start"
              justify="center"
              ml={["0%", "0%", "30%"]}
              direction="column"
              as={motion.div}
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              p={["80px 0 0", "80px 0 0", "100px 0 0 100px"]}
              // display={["none", "none", "flex"]}
              // overflow={["auto", "auto", "initial"]}
              bg={[purple, purple, "initial"]}
              position={["fixed", "fixed", "relative"]}
              top={["0", "0", "initial"]}
              minH={["100vh", "100vh", "initial"]}
            >
              <form
                style={{
                  width: "100%",
                  maxWidth: isMobile ? "100%" : "700px",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Flex
                  direction="column"
                  p={["0 0 0rem", "0 0 0rem", "0 0 6rem"]}
                >
                  {currentStep !== 2 && (
                    <Stack gap="0.5rem" p={["1.5rem 1.5rem", "0rem", "0rem"]}>
                      <Heading
                        fontSize="1.875rem"
                        fontWeight="700"
                        color={[white, white, textPrimary]}
                      >
                        {t("landingMegagen.registerTitle")}
                      </Heading>

                      <Text
                        fontSize="1rem"
                        color={[textWhite, textWhite, textSecondary]}
                        fontWeight="500"
                      >
                        {t("landingMegagen.registerText")}
                      </Text>
                    </Stack>
                  )}

                  {loadingRegisterVerification && (
                    <Flex
                      direction="column"
                      justify="center"
                      align="center"
                      p="8rem 0"
                    >
                      <Spinner size="xl" color={primary} />
                    </Flex>
                  )}

                  {currentStep === 1 && !loadingRegisterVerification && (
                    <Step1Form
                      email={email}
                      password={password}
                      companyName={companyName}
                      setCompanyName={setCompanyName}
                      phone={phone}
                      companyCode={companyCode}
                      setCompanyCode={setCompanyCode}
                      vatCode={vatCode}
                      setVatCode={setVatCode}
                      address={address}
                      setAddress={setAddress}
                      handleAddressSelect={handleAddressSelect}
                      handleAccountTypeClick={handleAccountTypeClick}
                      accountTypes={accountTypes}
                      selectedAccountType={selectedAccountType}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      profession={profession}
                      handleProfessionChange={handleProfessionChange}
                      setPhone={setPhone}
                      clinicName={clinicName}
                      handleClinicNameChange={handleClinicNameChange}
                      handleEmailChange={handleEmailChange}
                      handlePasswordChange={handlePasswordChange}
                      registerHandler={registerHandler}
                      isMobile={isMobile}
                      setSelectedAccountType={setSelectedAccountType}
                    />
                  )}
                </Flex>

                {currentStep === 2 &&
                  !loadingMessage &&
                  !loadingRegisterVerification && (
                    <StepVerification
                      onVerify={handleVerificationSubmit}
                      verificationCode={verificationCode}
                      setVerificationCode={setVerificationCode}
                      email={email}
                    />
                  )}

                {loadingMessage && (
                  <Flex direction="column" justify="center" align="center">
                    <Spinner size="xl" />
                    <Text mt={8} color={textPrimary} fontWeight="500" size="md">
                      {loadingMessage}
                    </Text>
                  </Flex>
                )}
              </form>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default Register;
