import Select from "react-select";
import {
  Box,
  Text,
  Avatar,
  Image,
  Button,
  Flex,
  HStack,
  FormLabel,
  useToast,
  Icon,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  white,
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  buttonBlue,
  buttonBlueText,
} from "../../utils/colors";
import { TbSquareRoundedX } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import StatusBadgeAlt from "../badges/StatusBadgeAlt";
import StatusBadge from "../badges/StatusBadge";

const XButton = styled.button`
  all: unset;
  margin-left: 0.3rem;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
`;

const OptionsOutsideSelect = (props) => {
  const {
    isMulti,
    isError,
    isSmall,
    value,
    onChange,
    title,
    isSelectedHidden,
    placeholderText,
  } = props;

  const toast = useToast();
  const { t } = useTranslation();

  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
      { action: "remove-value", removedValue }
    );

    // // Display a toast when removing a speaker
    // toast({
    //   title: `${removedValue.label} removed`,
    //   status: 'warning',
    //   duration: 2000,
    //   isClosable: true,
    // });
  };

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,

      border: `1px solid ${newBorder}`,
      fontSize: isSmall ? "0.875rem" : "0.938rem",
      fontWeight: "500",
      minHeight: "3rem",
      height: isMulti ? "initial" : isSmall ? "2.5rem" : "3rem",
      padding: "0 0rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: `0 0 0 1px ${newBorder}`,
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,
      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : null,
      color: state.isFocused ? white : state.data.color,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: primary,
      color: white,
      borderRadius: "0.75rem",
      fontSize: "0.938rem",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: state.data.bgDark,
        color: state.data.color,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `1px solid ${newBorder}`,
      boxShadow: "0 0 1rem rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
  };

  const formatOptionLabel = ({ label, image, status }) => (
    <Flex align="center" justify="space-between" p="0.5rem 0" m="0" w="100%">
      <Flex align="center">
        {image ? (
          <Box w="20px" h="20px" borderRadius="10rem" overflow="hidden">
            <Image
              w="100%"
              h="100%"
              objectFit="cover"
              src={image}
              alt={label}
              flexShrink="0"
            />
          </Box>
        ) : (
          <Avatar
            size="xs"
            name={label}
            src={image}
            flexShrink="0"
            bg={buttonBlue}
            color={buttonBlueText}
          />
        )}
        <Text pl={2} fontSize="15px">
          {label}
        </Text>
      </Flex>
      <StatusBadge status={status} options="course" isSmall />
    </Flex>
  );

  return (
    <div>
      <Select
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholderText}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? t("common.noOptions") : t("common.noOptions")
        }
        styles={customStyles}
        // styles={{
        //   control: (baseStyles, state) => ({
        //     ...baseStyles,
        //     borderColor: state.isFocused ? newBorder : newBorder,
        //     color: textSecondary,
        //     borderRadius: "1rem",
        //     backgroundColor: `${white}`,
        //     border: `1px solid ${newBorder}`,
        //     fontSize: "0.938rem",
        //     fontWeight: "500",
        //     height: "3rem",
        //     padding: "0 0.25rem",
        //     ":focus-visible": {
        //       borderColor: primary,
        //       boxShadow: `0 0 0 1px ${primary}`,
        //     },
        //   }),
        //   singleValue: (provided, state) => {
        //     const opacity = state.isDisabled ? 0.5 : 1;
        //     const transition = "opacity 300ms";

        //     // check if the value is null (placeholder)
        //     const color =
        //       state.data.value === "" ? "desired color" : "default color";

        //     return { ...provided, opacity, transition, color };
        //   },
        // }}
        {...props}
        controlShouldRenderValue={!isMulti}
      />

      {isSelectedHidden ? null : (
        <Flex flexWrap="wrap" align="center">
          {value.length > 0 ? (
            <Flex direction="column" w="100%">
              <FormLabel
                mt={4}
                fontSize="sm"
                display="flex"
                alignItems="center"
              >
                {title}
                <Text ml={1} color={textSecondary}>
                  ({value.length}){" "}
                  {/* Display the number of selected options */}
                </Text>
              </FormLabel>

              {isMulti
                ? value.map((val) => (
                    <Box
                      key={val.value}
                      p="0.5rem 1rem"
                      h="3rem"
                      border={`1px solid ${newBorder}`}
                      m="0.5rem 0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="0.75rem"
                      w="100%"
                    >
                      <HStack gap={0}>
                        <Avatar
                          src={val.image}
                          name={val.label}
                          fontSize="xs"
                          h="1.75rem"
                          w="1.75rem"
                          mr={2}
                          bg={buttonBlue}
                          color={buttonBlueText}
                        />
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textPrimary}
                        >
                          {val.label}
                        </Text>
                        <Text
                          pl={2}
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {val.birthDate}
                        </Text>
                      </HStack>
                      <HStack gap={0}>
                        {/* <Box
                          bg={primary}
                          color={white}
                          p="0.25rem 0.5rem"
                          borderRadius="10rem"
                          mr={2}
                          fontSize="xs"
                          fontWeight="600"
                        >
                          {t('common.new')}
                        </Box> */}
                        <XButton name={val.value} onClick={handleRemoveValue}>
                          ✕
                        </XButton>
                        {/* 
                      <Icon
                        as={TbSquareRoundedX}
                        mr="0.5rem"
                        color={textSecondary}
                        xr
                        transition="all 0.2s"
                        onClick={handleRemoveValue}
                        _hover={{
                          color: textPrimary,
                          cursor: 'pointer',
                          transform: 'scale(1.2)',
                        }}
                      /> */}
                      </HStack>
                      {/* <XButton name={val.value} onClick={handleRemoveValue}>
                      ✕
                    </XButton> */}
                    </Box>
                  ))
                : null}
            </Flex>
          ) : null}
        </Flex>
      )}
    </div>
  );
};

export default OptionsOutsideSelect;
