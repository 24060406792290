import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Tooltip,
  Flex,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
  errorRed,
  bg,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const FormControlCore = ({
  fixture,
  core,
  setValue,
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  isDisabled,
}) => {
  // GENERAL
  const { t } = useTranslation();

  // STATE
  const [options, setOptions] = useState([]);

  // HANDLERS
  const handlerClear = () => {
    onChange(null);
  };

  // Update options based on fixture

  // CIA REIKIA PADARYT KAD JEI VALUE  NULL GAL TAI NEBUTU FIXTURE USE EFFECTO NES DABAR JEI DEEP PARINKTAS IR ATEINA TAI PERKRAUNA I REGULAR

  useEffect(() => {
    if (fixture === "anyone") {
      setOptions([
        { value: "regular", label: "Regular" },
        { value: "deep", label: "Deep Thread" },
        { value: "special", label: "Special" },
      ]);
      if (core === null) {
        setValue("regular");
      }
    } else if (fixture === "bluediamond") {
      setOptions([
        { value: "regular", label: "Regular" },
        { value: "deep", label: "Deep Thread" },
      ]);
      if (core === null) {
        setValue("regular");
      }
    } else if (fixture === "anyridge") {
      setOptions([
        { value: "2.8", label: "2.8" },
        { value: "3.3", label: "3.3" },
        { value: "3.8", label: "3.8" },
        { value: "4.0", label: "4.0" },
        { value: "4.3", label: "4.3" },
        { value: "4.8", label: "4.8" },
      ]);
      if (core === null) {
        setValue("2.8");
      }
    }
  }, [fixture, core]);

  // LOGS
  console.log("fixture", fixture);
  // console.log("options", options);
  console.log("value", value);

  //  CUSTOM STYLES FOR REACT-SELECT
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: "3rem",
      padding: "0 0.25rem",
      pointerEvents: isDisabled ? "none" : "auto",
      opacity: isDisabled ? 0.5 : 1,
      width: "100%",

      ":hover": {
        cursor: state.isDisabled ? "not-allowed" : "pointer",
      },
      ":focus-visible": {
        borderColor: primary,
        boxShadow: "none",
      },
      ":focus": {
        boxShadow: "none",
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,

      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : white,
      color: state.isFocused ? white : textSecondary,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `0px solid ${newBorder}`,
      bg: white,
      overflow: "hidden",
      padding: 0,
      margin: "0.5rem 0 0",
      zIndex: 10,
      width: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      bg: white,
    }),
  };

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative" w="100%">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>
      <Select
        isSearchable
        value={
          value !== null && options.length > 0
            ? value && options.some((option) => option.value === value)
              ? options.find((option) => option.value === value)
              : options[0]
            : null
        }
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        isDisabled={isDisabled}
      />
      {/* <Tooltip
        label={t("formError.selectFixture")}
        p="0.5rem 1rem"
        borderRadius="1rem"
        placement="top"
        isOpen={showTooltip}
        bg={buttonRed}
        color={buttonRedText}
        w="100%"
      >
        <Flex
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          w="100%"
          minW="100%"
        >
          <Select
            isSearchable
            value={options.find((option) => option.value === value)}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={isDisabled}
          />
        </Flex>
      </Tooltip> */}
    </FormControl>
  );
};

export default FormControlCore;
