import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  white,
  primary,
  newBorder,
  textSecondary,
} from "../../../../../utils/colors";
import {
  listSpeakerDetails,
  updateSpeakerDescription,
} from "../../../../../actions/speakerActions";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import { SPEAKER_DESCRIPTION_UPDATE_RESET } from "../../../../../constants/speakerConstants";

const TabSpeakerDescription = ({ title }) => {
  const speakerId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [editDescriptionSidePanelOpen, setEditDescriptionSidePanelOpen] =
    useState(false);
  const [description, setDescription] = useState(""); // State variable to store the description

  const speakerDetails = useSelector((state) => state.speakerDetails);
  const { speaker } = speakerDetails;

  const speakerDescriptionUpdate = useSelector(
    (state) => state.speakerDescriptionUpdate
  );
  const { success: successUpdateDescription } = speakerDescriptionUpdate;

  const openEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(true);
  };

  const closeEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(false);
  };

  const updateDescriptionHandler = () => {
    dispatch(updateSpeakerDescription(speakerId, description));
    closeEditDescriptionSidePanel();
  };

  useEffect(() => {
    if (!speaker || speaker._id !== speakerId) {
      dispatch(listSpeakerDetails(speakerId));
    } else if (successUpdateDescription) {
      toastComponent.showToast(
        t("toasts.speakerDescriptionUpdated"),
        "success",
        3000
      );
      dispatch({ type: SPEAKER_DESCRIPTION_UPDATE_RESET });
      dispatch(listSpeakerDetails(speakerId));
    } else {
      setDescription(speaker.description);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, speaker, speakerId, successUpdateDescription]);

  return (
    <>
      <SidePanel
        open={editDescriptionSidePanelOpen}
        close={closeEditDescriptionSidePanel}
        title={t("speaker.editSpeakerDescription")}
      >
        <FormControl>
          <FormLabel fontSize="sm">
            {" "}
            {t("speaker.speakerDescription")}:
          </FormLabel>
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            value={description} // Use your state variable here
            onEditorChange={(content) => setDescription(content)} // Update your state variable
          />
        </FormControl>
        <Flex
          mt={4}
          justify="flex-end"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0"
        >
          <Button
            minWidth="10rem"
            type="submit"
            size="lg"
            bg={primary}
            color={white}
            fontSize="md"
            borderRadius="10rem"
            onClick={updateDescriptionHandler}
          >
            {t("button.update")}
          </Button>
        </Flex>
      </SidePanel>

      <Box p="0rem 0rem">
        <Flex align="center" justify="space-between" p="2rem 0">
          <Stack gap={1}>
            <Heading fontSize="xl" fontWeight="600">
              {t("speaker.speakerDescription")}
            </Heading>
            <Text color={textSecondary} fontSize="sm">
              {title}
            </Text>
          </Stack>
          <Button
            bg={primary}
            color={white}
            size="lg"
            borderRadius="10rem"
            fontSize="1rem"
            onClick={openEditDescriptionSidePanel}
          >
            {t("speaker.editSpeakerDescription")}
          </Button>
        </Flex>

        <Flex
          p="1.5rem 2rem"
          border={`1px dashed ${newBorder}`}
          borderRadius="1rem"
        >
          <Box
            minHeight="200px"
            dangerouslySetInnerHTML={{
              __html: speaker.description,
            }}
          />
        </Flex>
      </Box>
    </>
  );
};

export default TabSpeakerDescription;
