import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import BarChart from "../../../../components/stats/BarChart";
import {
  primary,
  textPrimary,
  textSecondary,
  buttonGreen,
  buttonBlue,
  buttonRed,
  buttonRedNew,
  greenVivid,
} from "../../../../utils/colors";
import { getCoursesAttendeesStatusCounts } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";

const AttendeesStatusCount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // REDUX
  const { loading, data, error } = useSelector(
    (state) => state.dashboardCoursesAttendeesStatusCounts
  );

  const { total, countsByStatus } = data;

  // USE EFFECT

  useEffect(() => {
    dispatch(getCoursesAttendeesStatusCounts());
  }, [dispatch]);

  // CUSTOM
  const statusOrder = [
    "cancelled",
    "not_attended",
    "attended",
    "registered",
    "confirmed",
  ];
  const statusColors = {
    Cancelled: buttonRed,
    Not_attended: buttonRedNew,
    Attended: greenVivid,
    Registered: buttonBlue,
    Confirmed: buttonGreen,
  };

  // LOGS
  console.log("total", total);
  console.log("countsByStatus", countsByStatus);

  if (loading) {
    return (
      <Flex>
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Link to="/admin/academy/attendees">
          <Text
            color={textPrimary}
            fontSize="sm"
            fontWeight="500"
            _hover={{
              color: primary,
            }}
          >
            {t("dashboard.courseAttendeesStatusCountTitle")}
            <Text
              as="span"
              fontWeight="500"
              color={textSecondary}
              ml={1}
              fontSize="sm"
            >
              {total}
            </Text>
          </Text>
        </Link>
      </Flex>
      <BarChart
        data={countsByStatus}
        statusOrder={statusOrder}
        statusColors={statusColors}
        backgroundLight="gray.200"
        textSecondary="gray.500"
        white="white"
      />
    </Flex>
  );
};

export default AttendeesStatusCount;
