import React from "react";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  buttonRedNew,
  buttonRed,
  buttonYellow,
  buttonGreen,
  greenVivid,
  backgroundLight,
  textSecondary,
  white,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const BarChart = ({ data, statusOrder, statusColors }) => {
  const { t } = useTranslation();
  console.log("data in BarChart", data);

  // Sort data according to the statusOrder provided by props
  const sortedData = statusOrder.map((status) => {
    const item = data.find(
      (item) => item.status.toLowerCase() === status.toLowerCase()
    );
    return {
      status: status.charAt(0).toUpperCase() + status.slice(1),
      count: item ? item.count : 0,
      color: statusColors[status.charAt(0).toUpperCase() + status.slice(1)], // Get the color based on status from props
    };
  });

  // Calculate the maximum value to scale the bars appropriately
  const maxValue = Math.max(...data.map((d) => d.count), 1); // Ensure it never divides by zero

  // Calculate the width of each bar dynamically based on the number of items
  const numItems = sortedData.length;
  const baseWidth = 100 / numItems; // Divide 100% by the number of items
  const minWidth = 34; // Minimum width of a bar in pixels
  const maxWidth = 100; // Maximum width of a bar in pixels
  const dynamicWidth = `${Math.max(minWidth, Math.min(maxWidth, baseWidth))}px`;

  // LOGS
  // console.log("data", data);
  // console.log("maxValue", maxValue);
  // console.log("sortedData", sortedData);

  return (
    <Flex
      align="center"
      justify="center"
      w="100%"
      gap="0.75rem"
      p="1rem 0 1rem"
    >
      {sortedData.map((item) => (
        <Tooltip
          p="0.5rem 1rem"
          borderRadius="1rem"
          placement="top"
          label={
            <Flex align="center">
              <Flex
                bg={item.color}
                w="0.5rem"
                h="0.5rem"
                mr="0.5rem"
                borderRadius="10rem"
              />

              <Text fontSize="sm" fontWeight="500">
                {t(`status.${item.status.toLowerCase()}`)}:{" "}
                {/* {item.status.charAt(0).toUpperCase() + item.status.slice(1)}:{" "} */}
                <Text as="span" fontWeight="600" color={white}>
                  {item.count}
                </Text>
              </Text>
            </Flex>
          }
        >
          <Flex
            key={item.status}
            align="flex-end"
            justify="center"
            w={dynamicWidth}
            h="140px"
            bg={backgroundLight}
            borderRadius="0.875rem"
          >
            <Flex
              as={motion.div}
              initial={{ height: 0 }}
              animate={{ height: `${(item.count / maxValue) * 100}%` }}
              transition={{ duration: 0.5 }}
              w="100%"
              position="relative"
              borderRadius="0.875rem"
            >
              <Flex
                as={motion.div}
                w="100%"
                bg={item.color}
                align="flex-end"
                justify="center"
                textAlign="center"
                borderRadius="0.875rem"
              >
                <Text
                  fontSize="xs"
                  color={textSecondary}
                  textAlign="center"
                  position="relative"
                  fontWeight="500"
                  bottom="-1.5rem"
                >
                  {item.count}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Tooltip>
      ))}
    </Flex>
  );
};

export default BarChart;
