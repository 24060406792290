import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Spinner,
  Text,
  Stack,
  Icon,
  HStack,
  Image,
} from "@chakra-ui/react";
import {
  newBorder,
  primary,
  textPrimary,
  textSecondary,
  buttonBlue,
  buttonBlueText,
  white,
} from "../../../../utils/colors";
import { getNewClients } from "../../../../actions/dashboardActions";
import { useTranslation } from "react-i18next";
import { FaUser, FaSuitcase } from "react-icons/fa";
import noServices from "../../../../assets/images/empty-box.png";

const EmployeeClients = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // STATE

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dashboardNewClients = useSelector((state) => state.dashboardNewClients);
  const { loading, error, clients } = dashboardNewClients;

  // USE EFFECT

  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getNewClients(userInfo._id, "all"));
    }
  }, [userInfo, dispatch]); // Depend on userInfo directly

  // LOGS

  console.log("clients", clients);
  console.log("userInfo", userInfo);
  // console.log("employeeId", employeeId);

  if (loading) {
    return (
      <Flex align="center" justify="center" h="100%" w="100%" p="6rem 0">
        <Spinner size="md" color={primary} />
      </Flex>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Flex
      //   border={`1px solid ${newBorder}`}
      //   p="1.5rem"
      direction="column"
      w="100%"
      //   borderRadius="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Stack gap="0">
          <Link to="/admin/clients">
            <Text
              color={textPrimary}
              fontSize="sm"
              fontWeight="500"
              _hover={{
                color: primary,
              }}
            >
              {t("dashboard.employeeClientsTitle")}
              <Text as="span" fontWeight="500" color={textSecondary} ml={1}>
                {clients.length}
              </Text>
            </Text>
          </Link>
          <Text color={textSecondary} fontSize="xs" fontWeight="500">
            {t("dashboard.employeeClientsSubtitle")}
          </Text>
        </Stack>
      </Flex>

      <Flex direction="column" w="100%" p="1rem 0 0">
        {clients.map((client, index) => (
          <Flex
            key={index}
            align="center"
            justify="space-between"
            bg={white}
            p="0.75rem 0rem"
            transition="all 0.2s"
            borderRadius="0rem"
            position="relative"
            borderBottom={`1px solid ${newBorder}`}
            _hover={{
              bg: white,
              color: buttonBlue,
              cursor: "pointer",
              px: "1rem",
            }}
            _last={{ borderBottom: "none" }}
            onClick={() => {
              navigate(`/admin/clients/${client._id}`);
            }}
          >
            <Flex align="center">
              <HStack gap={2} w="200px">
                <Flex
                  align="center"
                  justify="center"
                  w="2.35rem"
                  h="2.35rem"
                  borderRadius="10rem"
                  bg={buttonBlue}
                >
                  <Icon
                    as={
                      client.clientType === "individual" ? FaUser : FaSuitcase
                    }
                    boxSize={3}
                    color={buttonBlueText}
                  />
                </Flex>

                <Stack gap={0.5} overflow="hidden">
                  {client.clientType === "individual" ? (
                    <Text
                      fontSize="sm"
                      color={textPrimary}
                      fontWeight="500"
                      isTruncated
                      lineHeight={1}
                      maxW="150px"
                    >
                      {client.firstName} {client.lastName}
                    </Text>
                  ) : (
                    <Text
                      fontSize="sm"
                      color={textPrimary}
                      fontWeight="500"
                      isTruncated
                      lineHeight={1}
                      maxW="150px"
                    >
                      {client.companyName}
                    </Text>
                  )}

                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    maxW="120px"
                  >
                    {/* {clientType === "individual" ? companyName : name} */}
                    {t(`clientAccount.${client.accountType}`)}
                  </Text>
                </Stack>
              </HStack>

              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                ml="2rem"
              >
                {client && client.address.length > 0 && client.address[0].city
                  ? client.address[0].city
                  : "-"}
              </Text>
            </Flex>

            {/* <Flex align="center">
              <Flex
                align="center"
                justify="center"
                w="2.5rem"
                h="2.5rem"
                borderRadius="10rem"
                bg={buttonBlue}
                mr={1.5}
              >
                <Icon
                  as={client.clientType === "individual" ? FaUser : FaSuitcase}
                  boxSize={2.5}
                  color={buttonBlueText}
                />
              </Flex>
              <Text color={textPrimary} fontSize="1rem" fontWeight="500">
                asfasf
              </Text>
            </Flex> */}
            <Flex align="center">
              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                maxW="160px"
                isTruncated
                mr="2rem"
              >
                {client.phone ? client.phone : "-"}
              </Text>
              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                maxW="200px"
                w="200px"
                isTruncated
              >
                {client.email ? client.email : "-"}
              </Text>
            </Flex>
          </Flex>
        ))}

        {clients.length === 0 && (
          <Flex align="center" justify="center" w="100%" p="0rem 0 1rem">
            <Flex justify="center" align="center" direction="column" p="3rem 0">
              <Image h="3rem" w="auto" m="0 auto" src={noServices} />
              <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
                {t("common.noData")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EmployeeClients;
