import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listProfessions,
  deleteProfession,
  updateProfessionStatus,
} from "../../../../actions/professionActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Select, Input } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/ProfessionListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  PROFESSION_DELETE_RESET,
  PROFESSION_UPDATE_RESET,
  PROFESSION_CREATE_RESET,
} from "../../../../constants/professionConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateProfession from "../sidepanels/CreateProfession";
import EditProfession from "../sidepanels/EditProfession";
import { listProfessionGroups } from "../../../../actions/professionGroupActions";
import {
  white,
  primary,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../utils/colors";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
// import ProfessionsStats from "../partials/ProfessionsStats";
import { PROFESSION_UPDATE_STATUS_RESET } from "../../../../constants/professionConstants";

const ProfessionList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const [selectedGroup, setSelectedGroup] = useState("");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  const hardcodedProfessions = [];

  // SEARCH
  const [searchTitle, setSearchTitle] = useState("");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [professionId, setProfessionId] = useState("");

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const professionList = useSelector((state) => state.professionList);
  const {
    loading,
    error,
    professions,
    pages,
    totalCount,
    countsByStatus,
    totalAveragePrice,
    totalAverageDuration,
    globalTotalAveragePrice,
    globalTotalAverageDuration,
  } = professionList;

  const professionCreate = useSelector((state) => state.professionCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    profession: createdProfession,
  } = professionCreate;

  const professionUpdate = useSelector((state) => state.professionUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = professionUpdate;

  const professionDelete = useSelector((state) => state.professionDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = professionDelete;

  const professionGroupList = useSelector((state) => state.professionGroupList);
  const { professionGroups } = professionGroupList;

  const professionUpdateStatus = useSelector(
    (state) => state.professionUpdateStatus
  );
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = professionUpdateStatus;

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const filterConfig = {
    patients: false,
    professions: true,
  };

  const [filterParams, setFilterParams] = useState({
    status: "",
    professionGroup: "",
    minPrice: "",
    maxPrice: "",
    minDuration: "",
    maxDuration: "",
  });

  // SORTING
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listProfessions(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false,
        filterParams.status,
        filterParams.professionGroup,
        filterParams.minPrice,
        filterParams.maxPrice,
        filterParams.minDuration,
        filterParams.maxDuration
      )
    );
  };

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setPage(1);
    setIsFilterActive(true);
    localStorage.setItem("filterSettings_professions", JSON.stringify(filters)); // Example for PatientList
    dispatch(
      listProfessions(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false, // Assuming `fetchAll` is false by default
        filters.status,
        filters.professionGroup,
        filters.minPrice,
        filters.maxPrice,
        filters.minDuration,
        filters.maxDuration
      )
    );

    // console.log("filters", filters);
  };

  // FILTER INACTIVE
  // // Function to filter professions based on role
  // const filterProfessionsByRole = (professions) => {
  //   // Non-admins see only active professions
  //   return professions.filter((profession) => profession.status === "active");
  // };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteProfessionHandler = (professionId) => {
    console.log("click");
    dispatch(deleteProfession(professionId));
    toastComponent.showToast(t("toasts.professionDelete"), "success", 3000);
    dispatch({ type: PROFESSION_DELETE_RESET });

    if (professions.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  // useEffect(() => {
  //   dispatch(listProfessionGroups({ fetchAll: true }));
  // }, [dispatch]);

  useEffect(() => {
    console.log("trying to access saved filters");
    const savedFilters = localStorage.getItem("filterSettings_professions");
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setFilterParams(filters);
      setIsFilterActive(true);
    }
    console.log("savedFilters", savedFilters);
  }, []);

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(t("toasts.professionCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: PROFESSION_CREATE_RESET });

      dispatch(
        listProfessions(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.professionGroup,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration
        )
      );
    }

    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(t("toasts.professionUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: PROFESSION_UPDATE_RESET });

      dispatch(
        listProfessions(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.professionGroup,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration
        )
      );
    }
    // USEEFFECT UPDATE STATUS
    else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.professionUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: PROFESSION_UPDATE_STATUS_RESET });

      dispatch(listProfessionGroups(searchTitle, page, pageSize));
    }
    // USEEFFECT MAIN
    else {
      dispatch(
        listProfessions(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.professionGroup,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration
        )
      );
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    sortField,
    sortOrder,
    filterParams,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setProfessionId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setProfessionId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setProfessionId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    if (userInfo && userInfo.isAdmin) {
      openEditSidePanelModal(value);
    } else {
      // toastComponent.showToast(
      //   t("toasts.notAuthorized"), // Update this message to a suitable one
      //   "error",
      //   3000
      // );
      console.log("not authorized");
    }
  };

  // LOGS

  // console.log("professions", professions);
  // console.log("professionGroups", professionGroups);

  // console.log("globalTotalAveragePrice", globalTotalAveragePrice);
  // console.log("globalTotalAverageDuration", globalTotalAverageDuration);

  // console.log("totalAveragePrice", totalAveragePrice);
  // console.log("totalAverageDuration", totalAverageDuration);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.adminProfessions")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminProfessions")}
        />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("profession.createProfession")}
        noBorder={false}
      >
        <CreateProfession close={closeSidePanelModal} />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("profession.editProfession")}
        noBorder={false}
      >
        <EditProfession
          close={closeEditSidePanelModal}
          professionId={professionId}
        />
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("profession.professions")}
                onChange={onChangeSearchTitle}
                placeholder={t("profession.search")}
                searchName={searchTitle}
                buttonLabel={t("profession.createProfession")}
                buttonHandler={openSidePanelModal}
                // isFilter
                // filterLabel={t("patient.filter")}
                // filterHandler={handleFilterApply}
                // isFilterActive={isFilterActive}
                // setIsFilterActive={setIsFilterActive}
                // filterConfig={filterConfig}
                // savedFilters={filterParams}
                // savedFiltersKey="filterSettings_professions"
                // isDisabledButton={!isAdmin}
              />
            </Box>

            {/* <ProfessionsStats
              loading={loading}
              stats={countsByStatus}
              total={totalCount}
              averagePrice={totalAveragePrice}
              averageDuration={totalAverageDuration}
              globalTotalAverageDuration={globalTotalAverageDuration}
              globalTotalAveragePrice={globalTotalAveragePrice}
            /> */}

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteProfessionHandler,
                openEditSidePanelModal,
                handleSortChange,
                updateProfessionStatus
              )}
              data={professions}
              loading={loading}
              error={error}
              onRowClick={handleRowClick}
              navigate={navigate}
              navigateTo="/profession-groups"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noProfessions")}
              noItemsDescription={t("noItems.noProfessionsText")}
              noItemsButtonLabel={t("noItems.noProfessionsButton")}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={professions.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ProfessionList;
