import React, { useState, useMemo, useEffect, Component } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import { Box, Spinner, Flex, Text, FormControl, Icon } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import StatusBadge from "../../components/badges/StatusBadge";
import { tint } from "polished";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  buttonBlue,
  buttonBlueText,
  backgroundLight,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaUser, FaSuitcase } from "react-icons/fa";
import { getUserEmployeesSimpleList } from "../../actions/userActions";

const height = 48;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    const dynamicHeight = Math.min(children.length, 4) * height;

    return (
      <List
        height={dynamicHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <Flex
            h={`${height}px`}
            style={style}
            borderBottom={`1px solid ${newBorder}`}
            _last={{
              borderBottom: "none",
            }}
          >
            {children[index]}
          </Flex>
        )}
      </List>
    );
  }
}

const FormControlAttendeeEmployeeSelect = ({
  onChange,
  isError,
  isRequired,
  isTouched,
  w,
  placeholder,
  employee,
  setAttendee,
  employerId,
  attendees,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userClientGetEmployeesSimple = useSelector(
    (state) => state.userClientGetEmployeesSimple
  );

  const { loading, error, employees } = userClientGetEmployeesSimple;

  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu open/close

  // Fetch patient data on component mount
  useEffect(() => {
    if (!employee) {
      dispatch(getUserEmployeesSimpleList(employerId));
    } else {
      setIsMenuOpen(false);
    }
  }, [dispatch, employee]);

  // useEffect(() => {
  //   if (employees && employees.length > 0) {
  //     setAttendee(employees[0]);
  //   } else {
  //     setAttendee(null);
  //   }
  // }, [employees]);

  // Update to handle closing the menu upon selection
  const handleOnChange = (selectedOption) => {
    onChange(selectedOption); // Assuming onChange prop is used to handle selection outside
    setIsMenuOpen(false); // Close the menu after selection
  };

  const attendeeIds = useMemo(
    () => attendees.map((att) => att.attendee),
    [attendees]
  );

  const filteredOptions = useMemo(() => {
    const normalizedInput = inputValue.trim().toLowerCase();

    return employees
      ?.map((employee) => ({
        value: employee._id,
        label: `${employee.firstName} ${employee.lastName}`,
        firstName: employee.firstName,
        lastName: employee.lastName,
        firstNameNormalized: employee.firstNameNormalized,
        lastNameNormalized: employee.lastNameNormalized,
        profession: employee.profession,
        status: employee.status,
        email: employee.email,
        isAttendee: attendeeIds.includes(employee._id), // Flag to indicate if this employee is also an attendee
        isDisabled: attendeeIds.includes(employee._id),
      }))
      .filter(
        (employee) =>
          employee.status === "active" &&
          (!normalizedInput ||
            `${employee.firstNameNormalized} ${employee.lastNameNormalized} ${
              employee.companyName || ""
            }`
              .toLowerCase()
              .includes(normalizedInput))
      );
  }, [inputValue, employees, attendeeIds]);

  // Custom rendering for options
  const formatOptionLabel = ({
    attendeeType,
    firstName,
    lastName,
    profession,
    status,
    isAttendee,
    isDisabled,
  }) => (
    // console.log("isDisabled:", isDisabled),
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      isDisabled={isDisabled}
      _disabled={{
        opacity: 0.5,
      }}
    >
      <Flex align="center">
        <Flex
          align="center"
          justify="center"
          w="2rem"
          h="2rem"
          borderRadius="10rem"
          bg={buttonBlue}
          mr={1.5}
        >
          <Icon
            as={attendeeType === "individual" ? FaUser : FaSuitcase}
            boxSize={2}
            color={buttonBlueText}
          />
        </Flex>

        <Flex align="center">
          <Text fontSize="sm" fontWeight="500">
            {firstName} {lastName}
          </Text>
        </Flex>

        <Text ml={2} fontSize="xs">
          {(profession && profession.name && profession.name) || "-"}
        </Text>
      </Flex>

      {/* {isAttendee && <Icon as={FaSuitcase} color="green.500" />} */}

      <StatusBadge
        isSmall
        options="eventEmployee"
        status={isAttendee ? "registered" : "unregistered"}
        ml={2}
      />
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Box>
        <Spinner size="lg" color={primary} />
      </Box>
    );

  // console.log("attendees:", attendees);
  // console.log("employees:", employees);
  // console.log("filteredOptions:", filteredOptions);

  return (
    <FormControl isRequired={isRequired} isInvalid={isTouched && isError} w={w}>
      <Select
        // autoFocus={employee ? false : true}
        menuIsOpen={isMenuOpen}
        value={employee}
        // components={{ MenuList }}
        options={filteredOptions}
        onInputChange={(newInputValue, { action }) => {
          if (action === "input-change") {
            setInputValue(newInputValue);
          }
        }}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        onChange={handleOnChange}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
        filterOption={() => true} // Since you're manually filtering options, always return true here
        isOptionDisabled={(option) => option.isDisabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textPrimary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          menu: (provided) => ({
            ...provided,
            position: "relative",
            zIndex: 9999,
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: `0 0 0 1px ${newBorder}`,
            border: "none",
          }),
          menuList: (provided) => ({
            ...provided,
            borderRadius: "1rem",

            // maxHeight: "10rem",
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isDisabled
              ? tint(0.5, textPrimary)
              : state.isSelected
              ? "white"
              : textSecondary,
            backgroundColor: state.isDisabled
              ? tint(0.1, backgroundLight)
              : state.isSelected
              ? primary
              : white,
            opacity: state.isDisabled ? 0.5 : 1,
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: state.isDisabled ? tint(0.5, textPrimary) : "white",
              cursor: state.isDisabled ? "not-allowed" : "pointer",
            },
            // ":disabled": {
            //   opacity: 0.5,
            // },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("common.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlAttendeeEmployeeSelect;
