import React, { useState } from "react";
import {
  Flex,
  useDisclosure,
  Box,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import {
  textPrimary,
  newBorder,
  primary,
  white,
} from "../../../../../utils/colors";
import ToothButton from "./ToothButton";
import { useTranslation } from "react-i18next";

const TeethSelect = ({ tooth, setTooth, minW }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isChanged, setIsChanged] = useState(false); // flag to check if price has been changed

  const [selectedTooth, setSelectedTooth] = useState(null);

  // MULTIPLE
  // const handleToothClick = (toothNumber) => {
  //   // Update the selected teeth list
  //   const updatedSelectedTeeth = selectedTooth.includes(toothNumber)
  //     ? selectedTooth.filter((t) => t !== toothNumber) // Deselect if already selected
  //     : [...selectedTooth, toothNumber]; // Select if not already selected

  //   setSelectedTeeth(updatedSelectedTeeth);
  // };

  //SINGLE
  const handleToothClick = (toothNumber) => {
    // Check if the clicked tooth is already selected
    const isAlreadySelected = selectedTooth === toothNumber;

    // If it's already selected, deselect it
    if (isAlreadySelected) {
      setSelectedTooth(null);
      setTooth(null);
    } else {
      // If it's not selected, select the clicked one
      setSelectedTooth(toothNumber);
      setTooth(toothNumber);
    }
  };

  const handleConfirmClick = () => {
    setIsChanged(false);
    onClose(); // 3. Close the popover after confirming the changes
  };

  const handleClose = () => {
    onClose();
  };

  //LOGS
  console.log("tooth: ", tooth);
  console.log("selectedTooth: ", selectedTooth);

  const renderSelectedTeethButtons1 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 18 - i);
    return teethNumbers.map((toothNumber) => (
      <ToothButton
        toothNumber={toothNumber}
        key={toothNumber}
        bg={selectedTooth === toothNumber ? primary : "none"}
        onClick={() => handleToothClick(toothNumber)}
        color={selectedTooth === toothNumber ? white : "#DFDFDF"}
        isSelected={tooth === toothNumber}
        minW={minW}
      />
    ));
  };

  const renderSelectedTeethButtons2 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => i + 21);
    return teethNumbers.map((toothNumber) => (
      <ToothButton
        toothNumber={toothNumber}
        key={toothNumber}
        bg={selectedTooth === toothNumber ? primary : "none"}
        onClick={() => handleToothClick(toothNumber)}
        color={selectedTooth === toothNumber ? white : "#DFDFDF"}
        isSelected={tooth === toothNumber}
        minW={minW}
      />
    ));
  };

  const renderSelectedTeethButtons3 = () => {
    // Assuming teeth numbers 48 to 41
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 48 - i);
    return teethNumbers.map((toothNumber) => (
      <ToothButton
        toothNumber={toothNumber}
        key={toothNumber}
        bg={selectedTooth === toothNumber ? primary : "none"}
        onClick={() => handleToothClick(toothNumber)}
        color={selectedTooth === toothNumber ? white : "#DFDFDF"}
        isSelected={tooth === toothNumber}
        minW={minW}
      />
    ));
  };

  const renderSelectedTeethButtons4 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 31 + i);
    return teethNumbers.map((toothNumber) => (
      <ToothButton
        toothNumber={toothNumber}
        key={toothNumber}
        bg={selectedTooth === toothNumber ? primary : "none"}
        onClick={() => handleToothClick(toothNumber)}
        color={selectedTooth === toothNumber ? white : "#DFDFDF"}
        isSelected={tooth === toothNumber}
        minW={minW}
      />
    ));
  };
  return (
    <>
      <FormControl m="0 0 1.5rem" direction="column" isRequired>
        <FormLabel fontSize="sm" position="relative" color={textPrimary}>
          {t("form.teethArea")}
        </FormLabel>
        <Flex
          w="100%"
          align="center"
          justify="center"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="0.5rem"
        >
          <Flex direction="column">
            <Box display="flex" flexWrap="wrap">
              {renderSelectedTeethButtons1()}
            </Box>
            <Box bg={newBorder} w="100%" h="1px" my={1} />
            <Box display="flex" flexWrap="wrap">
              {renderSelectedTeethButtons3()}
            </Box>
          </Flex>
          <Box bg={newBorder} h="65px" w="1px" mx={4} />
          <Flex direction="column">
            <Box display="flex" flexWrap="wrap">
              {renderSelectedTeethButtons2()}
            </Box>
            <Box bg={newBorder} w="100%" h="1px" my={1} />
            <Box display="flex" flexWrap="wrap">
              {renderSelectedTeethButtons4()}
            </Box>
          </Flex>
        </Flex>
      </FormControl>
    </>
  );
};

export default TeethSelect;
