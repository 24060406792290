import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
  Card,
  CardBody,
  Stack,
  Text,
  Grid,
  Image,
} from "@chakra-ui/react";
import {
  white,
  primary,
  newBorder,
  backgroundLight,
  textPrimary,
  textSecondary,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import ToastComponent from "../../../../../components/partials/ToastComponent";

const TabRelatedProducts = ({ products }) => {
  const productId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  console.log("products", products);

  return (
    <>
      <Box p="0rem 0rem">
        <Flex align="center" justify="space-between" p="2rem 0">
          <Heading fontSize="xl" fontWeight="600">
            {t("product.tabRelatedProducts")}
          </Heading>
          {/* <Button
            bg={primary}
            color={white}
            size="lg"
            borderRadius="10rem"
            fontSize="1rem"
            onClick={openEditDescriptionSidePanel}
          >
            {t("course.editCourseDescription")}
          </Button> */}
        </Flex>

        <Flex
          p="1.5rem 2rem"
          border={`1px dashed ${newBorder}`}
          borderRadius="1rem"
        >
          <Grid templateColumns="repeat(4, 1fr)" gap={4} p="0 2rem">
            {products.map((product) => (
              <Card
                key={product._id}
                maxW="sm"
                borderRadius="1rem"
                border={`1px solid ${newBorder}`}
                p="0rem 0rem 1.5rem 0rem"
                boxShadow="none"
                mb={4}
              >
                <CardBody p={0}>
                  <Box
                    borderTopLeftRadius="0.75rem"
                    borderTopRightRadius="0.75rem"
                    h="10rem"
                    w="100%"
                    overflow="hidden"
                    bg={backgroundLight}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {product.image ? (
                      <Image
                        h="100%"
                        w="100%"
                        objectFit="cover"
                        src={product.image}
                        alt="Green double couch with wooden legs"
                      />
                    ) : (
                      <Image src={icons.noImage} />
                    )}
                  </Box>

                  <Stack spacing="0" p="1rem 1rem 0">
                    <Heading
                      fontSize="1rem"
                      fontWeight="600"
                      color={textPrimary}
                    >
                      {product.name}
                    </Heading>
                    <Text fontSize="0.875rem" color={textSecondary}>
                      {product.description}
                    </Text>

                    <Stack spacing={0.5} py={4}>
                      <Flex align="center">
                        <Text
                          fontSize="0.875rem"
                          color={textSecondary}
                          fontWeight="500"
                          w="100px"
                        >
                          Price:
                        </Text>
                      </Flex>
                    </Stack>
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </Grid>
        </Flex>
      </Box>
    </>
  );
};

export default TabRelatedProducts;
