import {
  REPORT_IMPLANT_FAILURE_LIST_REQUEST,
  REPORT_IMPLANT_FAILURE_LIST_SUCCESS,
  REPORT_IMPLANT_FAILURE_LIST_FAIL,
  REPORT_IMPLANT_FAILURE_DETAILS_REQUEST,
  REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS,
  REPORT_IMPLANT_FAILURE_DETAILS_FAIL,
  REPORT_IMPLANT_FAILURE_CREATE_REQUEST,
  REPORT_IMPLANT_FAILURE_CREATE_SUCCESS,
  REPORT_IMPLANT_FAILURE_CREATE_FAIL,
  REPORT_IMPLANT_FAILURE_CREATE_RESET,
  REPORT_IMPLANT_FAILURE_UPDATE_REQUEST,
  REPORT_IMPLANT_FAILURE_UPDATE_SUCCESS,
  REPORT_IMPLANT_FAILURE_UPDATE_FAIL,
  REPORT_IMPLANT_FAILURE_DELETE_REQUEST,
  REPORT_IMPLANT_FAILURE_DELETE_SUCCESS,
  REPORT_IMPLANT_FAILURE_DELETE_FAIL,
  REPORT_IMPLANT_FAILURE_DELETE_RESET,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL,
  REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET,
} from "../constants/reportConstants";

export const reportImplantFailureListReducer = (
  state = { reports: [] },
  action
) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_LIST_REQUEST:
      return { loading: true, reports: [] };
    case REPORT_IMPLANT_FAILURE_LIST_SUCCESS:
      return {
        loading: false,
        reports: action.payload.reports,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    case REPORT_IMPLANT_FAILURE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reportImplantFailureDetailsReducer = (
  state = { report: {} },
  action
) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS:
      return { loading: false, report: action.payload };
    case REPORT_IMPLANT_FAILURE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reportImplantFailureCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_CREATE_REQUEST:
      return { loading: true };
    case REPORT_IMPLANT_FAILURE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        trackingNumber: action.payload.trackingNumber,
      };
    case REPORT_IMPLANT_FAILURE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_IMPLANT_FAILURE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const reportImplantFailureUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_UPDATE_REQUEST:
      return { loading: true };
    case REPORT_IMPLANT_FAILURE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case REPORT_IMPLANT_FAILURE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reportImplantFailureDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_DELETE_REQUEST:
      return { loading: true };
    case REPORT_IMPLANT_FAILURE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REPORT_IMPLANT_FAILURE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_IMPLANT_FAILURE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const reportImplantFailureUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};
