import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Image,
  HStack,
  Stack,
  Avatar,
  Flex,
  Tooltip,
  Box,
  Input,
  Heading,
  Icon,
  Button,
  IconButton,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  buttonRed,
  buttonRedText,
  newDarkBlue,
  white,
} from "../../../../../utils/colors";
import dayjs from "dayjs";
import { FaUser, FaSuitcase, FaExchangeAlt } from "react-icons/fa";
import { TbDentalBroken } from "react-icons/tb";
import { shade } from "polished";
import StatusButton from "../../../../../components/buttons/StatusButton";
import MoreButton from "../../../../../components/buttons/MoreButton";

export const columns = (translate, handleSortChange, navigate) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("trackingNumber")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "trackingNumber",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { trackingNumber, createdAt } = row.original;

        // console.log("row.original", row.original);

        return (
          <Stack gap="0rem" w="150px">
            <Heading fontSize="sm" fontWeight="600" textTransform="capitalize">
              {dayjs(createdAt).format("MMMM D")}
            </Heading>
            <Text color={textSecondary} fontSize="xs" fontWeight="500">
              {trackingNumber}
            </Text>
          </Stack>
          // <Text
          //   maxWidth="60px"
          //   overflow="hidden"
          //   whiteSpace="nowrap"
          //   fontSize="12px"
          //   color={textSecondary}
          //   fontWeight="500"
          //   isTruncated
          //   pr="1rem"
          // >
          //   {trackingNumber}
          // </Text>
        );
      },
    },
    // {
    //   Header: () => (
    //     <Box onClick={() => handleSortChange("client")}>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>
    //           {translate("implantFailureReports.columnClient")}
    //         </Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "client",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     const { firstName, lastName, companyName, email, clientType } =
    //       row.original;

    //     const name = `${firstName} ${lastName}`;

    //     return (
    //       <HStack gap={2}>
    //         <Flex
    //           align="center"
    //           justify="center"
    //           w="2.5rem"
    //           h="2.5rem"
    //           borderRadius="10rem"
    //           bg={buttonBlue}
    //         >
    //           <Icon
    //             as={clientType === "individual" ? FaUser : FaSuitcase}
    //             boxSize={2.5}
    //             color={buttonBlueText}
    //           />
    //         </Flex>

    //         <Stack gap={0.5} overflow="hidden">
    //           <Text
    //             fontSize="13px"
    //             color={textPrimary}
    //             fontWeight="500"
    //             isTruncated
    //             lineHeight={1}
    //             maxW="150px"
    //           >
    //             {clientType === "individual" ? name : companyName}
    //           </Text>

    //           {email && (
    //             <Text
    //               fontSize="xs"
    //               color={textSecondary}
    //               fontWeight="500"
    //               isTruncated
    //               noOfLines={1}
    //               whiteSpace="normal"
    //               overflow="hidden"
    //               w="100%"
    //               maxW="100px"
    //             >
    //               {companyName}
    //             </Text>
    //           )}
    //         </Stack>
    //       </HStack>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("exchange")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("implantFailureReports.columnExchange")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "implant",
      disableSortBy: true,
      Cell: ({ row }) => {
        const {
          fixture,
          core,
          diameter,
          length,
          refCode,
          lotNumber,
          newFixture,
          newCore,
          newDiameter,
          newLength,
        } = row.original;

        return (
          <Flex>
            <Flex align="center" borderRadius="1rem" p="0.5rem 1rem">
              <Stack
                gap={0}
                overflow="hidden"
                w="155px"
                align="center"
                justify="center"
              >
                <Flex align="center" justify="center" textAlign="center">
                  {/* <Text
                    fontSize="13px"
                    color={textPrimary}
                    fontWeight="500"
                    isTruncated
                    lineHeight={1}
                  >
                    {(fixture && t(`implants.${fixture}`)) || "-"}
                  </Text> */}
                  <Text fontSize="13px" fontWeight="600" color={textPrimary}>
                    {translate(`implants.${fixture}`)}
                    {core === null ? null : (
                      <>
                        {core === "regular" ||
                        core === "special" ||
                        core === "deep" ? (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({translate(`coreSimple.${core}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            - (C{core})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                </Flex>

                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  textAlign="center"
                >
                  Ø{diameter}mm/L{length}{" "}
                </Text>
              </Stack>

              <Flex
                align="center"
                p="0.75rem"
                bg={backgroundLight}
                borderRadius="10rem"
                m="0 1rem"
              >
                <Icon as={FaExchangeAlt} boxSize={2.5} color={textSecondary} />
              </Flex>

              <Stack gap={0} overflow="hidden" w="155px">
                <Flex align="center" justify="center">
                  <Text fontSize="sm" fontWeight="600" color={textPrimary}>
                    {translate(`implants.${newFixture}`)}
                    {newCore === null ? null : (
                      <>
                        {newCore === "regular" ||
                        newCore === "special" ||
                        newCore === "deep" ? (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            {" "}
                            - ({translate(`coreSimple.${newCore}`)})
                          </Text>
                        ) : (
                          <Text
                            as="span"
                            fontSize="xs"
                            color={textPrimary}
                            fontWeight="500"
                          >
                            - (C{newCore})
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                </Flex>

                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  textAlign="center"
                >
                  Ø{newDiameter}mm/L{newLength}{" "}
                </Text>
              </Stack>
            </Flex>
          </Flex>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("reason")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("implantFailureReports.columnReason")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "reason",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { tooth, reason, reasonText } = row.original;

        return (
          <Flex align="center">
            <Stack gap={0} overflow="hidden">
              <Stack gap={1}>
                <Flex align="center">
                  <Flex
                    align="center"
                    justify="center"
                    w="20px"
                    h="20px"
                    borderRadius="10rem"
                    bg={backgroundLight}
                    mr={1}
                    flexShrink={0}
                  >
                    <Icon
                      as={TbDentalBroken}
                      boxSize={2.5}
                      color={shade(0.3, backgroundLight)}
                    />
                  </Flex>
                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    textTransform="capitalize"
                  >
                    {tooth ? `${tooth}` : ""}
                  </Text>
                </Flex>

                <Flex align="center">
                  {reason === "Other" ? (
                    <Text
                      fontSize="xs"
                      color={textSecondary}
                      fontWeight="500"
                      isTruncated
                      noOfLines={1}
                      whiteSpace="normal"
                      overflow="hidden"
                      maxW="160px"
                      w="100%"
                      textTransform="capitalize"
                    >
                      {translate(`implantForm.${reasonText}`)}
                    </Text>
                  ) : (
                    <Text
                      fontSize="xs"
                      color={textSecondary}
                      fontWeight="500"
                      isTruncated
                      noOfLines={1}
                      whiteSpace="normal"
                      overflow="hidden"
                      maxW="160px"
                      w="100%"
                      textTransform="capitalize"
                    >
                      {translate(`implantForm.${reason}`)}
                    </Text>
                  )}
                </Flex>
              </Stack>
            </Stack>
          </Flex>
        );
      },
    },
    // {
    //   Header: () => (
    //     <Box onClick={() => handleSortChange("tooth")}>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>{translate("implantFailureReports.columnTooth")}</Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "tooth",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     const { tooth } = row.original;

    //     return (
    //       <Flex align="center">
    //         <Flex
    //           align="center"
    //           justify="center"
    //           w="20px"
    //           h="20px"
    //           borderRadius="10rem"
    //           bg={backgroundLight}
    //         >
    //           <Icon
    //             as={LiaToothSolid}
    //             boxSize={3.5}
    //             color={shade(0.3, backgroundLight)}
    //           />
    //         </Flex>
    //         <Text ml={1} fontSize="13px" fontWeight="500" color={textPrimary}>
    //           {tooth ? tooth : "-"}
    //         </Text>
    //       </Flex>
    //     );
    //   },
    // },

    // {
    //   Header: () => (
    //     <Box onClick={() => handleSortChange("dates")}>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>{translate("implantFailureReports.columnDates")}</Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "dates",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     const { surgeryDate, failureDate } = row.original;

    //     const formattedSurgeryDate = dayjs(surgeryDate).format("MMM D");
    //     const formattedFailureDate = dayjs(failureDate).format("MMM D");

    //     const formattedSurgeryDateAltFormat =
    //       dayjs(surgeryDate).format("MMMM D");
    //     const formattedFailureDateAltFormat =
    //       dayjs(failureDate).format("MMMM D");

    //     return (
    //       <Tooltip
    //         bg={newDarkBlue}
    //         p="1rem"
    //         borderRadius="1rem"
    //         label={
    //           <Flex direction="column">
    //             <Text fontSize="13px" color={white} fontWeight="600" mb={2}>
    //               {translate("implantFailureReports.reportDates")}:
    //             </Text>

    //             <Flex align="center" mb={1}>
    //               <Text fontSize="xs" color={white} fontWeight="500">
    //                 {translate("implantFailureReports.surgeryDate")}:
    //               </Text>
    //               <Text ml={1} fontSize="xs" color={white} fontWeight="500">
    //                 {formattedSurgeryDateAltFormat}
    //               </Text>
    //             </Flex>

    //             <Flex align="center" mb={0}>
    //               <Text fontSize="xs" color={white} fontWeight="500">
    //                 {translate("implantFailureReports.failureDate")}:
    //               </Text>
    //               <Text ml={1} fontSize="xs" color={white} fontWeight="500">
    //                 {formattedFailureDateAltFormat}
    //               </Text>
    //             </Flex>
    //           </Flex>
    //         }
    //       >
    //         <Stack gap={0} overflow="hidden">
    //           {/* <Flex align="center" mb={2}>
    //           <Flex
    //             align="center"
    //             justify="center"
    //             w="20px"
    //             h="20px"
    //             borderRadius="10rem"
    //             bg={buttonBlue}
    //           >
    //             <Icon
    //               as={TbCalendarEvent}
    //               boxSize={3.5}
    //               color={buttonBlueText}
    //             />
    //           </Flex>
    //           <Text
    //             fontSize="xs"
    //             ml={1}
    //             color={textSecondary}
    //             fontWeight="500"
    //             isTruncated
    //             noOfLines={1}
    //             whiteSpace="normal"
    //             overflow="hidden"
    //             w="100%"
    //           >
    //             {(surgeryDate && formattedSurgeryDate) || "-"}
    //           </Text>
    //         </Flex> */}
    //           <Flex align="center">
    //             <Flex
    //               align="center"
    //               justify="center"
    //               w="20px"
    //               h="20px"
    //               borderRadius="10rem"
    //               bg={buttonRed}
    //             >
    //               <Icon
    //                 as={TbCalendarEvent}
    //                 boxSize={3.5}
    //                 color={buttonRedText}
    //               />
    //             </Flex>
    //             <Text
    //               fontSize="xs"
    //               ml={1}
    //               color={textSecondary}
    //               fontWeight="500"
    //               isTruncated
    //               noOfLines={1}
    //               whiteSpace="normal"
    //               overflow="hidden"
    //               w="100%"
    //             >
    //               {(failureDate && formattedFailureDate) || "-"}
    //             </Text>
    //           </Flex>
    //         </Stack>
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   Header: () => (
    //     <Box onClick={() => handleSortChange("xray")}>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>{translate("implantFailureReports.columnXray")}</Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "xray",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     const { beforeXrayImage, afterXrayImage } = row.original;

    //     console.log("beforeXrayImage", beforeXrayImage);
    //     console.log("afterXrayImage", afterXrayImage);

    //     return (
    //       <Tooltip
    //         bg={newDarkBlue}
    //         p="1rem"
    //         borderRadius="1rem"
    //         label={
    //           <>
    //             <Flex direction="column">
    //               <Text fontSize="13px" color={white} fontWeight="600" mb={2}>
    //                 {translate("implantFailureReports.columnXray")}:
    //               </Text>

    //               <Flex align="center" mb={1}>
    //                 <Text fontSize="xs" color={white} fontWeight="500">
    //                   {translate("implantFailureReports.xRayBefore")}:
    //                 </Text>
    //                 <Flex
    //                   bg={beforeXrayImage ? buttonGreen : buttonRed}
    //                   ml={2}
    //                   p="0.125rem 0.5rem"
    //                   borderRadius="10rem"
    //                 >
    //                   <Text
    //                     fontSize="xs"
    //                     color={
    //                       beforeXrayImage ? buttonGreenText : buttonRedText
    //                     }
    //                     fontWeight="600"
    //                   >
    //                     {beforeXrayImage
    //                       ? translate("common.uploaded")
    //                       : translate("common.noImage")}
    //                   </Text>
    //                 </Flex>
    //               </Flex>

    //               <Flex align="center">
    //                 <Text fontSize="xs" color={white} fontWeight="500">
    //                   {translate("implantFailureReports.xRayAfter")}:
    //                 </Text>
    //                 <Flex
    //                   bg={afterXrayImage ? buttonGreen : buttonRed}
    //                   ml={2}
    //                   p="0.125rem 0.5rem"
    //                   borderRadius="10rem"
    //                 >
    //                   <Text
    //                     fontSize="xs"
    //                     color={afterXrayImage ? buttonGreenText : buttonRedText}
    //                     fontWeight="600"
    //                   >
    //                     {afterXrayImage
    //                       ? translate("common.uploaded")
    //                       : translate("common.noImage")}
    //                   </Text>
    //                 </Flex>
    //               </Flex>
    //             </Flex>
    //           </>
    //         }
    //       >
    //         <Flex gap={0} align="center">
    //           <Flex align="center" mr={2}>
    //             <Flex
    //               align="center"
    //               justify="center"
    //               w="20px"
    //               h="20px"
    //               borderRadius="10rem"
    //               bg={beforeXrayImage ? buttonGreen : buttonRed}
    //             >
    //               <Icon
    //                 as={FaCircleCheck}
    //                 boxSize={3}
    //                 color={beforeXrayImage ? buttonGreenText : buttonRedText}
    //               />
    //             </Flex>
    //           </Flex>
    //           <Flex align="center">
    //             <Flex
    //               align="center"
    //               justify="center"
    //               w="20px"
    //               h="20px"
    //               borderRadius="10rem"
    //               bg={afterXrayImage ? buttonGreen : buttonRed}
    //             >
    //               <Icon
    //                 as={FaCircleCheck}
    //                 boxSize={3}
    //                 color={afterXrayImage ? buttonGreenText : buttonRedText}
    //               />
    //             </Flex>
    //           </Flex>
    //         </Flex>
    //       </Tooltip>
    //     );
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateSpeakerStatus(_id, newStatus));
        };
        const viewHandler = (id) => {
          navigate(`/admin/reports/implant-failure-reports/${id}`);
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="report"
              isUnclickable
            />
            <MoreButton
              id={_id}
              viewHandler={() => viewHandler(_id)}
              viewLabel={translate("clientAccount.viewReport")}
              // editHandler={(e) => {
              //   e.stopPropagation();
              //   openEditSidePanelModal(_id);
              // }}
            />
          </HStack>
        );
      },
    },
  ];
};
