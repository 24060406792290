import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listProfessionDetails,
  updateProfession,
} from "../../../../actions/professionActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  TabPanels,
  TabPanel,
  Select,
  HStack,
  Switch,
  Box,
  Heading,
  Checkbox,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
// import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlProfessionGroup from "../../../../components/input/FormControlProfessionGroup";
import FormControlDuration from "../../../../components/input/FormControlDuration";
import { PROFESSION_UPDATE_RESET } from "../../../../constants/professionConstants";

const EditProfession = ({ close, professionId }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  // const [customId, setCustomId] = useState("");
  const [name, setName] = useState("");
  const [professionGroup, setProfessionGroup] = useState("");
  const [status, setStatus] = useState("active");

  // REDUX
  const professionDetails = useSelector((state) => state.professionDetails);
  const { profession } = professionDetails;

  const professionUpdate = useSelector((state) => state.professionUpdate);
  const { success } = professionUpdate;

  // USEEFFECT
  useEffect(() => {
    if (!profession.name || profession._id !== professionId) {
      dispatch(listProfessionDetails(professionId));
    } else {
      // setCustomId(profession.customId);
      setName(profession.name);
      setProfessionGroup(profession.professionGroup);
      setStatus(profession.status);
    }
  }, [dispatch, professionId, profession]);

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: PROFESSION_UPDATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateProfession({
        _id: professionId,
        // customId: customId,
        name: name,
        professionGroup: professionGroup,
      })
    );
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [professionGroupTouched, setProfessionGroupTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";
  const isProfessionGroupError = professionGroup === "";

  // LOGS

  return (
    <>
      <form onSubmit={submitHandler}>
        <FormControlDefault
          type="text"
          isRequired
          label={t("form.professionName")}
          value={name}
          placeholder={t("placeholder.professionName")}
          onChange={(e) => {
            setName(e.target.value);
            setNameTouched(true);
          }}
          isTouched={nameTouched}
          isError={isNameError}
          w="100%"
          errorMessage={t("formError.professionNameRequired")}
        />
        <FormControlProfessionGroup
          label={t("form.professionGroup")}
          value={professionGroup}
          onChange={(selectedOption) => {
            setProfessionGroup(selectedOption.value);
            setProfessionGroupTouched(true);
          }}
          isRequired={true} //
          isTouched={professionGroupTouched}
          isError={isProfessionGroupError}
          errorMessage={t("formError.professionGroupRequired")}
          w="100%"
        />

        <Flex
          mt="3rem"
          justify="center"
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="1.5rem 0"
          position="relative"
          zIndex={0}
          bg={white}
          bottom="0"
          w="100%"
        >
          <Flex justify="flex-end">
            <Button
              minWidth="10rem"
              mr={4}
              bg={backgroundLight}
              size="lg"
              color={textSecondary}
              fontSize="md"
              borderRadius="10rem"
              onClick={closeHandler}
            >
              {t("common.cancel")}
            </Button>
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
              isDisabled={!name || !professionGroup}
              // isDisabled={!firstName.trim() || !lastName.trim()}
            >
              {t("profession.editProfession")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default EditProfession;
