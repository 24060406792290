import React, { useRef } from "react";
import { Box, Flex, Text, Heading, Image, Button } from "@chakra-ui/react";
import {
  purple,
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  primary,
  secondary,
} from "../../../utils/colors";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import registerPromo1 from "../../../assets/images/registerPromo1.png";
import registerPromo2 from "../../../assets/images/registerPromo2.png";
import registerPromo3 from "../../../assets/images/registerPromo3.png";
import { useMediaQuery } from "react-responsive";

// Import Swiper styles
import "swiper/css";

const RegisterPromo = ({ setShowPromo, setShowForm }) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const slides = [
    {
      image: registerPromo1,
      title: t("landingMegagen.registerPromoSlide1Title"),
      content: t("landingMegagen.registerPromoSlide1Text"),
    },
    {
      image: registerPromo2,
      title: t("landingMegagen.registerPromoSlide2Title"),
      content: t("landingMegagen.registerPromoSlide2Text"),
    },
    {
      image: registerPromo3,
      title: t("landingMegagen.registerPromoSlide3Title"),
      content: t("landingMegagen.registerPromoSlide3Text"),
    },
  ];

  return (
    <>
      <Box
        w={["100%", "100%", "30%"]}
        position={["fixed", "fixed", "fixed"]}
        h={["100%", "100%", "100%"]}
        // minH={["100vh", "100vh", "initial"]}
        flexShrink={0}
        bg={purple}
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
        zIndex={1}
        overflowY={["auto", "auto", "initial"]}
        minH={["100vh", "100vh", "initial"]}
      >
        <Flex
          m="0 auto"
          p={["1.5rem 2rem 6rem", "1.5rem 2rem 6rem", "3rem 2rem 0"]}
          postion={"relative"}
          minH={["calc(100vh - 80px)", "calc(100vh - 80px)", "initial"]}
        >
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            modules={[Pagination, Autoplay]}
            className="registerPromo-swiper"
            navigation={false}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000, // 5 seconds per slide
              disableOnInteraction: false,
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index} w="100%">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  h="100%"
                  w="100%"
                  p={["0 0 3rem", "0 0 3rem", "0 0 6rem"]}
                >
                  <Flex align="center" justify="center" w="80%">
                    <Image
                      src={slide.image}
                      alt={slide.title}
                      w="100%"
                      h="100%"
                      objectFit="cover"
                    />
                  </Flex>

                  <Heading
                    color={white}
                    fontSize={["1.125rem", "1.125rem", "1.25rem"]}
                    textAlign="center"
                    fontWeight="600"
                    m={["2rem auto 0", "2rem auto 0", "3rem auto 0"]}
                    maxW={["100%", "100%", "60%"]}
                  >
                    {t("landingMegagen.registerPromoTitle")}
                  </Heading>

                  <Flex w="100%" direction="column">
                    <Heading
                      color={white}
                      fontSize={["2.25rem", "2.25rem", "2.5rem"]}
                      textAlign="center"
                      whiteSpace="wrap"
                      m={["2rem 0 1rem", "2rem 0 1rem", "2rem 0 1.5rem"]}
                      lineHeight={["1.2", "1.2", "initial"]}
                    >
                      {slide.title}
                    </Heading>
                    <Text
                      color={white}
                      textAlign="center"
                      fontSize={["1rem", "1rem", "1rem"]}
                      fontWeight={["500", "500", "400"]}
                      opacity="0.8"
                    >
                      {slide.content}
                    </Text>
                  </Flex>
                  {isMobile && (
                    <Flex
                      w="100%"
                      align="center"
                      justify="center"
                      m="2rem 0 1rem"
                    >
                      <Button
                        h="3.5rem"
                        fontSize="md"
                        fontWeight="600"
                        borderRadius="10rem"
                        w="80%"
                        onClick={() => {
                          setShowPromo(false);
                          setShowForm(true);
                        }}
                      >
                        {t("landingMegagen.startRegister")}
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </Box>
    </>
  );
};

export default RegisterPromo;
