import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listSpeakers } from "../../../../actions/speakerActions";
import {
  listImplantFailureReports,
  deleteImplantFailureReport,
} from "../../../../actions/reportActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box } from "@chakra-ui/react";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/ImplantFailureReportListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { REPORT_IMPLANT_FAILURE_DELETE_RESET } from "../../../../constants/reportConstants";
import CreateImplantFailReport from "../sidepanels/CreateImplantFailReport";
import { use } from "i18next";

const ImplantFailureReportList = () => {
  // TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // NAVIGATION
  const navigate = useNavigate();

  // TOAST
  const toastComponent = ToastComponent();
  const toast = useToast();

  // NEW STATE

  // STATE
  const [newReportId, setNewReportId] = useState("");

  const [reportId, setReportId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reportImplantFailureList = useSelector(
    (state) => state.reportImplantFailureList
  );
  const { loading, reports, error, pages } = reportImplantFailureList;

  const reportImplantFailureCreate = useSelector(
    (state) => state.reportImplantFailureCreate
  );

  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    trackingNumber,
  } = reportImplantFailureCreate;

  const reportImplantFailureDelete = useSelector(
    (state) => state.reportImplantFailureDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = reportImplantFailureDelete;

  // SORTING
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listImplantFailureReports(searchTitle, 1, pageSize));
  };

  // HANDLERS

  const deleteHandler = (reportId) => {
    console.log("click");
    dispatch(deleteImplantFailureReport(reportId));

    dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
    if (reports.length === 1) {
      setPage(page - 1);
    }
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    console.log("New Page Size:", newSize);
    setPageSize(newSize);
    setPage(1); // Reset the page to 1
    dispatch(listImplantFailureReports(searchTitle, 1, pageSize));
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    } else {
      dispatch(listImplantFailureReports(searchTitle, page, pageSize));
    }
  }, [
    dispatch,
    userInfo,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successDelete,
  ]);

  useEffect(() => {
    if (successCreate) {
      setTimeout(() => setNewReportId(""), 9000);
      // toastComponent.showToast(
      //   t("implantFailureReports.toastCreate"),
      //   "success",
      //   3000
      // );
    }

    if (errorCreate) {
      toastComponent.showToast(
        t("implantFailureReports.toastCreateError"),

        "error",
        3000
      );
    }
  }, [successCreate, errorCreate]);

  useEffect(() => {
    if (successDelete) {
      toast({
        title: t("implantFailureReports.reportDeleted"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: REPORT_IMPLANT_FAILURE_DELETE_RESET });
    } else if (errorDelete) {
      toast({
        title: t("implantFailureReports.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successDelete, errorDelete]);

  const gotoPage = (page) => {
    console.log("gotoPage", page);
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setReportId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setReportId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setReportId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    navigate(`/admin/reports/implant-failure-reports/${value}`);
  };

  console.log("reports", reports);
  console.log("pages", pages);
  console.log("page", page);
  console.log("pageSize", pageSize);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.adminReportsImplantFailureReports")}</title>
        <meta
          name="description"
          content={t("pageDescription.adminReportsImplantFailureReports")}
        />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("implantForm.createImplantFailReport")}
        noBorder={true}
      >
        <CreateImplantFailReport
          close={closeSidePanelModal}
          setNewReportId={setNewReportId}
        />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("speaker.editSpeaker")}
        noBorder={false}
      >
        {/* <EditSpeaker speakerId={speakerId} /> */}
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("implantFailureReports.title")}
                onChange={onChangeSearchTitle}
                placeholder={t("implantFailureReports.search")}
                searchName={searchTitle}
                buttonLabel={t("implantFailureReports.createReport")}
                buttonHandler={openSidePanelModal}
                buttonDisabled={false}
                isFixedTitle={false}
              />
            </Box>

            <Table.Container
              columns={columns(translate, handleSortChange, deleteHandler)}
              data={reports}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/admin/reports/implant-failure-reports"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noImplantFailureReports")}
              noItemsDescription={t("noItems.noImplantFailureReportsText")}
              noItemsButtonLabel={t("noItems.noImplantFailureReportsButton")}
              onRowClick={handleRowClick}
              newReportId={newReportId}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ImplantFailureReportList;
