export const REPORT_IMPLANT_FAILURE_LIST_REQUEST =
  "REPORT_IMPLANT_FAILURE_LIST_REQUEST";
export const REPORT_IMPLANT_FAILURE_LIST_SUCCESS =
  "REPORT_IMPLANT_FAILURE_LIST_SUCCESS";
export const REPORT_IMPLANT_FAILURE_LIST_FAIL =
  "REPORT_IMPLANT_FAILURE_LIST_FAIL";
export const REPORT_IMPLANT_FAILURE_LIST_RESET =
  "REPORT_IMPLANT_FAILURE_LIST_RESET";

export const REPORT_IMPLANT_FAILURE_DETAILS_REQUEST =
  "REPORT_IMPLANT_FAILURE_DETAILS_REQUEST";
export const REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS =
  "REPORT_IMPLANT_FAILURE_DETAILS_SUCCESS";
export const REPORT_IMPLANT_FAILURE_DETAILS_FAIL =
  "REPORT_IMPLANT_FAILURE_DETAILS_FAIL";
export const REPORT_IMPLANT_FAILURE_DETAILS_RESET =
  "REPORT_IMPLANT_FAILURE_DETAILS_RESET";

export const REPORT_IMPLANT_FAILURE_CREATE_REQUEST =
  "REPORT_IMPLANT_FAILURE_CREATE_REQUEST";
export const REPORT_IMPLANT_FAILURE_CREATE_SUCCESS =
  "REPORT_IMPLANT_FAILURE_CREATE_SUCCESS";
export const REPORT_IMPLANT_FAILURE_CREATE_FAIL =
  "REPORT_IMPLANT_FAILURE_CREATE_FAIL";
export const REPORT_IMPLANT_FAILURE_CREATE_RESET =
  "REPORT_IMPLANT_FAILURE_CREATE_RESET";

export const REPORT_IMPLANT_FAILURE_UPDATE_REQUEST =
  "REPORT_IMPLANT_FAILURE_UPDATE_REQUEST";
export const REPORT_IMPLANT_FAILURE_UPDATE_SUCCESS =
  "REPORT_IMPLANT_FAILURE_UPDATE_SUCCESS";
export const REPORT_IMPLANT_FAILURE_UPDATE_FAIL =
  "REPORT_IMPLANT_FAILURE_UPDATE_FAIL";
export const REPORT_IMPLANT_FAILURE_UPDATE_RESET =
  "REPORT_IMPLANT_FAILURE_UPDATE_RESET";

export const REPORT_IMPLANT_FAILURE_DELETE_REQUEST =
  "REPORT_IMPLANT_FAILURE_DELETE_REQUEST";
export const REPORT_IMPLANT_FAILURE_DELETE_SUCCESS =
  "REPORT_IMPLANT_FAILURE_DELETE_SUCCESS";
export const REPORT_IMPLANT_FAILURE_DELETE_FAIL =
  "REPORT_IMPLANT_FAILURE_DELETE_FAIL";
export const REPORT_IMPLANT_FAILURE_DELETE_RESET =
  "REPORT_IMPLANT_FAILURE_DELETE_RESET";

export const REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST =
  "REPORT_IMPLANT_FAILURE_UPDATE_STATUS_REQUEST";
export const REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS =
  "REPORT_IMPLANT_FAILURE_UPDATE_STATUS_SUCCESS";
export const REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL =
  "REPORT_IMPLANT_FAILURE_UPDATE_STATUS_FAIL";
export const REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET =
  "REPORT_IMPLANT_FAILURE_UPDATE_STATUS_RESET";
