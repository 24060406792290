import Top from './TableTop';
import Container from './TableContainer';
import Cell from './TableCell';
import Text from './TableText';
import Button from './TableButton';
import Search from './TableSearch';
import Pagination from './TablePagination';
import DoubleText from './TableDoubleText';
import Image from './TableImage';
import Copy from './TableCopy';

const Table = {
  Container,
  Top,
  Cell,
  Text,
  Button,
  Search,
  Pagination,
  DoubleText,
  Image,
  Copy,
};

export default Table;
