import React, { useState, useEffect } from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import { textPrimary } from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from "react-redux";
import TooltipText from "../../../../../components/partials/TooltipText";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const Info = ({ data }) => {
  const { t } = useTranslation();

  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.address) {
      setPlace(data?.address[0]?.street + `, ` + data?.address[0]?.zip || "");
      setCity(data?.address[0]?.city + `, ` + data?.address[0]?.country || "");
    }
    setLocation(data.location);
  }, [data]);

  // // Define the titles for different slides
  // const slideTitles = [
  //   t("clientAccount.clientInformation"),
  //   t("clientAccount.representativeInformation"),
  // ];

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  // console.log("data prices", data.prices);

  const individualArray = [
    {
      label: "CompanyName",
      value: (
        <TooltipText
          icon="TbUser"
          label={t("form.name")}
          value={data.firstName + " " + data.lastName}
        />
      ),
    },
    {
      label: "Profession",
      value: (
        <TooltipText
          icon="TbUserStar"
          label={t("form.profession")}
          value={data.profession ? data.profession.name : ""}
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.companyName")}
          value={data.phone}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <TooltipText icon="TbMail" label={t("form.email")} value={data.email} />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={
            data && data.address && data.address.length > 0
              ? data.address[0].street
              : "-"
          }
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={
            data && data.address && data.address.length > 0
              ? data.address[0].city
              : "-"
          }
        />
      ),
    },
  ];

  const businessArray = [
    {
      label: "CompanyName",
      value: (
        <TooltipText
          icon="TbBriefcase"
          label={t("form.companyName")}
          value={data.companyName}
        />
      ),
    },
    {
      label: "CompanyCode",
      value: (
        <TooltipText
          icon="TbHash"
          label={t("form.companyCode")}
          value={
            data.vatCode
              ? `${data.companyCode} / ${data.vatCode}`
              : data.companyCode
          }
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.companyName")}
          value={data.phone}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <TooltipText icon="TbMail" label={t("form.email")} value={data.email} />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={
            data && data.address && data.address.length > 0
              ? data.address[0].street
              : "-"
          }
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={
            data && data.address && data.address.length > 0
              ? data.address[0].city
              : "-"
          }
        />
      ),
    },
  ];

  return (
    <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {/* {slideTitles[currentSlide]} */}
          {data && data.clientType && data.clientType === "individual"
            ? t("clientAccount.individualInfo")
            : t("clientAccount.companyInfo")}
        </Heading>
      </Flex>
      <Box>
        <Swiper
          navigation={{
            prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
            nextEl: ".custom-swiper-button-next", // Selector for your custom next button
          }}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Navigation]}
          onSlideChange={handleSlideChange}
          className="dataInfoSlider"
        >
          <SwiperSlide>
            {data && data.clientType && data.clientType === "individual" ? (
              <Box>
                {individualArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            ) : (
              <Box>
                {businessArray.map((item, index) => (
                  <Flex key={index} alignItems="center">
                    {item.value && (
                      <Flex p="0.35rem 0" alignItems="center">
                        {item.value}
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Box>
            )}
          </SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  );
};

export default Info;
