import React, { useState, useEffect } from "react";
import { Flex, Box, Button, Heading, Text, useToast } from "@chakra-ui/react";
import {
  dark,
  white,
  textPrimary,
  textSecondary,
  primary,
  textWhite,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import VerificationInputs from "../../../../components/input/VerificationInputs";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { shade } from "polished";

const VerifyAttendeeEmail = ({
  onVerify,
  verificationCode,
  setVerificationCode,
  isLoading,
  email,
}) => {
  // TRANSLATION
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // STATE
  const [timeLeft, setTimeLeft] = useState(180);

  // REDUX
  const dispatch = useDispatch();

  // HANDLERS

  // Countdown Logic
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // LOGS

  console.log("Verification Code: ", verificationCode);
  console.log("Email: ", email);

  return (
    <Box mt={0} w="100%" p={["3rem 1.5rem 0", "3rem 1.5rem 0", "0"]}>
      <Flex align="center" justify="center" w="100%" direction="column">
        <svg
          width="36"
          height="36"
          viewBox="0 0 54 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M52.0611 1.42951C53.1066 2.15412 53.6552 3.40666 53.4586 4.6592L46.8336 47.7217C46.6783 48.7258 46.0675 49.6057 45.1773 50.1026C44.2871 50.5994 43.2209 50.6615 42.2789 50.2682L29.8984 45.1235L22.8076 52.794C21.8863 53.7981 20.4371 54.1293 19.1638 53.6324C17.8906 53.1356 17.0625 51.9037 17.0625 50.5373V41.8834C17.0625 41.4694 17.2177 41.076 17.4972 40.7758L34.8464 21.8428C35.4468 21.1906 35.4261 20.1865 34.805 19.5654C34.1839 18.9444 33.1798 18.9029 32.5277 19.493L11.4726 38.1983L2.33219 33.6229C1.23493 33.0742 0.531019 31.977 0.499964 30.7555C0.46891 29.534 1.11071 28.3953 2.16657 27.7846L48.5416 1.28459C49.6492 0.653142 51.0156 0.715252 52.0611 1.42951Z"
            fill={isMobile ? white : primary}
          />
        </svg>

        <Heading
          fontSize={["1.5rem", "1.5rem", "1.75rem"]}
          fontWeight="700"
          color={[white, white, textPrimary]}
          m="1.5rem 0 0.5rem"
          maxW={["100%", "100%", "80%"]}
          textAlign="center"
        >
          {t("publicCourses.verifyAttendeeEmailTitle")}
        </Heading>

        <Text
          color={[textWhite, textWhite, textSecondary]}
          fontWeight="500"
          w="100%"
          fontSize="sm"
          maxW={["100%", "100%", "72%"]}
          textAlign="center"
        >
          {t("publicCourses.verifyAttendeeEmailText1")}
          <Text as="span" fontWeight="500" color={primary}>
            {email}
          </Text>
          . {t("publicCourses.verifyAttendeeEmailText2")}
        </Text>
      </Flex>

      <Text
        color={[textWhite, textWhite, textPrimary]}
        fontWeight="500"
        fontSize={["sm", "sm", "sm"]}
        textAlign="center"
        m={["0 0 1rem", "0 0 1rem", "2rem 0 1rem"]}
      >
        {t("publicCourses.enterVerificationCode")}
      </Text>

      <Flex
        w={["100%", "100%", "60%"]}
        p="0 2rem"
        align="center"
        justify="center"
        m="0 auto 1rem"
      >
        <VerificationInputs length={4} onComplete={setVerificationCode} />
      </Flex>

      {/* <FormControl mb={6}>
        <Input
          id="verificationCode"
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          bg={white}
          autoComplete="off"
          size="lg"
          h="3.5rem"
          fontSize="1rem"
          fontWeight="500"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          // placeholder={t("placeholder.email")}
          mb={4}
        />
      </FormControl> */}

      <Flex w="100%" align="center" justify="center">
        <Button
          onClick={onVerify}
          isLoading={isLoading}
          h="3.5rem"
          fontSize="md"
          borderRadius="10rem"
          bg={[white, white, primary]}
          m="0 auto"
          color={[primary, primary, white]}
          border={[
            `1px solid ${white}`,
            `1px solid ${white}`,
            `1px solid ${primary}`,
          ]}
          textAlign="center"
          //   w="100%"
          px="3rem"
          minW={["100%", "100%", "12rem"]}
          _hover={{
            bg: shade(0.3, primary),
            color: white,
            border: `1px solid ${shade(0.3, primary)}`,
          }}
          isDisabled={verificationCode.length < 4}
        >
          {t("publicCourses.verifyButton")}
        </Button>
      </Flex>

      <Flex direction="column" w="100%" m="1.5rem 0 0">
        <Text
          fontSize="xs"
          fontWeight="500"
          color={[textWhite, textWhite, textSecondary]}
          textAlign="center"
        >
          {t("publicCourses.expirationText")}

          <Text
            as="span"
            fontSize="xs"
            fontWeight="500"
            color={[white, white, textSecondary]}
            textAlign="center"
            mt={2}
            ml={1}
          >
            {formatTime(timeLeft)}
          </Text>
        </Text>
      </Flex>

      {/* <Agreement /> */}
    </Box>
  );
};

export default VerifyAttendeeEmail;
