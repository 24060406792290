import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Textarea,
  Stack,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";

const ConfirmCancelModalEvent = ({
  course,
  attendee,
  isOpen,
  onClose,
  confirmAction,
  cancelReason,
  setCancelReason,
  isTextArea,
}) => {
  const { t } = useTranslation();

  const cancelReasons = [
    {
      title: t("clientAccount.cancelEventReason1Title"),
      text: t("clientAccount.cancelEventReason1Text"),
    },
    {
      title: t("clientAccount.cancelEventReason2Title"),
      text: t("clientAccount.cancelEventReason2Text"),
    },
  ];

  useEffect(() => {
    setCancelReason("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p="0rem 0"
        maxW="600px"
        borderRadius="2rem"
      >
        <ModalHeader
          color={textPrimary}
          borderBottom={`1px solid ${newBorder}`}
          p="1.5rem 2rem"
          w="100%"
        >
          <Text fontSize="1.125rem" fontWeight="600">
            {t("clientAccount.cancelEventTitle")}
          </Text>
        </ModalHeader>
        <ModalCloseButton top="1.5rem" right="1.5rem" />
        <ModalBody
          p="1rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
          w="100%"
        >
          <Flex w="100%" p="1.5rem 0 0">
            <Flex align="center" direction="column" w="100%">
              <Flex w="100%" direction="column" m="0.5rem 0 0" p="0rem 3rem">
                <Flex
                  align="center"
                  w="100%"
                  justify="center"
                  textAlign="center"
                  m="0 auto"
                >
                  <Text
                    fontSize="1rem"
                    fontWeight="500"
                    color={textPrimary}
                    mr={1}
                  >
                    {t("clientAccount.cancelEventHeading")}{" "}
                    <Text as="span" color={primary}>
                      {attendee.firstName + " " + attendee.lastName}{" "}
                    </Text>
                    {t("clientAccount.fromCourse")}{" "}
                    <Text as="span" color={primary}>
                      {course.course.title}{" "}
                    </Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex p="1.5rem 0 " direction="column">
            <Text fontSize="sm" fontWeight="500" m="0 0 0.5rem">
              {t("implantFailureReports.cancelReportReason")}:{" "}
            </Text>

            {!isTextArea ? (
              <Flex gap="0.75rem" maxW="600px" m="0 auto">
                {cancelReasons.map((reason, index) => (
                  <Flex
                    align="center"
                    justify="center"
                    direction="column"
                    border={
                      cancelReason === reason.title
                        ? `1px solid ${primary}`
                        : `1px solid ${newBorder}`
                    }
                    borderRadius="1rem"
                    m="0 auto"
                    p="0.75rem 1.5rem"
                    w="100%"
                    onClick={() => setCancelReason(reason.title)}
                    transition="all 0.2s"
                    _hover={{
                      cursor: "pointer",
                      transform: "scale(1.02)",
                      shadow: "sm",
                    }}
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color={textPrimary}
                      whiteSpace="nowrap"
                    >
                      {reason.title}
                    </Text>
                    {/* <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                      {reason.text}
                    </Text> */}
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Textarea
                placeholder={t(
                  "implantFailureReports.cancelReportReasonPlaceholder"
                )}
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                borderRadius="1rem"
                p="1rem"
                m="0 auto"
                maxW="300px"
                minH="100px"
                resize="none"
              />
            )}
          </Flex>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderTop={`1px solid ${newBorder}`}
        >
          <Tooltip
            label={t("implantFailureReports.cancelReportReasonTooltip")}
            aria-label="A tooltip"
            p="0.5rem 1rem"
            borderRadius="1rem"
            isDisabled={cancelReason !== ""}
            placement="top"
            textAlign="center"
          >
            <Button
              colorScheme="red"
              size="lg"
              borderRadius="10rem"
              onClick={confirmAction}
              m={2}
              isDisabled={cancelReason === ""}
            >
              {t("common.yes")}
            </Button>
          </Tooltip>
          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            m={2}
          >
            {t("common.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCancelModalEvent;
