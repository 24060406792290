import React, { useState, useEffect } from "react";
import { HStack, Button, Flex } from "@chakra-ui/react";
import { white, primary } from "../../../../../utils/colors";
import FormControlDefault from "../../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../components/FormControlDatePickeris";
import TeethSelect from "../components/TeethSelect";
import FormControlReason from "../components/FormControlReason";
import { useTranslation } from "react-i18next";
import { shade } from "polished";

const Step2 = ({
  setActiveStep,
  tooth,
  setTooth,
  surgeonFirstName,
  setSurgeonFirstName,
  surgeonLastName,
  setSurgeonLastName,
  surgeryDate,
  setSurgeryDate,
  failureDate,
  setFailureDate,
  reason,
  setReason,
  reasonText,
  setReasonText,
  isValid,
  minW,
}) => {
  // GENERAL
  const { t } = useTranslation();

  // VALIDATION

  // LOGS
  // console.log("userInfo: ", userInfo);
  // console.log("tooth: ", tooth);
  // console.log("isToothValid: ", isToothValid);
  // console.log("isSurgeonFirstNameValid: ", isSurgeonFirstNameValid);

  // console.log("isSurgeonLastNameValid: ", isSurgeonLastNameValid);
  // console.log("isSurgeryDateValid: ", isSurgeryDateValid);
  // console.log("isFailureDateValid: ", isFailureDateValid);

  // console.log("isContinueDisabled: ", isContinueDisabled);

  // STATE
  const [showReasonText, setShowReasonText] = useState(false);

  // USE EFFECT
  useEffect(() => {
    if (reason === "Other") {
      setShowReasonText(true);
    } else {
      setShowReasonText(false);
      setReasonText("");
    }
    // eslint-disable-next-line
  }, [reason]);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <TeethSelect tooth={tooth} setTooth={setTooth} minW={minW} />
        {/* <FormControlTooth
          isRequired
          label={t("implantForm.tooth")}
          value={tooth}
          onChange={(selectedOption) => setTooth(selectedOption.value)}
          placeholder={t("implantForm.placeholderTooth")}
          w="100%"
        /> */}
        <HStack gap={4} w="100%">
          <FormControlDefault
            type="text"
            isRequired
            label={t("form.surgeonFirstname")}
            value={surgeonFirstName}
            placeholder={t("placeholder.surgeonFirstname")}
            onChange={(e) => {
              if (e.target.value) {
                setSurgeonFirstName(e.target.value);
              } else {
                setSurgeonFirstName(""); // or any default value you prefer
              }
            }}
            w="50%"
            // isDisabled={userInfo.firstName ? true : false}
          />

          <FormControlDefault
            type="text"
            isRequired
            label={t("form.surgeonLastname")}
            placeholder={t("placeholder.surgeonLastname")}
            value={surgeonLastName}
            onChange={(e) => {
              if (e.target.value) {
                setSurgeonLastName(e.target.value);
              } else {
                setSurgeonLastName(""); // or any default value you prefer
              }
            }}
            w="50%"
            // isDisabled={userInfo.lastName ? true : false}
          />
        </HStack>
        <FormControlReason
          isRequired
          setValue={setReason}
          label={t("form.reason")}
          value={reason}
          // onChange={handleFixtureChange}
          onChange={(selectedOption) => {
            if (selectedOption) {
              setReason(selectedOption.value);
            } else {
              setReason(null); // or any default value you prefer
            }
          }}
          // onChange={(selectedOption) => {
          //   setCore(selectedOption.value);
          // }}
          placeholder={t("placeholder.reason")}
          w="100%"
          errorMessage={t("formError.reasonRequired")}
          // isDisabled={!reason}
        />

        {showReasonText && (
          <FormControlDefault
            type="text"
            isRequired
            label={t("form.reasonText")}
            placeholder={t("placeholder.reasonText")}
            value={reasonText}
            onChange={(e) => {
              if (e.target.value) {
                setReasonText(e.target.value);
              } else {
                setReasonText(""); // or any default value you prefer
              }
            }}
            w="100%"
            // isDisabled={userInfo.lastName ? true : false}
          />
        )}

        <HStack gap={4} w="100%">
          <FormControlDatePicker
            type="date"
            isRequired
            //   minDate={surgeryDate}
            // isRequired
            maxDate={new Date()}
            label={t("implantForm.failureDate")}
            value={failureDate}
            // value="1970-01-01T00:00:34.357Z"
            placeholder={t("implantForm.placeholderFailureDate")}
            onChange={(newValue) => {
              setFailureDate(newValue);
            }}
            onOpen={() => {}}
            // onClose={() => setBirthDateTouched(true)}
            // isTouched={birthDateTouched}
            // isError={isBirthDateError}
            w="50%"
            errorMessage={t("implantForm.failureDateRequired")}
          />

          <FormControlDatePicker
            type="date"
            isRequired
            label={t("implantForm.surgeryDate")}
            value={surgeryDate}
            maxDate={failureDate}
            // value="1970-01-01T00:00:34.357Z"
            placeholder={t("implantForm.placeholderSurgeryDate")}
            onChange={(newValue) => {
              setSurgeryDate(newValue);
            }}
            onOpen={() => {}}
            // onClose={() => setBirthDateTouched(true)}
            // isTouched={birthDateTouched}
            // isError={isBirthDateError}
            w="50%"
            errorMessage={t("implantForm.surgeryDateRequired")}
          />
        </HStack>
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        <Button
          size="lg"
          fontSize="md"
          bg={primary}
          color={white}
          fontWeight="500"
          borderRadius="10rem"
          minW="8rem"
          onClick={() => {
            setActiveStep(3);
          }}
          _hover={{
            bg: shade(0.3, primary),
          }}
          // isDisabled={isContinueDisabled}
          isDisabled={!isValid}
        >
          {t("common.continue")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Step2;
