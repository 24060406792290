import React, { useState } from "react";
import {
  HStack,
  Button,
  Flex,
  Tooltip,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  FormErrorMessage,
  Icon,
  Text,
  Heading,
  Grid,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import {
  newDarkBlue,
  white,
  textPrimary,
  newBorder,
  buttonGreen,
  buttonGreenText,
  primary,
  buttonRed,
  buttonRedText,
  textSecondary,
} from "../../../../../utils/colors";
import FormControlFixtureExchange from "../components/FormControlFixtureExchange";
import FormControlDiameter from "../components/FormControlDiameter";
import FormControlLength from "../components/FormControlLength";
import FormControlCore from "../components/FormControlCore";

import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { shade } from "polished";

const Step4 = ({
  handleNewFixtureChange,
  fixtureOptions,
  newFixture,
  setNewFixture,
  newCore,
  setNewCore,
  newDiameter,
  setNewDiameter,
  newLength,
  setNewLength,
  setStepSuccess,

  fixture,
  core,
  diameter,
  length,
  refCode,
  lotNumber,
  handleSubmit,
  isValid,
  isStep0Valid,
  isStep1Valid,
  isStep2Valid,
  isStep3Valid,
  isStep4Valid,
}) => {
  // GENERAL
  const { t } = useTranslation();

  //VALIDATION

  const isCoreValid = newCore !== null && newCore !== "";
  const isDiameterValid = newDiameter !== "" && newDiameter !== null;
  const isLengthValid = newLength !== "" && newLength !== null;

  const isContinueDisabled = !isDiameterValid || !isLengthValid;

  // CHECK
  const isCoreNeeded = newFixture
    ? fixtureOptions.find((f) => f.value === newFixture).core
    : false;

  // LOGS
  // console.log("isCoreNeeded: ", isCoreNeeded);

  // console.log("newFixture: ", newFixture);
  // console.log("core: ", core);
  //   console.log("newCore: ", newCore);
  //   console.log("newDiameter: ", newDiameter);
  //   console.log("newLength: ", newLength);
  //   console.log("newRefCode: ", newRefCode);

  const allStepsValid =
    isStep0Valid &&
    isStep1Valid &&
    isStep2Valid &&
    isStep3Valid &&
    isStep4Valid;

  console.log("isStep0Valid", isStep0Valid);
  console.log("isStep1Valid", isStep1Valid);
  console.log("isStep2Valid", isStep2Valid);
  console.log("isStep3Valid", isStep3Valid);
  console.log("isStep4Valid", isStep4Valid);
  console.log("allStepsValid", allStepsValid);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          align="flex-start"
          justify="center"
          direction="column"
          m="0 0 0rem"
        >
          <FormLabel fontSize="sm" position="relative">
            {t("implantForm.currentImplant")}
          </FormLabel>
          <Button
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            bg={white}
            w="100%"
            h="48px"
            align="center"
            justify="flex-start"
            p="0 1rem"
            isDisabled
            _disabled={{
              opacity: 0.5,
              cursor: "not-allowed",
            }}
            _hover={{
              bg: white,
            }}
          >
            <Flex align="center" w="100%" textAlign="left">
              <Text fontSize="sm" color={textPrimary} fontWeight="500" w="100%">
                {t(`implants.${fixture}`)} -{" "}
                <Text as="span">
                  {core === null ? null : (
                    <>
                      {core === "regular" ||
                      core === "special" ||
                      core === "deep" ? (
                        <Text
                          as="span"
                          fontSize="sm"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          {" "}
                          ({t(`core.${core}`)})
                        </Text>
                      ) : (
                        <Text
                          as="span"
                          fontSize="sm"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          (C{core})
                        </Text>
                      )}
                    </>
                  )}
                </Text>{" "}
                Ø{diameter}mm/L{length}{" "}
              </Text>
            </Flex>
          </Button>

          <Flex w="100%" m="1.5rem auto" align="center" justify="center">
            <Icon
              as={FaExchangeAlt}
              fontSize="0.875rem"
              color={textSecondary}
              transform={"rotate(90deg)"}
              mr={2}
            />
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              {t("implantForm.exchangingTo")}
            </Text>
          </Flex>
        </Flex>

        <Flex w="100%" align="center" justify="space-between" gap="1rem">
          <FormControlFixtureExchange
            fixture={fixture}
            isRequired
            options={fixtureOptions}
            label={t("implantForm.exchangeImplant")}
            value={newFixture}
            // onChange={(selectedOption) => {
            //   setNewFixture(selectedOption.value);
            // }}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewFixture(selectedOption.value);
                setNewCore(null);
                setNewDiameter(null);
                setNewLength(null);
              } else {
                setNewFixture(null); // or any default value you prefer
                setNewCore(null);
                setNewDiameter(null);
                setNewLength(null);
              }
            }}
            placeholder={t("implantForm.placeholderFixture")}
            w={isCoreNeeded ? "70%" : "100%"}
          />
          {isCoreNeeded && (
            <FormControlCore
              isRequired
              fixture={newFixture}
              core={newCore}
              setValue={setNewCore}
              label={t("form.core")}
              value={newCore}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setNewCore(selectedOption.value);
                  setNewDiameter(null);
                  setNewLength(null);
                } else {
                  setNewCore(null); // or any default value you prefer
                  setNewDiameter(null);
                  setNewLength(null);
                }
              }}
              //   onChange={(selectedOption) => {
              //     setNewCore(selectedOption.value);
              //   }}
              placeholder={t("placeholder.core")}
              w="30%"
              errorMessage={t("formError.coreRequired")}
              isDisabled={!newFixture}
            />
          )}
        </Flex>

        {/* STEP 1 START */}
        <HStack gap={4} w="100%">
          <FormControlDiameter
            isRequired
            fixture={newFixture}
            setFixture={setNewFixture}
            core={newCore}
            setValue={setNewDiameter}
            label={t("form.diameter")}
            value={newDiameter}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewDiameter(selectedOption.value);
                setNewLength(null);
              } else {
                setNewDiameter(null); // or any default value you prefer
                setNewLength(null);
              }
            }}
            placeholder={t("placeholder.diameter")}
            w="100%"
            errorMessage={t("formError.diameterRequired")}
          />

          <FormControlLength
            isRequired
            fixture={newFixture}
            core={newCore}
            diameter={newDiameter}
            setValue={setNewLength}
            label={t("form.length")}
            value={newLength}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setNewLength(selectedOption.value);
              } else {
                setNewLength(null); // or any default value you prefer
              }
            }}
            //   onChange={(selectedOption) => setNewLength(selectedOption.value)}
            placeholder={t("placeholder.length")}
            w="100%"
            errorMessage={t("formError.lengthRequired")}
            isDisabled={!isDiameterValid}
          />
        </HStack>

        {/* STEP 1 END */}
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        <Tooltip
          label={
            <Flex direction="column" align="center">
              {/* {!newFixture && (
                <Text fontWeight="500">{t("formError.selectFixture")}</Text>
              )}

              {newFixture && !isDiameterValid && (
                <Text fontWeight="500">{t("formError.selectDiameter")}</Text>
              )}
              {newFixture && isDiameterValid && !isLengthValid && (
                <Text fontWeight="500">{t("formError.selectLength")}</Text>
              )}
              {newFixture && isDiameterValid && isLengthValid && (
                <Text fontWeight="500">{t("formError.selectLotNumber")}</Text>
              )}
              {newFixture &&
                isCoreValid &&
                isDiameterValid &&
                isLengthValid && (
                  <Text fontWeight="500">{t("formError.selectLotNumber")}</Text>
                )} */}

              {!isStep0Valid && (
                <Text fontWeight="500">{t("formError.step0")}</Text>
              )}

              {!isStep1Valid && (
                <Text fontWeight="500">{t("formError.step1")}</Text>
              )}

              {!isStep2Valid && (
                <Text fontWeight="500">{t("formError.step2")}</Text>
              )}

              {!isStep3Valid && (
                <Text fontWeight="500">{t("formError.step3")}</Text>
              )}

              {!isValid && <Text fontWeight="500">{t("formError.step4")}</Text>}
            </Flex>
          }
          bg={buttonRed}
          color={buttonRedText}
          placement="top"
          p="0.5rem 1rem"
          borderRadius="1rem"
          fontSize="xs"
          isDisabled={allStepsValid}
        >
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={handleSubmit}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!allStepsValid}
          >
            {t("implantForm.adminSubmit")}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Step4;
