import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { white, primary, newBorder } from "../../../utils/colors";
import { divTransition } from "../../../assets/animations/divTransition";
import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { shade } from "polished";

const Cta = ({ userInfo }) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Flex
      as={motion.div}
      ref={ref}
      variants={divTransition}
      initial="hidden"
      animate={control}
      w="100%"
      p="4rem 0"
      position="relative"
      bg={white}
    >
      <Flex
        direction="column"
        bg="#7138FB"
        p={["6rem 1.5rem", "6rem 1.5rem", "8rem 20%"]}
        w="100%"
        position="relative"
        minH="600px"
        borderRadius="0px"
      >
        <Flex
          direction="column"
          maxW={["100%", "100%", "1200px"]}
          w="100%"
          m="0 auto"
          align="center"
          justify="center"
          textAlign="center"
        >
          <Heading fontSize={["2rem", "2rem", "3rem"]} color={white}>
            {t("landingMegagen.ctaTitle")}
          </Heading>
          <Text
            fontSize="1.125rem"
            color={white}
            m="1rem auto 0"
            maxW={["100%", "100%", "1200px"]}
          >
            {t("landingMegagen.ctaText")}
          </Text>
          <Button
            size="lg"
            bg={white}
            color="#7138FB"
            borderRadius="10rem"
            px="3rem"
            fontSize="md"
            mt="2rem"
            _hover={{ bg: shade(0.3, primary), color: white }}
            onClick={
              userInfo
                ? userInfo.role === "employee"
                  ? () => navigate("/admin/dashboard")
                  : () => navigate("/account")
                : () => navigate("/register")
            }
          >
            {userInfo
              ? userInfo.role === "employee"
                ? t("landingMegagen.menuAdmin")
                : t("landingMegagen.menuMyPortal")
              : t("landingMegagen.registerButton")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Cta;
