import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Stack,
  Text,
  Heading,
  Box,
  Button,
  Icon,
  Spinner,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  primary,
  newBorder,
  backgroundLight,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../components/partials/TooltipText";
import {
  TbMail,
  TbPhone,
  TbPhotoOff,
  TbSettings,
  TbUsb,
  TbUser,
} from "react-icons/tb";
import { motion } from "framer-motion";
import { shade } from "polished";

const UserDetails = ({ loading, client }) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const infoArray = [
    {
      label: "CompanyName",
      value: (
        <TooltipText
          icon="TbUser"
          label={t("form.name")}
          value={client.firstName + " " + client.lastName}
        />
      ),
    },
    {
      label: "Profession",
      value: (
        <TooltipText
          icon="TbUserStar"
          label={t("form.profession")}
          value={client.profession ? client.profession.name : ""}
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.companyName")}
          value={client.phone}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <TooltipText
          icon="TbMail"
          label={t("form.email")}
          value={client.email}
        />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={
            client && client.address && client.address.length > 0
              ? client.address[0].street
              : "-"
          }
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={
            client && client.address && client.address.length > 0
              ? client.address[0].city
              : "-"
          }
        />
      ),
    },
  ];

  const businessArray = [
    {
      label: "CompanyName",
      value: (
        <TooltipText
          icon="TbBriefcase"
          label={t("form.companyName")}
          value={client.companyName}
        />
      ),
    },
    {
      label: "CompanyCode",
      value: (
        <TooltipText
          icon="TbHash"
          label={t("form.companyCode")}
          value={
            client.vatCode
              ? `${client.companyCode} / ${client.vatCode}`
              : client.companyCode
          }
        />
      ),
    },
    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.companyName")}
          value={client.phone}
        />
      ),
    },
    {
      label: "Email",
      value: (
        <TooltipText
          icon="TbMail"
          label={t("form.email")}
          value={client.email}
        />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={
            client && client.address && client.address.length > 0
              ? client.address[0].street
              : "-"
          }
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={
            client && client.address && client.address.length > 0
              ? client.address[0].city
              : "-"
          }
        />
      ),
    },
  ];

  return (
    <Flex
      w={["100%", "100%", "300px  "]}
      minW={["100%", "100%", "300px  "]}
      maxW={["100%", "100%", "300px"]}
      direction="column"
      bg={white}
      borderRadius="2rem"
      p={["1.5rem 1.5rem", "1.5rem 1.5rem", "1.5rem 2rem"]}
    >
      <Flex w="100%" direction="column">
        <Flex w="100%">
          <Flex
            w="100%"
            bg={backgroundLight}
            border={`0px solid ${newBorder}`}
            borderRadius="2rem"
            align="center"
            justify="center"
            p={["1.5rem", "1.5rem", "3rem 0"]}
          >
            {client && client.image ? (
              <Flex
                w={["100%", "100%", "8rem"]}
                h={["100%", "100%", "8rem"]}
                borderRadius="1.75rem"
                overflow="hidden"
              >
                <Image
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  src={client.image}
                  alt={client.firstName}
                  border={`1px solid ${newBorder}`}
                />
              </Flex>
            ) : (
              <Flex
                w={["100%", "100%", "8rem"]}
                h={["100%", "100%", "8rem"]}
                minH={["200px", "200px", "initial"]}
                bg={backgroundLight}
                align="center"
                justify="center"
                borderRadius="1rem"
                overflow="hidden"
                border={`0px solid ${newBorder}`}
              >
                <Icon
                  as={TbPhotoOff}
                  fontSize="1.75rem"
                  fontWeight="500"
                  color={textSecondary}
                />
              </Flex>
            )}
          </Flex>
        </Flex>

        {!loading ? (
          <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {client &&
            client.clientType &&
            client.clientType === "individual" ? (
              <Flex
                direction="column"
                justify="center"
                textAlign="center"
                p="1.5rem 0"
              >
                <Heading
                  fontSize="1.125rem"
                  fontWeight="600"
                  color={textPrimary}
                >
                  {client.firstName} {client.lastName}
                </Heading>
                <Text fontSize="sm" fontWeight="400" color={textSecondary}>
                  {t(`implantForm.${client.clientType}`)}
                </Text>
                <Box p="1.5rem 0rem">
                  {infoArray.map((item, index) => (
                    <Flex key={index} alignItems="center">
                      {item.value && (
                        <Flex p="0.35rem 0" alignItems="center">
                          {item.value}
                        </Flex>
                      )}
                    </Flex>
                  ))}
                </Box>
              </Flex>
            ) : (
              <Stack gap={1} justify="center" textAlign="center" p="1.5rem 0 0">
                <Heading
                  fontSize="1.125rem"
                  fontWeight="600"
                  color={textPrimary}
                >
                  {client.companyName}
                </Heading>
                <Text fontSize="sm" fontWeight="400" color={textSecondary}>
                  {t(`implantForm.${client.clientType}`)}
                </Text>
                <Box p="1.5rem 0rem">
                  {businessArray.map((item, index) => (
                    <Flex key={index} alignItems="center">
                      {item.value && (
                        <Flex p="0.35rem 0" alignItems="center">
                          {item.value}
                        </Flex>
                      )}
                    </Flex>
                  ))}
                </Box>
              </Stack>
            )}

            <Flex w="100%" direction="column" p="0 0 3rem">
              <Text
                fontSize="xs"
                fontWeight="500"
                color={textSecondary}
                m="0 0 0.5rem"
              >
                {t("clientAccount.megagenEmployee")}
              </Text>
              <Flex
                gap="0.5rem"
                align="flex-start"
                direction="column"
                justify="center"
              >
                <Tooltip
                  label={
                    <Flex direction="column">
                      <Flex align="center">
                        <Icon
                          as={TbUser}
                          fontSize="sm"
                          color={shade(0.3, white)}
                          mr="0.5rem"
                        />
                        <Text fontSize="sm" fontWeight="500" color={white}>
                          {(client &&
                            client.employee &&
                            client.employee.firstName &&
                            client.employee.lastName &&
                            client.employee.firstName +
                              " " +
                              client.employee.lastName) ||
                            "-"}
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <Icon
                          as={TbPhone}
                          fontSize="sm"
                          color={shade(0.3, white)}
                          mr="0.5rem"
                        />
                        <Text fontSize="sm" fontWeight="500" color={white}>
                          {(client &&
                            client.employee &&
                            client.employee.phone &&
                            client.employee.phone) ||
                            "-"}
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <Icon
                          as={TbMail}
                          fontSize="sm"
                          color={shade(0.3, white)}
                          mr="0.5rem"
                        />
                        <Text fontSize="sm" fontWeight="500" color={white}>
                          {(client &&
                            client.employee &&
                            client.employee.email &&
                            client.employee.email) ||
                            "-"}
                        </Text>
                      </Flex>
                    </Flex>
                  }
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  isDisabled={client && !client.employee}
                >
                  <Flex align="center">
                    <Icon
                      as={TbUser}
                      fontSize="sm"
                      color={textSecondary}
                      mr="0.5rem"
                    />
                    <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                      {(client &&
                        client.employee &&
                        client.employee.firstName &&
                        client.employee.lastName &&
                        client.employee.firstName +
                          " " +
                          client.employee.lastName) ||
                        "-"}
                    </Text>
                  </Flex>
                </Tooltip>
                {/* <TooltipText
                  icon="TbUser"
                  label={t("form.name")}
                  value={
                    client.employee.firstName +
                      " " +
                      client.employee.lastName || "-"
                  }
                /> */}
              </Flex>
            </Flex>

            <Button
              size="lg"
              w="100%"
              variant="outline"
              color={textSecondary}
              fontSize="sm"
              fontWeight="500"
              borderRadius="10rem"
              // onClick={handleSettingsTabChange}
              onClick={() => navigate("/account/settings")}
              border={`1px solid ${newBorder}`}
              m="0 0 0rem"
              transition="all 0.2s"
              position="relative"
              _hover={{
                color: textPrimary,
                border: `1px solid ${textSecondary}`,
                bg: white,
              }}
            >
              <Icon as={TbSettings} mr="0.5rem" fontSize="1.125rem" />
              {t("clientAccount.mySettings")}
            </Button>
          </Box>
        ) : (
          <Flex
            w="100%"
            align="center"
            justify="center"
            p="3rem 0"
            bg={white}
            borderRadius="1rem"
            direction="column"
            border={`0px solid ${newBorder}`}
          >
            <Spinner size="lg" color={primary} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default UserDetails;
