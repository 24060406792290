import React, { useState } from "react";
import { HStack, Button, Flex, Tooltip } from "@chakra-ui/react";
import {
  white,
  primary,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";

import FormControlImageUpload from "../components/FormControlImageUpload";

import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { shade } from "polished";

const Step3 = ({
  setActiveStep,
  userInfo,
  beforeXrayImage,
  setBeforeXrayImage,
  afterXrayImage,
  setAfterXrayImage,
  setIsStep3Touched,
  isValid,
}) => {
  // GENERAL
  const { t } = useTranslation();

  // LOGS
  // console.log("userInfo: ", userInfo);

  // RENDER
  return (
    <Flex w="100%" maxW="600px" direction="column">
      <Flex w="100%" direction="column">
        <Flex
          w="100%"
          align="center"
          justify="center"
          direction="column"
          pb="0.5rem"
        >
          <HStack gap={4} w="100%" mt={0}>
            <FormControlImageUpload
              isRequired
              id="beforeXrayImage"
              label={t("implantForm.beforeXrayImage")}
              image={beforeXrayImage}
              setImage={setBeforeXrayImage}
              onChange={(e) => {
                setBeforeXrayImage(e.target.value);
              }}
              w="100%"
              labelTitle={t("implantForm.beforeXrayImageTitle")}
            />

            <FormControlImageUpload
              isRequired
              id="afterXrayImage"
              label={t("implantForm.afterXrayImage")}
              image={afterXrayImage}
              setImage={setAfterXrayImage}
              onChange={(e) => {
                setAfterXrayImage(e.target.value);
              }}
              w="100%"
              labelTitle={t("implantForm.afterXrayImageTitle")}
            />
          </HStack>
        </Flex>
      </Flex>

      <Flex w="100%" justify="flex-end" m="1.5rem 0 0">
        <Tooltip
          p="0.5rem 1rem"
          bg={buttonRed}
          color={buttonRedText}
          label={t("formError.xrayError")}
          isDisabled={isValid}
          borderRadius="1rem"
          fontSize="xs"
          placement="top"
        >
          <Button
            size="lg"
            fontSize="md"
            bg={primary}
            color={white}
            fontWeight="500"
            borderRadius="10rem"
            minW="8rem"
            onClick={() => {
              setActiveStep(4);
              setIsStep3Touched(true);
            }}
            _hover={{
              bg: shade(0.3, primary),
            }}
            isDisabled={!isValid}
          >
            {t("common.continue")}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Step3;
