import React, { useState } from 'react';
import { FormControl, FormLabel, Text, Input } from '@chakra-ui/react';
import {
  primary,
  newBorder,
  buttonRed,
  buttonRedText,
} from '../../utils/colors';

const InputField = ({
  label,
  value,
  onChange,
  placeholder,
  isDate,
  isEmail,
  requiredLength,
  requiredText,
  allowBorderChange,
  isInvalid,
  errorMessage,
  isDateTime,
}) => {
  const [touched, setTouched] = useState(false); // Track whether the input has been touched

  const isValueValid = allowBorderChange
    ? value.length >= requiredLength || !touched
    : true;

  const handleInputFocus = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  return (
    <FormControl mb={isDate ? 4 : 4}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Input
        type={
          isDate
            ? 'date'
            : isEmail
            ? 'email'
            : isDateTime
            ? 'datetime-local'
            : 'text'
        }
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleInputFocus}
        onBlur={() => setTouched(true)}
        size="lg"
        isInvalid={isInvalid}
        fontSize="1rem"
        fontWeight="500"
        borderRadius="0.75rem"
        _focusVisible={{
          zIndex: '1',
          borderColor: primary,
          boxShadow: `0 0 0 1px ${primary}`,
        }}
        border={
          allowBorderChange && !touched
            ? `1px solid ${newBorder}`
            : isValueValid
            ? `1px solid ${newBorder}`
            : `1px solid ${buttonRed}`
        }
      />
      {/* {!isValueValid && touched && (
        <Text mt={1} fontSize="sm" color={buttonRedText}>
          {requiredText}
        </Text>
      )} */}
      {/* Render the error message if the input is invalid */}
      {/* {isInvalid && (
        <Text mt={1} fontSize="sm" color={buttonRedText}>
          {errorMessage}
        </Text>
      )} */}
    </FormControl>
  );
};

export default InputField;
