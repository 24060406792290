import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Spinner,
  Button,
  Heading,
  Text,
  Stack,
  useToast,
} from "@chakra-ui/react";
// import { verifyEmailWithToken } from "../../actions/userActions";
import { resetPassword } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Header from "../landing/layout/Header";
import RegisterPromo from "./partials/RegisterPromo";
import {
  purple,
  white,
  textPrimary,
  textSecondary,
  textWhite,
  primary,
  newBorder,
} from "../../utils/colors";
import { shade } from "polished";
import { PasswordField } from "../../components/partials/PasswordField";
import { logout } from "../../actions/userActions";
import { USER_RESET_PASSWORD_RESET } from "../../constants/userConstants";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  // Extract token from URL query
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { loading, success, error } = userResetPassword;

  // HANDLERS

  const hanldeResetPassword = () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: t("toasts.passwordsDontMatch"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      dispatch(resetPassword(token, newPassword));
    }
  };

  // USE EFFECT
  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(logout());
    }
  }, []);

  useEffect(() => {
    if (token) {
      console.log("token exists");
      //   dispatch(verifyEmailWithToken(token));
    } else {
      //   navigate("/register");
      toast({
        title: "Token error",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [token]);

  useEffect(() => {
    if (success) {
      toast({
        title: t("toasts.resetPasswordSuccess"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setShowSuccess(true);
    } else if (error) {
      toast({
        title: t("toasts.resetPasswordError"),
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setShowSuccess(false);
    }

    dispatch({ type: USER_RESET_PASSWORD_RESET });
  }, [success, error]);

  useEffect(() => {
    if (userInfo) {
      navigate("/account");
    }
  }, [userInfo]);

  // LOGS
  //   console.log("token", token);

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        minH="100vh"
        align="stretch"
        justify="flex-start"
        bg={white}
        p={["0px 0 0", "0rem 0 0", "0rem 0 0"]}
      >
        <Header
          bg="transparent"
          isRegister
          showPromo={true}
          showForm={true}
          isVerify
        />

        <RegisterPromo setShowPromo={true} setShowForm={true} />

        <Flex
          w={["100%", "100%", "70%"]}
          h={["100%", "100%", "100%"]}
          align="flex-start"
          justify="center"
          ml={["0%", "0%", "30%"]}
          direction="column"
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          p={["80px 0 0", "80px 0 0", "100px 0 0 100px"]}
          // display={["none", "none", "flex"]}
          // overflow={["auto", "auto", "initial"]}
          bg={[purple, purple, "initial"]}
          position={["fixed", "fixed", "relative"]}
          top={["0", "0", "initial"]}
          minH={["100vh", "100vh", "80vh"]}
        >
          {!loading ? (
            <>
              <Stack gap="0.5rem" p={["1.5rem 1.5rem", "0rem", "0rem"]}>
                <Heading
                  fontSize="1.875rem"
                  fontWeight="700"
                  color={[white, white, textPrimary]}
                >
                  {showSuccess
                    ? t("landingMegagen.resetPasswordTitleSuccess")
                    : t("landingMegagen.resetPasswordTitle")}
                </Heading>

                <Text
                  fontSize="1rem"
                  color={[textWhite, textWhite, textSecondary]}
                  fontWeight="500"
                >
                  {showSuccess
                    ? t("landingMegagen.resetPasswordTextSuccess")
                    : t("landingMegagen.resetPasswordText")}
                </Text>

                <Flex
                  mt={[0, 0, 12]}
                  w="100%"
                  bg={[purple, purple, white]}
                  h="100%"
                  p={["1.5rem 1.5rem 6rem", "1.5rem 1.5rem 6rem", "0rem"]}
                  direction="column"
                  minW="500px"
                  maxW="500px"
                >
                  {showSuccess ? (
                    <Flex w="100%" align="center">
                      <Button
                        variant="outline"
                        h="3.5rem"
                        borderRadius="100rem"
                        bg={primary}
                        color={white}
                        fontSize="md"
                        fontWeight="500"
                        align="center"
                        justifyContent="center"
                        minW="160px"
                        whiteSpace="nowrap"
                        isLoading={loading}
                        onClick={() => navigate("/login")}
                        isDisabled={!token}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                      >
                        {t("common.signin")}
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      <PasswordField
                        bg={white}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        size="lg"
                        h="3.5rem"
                        fontSize="0.938rem"
                        fontWeight="500"
                        color={textPrimary}
                        border={`1px solid ${newBorder}`}
                        borderRadius="0.75rem"
                        placeholder={t("placeholder.newPassword")}
                        label={t("form.newPassword")}
                        mb="1rem"
                      />

                      <PasswordField
                        bg={white}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        size="lg"
                        h="3.5rem"
                        fontSize="0.938rem"
                        fontWeight="500"
                        color={textPrimary}
                        border={`1px solid ${newBorder}`}
                        borderRadius="0.75rem"
                        placeholder={t("placeholder.confirmPassword")}
                        label={t("form.confirmPassword")}
                      />
                    </>
                  )}
                </Flex>

                {!showSuccess && (
                  <Flex
                    w="100%"
                    m="2rem auto"
                    align="center"
                    justify="flex-end"
                  >
                    <Button
                      variant="outline"
                      h="3.5rem"
                      borderRadius="100rem"
                      bg={primary}
                      color={white}
                      fontSize="md"
                      fontWeight="500"
                      align="center"
                      justifyContent="center"
                      minW="160px"
                      whiteSpace="nowrap"
                      isLoading={loading}
                      onClick={hanldeResetPassword}
                      isDisabled={!token}
                      _hover={{
                        bg: shade(0.3, primary),
                      }}
                    >
                      {t("landingMegagen.resetPasswordButton")}
                    </Button>
                  </Flex>
                )}
              </Stack>
            </>
          ) : (
            <Flex
              w="100%"
              maxW="600px"
              h="100%"
              align="center"
              justify="center"
            >
              <Spinner size="xl" color={primary} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ResetPassword;
