export const GET_NEW_CLIENTS_REQUEST = "GET_NEW_CLIENTS_REQUEST";
export const GET_NEW_CLIENTS_SUCCESS = "GET_NEW_CLIENTS_SUCCESS";
export const GET_NEW_CLIENTS_FAIL = "GET_NEW_CLIENTS_FAIL";
export const GET_NEW_CLIENTS_RESET = "GET_NEW_CLIENTS_RESET";

export const GET_NEW_CLIENTS_TOP_REQUEST = "GET_NEW_CLIENTS_TOP_REQUEST";
export const GET_NEW_CLIENTS_TOP_SUCCESS = "GET_NEW_CLIENTS_TOP_SUCCESS";
export const GET_NEW_CLIENTS_TOP_FAIL = "GET_NEW_CLIENTS_TOP_FAIL";
export const GET_NEW_CLIENTS_TOP_RESET = "GET_NEW_CLIENTS_TOP_RESET";

export const GET_REPORT_STATUS_COUNTS_REQUEST =
  "GET_REPORT_STATUS_COUNTS_REQUEST";
export const GET_REPORT_STATUS_COUNTS_SUCCESS =
  "GET_REPORT_STATUS_COUNTS_SUCCESS";
export const GET_REPORT_STATUS_COUNTS_FAIL = "GET_REPORT_STATUS_COUNTS_FAIL";
export const GET_REPORT_STATUS_COUNTS_RESET = "GET_REPORT_STATUS_COUNTS_RESET";

export const GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST =
  "GET_COURSES_ATTENDEES_STATUS_COUNTS_REQUEST";
export const GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS =
  "GET_COURSES_ATTENDEES_STATUS_COUNTS_SUCCESS";
export const GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL =
  "GET_COURSES_ATTENDEES_STATUS_COUNTS_FAIL";
export const GET_COURSES_ATTENDEES_STATUS_COUNTS_RESET =
  "GET_COURSES_ATTENDEES_STATUS_COUNTS_RESET";

export const GET_CLIENTS_STATUS_COUNTS_REQUEST =
  "GET_CLIENTS_STATUS_COUNTS_REQUEST";
export const GET_CLIENTS_STATUS_COUNTS_SUCCESS =
  "GET_CLIENTS_STATUS_COUNTS_SUCCESS";
export const GET_CLIENTS_STATUS_COUNTS_FAIL = "GET_CLIENTS_STATUS_COUNTS_FAIL";
export const GET_CLIENTS_STATUS_COUNTS_RESET =
  "GET_CLIENTS_STATUS_COUNTS_RESET";

export const GET_TOP_REPORTS_BY_FIXTURE_REQUEST =
  "GET_TOP_REPORTS_BY_FIXTURE_REQUEST";
export const GET_TOP_REPORTS_BY_FIXTURE_SUCCESS =
  "GET_TOP_REPORTS_BY_FIXTURE_SUCCESS";
export const GET_TOP_REPORTS_BY_FIXTURE_FAIL =
  "GET_TOP_REPORTS_BY_FIXTURE_FAIL";
export const GET_TOP_REPORTS_BY_FIXTURE_RESET =
  "GET_TOP_REPORTS_BY_FIXTURE_RESET";

export const GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST =
  "GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_REQUEST";
export const GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS =
  "GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_SUCCESS";
export const GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL =
  "GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_FAIL";
export const GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_RESET =
  "GET_EMPLOYEE_CLIENTS_LATEST_REPORTS_RESET";
